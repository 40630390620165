import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  DashboardProvider,
  CampaignMetrics,
} from "@contexts/DashboardProvider";
import { UserContext } from "@contexts/index";
import MultiPageLayout from "@layouts/MultiPageLayout";
import { CampaignContent, ICampaign } from "types/types";
import {
  DateRange,
  dateRangeOptions,
  fetchCampaignData,
  getAnalyticsData,
  sortMembershipsByCurrentBrand,
} from "@utils/dashboardUtils";
import { changeMembership } from "@api/user";

import { DashboardHeader } from "./components/DashboardHeader";
import { Role } from "@constants/roles";
import useAuth from "@hooks/useAuth";
import Profile from "@pages/profile";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const dashboardNavItems = [
  {
    label: "Overview",
    value: "overview",
    link: "/home",
    matchPattern: "/home",
  },
  {
    label: "Content",
    value: "content",
    link: "/content",
    matchPattern: "/content",
  },
];

export default function DashboardWrap() {
  const location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [dateRange, setDateRange] = useState<DateRange>("90");
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [metrics, setMetrics] = useState<CampaignMetrics>({
    campaigns: 0,
    engagementRate: 0,
    impressions: 0,
    posts: 0,
    influencers: 0,
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [campaignContent, setCampaignContent] = useState<CampaignContent[]>([]);
  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const brandDropdownRef = useRef<HTMLDivElement>(null);

  const { trackEvent } = useContext(MixpanelContext);

  const fetchAndProcessData = useCallback(async () => {
    setIsLoading(true);
    setIsPageLoading(true);
    try {
      const { campaigns: newCampaigns, content: newContent } =
        await fetchCampaignData(user.brand_id, dateRange);
      setCampaigns(newCampaigns);
      setCampaignContent(newContent);
    } catch (error) {
      console.error("Error in fetchAndProcessData:", error);
    } finally {
      setIsLoading(false);
      setIsPageLoading(false);
    }
  }, [dateRange, user.brand_id]);

  useEffect(() => {
    document.title = "Home";
  }, []);

  useEffect(() => {
    if (user?.brand_id) {
      fetchAndProcessData();
    }
  }, [dateRange, fetchAndProcessData, user.brand_id]);

  // Handle click outside for date range dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Handle click outside for brand dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        brandDropdownRef.current &&
        !brandDropdownRef.current.contains(event.target as Node)
      ) {
        setIsBrandDropdownOpen(false);
      }
    };

    if (isBrandDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isBrandDropdownOpen]);

  const handleDateRangeChange = (newRange: DateRange) => {
    // Track date range change
    const currentRangeLabel =
      dateRangeOptions.find((option) => option.value === dateRange)?.label ||
      "";
    const newRangeLabel =
      dateRangeOptions.find((option) => option.value === newRange)?.label || "";

    trackEvent(EVENTS.DASHBOARD.DATE_RANGE_CHANGED, {
      previous_range: currentRangeLabel,
      new_range: newRangeLabel,
      previous_days: parseInt(dateRange),
      new_days: parseInt(newRange),
    });

    setDateRange(newRange);
    setIsDropdownOpen(false);
  };

  const handleBrandChange = async (membership) => {
    try {
      // Store previous brand info for tracking
      const previousBrandId = user.brand_id;
      const previousBrandName = user.brand_name;

      const result = await changeMembership({ id: membership.id });
      if (result.data.brand_id === membership.brand_id) {
        // Track successful brand switch
        trackEvent(EVENTS.DASHBOARD.BRAND_SWITCHED, {
          previous_brand_id: previousBrandId,
          previous_brand_name: previousBrandName,
          new_brand_id: membership.brand_id,
          new_brand_name: membership.name,
          user_role: user.role,
        });

        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        setIsBrandDropdownOpen(false);

        window.location.reload();
      } else {
        console.error("Failed to change membership");

        // Track error
        trackEvent(EVENTS.ERROR.API_ERROR, {
          error_type: "brand_switch_failure",
          error_message: "Failed to change membership",
          target_brand_id: membership.brand_id,
        });
      }
    } catch (error) {
      console.error("Error changing membership:", error);

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "brand_switch_error",
        error_message:
          error?.response?.data?.message || "Error changing membership",
        target_brand_id: membership.brand_id,
      });
    }
  };

  const handleContentModalClick = (content) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  const currentMembership = user.memberships.find(
    (m) => m.brand_id === user.brand_id
  );

  const sortedMemberships = sortMembershipsByCurrentBrand(
    user.memberships,
    user.brand_id
  );

  const analyticsData = getAnalyticsData(metrics, dateRange);

  const customHeader = (
    <DashboardHeader
      user={user}
      dateRange={dateRange}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      dropdownRef={dropdownRef}
      handleDateRangeChange={handleDateRangeChange}
      isBrandDropdownOpen={isBrandDropdownOpen}
      setIsBrandDropdownOpen={setIsBrandDropdownOpen}
      brandDropdownRef={brandDropdownRef}
      currentMembership={currentMembership}
      handleBrandChange={handleBrandChange}
      currentBrandId={user.brand_id}
      sortedMemberships={sortedMemberships}
      isLoading={isLoading}
      campaigns={campaigns}
      campaignContent={campaignContent}
      setMetrics={setMetrics}
      analyticsData={analyticsData}
    />
  );

  const contextValue = {
    dateRange,
    setDateRange,
    metrics,
    setMetrics,
    isLoading,
    isPageLoading,
    currentBrandId: user.brand_id,
    setCurrentBrandId: () => {},
    campaigns,
    campaignContent,
    activePost,
    contentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
    isDropdownOpen,
    setIsDropdownOpen,
    isBrandDropdownOpen,
    setIsBrandDropdownOpen,
    dropdownRef,
    brandDropdownRef,
    handleDateRangeChange,
    handleBrandChange,
    currentMembership,
    sortedMemberships,
  };

  const activeSubNavItems = dashboardNavItems.map((item) => ({
    ...item,
    active:
      location.pathname === item.link ||
      (item.matchPattern === "/" && location.pathname === "/") ||
      (item.matchPattern !== "/" &&
        location.pathname.startsWith(item.matchPattern)),
  }));

  const { hasRole } = useAuth();

  if (hasRole(Role.INFLUENCER)) {
    return <Profile isMe />;
  }

  return (
    <DashboardProvider value={contextValue}>
      <MultiPageLayout
        pageName="Dashboard"
        subNavClassName="!pt-4"
        subNavItems={activeSubNavItems}
        customHeader={customHeader}
      >
        <Outlet />
      </MultiPageLayout>
    </DashboardProvider>
  );
}
