import React, { useState } from "react";

import FButton from "@components/FButton";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";

import { NavItem } from "@components/Profile/ProfileNav";
import { useNavigate, useParams } from "react-router-dom";
import { Profile, User } from "@types";
import FDropdown from "@components/FDropdown";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";

interface ProfileNavProps {
  navItems?: NavItem[];
  dropdownOptions: { label: string; value: string }[];
  onDropdownOptionSelect?: (value: string) => void;
  profile: Profile;
  user: User;
  readOnly?: boolean;
}

export default function ProfileNav({
  navItems,
  profile,
  dropdownOptions,
  onDropdownOptionSelect,
  user,
  readOnly,
}: ProfileNavProps) {
  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);

  const { subpage } = useParams();

  const { hasRole } = useAuth();

  const navigate = useNavigate();

  const handleNavChange = (newValue: string) => {
    const item = navItems.find((item) => item.value === newValue);

    if (hasRole(Role.INFLUENCER)) {
      navigate(`/home/${item.value}`);

      return;
    }

    if (item && item.value !== location.pathname) {
      if (subpage) {
        navigate(`../${item.value}`, { relative: "path" });
      } else {
        navigate(item.value);
      }
    }
  };

  const handleAddToGroup = () => setAddToGroupModalVisible(true);

  return (
    <div className="border-b border-medium_border flex items-center pl-[50px] w-full">
      <div className="flex gap-[57px] items-center">
        <div className="flex h-full items-center font-normal text-base overflow-x-auto gap-14">
          {navItems.map((item, index) => {
            const isActive = subpage ? subpage === item.value : index === 0;

            return (
              <p
                key={index}
                className={`cursor-pointer pt-[18px] pb-[15px] text-center whitespace-nowrap ${isActive ? "border-b-2" : ""}`}
                onClick={() => handleNavChange(item.value)}
              >
                {item.label}
              </p>
            );
          })}
        </div>
        {user && !readOnly ? (
          <div className="absolute right-[43px] flex gap-2 items-center">
            <FDropdown
              options={dropdownOptions}
              onChange={onDropdownOptionSelect}
              width="auto"
              height="28"
              iconOnly
              iconColor="#000F45"
              icon="ellipsis"
            />
            <FButton
              onClick={handleAddToGroup}
              label="Add to group"
              width="max-content"
            ></FButton>
          </div>
        ) : null}
      </div>
      {isAddToGroupModalVisible ? (
        <AddCreatorsToGroupModal
          creatorIds={[profile.id]}
          onClose={() => setAddToGroupModalVisible(false)}
        />
      ) : null}
    </div>
  );
}
