import React, { useContext, useEffect, useState } from "react";

import { ProfileContext, UserContext } from "@contexts/index";
import LoadingSpinner from "@components/LoadingSpinner";
import { listAmbassadors } from "@api/Ambassadors/Ambassadors";
import { FChip } from "@components/FChip";
import {
  CampaignParticipantParams,
  getCampaignParticipantList,
} from "@api/Campaign/CampaignParticipants";
import { AmbassadorData } from "@apiTypes";
import Table from "@components/Table";
import { isArray, isEmpty } from "lodash";
import BlankState from "@components/BlankState";
import FIcon from "@components/FIcon";
import FButton from "@components/FButton";
import { Role } from "@constants/roles";
import useAuth from "@hooks/useAuth";
import InviteOffPlatformCreatorModal from "@components/Modals/InviteOffPlatformCreatorModal";

interface Platform {
  name: string;
  followers: number;
  handle: string;
  url: string;
  connection_type: string;
}

interface TopDemographic {
  name: string;
  percentage: number;
}

interface ProfileData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  location: string;
  mailing_address?: string;
  platforms?: Platform[];
  topDemographics?: TopDemographic[];
  gender?: string[];
  ethnicity?: string[];
  sexual_orientation?: string[];
  categories?: string[];
  birthday?: string;
  age?: number;
  tagline?: string;
  pronouns?: string;
  profile_image?: string;
  off_platform?: boolean;
}

const CAMPAIGN_HISTORY_TABLE_COLUMNS = [
  {
    key: "campaign_name",
    label: "Campaign Name",
  },
  {
    key: "current_offer_price",
    label: "Accepted Offer",
    isMoney: true,
  },
  {
    key: "deliverables",
    label: "Deliverables",
    getValue: (row) => row.deliverables?.name,
  },
  {
    key: "created_at",
    label: "Start Date",
    isDate: true,
  },
  {
    key: "end_date",
    label: "End Date",
    isDate: true,
  },
  {
    key: "brand_name",
    label: "Brand",
  },
];

export default function ProfileDetails() {
  const { profile } = useContext(ProfileContext);
  const profileData = profile as unknown as ProfileData;

  const { user } = useContext(UserContext);
  const { hasRole } = useAuth();

  const [ambassadors, setAmbassadors] = useState<AmbassadorData[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignParticipantParams[]>([]);
  const [showOffPlatformInviteModal, setShowOffPlatformInviteModal] =
    useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!user) {
        return;
      }

      const ambassadorsResponse = await listAmbassadors(null, null, {
        creator_id_eq: profileData.id,
      });

      const campaignsResponse = await getCampaignParticipantList(null, {
        creator_id_eq: profileData.id,
      });

      setAmbassadors(ambassadorsResponse.ambassadors);
      setCampaigns(campaignsResponse.data.participants);
    };

    getData();
  }, [profileData.id, user]);

  if (!profileData) {
    return <LoadingSpinner />;
  }

  const renderPlatformCard = (platform: Platform) => (
    <div
      key={platform.name}
      className="bg-white cursor-pointer rounded-lg p-4 shadow-sm border border-highlight_red_opacity shadow-sm"
      onClick={() =>
        window.open(
          platform.url.startsWith("https")
            ? platform.url
            : `https://${platform.url}`,
          "_blank"
        )
      }
    >
      <div className="flex items-center gap-3">
        <div className="bg-black rounded-full p-3">
          <FIcon
            icon={platform.name}
            size={16}
            color="white"
            className="-mt-[2px]"
          />
        </div>
        <div>
          <p className="font-medium">@{platform.handle}</p>
          <p className="text-sm text-neutral_600">
            {platform.followers.toLocaleString()} followers
          </p>
        </div>
      </div>
    </div>
  );

  const personalInfo = [
    {
      label: "Email",
      value: profileData.email,
      icon: "email",
      include: hasRole(Role.FREEMIUM) ? false : profileData.email,
    },
    {
      label: "Location",
      value: profileData.location,
      icon: "location",
      include: profileData.location,
    },
    {
      label: "Mailing Address",
      value: profileData.mailing_address,
      icon: "home",
      include: hasRole(Role.FREEMIUM) ? false : profileData.mailing_address,
    },
    {
      label: "Birthday",
      value: `${profileData.birthday} (Age ${profileData.age})`,
      icon: "calendar",
      include: profileData.birthday,
    },
  ];

  const demographicInfo = [
    {
      label: "Pronouns",
      value: profileData.pronouns,
      icon: "user",
      include: profileData.pronouns,
    },
    {
      label: "Gender",
      value: profileData.gender?.join(", "),
      icon: "user",
      include: profileData.gender,
    },
    {
      label: "Ethnicity",
      value: profileData.ethnicity?.join(", "),
      icon: "users",
      include: profileData.ethnicity,
    },
    {
      label: "Sexual Orientation",
      value: profileData.sexual_orientation?.join(", "),
      icon: "heart",
      include: profileData.sexual_orientation,
    },
  ];

  const InfoCard = ({ title, items }) => (
    <div className="bg-white rounded-lg p-6 shadow-sm border border-highlight_red_opacity shadow-sm">
      <h3 className="text-lg font-medium mb-4">{title}</h3>
      <div className="grid grid-cols-2 gap-6">
        {items
          .filter(
            (item) =>
              item.include &&
              (isArray(item.value) ? !isEmpty(item.value) : item.value)
          )
          .map((item, index) => (
            <div key={index} className="flex flex-col">
              <p className="text-neutral_600 text-sm mb-1">{item.label}</p>
              <p className="text-sm break-words">{item.value}</p>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div className="py-[52px] px-[46px]">
      {showOffPlatformInviteModal && (
        <InviteOffPlatformCreatorModal
          creatorId={profileData.id}
          isOpen={showOffPlatformInviteModal}
          onClose={() => setShowOffPlatformInviteModal(false)}
        />
      )}
      {/* Header Section */}
      <div className="flex items-start gap-6 mb-8">
        <img
          src={profileData.profile_image}
          alt={`${profileData.first_name} ${profileData.last_name}`}
          className="w-24 h-24 rounded-full object-cover"
        />
        <div className="flex-1">
          <h1 className="text-2xl font-medium mb-2">
            {profileData.first_name} {profileData.last_name}
          </h1>
          {profileData.tagline && (
            <p className="text-neutral_600 mb-2">{profileData.tagline}</p>
          )}
          <div className="flex flex-wrap gap-2">
            {profileData.categories?.map((category) => (
              <FChip
                key={category}
                label={category}
                className="border-neutral_400 text-sm"
              />
            ))}
          </div>
        </div>
      </div>

      {/* Platform Section */}
      <div className="mb-8">
        <h2 className="text-xl font-medium mb-4">Social Platforms</h2>
        <div className="grid grid-cols-3 gap-4">
          {profileData.platforms?.map(renderPlatformCard)}
        </div>
      </div>

      {/* Info Cards Grid */}
      <div className="grid grid-cols-2 gap-6 mb-8">
        {profileData.off_platform == true ? (
          <div className="bg-white rounded-lg p-6 shadow-sm border border-highlight_red_opacity ">
            <h3 className="text-lg font-medium mb-4">Personal Details</h3>
            <div className="flex flex-col items-center text-center">
              <p className="mb-2">
                This is an off-platform creator. Invite them to join Fohr and
                verify their account.
              </p>
              <FButton
                onClick={() => setShowOffPlatformInviteModal(true)}
                label="Invite to Fohr"
                width="max-content"
                primary
              />
            </div>
          </div>
        ) : (
          <InfoCard title="Personal Information" items={personalInfo} />
        )}
        <InfoCard title="Demographics" items={demographicInfo} />
      </div>

      {/* Top Demographics */}
      {profileData.topDemographics && (
        <div className="mb-8">
          <h2 className="text-xl font-medium mb-4">
            Top Audience Demographics
          </h2>
          {isEmpty(campaigns) ? (
            <BlankState
              subtitle="There's no Top Audience Demographics data for this profile"
              title="No Top Audience Demographics"
              icon="user"
            />
          ) : (
            <div className="grid grid-cols-3 gap-4">
              {profileData.topDemographics.map((demo, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg p-4 shadow-sm border border-highlight_red_opacity shadow-sm"
                >
                  <p className="text-neutral_600 text-sm mb-1">{demo.name}</p>
                  <p className="text-lg font-medium">{demo.percentage}%</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Ambassador Section */}
      {!isEmpty(ambassadors) && (
        <div className="mb-8">
          <h2 className="text-xl font-medium mb-4">Brand Ambassador</h2>
          <div className="flex flex-wrap gap-2">
            {ambassadors.map((ambassador) => (
              <FChip
                key={ambassador["brand_name="]}
                label={ambassador["brand_name="]}
                className="border-neutral_400 text-sm"
              />
            ))}
          </div>
        </div>
      )}

      {/* Campaign History */}
      <div>
        <h2 className="text-xl font-medium mb-4">Campaign History</h2>
        {user ? (
          isEmpty(campaigns) ? (
            <BlankState
              title="No Campaigns"
              subtitle="There's no campaign data for this profile"
              icon="use"
            />
          ) : (
            <div className="bg-white rounded-lg p-6 shadow-sm border border-highlight_red_opacity shadow-sm">
              <Table
                columns={CAMPAIGN_HISTORY_TABLE_COLUMNS}
                data={campaigns}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}
