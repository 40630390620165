import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { Bulletin, UploadedFile } from "@types";
import {
  deleteBulletinCoverImage,
  updateBulletin,
} from "@api/Bulletins/Bulletins";
import { BulletinContext } from "@contexts/index";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import FButton from "@components/FButton";
import BulletinContentForm from "../components/BulletinContentForm";
import BulletinPreview from "../components/BulletinPreview";
import { useParams } from "react-router-dom";

const CATEGORIES = ["survey", "opportunity", "gifting", "event"];

export default function BulletinSetupContent() {
  const { bulletin, setBulletin } = useContext(BulletinContext);
  const { trackEvent } = useContext(MixpanelContext);
  const { bulletin_id } = useParams();
  const [loading, setLoading] = useState(false);
  const { image_url } = bulletin || {};
  const hasCoverImage = image_url && !image_url.endsWith("missing_default.png");
  const [uploadedImage, setUploadedImage] = useState<UploadedFile>(
    hasCoverImage
      ? {
          file: null,
          url: image_url,
        }
      : null
  );

  const [isSubmitted, setSubmitted] = useState(false);
  const [values, setValues] = useState<Partial<Bulletin>>({
    name: "",
    description: "",
    category: CATEGORIES[0],
    start_date: null,
    end_date: null,
    brand: {
      id: null,
    },
    ...(bulletin || {}),
  });

  const handleUpdate = (updates: Partial<Bulletin>) => {
    // Track content changes as they happen
    if (Object.keys(updates).length > 0) {
      trackEvent(EVENTS.BULLETIN.CONTENT_UPDATED, {
        bulletin_id: bulletin_id,
        bulletin_name: bulletin?.name,
        action: "field_edited",
        fields_updated: Object.keys(updates),
      });
    }

    setValues({ ...values, ...updates });
  };

  const handleSave = async () => {
    setSubmitted(true);

    const { name } = values;

    if (!name) {
      return;
    }

    setLoading(true);

    try {
      if (bulletin?.id) {
        // Track bulletin content save
        trackEvent(EVENTS.BULLETIN.CONTENT_UPDATED, {
          bulletin_id: bulletin.id,
          bulletin_name: bulletin.name,
          action: "content_saved",
          has_image_update:
            !!uploadedImage?.file || (hasCoverImage && !uploadedImage),
          fields_updated: Object.keys(values).filter(
            (key) => values[key] !== bulletin[key]
          ),
        });

        let response = await updateBulletin(bulletin.id, {
          ...values,
          image: uploadedImage?.file || null,
        });

        if (hasCoverImage && !uploadedImage) {
          response = await deleteBulletinCoverImage(bulletin.id);
        }

        setBulletin(response.data);
        toast.success("Bulletin Updated");
      }
    } catch (e) {
      toast.error("Error updating bulletin");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "bulletin_content_update_error",
        bulletin_id: bulletin.id,
        error_message: e?.message || "Error updating bulletin",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 mb-8">
        <h2 className="text-[24px] font-bold">Content</h2>
        <p className="text-[16px] opacity-50">
          This is the content that will be sent to the audience.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-8 xl:gap-[40px] w-full">
        <div className="w-full xl:w-2/3">
          <BulletinContentForm
            values={values}
            isSubmitted={isSubmitted}
            handleUpdate={handleUpdate}
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
          />
          <div className="flex justify-end mt-8 sticky bottom-0 bg-white py-[20px]">
            <FButton
              primary
              label="Save Changes"
              onClick={handleSave}
              loading={loading}
            />
          </div>
        </div>
        <div className="w-full xl:w-1/3">
          <BulletinPreview values={values} uploadedImage={uploadedImage} />
        </div>
      </div>
    </>
  );
}
