import React from "react";

interface Props {
  value: number;
  className?: string;
}

const ProgressBar = ({ value, className }: Props) => {
  return (
    <div className={`w-full bg-neutral_400 rounded-full h-[5px] ${className}`}>
      <div
        className="bg-blue_secondary h-[5px] rounded-full max-w-full"
        style={{ width: `${value}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
