import React, { useState, useEffect, useContext } from "react";
import { useParams, Outlet, useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "@components/LoadingSpinner";
import toast from "react-hot-toast";

import apiClient from "@apiClient";
import "App.css";
import FButton from "@components/FButton";
import { Permission } from "@constants/roles";
import FSubNav from "@components/FSubNav";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { Bulletin } from "@types";
import BgCoverImage from "@pages/campaigns/brief-preview/bgCoverImage";
import { BulletinContext } from "@contexts/index";
import ConfirmModal from "@components/Modals/ConfirmModal";
import { sendEmailToAllRecipients } from "@api/Bulletins/BulletinRecipients";
import { updateBulletin } from "@api/Bulletins/Bulletins";
import moment from "moment";
import FIcon from "@components/FIcon";
import { FTooltip } from "@components/FTooltip";

import { ButtonDropDown } from "@components/ButtonDropDown";
import CreateOrUpdateBulletinModal from "./components/CreateOrUpdateBulletinModal";
import { UserContext } from "@contexts/index";
import TestEmailModal from "./components/TestEmailModal";
import { last } from "lodash";

export default function BulletinsProfile() {
  const { bulletin_id } = useParams<{ bulletin_id: string }>();

  const [selectedNav, setSelectedNav] = useState<string>("");

  const { trackEvent } = useContext(MixpanelContext);

  const navigate = useNavigate();

  const location = useLocation();

  const [bulletin, setBulletin] = useState<Bulletin>();
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [isConfirmPublishModalVisible, setConfirmPublishModalVisible] =
    useState(false);
  const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);

  const { can } = useContext(UserContext);

  const [showTestEmailModal, setShowTestEmailModal] = useState(false);

  const loadBulletin = async (bulletin_id) => {
    try {
      const result = await apiClient.bulletins.get(bulletin_id);

      setBulletin(result.data);

      trackEvent(EVENTS.PAGE_VIEW.BULLETIN, { bulletin: result.data.name });
    } catch (error) {
      if (error.response?.status === 404) {
        console.error(`404 Error: Bulletin with ID ${bulletin_id} not found`);
      } else {
        console.error("Error loading bulletin:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailRecipients = async () => {
    setWaiting(true);

    try {
      // Track email sending event
      trackEvent(EVENTS.BULLETIN.EMAIL_SENT, {
        bulletin_id: bulletin.id,
        bulletin_name: bulletin.name,
        recipient_count: bulletin.recipients_count || 0,
      });

      await sendEmailToAllRecipients(bulletin.id);
      toast.success("Email sent to all recipients");
    } catch (e) {
      toast.error("Error sending email");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "bulletin_email_error",
        bulletin_id: bulletin.id,
        error_message: e?.message || "Error sending email",
      });
    } finally {
      setWaiting(false);
    }
  };

  const handlePublish = async () => {
    setWaiting(true);

    const updates = {
      status: "active",
    };

    try {
      await updateBulletin(bulletin.id, updates);

      setBulletin({
        ...bulletin,
        ...updates,
      });

      setConfirmPublishModalVisible(false);

      toast.success("Bulletin published");
    } catch (e) {
      toast.error("Error publishing bulletin");
    } finally {
      setWaiting(false);
    }
  };

  const handleBulletinUpdate = (updatedBulletin) => {
    setBulletin(updatedBulletin);
    setShowEditModal(false);
  };

  const handleDelete = async () => {
    setWaiting(true);
    try {
      // Track bulletin archive action
      trackEvent(EVENTS.BULLETIN.ARCHIVED, {
        bulletin_id: bulletin_id,
        bulletin_name: bulletin?.name,
        bulletin_status: bulletin?.status,
      });

      await apiClient.bulletins.delete(bulletin_id);
      toast.success("Bulletin archived successfully");
      navigate("/bulletins");
    } catch (e) {
      toast.error("Error archiving bulletin");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "bulletin_archive_error",
        bulletin_id: bulletin_id,
        error_message: e?.message || "Error archiving bulletin",
      });
    } finally {
      setWaiting(false);
      setConfirmDeleteModalVisible(false);
    }
  };

  const handleDropdownChange = (value: string) => {
    if (value === "delete") {
      setConfirmDeleteModalVisible(true);
      return;
    }

    // Track when users navigate to specific bulletin sections
    if (value === "setup") {
      trackEvent(EVENTS.BULLETIN.CONTENT_UPDATED, {
        bulletin_id: bulletin_id,
        bulletin_name: bulletin?.name,
        action: "content_edit_started",
      });
    } else if (value === "setup/questions") {
      trackEvent(EVENTS.BULLETIN.QUESTIONS_UPDATED, {
        bulletin_id: bulletin_id,
        bulletin_name: bulletin?.name,
        action: "questions_edit_started",
      });
    } else if (value === "recipients") {
      trackEvent(EVENTS.BULLETIN.RECIPIENTS_ADDED, {
        bulletin_id: bulletin_id,
        bulletin_name: bulletin?.name,
        action: "recipients_management_started",
      });
    }

    navigate(`/bulletins/${bulletin_id}/${value}`);
  };

  const handleEmailOptionSelect = (value: string) => {
    if (value === "test") {
      setShowTestEmailModal(true);
    } else if (value === "send") {
      handleEmailRecipients();
    }
  };

  useEffect(() => {
    loadBulletin(bulletin_id);
  }, [bulletin_id]);

  useEffect(() => {
    // Update selectedNav based on current path
    const path = last(location.pathname.split("/"));

    setSelectedNav(location.pathname.includes("/setup") ? "setup" : path);
  }, [location]);

  const handleNavChange = (value: string) => {
    navigate(`/bulletins/${bulletin_id}/${value}`);
  };

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  const isActive = bulletin?.status === "active";

  return (
    <BulletinContext.Provider
      value={{ bulletin, setBulletin, loading, setLoading }}
    >
      <div className="flex flex-col">
        <div
          className={`relative z-0 ${Permission.UPDATE_BULLETINS ? "" : ""} `}
        >
          <BgCoverImage absolute imageUrl={bulletin?.image_url} />
          {can(Permission.UPDATE_BULLETINS) && (
            <div className="relative inset-0 z-10">
              <div className="relative mt-8 px-[80px] flex justify-end gap-2 z-[11]">
                {isActive ? (
                  <ButtonDropDown
                    primary
                    label="Send Email"
                    options={[
                      { label: "Test Email", value: "test" },
                      { label: "Email Recipients", value: "send" },
                    ]}
                    onChange={handleEmailOptionSelect}
                    loading={waiting}
                    className="border border-white rounded-md"
                  />
                ) : (
                  <FButton
                    primary
                    loading={waiting}
                    onClick={() => setConfirmPublishModalVisible(true)}
                    width="max-content"
                    className="border border-white"
                    label="Publish"
                  />
                )}
                <ButtonDropDown
                  options={[
                    { label: "Edit Content", value: "setup" },
                    { label: "Add Recipients", value: "recipients" },
                    { label: "Add Questions", value: "setup/questions" },
                    {
                      label: "Archive",
                      value: "delete",
                      static: "text-highlight_red hover:bg-highlight_red/10",
                    },
                  ]}
                  icon="ellipsis"
                  iconOnly
                  className="rounded-md"
                  onChange={handleDropdownChange}
                  alignDropDownRight
                />
              </div>
              <div className="relative inset-x-0 z-10 top-[0px] mt-[20px] mb-[80px] flex items-center justify-center flex-col gap-[12px]">
                <div
                  className="w-[72px] h-[72px] mb-[20px] border border-light_border bg-cover bg-center relative rounded-lg box-shadow-md bg-white"
                  style={{ backgroundImage: `url("${bulletin?.image_url}")` }}
                />
                <p
                  className={`text-xs px-4 py-1 rounded-full border border-white ${bulletin?.status === "active" ? "bg-green text-white" : "bg-yellow text-white"}`}
                >
                  {bulletin?.status === "active" ? "Active" : "Draft"}
                </p>
                <h1 className="text-[32px] text-black text-center max-w-[800px]">
                  {bulletin?.name}
                </h1>
                <div className="flex items-center flex-row gap-4 text-sm text-default_weak">
                  <p className="flex items-center gap-2">
                    <FTooltip label="Created at">
                      <FIcon
                        icon="calendar"
                        size={16}
                        color="black"
                        className="-mt-[2px] default_weak opacity-50"
                      />
                    </FTooltip>
                    {moment(bulletin?.created_at).format("MMM DD, YYYY")}
                  </p>
                  <p>|</p>
                  <p className="flex items-center gap-2">
                    <FTooltip label="Created by">
                      <FIcon
                        icon="user-bold"
                        size={14}
                        color="black"
                        className="-mt-[2px] default_weak opacity-50"
                      />
                    </FTooltip>
                    <p>{bulletin?.owner_user?.full_name}</p>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="px-[80px] relative">
          <div className="bg-white rounded-2xl shadow-lg mb-[40px]">
            <div className="px-[40px]">
              {can(Permission.UPDATE_BULLETINS) && (
                <FSubNav
                  className={`pt-4`}
                  items={[
                    {
                      value: "setup",
                      label: "Bulletin",
                    },

                    {
                      value: "recipients",
                      label: "Recipients",
                    },
                    {
                      value: "responses",
                      label: "Responses",
                    },
                  ]}
                  selectedValue={selectedNav}
                  onChange={handleNavChange}
                />
              )}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {isConfirmPublishModalVisible ? (
        <ConfirmModal
          title="Publish bulletin"
          isOpen
          onClose={() => setConfirmPublishModalVisible(false)}
          onAction={handlePublish}
          actionLabel="Confirm"
          isWaiting={waiting}
          subtitle={`Are you sure you want to publish this bulletin?`}
        />
      ) : null}
      {isConfirmDeleteModalVisible && (
        <ConfirmModal
          title="Archive Bulletin"
          isOpen
          onClose={() => setConfirmDeleteModalVisible(false)}
          onAction={handleDelete}
          actionLabel="Archive"
          isWaiting={waiting}
          subtitle="Are you sure you want to archive this bulletin?"
          className="z-[1001]"
        />
      )}
      {showEditModal && (
        <CreateOrUpdateBulletinModal
          entity={bulletin}
          onClose={() => setShowEditModal(false)}
          onUpdated={handleBulletinUpdate}
        />
      )}
      {showTestEmailModal && (
        <TestEmailModal
          id={bulletin_id}
          isOpen={showTestEmailModal}
          onClose={() => setShowTestEmailModal(false)}
        />
      )}
    </BulletinContext.Provider>
  );
}
