import React from "react";
import { StripeInvoice } from "./types";
import Table from "@components/Table";

interface SubscriptionInvoicesProps {
  invoices: StripeInvoice[];
}

const SubscriptionInvoices: React.FC<SubscriptionInvoicesProps> = ({
  invoices,
}) => {
  if (!invoices.length) return null;

  const columns = [
    {
      key: "amount",
      label: "Amount",
      isMoney: true,
      getValue: (invoice: StripeInvoice) => invoice.total / 100,
    },
    {
      key: "status",
      label: "Status",
      isStateBubble: true,
      getValue: (invoice: StripeInvoice) => invoice.status,
    },
    {
      key: "invoice_number",
      label: "Invoice #",
      getContent: (invoice: StripeInvoice) =>
        invoice.hosted_invoice_url ? (
          <a
            href={invoice.hosted_invoice_url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark_night_sky font-medium underline hover:underline"
          >
            {invoice.number}
          </a>
        ) : (
          invoice.number
        ),
    },
    {
      key: "pdf",
      label: "PDF",
      getContent: (invoice: StripeInvoice) =>
        invoice.invoice_pdf ? (
          <a
            href={invoice.invoice_pdf}
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark_night_sky font-medium underline hover:underline"
          >
            Download PDF
          </a>
        ) : (
          "--"
        ),
    },
    {
      key: "date",
      label: "Date",
      isDate: true,
      getValue: (invoice: StripeInvoice) => invoice.created * 1000,
    },
    {
      key: "description",
      label: "Description",
      getValue: (invoice: StripeInvoice) =>
        invoice.lines.data[0]?.description || "--",
    },
    {
      key: "period",
      label: "Period",
      getValue: (invoice: StripeInvoice) => {
        const lineItem = invoice.lines.data[0];
        if (!lineItem) return "--";
        return `${new Date(lineItem.period.start * 1000).toLocaleDateString()} - ${new Date(
          lineItem.period.end * 1000
        ).toLocaleDateString()}`;
      },
    },
    {
      key: "plan_type",
      label: "Plan Type",
      getValue: (invoice: StripeInvoice) => {
        const lineItem = invoice.lines.data[0];
        if (!lineItem?.plan) return "--";
        return `${lineItem.plan.interval_count === 1 ? "Monthly" : "Yearly"} Plan`;
      },
    },
  ];

  return (
    <div className="bg-white rounded-lg p-6 border border-highlight_red shadow-sm">
      <h2 className="text-xl font-semibold mb-4">Invoices</h2>
      <Table data={invoices} columns={columns} lastRowNoBorder />
    </div>
  );
};

export default SubscriptionInvoices;
