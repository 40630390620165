import React, { useContext, useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { NewReportContext } from "@contexts/index";
import MultiPageLayout from "@layouts/MultiPageLayout";
import { ReportHeader } from "./components/ReportHeader";
import { formatNumber } from "@utils/number_utilities";
import { useReportData } from "./hooks/useReportData";
import { NewReportContextType } from "@contexts/types";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

export default function ReportWrap() {
  const location = useLocation();
  const { report_id } = useParams();
  const { trackEvent } = useContext(MixpanelContext);

  // Track 'bonus' based on URL
  const [bonus, setBonus] = useState(location.pathname.includes("bonus"));

  // Detect when 'bonus' changes in the URL
  useEffect(() => {
    setBonus(location.pathname.includes("bonus"));
  }, [location.pathname]); // Runs every time the URL changes

  // Call `useReportData` at the top level so it re-runs when `bonus` changes
  const reportData = useReportData(report_id, bonus);

  const {
    isLoading,
    setIsLoading,
    isPageLoading,
    setIsPageLoading,
    metrics,
    setMetrics,
    report,
    setReport,
    performanceGraphs,
    engagementGraphData,
    audience,
    selectedPlatform,
    setSelectedPlatform,
    audienceDataFetched,
    availablePlatforms,
    activePost,
    setActivePost,
    contentModalOpen,
    setContentModalOpen,
  } = reportData;

  const handleContentModalClick = useCallback((content) => {
    setActivePost(content);
    setContentModalOpen(true);
  }, []);

  const handleContentModalClose = useCallback(() => {
    setContentModalOpen(false);
    setActivePost(null);
  }, []);

  const reportNavItems = [
    {
      label: "Overview",
      value: "overview",
      link: "overview",
    },
    {
      label: `Content (${formatNumber(metrics.posts)})`,
      value: "content",
      link: "content",
    },
    {
      label: `Creators (${formatNumber(metrics.creators)})`,
      value: "creators",
      link: "creators",
    },
    ...(report && report.bonus_content_count > 0
      ? [
          // {
          //   label: `Bonus Overview`,
          //   value: "bonus",
          //   link: "bonus",
          //   onClick: () => handleNavClick("bonus"),
          // },
          {
            label: `Bonus Content (${formatNumber(report.bonus_content_count)})`,
            value: "bonus-posts",
            link: "bonus-posts",
          },
        ]
      : []),
  ];

  const customHeader = (
    <ReportHeader
      report={report}
      metrics={metrics}
      performanceGraphs={performanceGraphs}
      isLoading={isLoading}
    />
  );

  const contextValue: NewReportContextType = {
    report,
    setReport,
    metrics,
    setMetrics,
    isLoading,
    setIsLoading,
    isPageLoading,
    setIsPageLoading,
    activePost,
    setActivePost,
    contentModalOpen,
    setContentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
    performanceGraphs,
    engagementGraphData,
    audience,
    selectedPlatform,
    setSelectedPlatform,
    audienceDataFetched,
    availablePlatforms,
    contentSummary: undefined,
  };

  const activeSubNavItems = reportNavItems.map((item) => ({
    ...item,
    active: location.pathname === item.link,
  }));

  // Track report view when report data is loaded
  useEffect(() => {
    if (report) {
      trackEvent(EVENTS.REPORT.VIEWED, {
        report_id: report.id,
        report_title: report.title,
        report_source: report.source_type,
        report_platforms: report.platforms,
        creator_count: metrics.creators || 0,
        content_count: metrics.posts || 0,
        engagement_rate: metrics.engagement_rate || 0,
        views_count: metrics.views_count || 0,
      });
    }
  }, [report, metrics, trackEvent]);

  if (report) {
    // Remove redundant tracking call here
    // trackEvent was already called in the useEffect above
  }

  return (
    <NewReportContext.Provider value={contextValue}>
      <MultiPageLayout
        pageName="Report"
        subNavClassName="!pt-4"
        subNavItems={activeSubNavItems}
        customHeader={customHeader}
      >
        <Outlet />
      </MultiPageLayout>
    </NewReportContext.Provider>
  );
}
