import React, { useEffect, useState } from "react";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";

import CampaignPageHeader from "../components/CampaignPageHeader";
import LoadingSpinner from "@components/LoadingSpinner";
import {
  BriefSection,
  Campaign,
  CampaignDeliverable,
  ParticipantOffer,
  ParticipantStatus,
} from "@types";
import { Creator } from "@apiTypes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getOverviewData } from "@api/Profile";
import FSubNav from "@components/FSubNav";
import { getSubNavItems, SubNavItems } from "../components/SubNavItems";

import BriefProductPost from "@components/Campaign/Brief/ProductPost";
import CmsBriefDeliverablePost from "@components/Campaign/CMS/DeliverablePost";
import { getContractTemplate } from "@api/ContractTemplates/ContractTemplates";
import BriefContractSection from "../brief/BriefContractSection";
import Modal from "@components/Modals/Modal";

import FButton from "@components/FButton";
import moment from "moment";

import { uploadContract } from "@api/upload";
import useAuth from "@hooks/useAuth";
import FIcon from "@components/FIcon";
import BriefPreviewTimeline from "../components/BriefPreviewTimeline";
import { Permission, Role } from "@constants/roles";
import ContentPreview from "@components/media/content-highlight/ContentPreview";
import BgCoverImage from "./bgCoverImage";

import ConfirmModal from "@components/Modals/ConfirmModal";
import InviteOffPlatformCreatorModal from "@components/Modals/InviteOffPlatformCreatorModal";
import { updateCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import toast from "react-hot-toast";
import { updateParticipantRecord } from "@api/creator_view";

import BriefDeliverablePreviewModal from "./BriefDeliverablePreview";
import { getContractInstance, getContractSections } from "../brief/contract";

interface BriefPreviewContentProps {
  backLink?: string;
  campaign: Campaign;
  className?: string;
  hideHeader?: boolean;
  participant_id?: number;
  participantOffers?: ParticipantOffer[];
}

export default function BriefPreviewContent({
  backLink,
  campaign,
  className,
  hideHeader,
  participant_id,
  participantOffers,
}: BriefPreviewContentProps) {
  const { campaign_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [googleDocUrl, setGoogleDocUrl] = useState(null);

  const [uploadingToDrive, setUploadingToDrive] = useState(false);

  const [contract, setContract] = useState(null);

  const [isAcceptingOffer, setAcceptingOffer] = useState(false);

  const [isSharingOffer, setSharingOffer] = useState(false);

  const [activeContractSection, setActiveContractSection] =
    useState<BriefSection>(null);

  const [isAcceptOfferModalVisible, setAcceptOfferModalVisible] =
    useState(false);

  const [isConfirmShareModalVisible, setIsConfirmShareModalVisible] =
    useState(false);

  const [
    isInviteOffPlatformCreatorModalVisible,
    setIsInviteOffPlatformCreatorModalVisible,
  ] = useState(false);

  const [creatorDetails, setCreatorDetails] = useState<Creator>(null);
  const [selectedDeliverable, setSelectedDeliverable] =
    useState<CampaignDeliverable>(null);

  const [selectedNav, setSelectedNav] = useState<string>(SubNavItems[0].value);

  const { can, hasRole, user } = useAuth();
  const { trackEvent, trackWorkflowComplete } = useMixpanelTracking();

  const navigate = useNavigate();

  const {
    brief,
    owner_user: manager,
    participants,
    payment_terms: paymentTerms,
    products,
  } = campaign;

  const participant = participant_id
    ? participants.find(({ id }) => id === participant_id)
    : null;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const { contract_template_id } = campaign;

      let contractTemplate;

      if (contract_template_id) {
        try {
          const contractTemplateResponse =
            await getContractTemplate(contract_template_id);

          contractTemplate = contractTemplateResponse.data;

          const sections = getContractSections(brief, contractTemplate);

          setContract({
            ...contractTemplate,
            sections,
          });
        } catch {
          setContract(null);
        }
      }

      if (!hasRole(Role.INFLUENCER) && participant) {
        const overviewResponse = await getOverviewData(
          participant.creator.slug
        );
        const { data } = overviewResponse;
        setCreatorDetails(data);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  const { description, sections = [] } = brief;

  const mainSection = sections.find(
    ({ custom, kind }) => kind === selectedNav && !custom
  );

  const customSections = sections.filter(
    ({ custom, kind }) => kind === selectedNav && custom
  );

  const creativeDirection = sections.find(
    ({ kind }) => kind === "creative_direction"
  );

  const { assets = [] } = creativeDirection || {};

  let allSections;

  if (selectedNav === "about") {
    allSections = [
      {
        kind: "about",
      },
    ];
  } else if (selectedNav === "guidelines") {
    allSections = [
      sections.find(({ kind }) => kind === "do"),
      sections.find(({ kind }) => kind === "dont"),
    ];
  } else {
    allSections = mainSection
      ? [mainSection, ...customSections]
      : customSections;
  }

  const deliverables =
    (participant && participant.deliverables) || campaign.deliverables || [];

  if (selectedNav === "payment") {
    sections.unshift({
      body: paymentTerms,
    } as BriefSection);
  }

  const handleAcceptOffer = async () => {
    setAcceptingOffer(true);

    try {
      await updateParticipantRecord(participant_id, {
        status: ParticipantStatus.ACCEPTED,
      });

      // Track campaign application acceptance
      trackEvent(EVENTS.CAMPAIGN.APPLIED, {
        campaign_id: campaign?.id || campaign_id,
        campaign_name: campaign?.title,
        participant_id,
        user_id: user?.id,
      });

      // Complete the application workflow
      if (
        sessionStorage.getItem(
          `campaign_application_${campaign?.id || campaign_id}`
        ) === "started"
      ) {
        trackWorkflowComplete("Campaign Application", {
          campaign_id: campaign?.id || campaign_id,
          campaign_name: campaign?.title,
          outcome: "accepted",
        });
        sessionStorage.removeItem(
          `campaign_application_${campaign?.id || campaign_id}`
        );
      }

      navigate(`/campaign-view/${participant_id}`);
    } catch (error) {
      toast.error("Error accepting offer");

      // Track failure
      trackEvent("Error", {
        error_type: "Campaign Application Acceptance Error",
        campaign_id: campaign?.id || campaign_id,
        participant_id,
        error_message: error?.message || "Unknown error",
      });
    } finally {
      setAcceptingOffer(false);
    }
  };

  const handleApprove = () => {};

  const handleEdit = async () => {
    if (!participant) {
      return;
    }

    const dealMemo = contract.sections.find(
      ({ kind }) => kind === "deal_memo"
    ).body;

    const htmlContent = `<html><body>
    <a href="${window.location.href}?hasAccepted=true" target="_blank">Accept changes and mark as ready for review by Fohr</a>
    ${dealMemo.replace("’", "'")}
    </body></html>`;

    const { firstName, lastName } = participant.creator;

    setUploadingToDrive(true);

    const response = await uploadContract({
      htmlContent,
      participant: `${firstName} ${lastName}`,
    });

    setUploadingToDrive(false);

    const { docUrl } = response;

    setGoogleDocUrl(docUrl);

    window.open(docUrl, "_blank").focus();

    setActiveContractSection(null);
  };

  const handleShareOfferLink = async () => {
    setSharingOffer(true);

    try {
      await updateCampaignParticipant(participant.id, {
        status: ParticipantStatus.SENT,
      });

      // Track offer being shared with influencer
      trackEvent(EVENTS.CAMPAIGN.UPDATED, {
        campaign_id: campaign?.id || campaign_id,
        campaign_name: campaign?.title,
        participant_id: participant.id,
        action: "share_offer",
        user_id: user?.id,
        user_role: hasRole(Role.INFLUENCER) ? "influencer" : "brand",
      });

      setSharingOffer(false);
      setIsConfirmShareModalVisible(false);
      toast.success("Offer shared");
    } catch (error) {
      toast.error("Error sharing offer");

      // Track failure
      trackEvent("Error", {
        error_type: "Share Offer Error",
        campaign_id: campaign?.id || campaign_id,
        participant_id: participant.id,
        error_message: error?.message || "Unknown error",
      });

      setSharingOffer(false);
      setIsConfirmShareModalVisible(false);
    }
  };

  const handleSetActiveContractSection = (briefSection: BriefSection) => {
    const contract = getContractInstance(
      campaign,
      participant,
      user,
      participantOffers,
      brief,
      briefSection
    );

    setActiveContractSection({ ...briefSection, body: contract });
  };

  let subNavItems = getSubNavItems(campaign).filter(
    ({ label }) => !["Details", "Images"].includes(label)
  );

  subNavItems = subNavItems.filter(({ value }) => {
    if (value === "contract") {
      return (
        hasRole(Role.INFLUENCER) ||
        can(Permission.VIEW_CAMPAIGN_BRIEFS_CONTRACTS)
      );
    }

    if (value === "payment") {
      return (
        hasRole(Role.INFLUENCER) ||
        can(Permission.VIEW_CAMPAIGN_BRIEFS_PAYMENT_TERMS)
      );
    }

    return true;
  });

  const subNavItemsWithContent = subNavItems.filter(({ value }) => {
    if (value === "guidelines") {
      return (
        sections.find(({ kind }) => kind === "do") ||
        sections.find(({ kind }) => kind === "dont")
      );
    }

    if (value === "contract") {
      return true;
    }

    return sections.find(({ kind }) => kind === value);
  });
  const offerPrice = participantOffers && participantOffers[0]?.selected_price;

  const handleNavChange = (value: string) => {
    // Track the subtab view
    trackEvent(EVENTS.PAGE_VIEW.CAMPAIGN_BRIEF_SUBTAB_VIEWED, {
      campaign_id: campaign?.id,
      campaign_name: campaign?.title,
      participant_id: participant_id,
      subtab: value,
    });

    // If viewing contract, track that specifically
    if (value === "contract") {
      trackEvent(EVENTS.CAMPAIGN.CONTRACT_VIEWED, {
        campaign_id: campaign?.id,
        campaign_name: campaign?.title,
        participant_id: participant_id,
      });
    }

    setSelectedNav(value as string);
  };

  return (
    <div>
      <BgCoverImage
        imageUrl={brief.cover_image.original || brief.cover_image.large}
      />
      <div className={`relative z-10 px-[64px] -mt-[400px] ${className || ""}`}>
        {!hideHeader ? (
          <>
            <div className="mb-[32px] pb-[14px] flex flex-row justify-between w-full pt-[32px]">
              <Link
                to={
                  backLink ||
                  (user
                    ? `/campaigns/${campaign_id}${participant_id ? `/participants/${participant_id}/brief` : ""}`
                    : `/campaigns/${campaign_id}/participant-review`)
                }
                className="h-[40px] rounded-lg bg-white flex items-center gap-2 border border-neutral_400 cursor-pointer hover:border-neutral_600 px-4 w-max"
              >
                <FIcon
                  icon="undo"
                  size={18}
                  color="#000F45"
                  className="-mt-1"
                />
                <p className="text-sm text-dark_night_sky">Back</p>
              </Link>
              <div className="flex items-center gap-4">
                {participant_id &&
                !hasRole(Role.INFLUENCER) &&
                !creatorDetails.off_platform ? (
                  <FButton
                    label="Send to Influencer"
                    onClick={() => setIsConfirmShareModalVisible(true)}
                  />
                ) : null}

                {participant_id &&
                !hasRole(Role.INFLUENCER) &&
                creatorDetails.off_platform ? (
                  <FButton
                    label="Invite Creator to Fohr"
                    onClick={() => {
                      setIsInviteOffPlatformCreatorModalVisible(true);
                    }}
                  />
                ) : null}

                {brief?.submit_application && (
                  <div className="flex flex-row gap-0 items-center bg-black rounded-lg border border-white px-4 py-1 w-max gap-2 h-[40px]">
                    <p className="text-sm text-white">Acceptance Deadline:</p>
                    <p className="font-light text-sm text-white">
                      {moment(brief.submit_application).format("MMMM D, YYYY")}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-[64px]">
              <CampaignPageHeader
                centered
                campaign={campaign}
                participant={participant}
              />
            </div>
          </>
        ) : null}

        <div className="bg-white rounded-2xl shadow-lg mb-[40px]">
          <div className="px-[40px]">
            <FSubNav
              className={`pt-4`}
              items={subNavItemsWithContent.map((item) => ({
                ...item,
                active: item.value === selectedNav,
              }))}
              selectedValue={selectedNav}
              onChange={handleNavChange}
            />
          </div>
          <div className="flex p-[40px]">
            <div className="flex flex-1 flex-col gap-8 pr-6 w-[calc(100%-300px)]">
              {allSections.map((section, index) => {
                const { custom, kind, title } = section;

                const body = kind === "about" ? description : section.body;

                return (
                  <div key={index}>
                    {custom ? <h2 className="text-lg mb-4">{title}</h2> : null}
                    <div className="ql-snow">
                      <div
                        className="ql-editor no-padding"
                        dangerouslySetInnerHTML={{ __html: body }}
                      />
                    </div>
                  </div>
                );
              })}
              {selectedNav === "about" ? (
                <BriefPreviewTimeline brief={brief} campaign={campaign} />
              ) : null}
              {selectedNav === "products"
                ? products
                    ?.sort(
                      (a, b) =>
                        new Date(b.updated_at).getTime() -
                        new Date(a.updated_at).getTime()
                    )
                    .map((product, index) => (
                      <div className="" key={index}>
                        <BriefProductPost key={product.id} product={product} />
                      </div>
                    ))
                : null}
              {selectedNav === "deliverables"
                ? deliverables
                    ?.sort(
                      (a, b) =>
                        new Date(b.updated_at).getTime() -
                        new Date(a.updated_at).getTime()
                    )
                    .map((deliverable) => (
                      <CmsBriefDeliverablePost
                        deliverable={deliverable}
                        key={deliverable.id}
                        isReadOnly
                        influencers={participants}
                        openModal={setSelectedDeliverable}
                      />
                    ))
                : null}
              {selectedNav === "moodboard" ? (
                <div className="grid grid-cols-5 gap-4 auto-rows-fr">
                  {assets.map((asset, index) => {
                    const { image_url } = asset;

                    const content = { photo_url: image_url };

                    return (
                      <div className="h-[200px] relative" key={index}>
                        <ContentPreview
                          content={content}
                          showFullAssetInModal
                        />
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {selectedNav === "contract" && contract ? (
                <>
                  {contract.sections
                    ?.sort(
                      (a, b) =>
                        new Date(b.updated_at).getTime() -
                        new Date(a.updated_at).getTime()
                    )
                    .map((section) => (
                      <div>
                        <BriefContractSection
                          key={section.id}
                          onClick={handleSetActiveContractSection}
                          section={section}
                        />
                        {googleDocUrl && section.kind === "deal_memo" ? (
                          <div>
                            <p>Pending document on Google Drive:</p>
                            <a
                              className="text-dark_night_sky font-medium underline hover:underline normal-case"
                              href={googleDocUrl}
                              target="_blank"
                            >
                              {googleDocUrl}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    ))}
                </>
              ) : null}
              {selectedNav === "payment" && brief.invoice_template ? (
                <div className="flex w-fit flex-row items-center gap-2 px-4 py-2 justify-between ml-2 relative bg-highlight_red rounded-lg">
                  <a
                    className="text-[15px] text-dark_night_sky underline font-medium"
                    href={brief.invoice_template}
                    target="_blank"
                    download
                  >
                    Uploaded Template
                  </a>
                </div>
              ) : null}
            </div>
            <div className="w-[300px]">
              {!hasRole(Role.INFLUENCER) ||
              participant?.status !== ParticipantStatus.ACCEPTED ? (
                <div className="border border-neutral_400 bg-white rounded-lg shadow-md px-[24px] py-[32px]">
                  <h3 className="text-[20px]">Offer</h3>
                  {offerPrice ? (
                    <h3 className="my-[16px] text-[22px] text-[#1F8855]">
                      ${offerPrice.toLocaleString()}
                    </h3>
                  ) : null}
                  {hasRole(Role.INFLUENCER) ? (
                    <div className="space-y-2">
                      <FButton
                        label="Accept Offer"
                        onClick={() => setAcceptOfferModalVisible(true)}
                        primary
                        width="100%"
                        loading={isAcceptingOffer}
                        height="40px"
                      />
                    </div>
                  ) : null}

                  {brief?.submit_application && (
                    <div className="mt-[8px]">
                      <p className="mb-[4px] mt-[16px] text-[14px] opacity-50">
                        Acceptance Deadline
                      </p>
                      <p className="font-light text-[16px]">
                        {brief?.submit_application
                          ? moment(brief?.submit_application).format(
                              "MMMM D, YYYY"
                            )
                          : null}
                      </p>
                    </div>
                  )}

                  <p className="mb-[4px] mt-[16px] text-[14px] opacity-50">
                    Campaign Manager
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p className="font-light text-[16px]">
                      {manager?.full_name}
                    </p>
                    {manager?.email && (
                      <a
                        href={`mailto:${manager.email}`}
                        className="flex items-center gap-1 font-light text-[16px]"
                      >
                        <FIcon
                          icon="email-bold"
                          size={16}
                          color="black"
                          className="-mt-[2px]"
                        />
                      </a>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {activeContractSection ? (
        <Modal
          title={activeContractSection.title}
          isOpen
          hasForm
          actionLabel="Confirm"
          onAction={() => {
            setActiveContractSection(null);
          }}
          onClose={() => {
            setActiveContractSection(null);
          }}
          className="h-[500px] overflow-auto w-[850px] pb-0"
          children={
            <div>
              <div className="ql-editor">
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeContractSection.body,
                  }}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <FButton
                  onClick={() => {
                    setActiveContractSection(null);
                  }}
                  label="Cancel"
                  width="100%"
                  height="40px"
                />
                <FButton
                  onClick={handleEdit}
                  label="Edit"
                  loading={uploadingToDrive}
                  primary
                  width="100%"
                  height="40px"
                />
                <FButton
                  onClick={handleApprove}
                  primary
                  label="Approve"
                  width="100%"
                  height="40px"
                />
              </div>
            </div>
          }
        />
      ) : null}
      {isAcceptOfferModalVisible ? (
        <ConfirmModal
          title="Accept Offer"
          isOpen
          onClose={() => setAcceptOfferModalVisible(false)}
          onAction={handleAcceptOffer}
          actionLabel="Accept"
          isWaiting={isAcceptingOffer}
          subtitle="Are you sure you want to accept this offer?"
        />
      ) : null}
      {isConfirmShareModalVisible ? (
        <ConfirmModal
          title="Confirm Share"
          isOpen
          onClose={() => setIsConfirmShareModalVisible(false)}
          onAction={handleShareOfferLink}
          actionLabel="Confirm"
          isWaiting={isSharingOffer}
          subtitle="Are you sure you want to share this offer?"
        />
      ) : null}

      {isInviteOffPlatformCreatorModalVisible ? (
        <InviteOffPlatformCreatorModal
          creatorId={participant.creator.id}
          isOpen={isInviteOffPlatformCreatorModalVisible}
          onClose={() => setIsInviteOffPlatformCreatorModalVisible(false)}
        />
      ) : null}
      {selectedDeliverable ? (
        <BriefDeliverablePreviewModal
          deliverable={selectedDeliverable}
          onClose={() => setSelectedDeliverable(null)}
        />
      ) : null}
    </div>
  );
}
