import React, { useEffect, useState, useContext } from "react";
import { Outreach, OutreachTemplate } from "@types";
import { FInput } from "../../components/FInputs";
import FButton from "../../components/FButton";
import FDropdown from "../../components/FDropdown";
import RichTextEditor from "../../components/RichTextEditor";
import FormField from "@components/Form/FormField";
import { toast } from "react-hot-toast";
import FIcon from "@components/FIcon";
import { getOutreachTemplateList } from "@api/Outreach/OutreachTemplates";
import apiClient from "@apiClient";
import TestEmailModal from "./TestEmailModal";
import { FTooltip } from "@components/FTooltip";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const { update } = apiClient.outreach;

interface EditMessageFormProps {
  isTemplate?: boolean;
  isSubmitted?: boolean;
  outreach: Partial<Outreach>;
  onUpdated?: (updates: Partial<Outreach>) => void;
  onUpdate?: (values: Partial<Outreach>) => void;
}

const EditMessageForm = ({
  isTemplate,
  isSubmitted,
  outreach,
  onUpdated,
  onUpdate,
}: EditMessageFormProps) => {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [outreachTemplates, setOutreachTemplates] = useState<
    OutreachTemplate[]
  >([]);
  const { trackEvent } = useContext(MixpanelContext);

  const { assets = [], cc_emails, reply_to, message } = outreach;

  const [values, setValues] = useState({
    message,
    reply_to: reply_to || "",
    cc_emails: cc_emails || "",
  });

  const [attachments, setAttachments] = useState(assets);
  const [isTestEmailModalVisible, setTestEmailModalVisible] = useState(false);

  const handleUpdate = (updates) => {
    setValues({ ...values, ...updates });

    if (onUpdate) {
      onUpdate(updates);
    }
  };

  useEffect(() => {
    const loadTemplates = async () => {
      const response = await getOutreachTemplateList();
      setOutreachTemplates(response.data.templates);
    };

    loadTemplates();
  }, []);

  const handleTemplateChange = (templateId: number) => {
    setSelectedTemplateId(templateId);

    const selectedTemplate = outreachTemplates.find(
      (template) => template.id === templateId
    );

    handleUpdate({
      message: {
        subject: selectedTemplate.subject,
        body: selectedTemplate.body,
      },
    });

    // Track template application
    trackEvent(EVENTS.OUTREACH.TEMPLATE_APPLIED, {
      outreach_id: outreach.id,
      template_id: templateId,
      template_name: selectedTemplate.name,
    });
  };

  const handleAddNewAttachment = (e) => {
    if (!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    const newAttachments = [
      ...attachments,
      { url: URL.createObjectURL(file), file },
    ];

    setAttachments(newAttachments);

    // Track attachment added
    trackEvent(EVENTS.OUTREACH.ATTACHMENT_ADDED, {
      outreach_id: outreach.id,
      file_name: file.name,
      file_size: file.size,
      file_type: file.type,
    });

    if (onUpdate) {
      onUpdate({ assets: newAttachments });
    }
  };

  const handleRemoveAttachment = (index) => {
    const removedAttachment = attachments[index];
    const newAttachments = [
      ...attachments.slice(0, index),
      ...attachments.slice(index + 1),
    ];

    setAttachments(newAttachments);

    // Track attachment removed
    trackEvent(EVENTS.OUTREACH.ATTACHMENT_REMOVED, {
      outreach_id: outreach.id,
      file_name: removedAttachment.file?.name || "Unknown",
      attachment_id: removedAttachment.id,
    });

    if (onUpdate) {
      onUpdate({ assets: newAttachments });
    }
  };

  const handleSave = async () => {
    const { message, reply_to } = values;
    const { subject, body } = message;

    if (!reply_to || !subject || !body) {
      setFormSubmitted(true);
      return;
    }

    setWaiting(true);

    const removedAssets = assets.filter(
      (asset) => !attachments.find(({ id }) => asset.id === id)
    );

    const addedAssets = attachments.filter((asset) => !asset.id);

    try {
      const response = await update(
        outreach.id,
        values,
        addedAssets,
        removedAssets
      );

      setAttachments(response.data.assets);
      onUpdated(response.data);

      // Track message update
      trackEvent(EVENTS.OUTREACH.MESSAGE_UPDATED, {
        outreach_id: outreach.id,
        subject_updated: message.subject !== outreach?.message?.subject,
        body_updated: message.body !== outreach?.message?.body,
        reply_to_updated: reply_to !== outreach?.reply_to,
        cc_emails_updated: values.cc_emails !== outreach?.cc_emails,
        assets_added: addedAssets.length,
        assets_removed: removedAssets.length,
      });

      toast.success("Saved");
    } catch (e) {
      toast.error("Error saving outreach");
    } finally {
      setWaiting(false);
    }
  };

  const submitted = isSubmitted || isFormSubmitted;

  const subjectTooltip = (
    <p>
      Variables for the subject:
      <br />
      {"{FirstName}"}
      <br />
      {"{LastName}"}
      <br />
      {"{BlogName}"}
      <br />
      {"{BrandName}"}
    </p>
  );

  const bodyTooltip = (
    <p>
      Variables for the body:
      <br />
      {"{FirstName}"}
      <br />
      {"{LastName}"}
      <br />
      {"{BlogName}"}
      <br />
      {"{BrandName}"}
      <br />
      {"{CreatorName}"}
      <br />
      {"{SiteURL}"}
      <br />
      {"{BrandSlug}"}
      <br />
      {"{BrandUserName}"}
      <br />
    </p>
  );

  return (
    <div className="bg-white max-w-[800px] p-10 border border-light_border rounded-lg m-auto">
      {isTestEmailModalVisible ? (
        <TestEmailModal
          outreachId={outreach.id}
          isOpen={isTestEmailModalVisible}
          onClose={() => setTestEmailModalVisible(false)}
        />
      ) : null}
      <div className="flex flex-row justify-between mb-10 border-b border-light_border pb-10">
        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-bold ">Message:</h2>
          <p className="text-sm text-neutral_700">
            Add a subject line, copy, and attachments to your message.
          </p>
          <p className="text-sm text-neutral_700">
            Special variables can be used in the{" "}
            <FTooltip label={subjectTooltip}>
              <u>subject</u>
            </FTooltip>{" "}
            and{" "}
            <FTooltip label={bodyTooltip}>
              <u>body</u>
            </FTooltip>{" "}
            of the message.
          </p>
        </div>
        {outreachTemplates.length ? (
          <div>
            <FDropdown
              hasNoDefault
              label="Select Template:"
              options={outreachTemplates.map((template) => ({
                label: template.name,
                value: template.id,
              }))}
              onChange={handleTemplateChange}
              selectedValue={selectedTemplateId}
              width="202"
              limitedWidth
            />
          </div>
        ) : null}
      </div>
      <div className="flex flex-col gap-6">
        {!isTemplate ? (
          <>
            <FInput
              width="100%"
              label="Reply To Address"
              required
              value={values.reply_to}
              validate={submitted}
              onChange={(value) => handleUpdate({ reply_to: value })}
            />
            <FInput
              label="Add CC Recipients"
              value={values.cc_emails}
              onChange={(value) => handleUpdate({ cc_emails: value })}
              width="100%"
            />
          </>
        ) : null}
        <FInput
          label="Subject"
          width="100%"
          value={values.message.subject}
          validate={submitted}
          onChange={(value) =>
            handleUpdate({
              message: { ...values.message, subject: value },
            })
          }
          required
        />
        <FormField
          error={!values.message.body && submitted && "Body is required"}
          label="Body"
          required
          width="100%"
          key={selectedTemplateId}
        >
          <RichTextEditor
            id={`body-editor-${outreach.id}`}
            value={values.message.body}
            onChange={(value) =>
              handleUpdate({ message: { ...values.message, body: value } })
            }
          />
        </FormField>
      </div>
      <div className="flex justify-between items-center space-x-2 pb-3  z-50 pt-4 mt-10">
        <FormField width="auto">
          {attachments.map((attachment, index) => (
            <div
              className="flex w-fit flex-row items-center gap-2  px-4 py-2 justify-between relative bg-highlight_red rounded-lg"
              key={index}
            >
              {attachment.file_url ? (
                <a
                  className="text-[15px] text-dark_night_sky underline font-medium"
                  href={attachment.file_url}
                  target="_blank"
                  download
                >
                  {attachment.file_url}
                </a>
              ) : (
                <span>{attachment.file?.name}</span>
              )}
              <div
                className="cursor-pointer"
                onClick={() => handleRemoveAttachment(index)}
              >
                <FIcon
                  icon="exit-2"
                  color="#000F45"
                  className="cursor-pointer"
                  size={15}
                />
              </div>
            </div>
          ))}
          <div className="flex h-[40px] border-[1px] border-dark_night_sky px-4 items-center gap-3 cursor-pointer relative">
            <FIcon
              icon="upload"
              color="#000F45"
              className="inline-block"
              size={15}
            />
            <span className="text-[15px] text-dark_night_sky font-medium">
              Add attachment
            </span>
            <input
              className="block h-full w-full absolute left-0 top-0"
              type="file"
              onChange={handleAddNewAttachment}
            />
          </div>
        </FormField>
        {onUpdate ? null : (
          <div className="flex gap-2">
            <FButton
              type="button"
              loading={waiting}
              label="Send Test Email"
              onClick={() => {
                setTestEmailModalVisible(true);
              }}
              primary
            />
            <FButton
              type="submit"
              loading={waiting}
              label="Save"
              onClick={handleSave}
              primary
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditMessageForm;
