import React, { useCallback, useState } from "react";

import { Sort } from "@types";
import { GroupData } from "@apiTypes";
import { getGroupList, GroupListRequestParams } from "@api/Groups/Groups";
import SelectEntitiesTable from "@components/SelectEntities/SelectEntitiesTable";

import CreateGroupForm from "@components/Profile/CreateGroupForm";

const TABLE_COLUMNS = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "creators_count",
    label: "Creators",
    isNumber: true,
  },
];

interface GroupsTableProps {
  excludeIds?: number[];
  onChangeSelectedItems?: (groups: GroupData[]) => void;
  selectedItems?: GroupData[];
  hasSingleSelection?: boolean;
  showJustSelected?: boolean;
  enableGroupCreateForm?: boolean;
}

export default function SelectGroupsTable({
  excludeIds,
  onChangeSelectedItems,
  selectedItems,
  hasSingleSelection,
  showJustSelected,
  enableGroupCreateForm,
}: GroupsTableProps) {
  const [tableKey, setTableKey] = useState(0);
  const [waiting, setWaiting] = useState(false);
  const [items, setItems] = useState<GroupData[]>([]);
  const getGroups = useCallback(
    async (page?: number, sort?: Sort, params?: GroupListRequestParams) => {
      setWaiting(true);
      const response = await getGroupList(false, page, sort, params);
      setWaiting(false);
      const { groups: items } = response.data;
      setItems(items);
      return {
        ...response.data,
        items,
      };
    },
    []
  );
  function handleOnCreateGroup() {
    tableKey === 0 ? setTableKey(1) : setTableKey(0);
  }
  const shouldShowCreateForm =
    items.length === 0 && enableGroupCreateForm && waiting === false;
  return (
    <>
      {shouldShowCreateForm && (
        <CreateGroupForm onCreateGroup={handleOnCreateGroup} />
      )}
      <SelectEntitiesTable
        key={tableKey}
        excludeIds={excludeIds}
        tableColumns={TABLE_COLUMNS}
        onChangeSelectedItems={onChangeSelectedItems}
        selectedItems={selectedItems}
        hasSingleSelection={hasSingleSelection}
        showJustSelected={showJustSelected}
        getItems={getGroups}
      />
    </>
  );
}
