import Table from "@components/Table";
import {
  Campaign,
  CampaignContentReview,
  ContentReviewDeliverable,
} from "@types";
import { groupBy, uniq } from "lodash";
import STATUSES from "../statuses";
import FIcon from "@components/FIcon";
import { platformList } from "@constants/constants";

const getAllDeliverables = (contentReview: CampaignContentReview) =>
  contentReview.participants.reduce((result, item) => {
    const deliverables = (item.deliverables || []).map((deliverable) => ({
      ...deliverable,
      participant: item,
    }));

    return [...result, ...deliverables];
  }, []);

interface DeliverablesOverviewProps {
  campaign: Campaign;
  contentReview: CampaignContentReview;
}

const TABLE_COLUMNS = [
  {
    key: "participant",
    label: "Participant",
    getValue: (deliverable: ContentReviewDeliverable) =>
      deliverable.participant.full_name,

    isFirstColumn: true,
  },
  {
    key: "platform",
    label: "Platform",
    type: "platform",
  },
  {
    key: "submission_due_date",
    label: "Due date",
    isDate: true,
  },
  {
    key: "status",
    label: "Status",
    isStateBubble: true,
    getValue: (item) => {
      const statusValue = item.content?.status || "-";
      const status = STATUSES.find(({ value }) => value === statusValue);
      return status?.label;
    },
  },
  {
    key: "updated_at",
    label: "Modified",
    isDate: true,
  },
];

const DeliverablesOverview = ({ contentReview }: DeliverablesOverviewProps) => {
  const deliverables = contentReview ? getAllDeliverables(contentReview) : [];

  const groupedDeliverables = groupBy(deliverables, "name");

  const getPlatformIcon = (platform) => {
    const { icon } = platformList.find(({ value }) => platform === value) || {};

    return <FIcon icon={icon} size={20} color="#fff" />;
  };

  return (
    <div className="flex flex-col gap-8">
      {Object.keys(groupedDeliverables).map((deliverableName) => {
        const deliverables = groupedDeliverables[deliverableName];

        const participantCount = uniq(deliverables.map(({ id }) => id)).length;

        const submitted = deliverables.filter(
          (deliverable) => deliverable.content?.status !== "not_submitted"
        );

        return (
          <div
            className="border border-light_border rounded-lg bg-white shadow-sm"
            key={deliverableName}
          >
            <div className="border-b border-light_border flex items-center p-7">
              <div className="flex items-center">
                <div
                  className={` w-[46px] h-[46px] mr-4 rounded-full bg-black flex items-center justify-center`}
                >
                  {getPlatformIcon(deliverables[0].platform)}
                </div>
                <div>
                  <p className="text-lg">{deliverableName}</p>
                  <p className="text-default_weak flex flex-row gap-2 text-sm">
                    <span>
                      {participantCount} Participant
                      {participantCount === 1 ? "" : "s"}
                    </span>
                    <span className="text-neutral_400">|</span>
                    <span>{submitted.length} Submitted</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <Table
                lastRowNoBorder
                columns={TABLE_COLUMNS}
                data={deliverables}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DeliverablesOverview;
