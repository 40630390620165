import React, { useState, useContext } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { capitalize, groupBy, isEmpty, isNumber, sortBy } from "lodash";
import { Content, Platform, PostProps } from "@types";
import { formatLargeNumber } from "@utils/number_utilities";
import getMedian from "@utils/array";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import { format } from "date-fns";
import { ProfileContext } from "@contexts/index";
import FIcon from "@components/FIcon";

const INPUT_DATA_FORMAT = "MMM DD, YYYY";

const COLORS = {
  RED: "#E47667",
  YELLOW: "#DBB200",
  GREEN: "#47B37F",
};

const PLOTLINE_OPTIONS = {
  color: "#000721",
  dashStyle: "dash",
  width: 2,
};

const OPTIONS = {
  xAxis: {
    labels: {
      formatter: function () {
        return moment(this.value).format("MMM 'YY");
      },
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
    },
  },
  tooltip: {
    useHTML: true,
    hideDelay: 0,
    animation: false,
    followPointer: true,
    snap: 0,
    formatter: function () {
      // @ts-ignore - this.point.post is added in the series data
      const post = this.point.post;
      if (!post) return "";

      return `
        <div style="width: 100px;">
          <img src="${post.photo_url}" style="width: 100%; height: 100px; object-fit: cover; border-radius: 4px; margin-bottom: 8px;" />
          <div style="text-align: center; font-size: 14px;">
            Views: <b>${formatLargeNumber(post.stats.views_count)}</b>
          </div>
        </div>
      `;
    },
  },
};

interface ViewershipOverTimeProps {
  content: Content[];
  platforms: Platform[];
  selectedPlatform: string;
}

export default function ViewershipOverTime({
  content,
  platforms,
  selectedPlatform,
}: ViewershipOverTimeProps) {
  const { profile } = useContext(ProfileContext);
  const [activePost, setActivePost] = useState<PostProps | null>(null);

  // Add check for content
  if (!content || content.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full py-20">
        <FIcon icon="search" size={40} color="#000721" />
        <p className="text-center mt-[23px] text-black text-[18px]">
          No recent posts
        </p>
      </div>
    );
  }

  const contentByPlatform = groupBy(content, ({ platform }) => platform);

  const median = getMedian(
    content.map(({ stats: { views_count } }) => views_count)
  );

  // Calculate breaks for y-axis
  const calculateYAxisBreaks = (data: number[]) => {
    const sortedData = [...data].sort((a, b) => a - b);
    const median = sortedData[Math.floor(sortedData.length / 2)];

    // For log scale, we want to ensure we don't hit zero
    const minViews = Math.max(1, Math.min(...sortedData));
    const maxViews = Math.max(...sortedData);

    return {
      min: minViews,
      max: maxViews * 1.1,
    };
  };

  const yAxisData = content.map((item) => item.stats.views_count);
  const yAxisConfig = calculateYAxisBreaks(yAxisData);

  const getPointColor = (value: number, median: number) => {
    const margin = median * 0.1; // 10% margin around median
    if (Math.abs(value - median) <= margin) {
      return COLORS.YELLOW;
    }
    return value > median ? COLORS.GREEN : COLORS.RED;
  };

  const series = platforms.reduce((result, platform) => {
    const { name } = platform;

    if (selectedPlatform && selectedPlatform !== name) {
      return result;
    }

    const contentForPlatform = contentByPlatform[name];

    const contentSortedByTime = sortBy(
      contentForPlatform,
      ({ published_at: publishedAt }) =>
        moment(publishedAt, INPUT_DATA_FORMAT).valueOf()
    );

    const data = contentSortedByTime.map((item) => {
      const { published_at: publishedAt, stats } = item;
      const { views_count } = stats;
      const value = isNumber(views_count)
        ? views_count
        : parseFloat(views_count);

      return {
        x: moment(publishedAt, INPUT_DATA_FORMAT).valueOf(),
        y: value,
        post: item,
        color: getPointColor(value, median),
        events: {
          click: function () {
            setActivePost(formatPostForModal(item));
          },
        },
      };
    });

    if (!isEmpty(data)) {
      return [
        ...result,
        {
          data,
          name: name === "tiktok" ? "TikTok" : capitalize(name),
        },
      ];
    }

    return result;
  }, []);

  const options = {
    ...OPTIONS,
    chart: {
      type: "scatter",
      height: 400,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          return moment(this.value).format("MMM 'YY");
        },
      },
      tickInterval: 30 * 24 * 3600 * 1000, // One month interval
      startOnTick: true,
      endOnTick: true,
    },
    yAxis: {
      type: "logarithmic",
      title: {
        text: "Views",
      },
      min: yAxisConfig.min,
      max: yAxisConfig.max,
      gridLineWidth: 1,
      plotLines: [
        {
          ...PLOTLINE_OPTIONS,
          value: median,
          label: {
            text: "Median",
            align: "right",
            x: -10,
            style: {
              color: "#000721",
              zIndex: 1000,
            },
          },
        },
      ],
      labels: {
        formatter: function () {
          return formatLargeNumber(this.value);
        },
      },
    },
    tooltip: {
      ...OPTIONS.tooltip,
      followPointer: true,
      snap: 0,
      hideDelay: 0,
      animation: false,
    },
    plotOptions: {
      scatter: {
        enableMouseTracking: true,
        stickyTracking: false,
        marker: {
          enabled: true,
          radius: 4,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              radiusPlus: 0,
            },
          },
        },
        states: {
          hover: {
            enabled: true,
            lineWidth: 0,
          },
        },
        point: {
          events: {
            click: function (e) {
              const post = this.options?.post;

              if (post) {
                const formattedPost = formatPostForModal(post);

                setActivePost(formattedPost);
              }
            },
          },
        },
      },
      series: {
        cursor: "pointer",
        lineWidth: 0,
        states: {
          hover: {
            enabled: true,
            lineWidth: 0,
          },
        },
      },
    },
    series,
  };

  const formatPostForModal = (post: Content): PostProps => {
    const formattedDate = format(new Date(post.published_at), "MMM d, yyyy");

    // Get social profile URL based on platform
    const getSocialProfileUrl = (platform: string, username: string) => {
      const urls = {
        instagram: `https://instagram.com/${username}`,
        tiktok: `https://tiktok.com/@${username}`,
        youtube: `https://youtube.com/@${username}`,
        facebook: `https://facebook.com/${username}`,
      };
      return urls[platform.toLowerCase()] || "#";
    };

    const platformData = profile?.platforms?.find(
      (p) => p.name.toLowerCase() === post.platform.toLowerCase()
    );
    const username = platformData?.handle || profile?.slug;
    const socialProfileUrl = getSocialProfileUrl(post.platform, username);

    return {
      id: post.id,
      full_name: `${profile?.first_name} ${profile?.last_name}`,
      username,
      profile_image: profile?.profile_image,
      permalink: post.permalink,
      caption: post.caption || "",
      published_at: formattedDate,
      platform: post.platform,
      type: post.media_type,
      content_type: post.content_type,
      photo_url: post.photo_url,
      video_url: post.video_url || post.permalink,

      // Profile links
      profile_link: `/profile/${profile?.slug}`,
      social_profile_url: socialProfileUrl,

      // Stats
      views_count: post.stats?.views_count || 0,
      likes_count: post.stats?.likes_count || 0,
      comments_count: 0, // Since stats doesn't have comments_count, defaulting to 0
      reach: post.stats?.reach || 0,

      // Required fields from PostProps
      active: true,
      campaign_id: 0,
      participant_id: 0,
      fohr_content_id: post.id.toString(),
      bonus: false,
      platform_uuid: post.id.toString(),
      image_file_name: "",
      image_content_type: "",
      image_file_size: 0,
      image_updated_at: "",
      approved: true,

      // Additional required fields
      insights: {},
      created_at: post.published_at,
      updated_at: post.published_at,
      image: {
        thumb: post.photo_url || "",
        poster: post.photo_url || "",
        medium: post.photo_url || "",
        large: post.photo_url || "",
      },
      stats_screenshot: null,
      stats_screenshot_file_name: null,
      stats_screenshot_content_type: null,
      stats_screenshot_file_size: null,
      stats_screenshot_updated_at: null,

      // UI specific fields
      cardDropDownOptions: [],
      checkbox: false,
      statHelperText: "",
      state: true,

      data: {
        id: post.id,
        photo: post.photo_url || "",
        photo_content_type: "",
        caption: post.caption,
        published_at: post.published_at,
        provider: post.platform,
        type: post.media_type,
      },
    };
  };

  return (
    <div className="pr-8 pt-8 pl-2 pb-4">
      {content?.length > 0 ? (
        <div>
          <HighchartsReact highcharts={Highcharts} options={options} />
          {activePost && (
            <GeneralPostModal
              post={activePost}
              isOpen
              onClose={() => setActivePost(null)}
            />
          )}
        </div>
      ) : (
        <p className="text-center mt-[23px] text-neutral_500 text-[18px]">
          No recent posts
        </p>
      )}
    </div>
  );
}
