import React, { useState, useEffect, useContext } from "react";

import { FInput } from "@components/FInputs";
import FDropdown from "@components/FDropdown";

import FButton from "@components/FButton";
import useAuth from "@hooks/useAuth";
import { Brand, Photo, UploadedFile } from "@types";
import { showBrand, updateBrand } from "@api/brands";
import toast from "react-hot-toast";
import FFileUpload from "@components/FFileUpload/FFileUpload";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const getUploadedPhoto = (photo: Photo) => {
  if (!photo) {
    return null;
  }

  const { original } = photo;

  if (!original || original.endsWith("missing_default.png")) {
    return null;
  }

  return {
    url: original,
    file: null,
  };
};

const BrandSettings = () => {
  const { user } = useAuth();
  const { trackEvent } = useContext(MixpanelContext);

  const { memberships } = user;

  const [selectedBrandId, setSelectedBrandId] = useState(
    memberships?.[0]?.brand_id || null
  );

  const [isSubmitted, setSubmitted] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const [values, setValues] = useState<Partial<Brand>>({});

  const [logo, setLogo] = useState<UploadedFile>(null);

  useEffect(() => {
    const loadBrandSettings = async () => {
      const brand = await showBrand(selectedBrandId);

      setValues({
        name: brand?.name || "",
        url: brand?.url || "",
        instagram_handle: brand?.instagram_handle || "",
      });

      setLogo(getUploadedPhoto(brand.logo_photo));
    };

    loadBrandSettings();
  }, [selectedBrandId]);

  const handleSave = async () => {
    const { name, url } = values;

    if (!name || !url) {
      setSubmitted(true);

      return;
    }

    setSaving(true);

    const params = {
      ...values,
      background_photo: null,
      logo_photo: logo?.file || null,
    };

    try {
      await updateBrand(selectedBrandId, params);

      // Track brand settings update
      trackEvent(EVENTS.SETTINGS.BRAND_PREFERENCES_UPDATED, {
        brand_id: selectedBrandId,
        fields_updated: Object.keys(values),
        has_logo_update: !!logo?.file,
      });

      toast.success("Changes saved");
    } catch (e) {
      console.error("E", e);
      toast.error("Error updating brand", e.response?.data);
    } finally {
      setSaving(false);
    }
  };

  const brandOptions =
    memberships
      .filter(({ brand_id }) => !!brand_id)
      .map(({ brand_id, name }) => ({
        label: name,
        value: brand_id,
      })) || [];

  const brandInfoFields = [
    { label: "Company Name", name: "name", required: true },

    { label: "Main Website", name: "url", required: true },
    {
      label: "Instagram Handle",
      name: "instagram_handle",
    },
  ];

  return (
    <div className="max-w-[800px]">
      {brandOptions.length > 0 && (
        <>
          <h1 className="text-[24px] mb-[24px]">Your Brands</h1>
          <div className="mt-[30px] max-w-[400px]">
            <FDropdown
              options={brandOptions}
              selectedValue={selectedBrandId}
              onChange={(value) => setSelectedBrandId(Number(value))}
              width="auto"
              height="32"
            />
          </div>
        </>
      )}
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Basic Info</h1>
        {brandInfoFields.map(({ name, label, required }) => (
          <FInput
            label={label}
            className="mb-[16px]"
            key={name}
            required={required}
            width="100%"
            value={values[name]}
            validate={isSubmitted}
            onChange={(value) => setValues({ ...values, [name]: value })}
          />
        ))}
        <div className="w-1/2">
          <h2 className="text-xl font-semibold mb-4">Brand Logo</h2>
          <FFileUpload
            multiple={false}
            uploadedFiles={[logo]}
            onUpload={(files) => setLogo(files[0])}
            onDelete={() => setLogo(null)}
            includeHover={true}
          />
        </div>
        <div className="mt-[30px] flex justify-end max-w-[200px]">
          <FButton
            primary={true}
            loading={isSaving}
            label="Save"
            width="100%"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default BrandSettings;
