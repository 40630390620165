// src/components/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you're looking for does not exist.</p>
      <Link to="/" style={{ fontSize: '16px', color: 'blue' }}>
        Go back to the homepage
      </Link>
    </div>
  );
};

export default NotFound;
