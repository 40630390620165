import React from "react";

import SocialConnectButton from "@pages/auth/influencer-onboarding/social/SocialConnectButton";

interface InstagramConnectButtonProps {
  isConnected: boolean;
  iconOnly: boolean;
  onClick: () => void;
}

const InstagramConnectButton = ({
  isConnected,
  iconOnly,
  onClick,
}: InstagramConnectButtonProps) => {
  const connectInstagram = async () => {
    try {
      const clientId = "1225636931264268";

      // Determine the origin value based on the current URL.
      const currentUrl = window.location.href;
      const originValue = currentUrl.includes("ambassador")
        ? "ambassador-onboarding"
        : "creator-onboarding";

      // Construct the redirect URI with the appropriate origin parameter.
      const redirectUri = encodeURIComponent(
        `https://api.fohr.co/api/v1/instagram_oauth/callback?origin=${originValue}`
      );

      const instagramOAuthUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement,business_management,public_profile`;

      // Redirect the user to Instagram's OAuth URL.
      window.location.href = instagramOAuthUrl;
    } catch (error) {
      console.error("Failed to connect to Instagram:", error);
    }
  };

  return (
    <SocialConnectButton
      onClick={() => (isConnected ? onClick() : connectInstagram())}
      name="Instagram"
      icon="instagram"
      iconOnly={iconOnly}
      isConnected={isConnected}
    />
  );
};

export default InstagramConnectButton;
