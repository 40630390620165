/* eslint-disable */

import { isArray, isEmpty, isPlainObject } from "lodash";

const setBrowserHistory = (paramString: string) => {
  const url = `${window.location.origin}${window.location.pathname}${paramString}`;
  window.history.pushState({}, document.title, url);
};

const getStatefulUrl = (key: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const getSearchString = (params) => {
  const queryParams = new URLSearchParams();

  Object.keys(params).forEach((param) => {
    if (
      (params[param] && (!isArray(params[param]) || !isEmpty(params[param]))) ||
      params[param] === false
    ) {
      let value = params[param];

      if (isPlainObject(value)) {
        if (!isEmpty(value)) {
          queryParams.append(param, JSON.stringify(value));
        }
      } else {
        queryParams.append(param, `${value}`);
      }
    }
  });

  return queryParams.toString();
};

export { setBrowserHistory, getStatefulUrl };
