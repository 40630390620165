import React from "react";
import { StripeSubscription } from "./types";
import FButton from "@components/FButton";
import ConfirmModal from "@components/Modals/ConfirmModal";

interface SubscriptionStatusProps {
  subscription: StripeSubscription;
  onPortalAccess: () => void;
  onSubscriptionAction: () => void;
  isModalOpen: boolean;
  onModalClose: () => void;
  onConfirmedAction: () => void;
  isActionLoading: boolean;
}

const SubscriptionStatus: React.FC<SubscriptionStatusProps> = ({
  subscription,
  onPortalAccess,
  onSubscriptionAction,
  isModalOpen,
  onModalClose,
  onConfirmedAction,
  isActionLoading,
}) => {
  const formatPaymentMethod = (
    method: StripeSubscription["default_payment_method"]
  ) => {
    if (!method) return "";
    return `${method.card.brand.toUpperCase()} ending in ${method.card.last4}`;
  };

  const isActive = subscription.status === "active";
  const isCanceled = subscription.cancel_at_period_end;

  const monthlyPrice = subscription.items.data[0]?.price.unit_amount
    ? subscription.items.data[0].price.unit_amount / 100
    : 0;

  const getModalContent = () => {
    if (isActive && !isCanceled) {
      return {
        title: "Cancel Subscription",
        subtitle:
          "Are you sure? You will still have access until the end of your billing period.",
        actionLabel: "Yes, Cancel",
      };
    }
    return {
      title: "Renew Subscription",
      subtitle: "Would you like to renew your subscription?",
      actionLabel: "Yes, Renew",
    };
  };

  const modalContent = getModalContent();

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-4 px-6">
          <div className="flex items-center justify-between text-sm">
            <span className="text-black opacity-70">Payment Method:</span>
            <div className="flex-1 max-w-[300px] ml-4">
              <div className="bg-neutral_200 px-4 py-2 rounded-md border border-default_border">
                {subscription.default_payment_method
                  ? formatPaymentMethod(subscription.default_payment_method)
                  : "No payment method"}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between text-sm">
            <span className="text-black opacity-70">Current Period Ends:</span>
            <div className="flex-1 max-w-[300px] ml-4">
              <div className="bg-neutral_200 px-4 py-2 rounded-md border border-default_border">
                {new Date(
                  subscription.current_period_end * 1000
                ).toLocaleDateString()}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between text-sm">
            <span className="text-black opacity-70">Monthly Price:</span>
            <div className="flex-1 max-w-[300px] ml-4">
              <div className="bg-neutral_200 px-4 py-2 rounded-md border border-default_border">
                ${monthlyPrice.toLocaleString()}/month
              </div>
            </div>
          </div>
        </div>

        <div className="pt-4 border-t border-light_border flex justify-between px-6 pb-6">
          <FButton
            onClick={onPortalAccess}
            label="Access Customer Portal"
            primary={false}
          />
          <div>
            {isActive && !isCanceled ? (
              <FButton
                label="Cancel Subscription"
                onClick={onSubscriptionAction}
                primary={true}
                className="bg-red"
              />
            ) : (
              <FButton
                label="Renew Subscription"
                onClick={onSubscriptionAction}
                primary={true}
              />
            )}
          </div>
        </div>
      </div>

      <ConfirmModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        onAction={onConfirmedAction}
        title={modalContent.title}
        subtitle={modalContent.subtitle}
        actionLabel={modalContent.actionLabel}
        isWaiting={isActionLoading}
      />
    </>
  );
};

export default SubscriptionStatus;
