import React, { useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import apiClient from "@apiClient";
import { Sort } from "@types";
import { UserContext } from "@contexts/index";

import CreateOrUpdateCampaignModal from "@pages/campaigns/components/CreateOrUpdateCampaignModal";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { getCampaignList } from "@api/Campaign/Campaigns";
import { changeMembership } from "@api/user";

const defaultSort: Sort = {
  key: "created_at",
  direction: "desc",
};

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Archive", value: "archive" },
  { label: "Add Me", value: "addMembership" },
];

const filterOptions = [
  { label: "All Campaigns", static: "Campaigns", value: null },
  { label: "Active", static: "Campaigns", value: "active" },
  { label: "Proposal", static: "Campaigns", value: "proposal" },
  { label: "Finished", static: "Campaigns", value: "finished" },
  {
    label: "Pending Finance Approval",
    static: "Campaigns",
    value: "pending_finance_approval",
  },
];

const CampaignsAdmin = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const { campaigns, memberships } = apiClient;

  const fetchCampaigns = useCallback(
    async (
      page?: number,
      sort?: Sort,
      params?: { search?: string; filter?: string }
    ) => {
      const { search, filter } = params || {};

      const showPendingFinanceApproval = filter === "pending_finance_approval";

      const response = await getCampaignList(page, sort || defaultSort, true, {
        status_eq: showPendingFinanceApproval ? null : filter,
        salesforce_project_number_or_title_i_cont: search,
        approved_by_finance_eq: showPendingFinanceApproval ? "false" : null,
        submitted_for_finance_review_eq: showPendingFinanceApproval
          ? "true"
          : null,
      });

      return { ...response.data, items: response.data.campaigns };
    },
    []
  );

  const actions = {
    archive: async (id: number) => campaigns.update(id, { status: "archived" }),
    addMembership: async (brandId: number) =>
      memberships.create({
        brand_id: brandId,
        user_id: user.id,
        account_id: user.account_id,
      }),
  };

  const handleCampaignCreated = (newCampaign) => {
    setModalVisible(false);
    navigate(`/campaigns/${newCampaign.id}`);
  };

  const handleTableCellClick = async (item, columnKey) => {
    if (columnKey === "title" && !item.approved_by_finance) {
      window.open(`/campaigns/${item.id}/finance-approval`, "_blank");

      return;
    }

    const existingMembership = user.memberships.find(
      (membership) => membership.brand_id === item.brand_id
    );

    if (user.brand_id === item.brand_id) {
      // Redirect if the user already belongs to the brand
      return redirectToCampaign(item.id);
    }

    if (existingMembership) {
      // Handle existing membership
      return handleMembershipChange(existingMembership, item);
    }

    try {
      // Create a new membership if it doesn't exist
      const newMembership = await actions.addMembership(item.brand_id);

      if (newMembership?.data?.membership) {
        return handleMembershipChange(newMembership.data.membership, item);
      }

      toast.error("Failed to create a new membership. Please try again.");
    } catch (error) {
      console.error("Error creating membership:", error);
      toast.error("Error creating membership. Please try again.");
    }
  };

  const handleMembershipChange = async (membership, item) => {
    try {
      const result = await changeMembership({ id: membership.id });

      if (result.data.brand_id === membership.brand_id) {
        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        redirectToCampaign(item.id);
      } else {
        toast.error("Failed to switch membership. Please try again.");
      }
    } catch (error) {
      console.error("Error changing membership:", error);
      toast.error("Error changing membership. Please try again.");
    }
  };

  const redirectToCampaign = (campaignId) => {
    navigate(`/campaigns/${campaignId}`);
  };

  const tableColumns = [
    {
      key: "title",
      label: "Title",
      isLink: true,
      linkTarget: "_blank",
      isImage: false,
    },
    { key: "status", label: "Status", isStateBubble: true },
    {
      key: "brand_name",
      label: "Company Name",
      isLink: true,
      linkURL: "/admin/brands/{brand_id}",
    },
    {
      key: "membership",
      label: "Your Membership",
      getValue: (item) =>
        user.memberships.some((m) => m.brand_id === item.brand_id)
          ? "User"
          : "",
    },
    {
      key: "salesforce_project_number",
      label: "Project Number",
    },
    {
      key: "budget",
      label: "Budget",
      isMoney: true,
      defaultLabel: "No budget specified",
    },
    { key: "go_live_start", label: "Start Date", isDate: true },
    { key: "go_live_end", label: "End Date", isDate: true },
    { key: "full_name", label: "Manager", nestedKey: "owner_user" },
    { key: "created_at", label: "Created On", isDate: true },
  ];

  return (
    <>
      <SinglePageLayoutWithData
        entityName="campaign"
        pageTitle="Campaigns"
        pageName="Campaigns"
        cardType="asset"
        sortPathName="title"
        sortPathDate="created_at"
        filterOptions={filterOptions}
        tableColumns={tableColumns}
        rowActions={cardDropDownOptions}
        tableOnly
        asyncActions={actions}
        onTableCellClick={handleTableCellClick}
        createWithEntityCrud={() => setModalVisible(true)}
        getItems={fetchCampaigns}
        updateModal={CreateOrUpdateCampaignModal}
      />
      {isModalVisible && (
        <CreateOrUpdateCampaignModal
          onClose={() => setModalVisible(false)}
          onCreated={handleCampaignCreated}
        />
      )}
    </>
  );
};

export default CampaignsAdmin;
