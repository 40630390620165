import React, { useState, useContext } from "react";
import FInput from "@components/FInputs/FInput";
import FButton from "@components/FButton";
import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

interface CombineReportsFormProps {
  onClose: () => void;
  selectedReports: number[];
  getReports: () => void;
  setSelectedReports: (reports: number[]) => void;
}

// Interface for combine reports response
interface CombineReportsResponse {
  new_report_id: number;
  [key: string]: any;
}

const CombineReportsForm: React.FC<CombineReportsFormProps> = ({
  onClose,
  selectedReports,
}) => {
  const [combinedReportName, setCombinedReportName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const { trackEvent } = useContext(MixpanelContext);

  const submit = async () => {
    if (!combinedReportName.trim()) {
      toast.error("Report name cannot be empty.");
      return;
    }
    setIsUpdating(true);
    const response = await onSubmit();
    if (response.success) {
      // Track reports combined event
      trackEvent(EVENTS.REPORT.COMBINED, {
        new_report_id: response.data.new_report_id,
        new_report_name: combinedReportName,
        source_report_ids: selectedReports,
        report_count: selectedReports.length,
      });

      navigate(`/reports/${response.data.new_report_id}`);
      toast.success("Reports Sucessfully Combined!");
    }
    setIsUpdating(false);
  };

  const onSubmit = async (): Promise<ApiResponse<CombineReportsResponse>> => {
    return apiWrapper("/reports/combine", "POST", {
      report_ids: selectedReports,
      new_report_name: combinedReportName,
    });
  };

  return (
    <>
      <div className="text-center p-2">
        <FInput
          type="text"
          required
          label="Report Name"
          onChange={(value) => setCombinedReportName(value)}
          // value={name}
          width="100%"
        />
      </div>
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 pt-6 border-t border-light_border">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={submit}
          label="Save"
          loading={isUpdating}
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </>
  );
};

export default CombineReportsForm;
