import React, { useContext } from "react";

import ManageQuestions from "@components/questions/ManageQuestions";
import {
  createBulletinQuestion,
  deleteBulletinQuestion,
  listBulletinQuestions,
  updateBulletinQuestion,
} from "@api/Bulletins/BulletinQuestions";
import { useParams } from "react-router-dom";
import { QuestionnaireQuestionRequest } from "types/questionnaire";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const getUpdateParams = (originalParams: QuestionnaireQuestionRequest) => {
  const params = {
    ...originalParams,
  };

  if (typeof params.data === "string") {
    params.data = { options: params.data.split(",") };
  }

  return params;
};

const BulletinQuestions = () => {
  const { bulletin_id } = useParams();
  const { trackEvent } = useContext(MixpanelContext);

  const handleCreateQuestion = async (originalParams) => {
    // Track question creation
    trackEvent(EVENTS.BULLETIN.QUESTIONS_UPDATED, {
      bulletin_id: bulletin_id,
      action: "question_created",
      question_type: originalParams.field_type,
      is_required: originalParams.required,
    });

    return createBulletinQuestion({
      ...getUpdateParams(originalParams),
      bulletin_id: parseInt(bulletin_id, 10),
    });
  };

  const handleUpdateQuestion = async (questionId, originalParams) => {
    // Track question update
    trackEvent(EVENTS.BULLETIN.QUESTIONS_UPDATED, {
      bulletin_id: bulletin_id,
      question_id: questionId,
      action: "question_updated",
      question_type: originalParams.field_type,
      fields_updated: Object.keys(originalParams),
    });

    return updateBulletinQuestion(
      questionId,
      getUpdateParams(originalParams as QuestionnaireQuestionRequest)
    );
  };

  const handleDeleteQuestion = async (questionId) => {
    // Track question deletion
    trackEvent(EVENTS.BULLETIN.QUESTIONS_UPDATED, {
      bulletin_id: bulletin_id,
      question_id: questionId,
      action: "question_deleted",
    });

    return deleteBulletinQuestion(questionId);
  };

  return (
    <div className="w-full z-10">
      <ManageQuestions
        entityName="Bulletin"
        listQuestions={() =>
          listBulletinQuestions({ bulletin_id: parseInt(bulletin_id, 10) })
        }
        createQuestion={handleCreateQuestion}
        updateQuestion={handleUpdateQuestion}
        deleteQuestion={handleDeleteQuestion}
        hideChildQuestions
        hideVisibilitySettings
        updatedQuestionEventName={EVENTS.BULLETIN.QUESTION_UPDATED}
        updatedSettingsEventName={EVENTS.BULLETIN.SETTINGS_UPDATED}
      />
    </div>
  );
};

export default BulletinQuestions;
