import moment from "moment";

export const isValidUrl = (urlString) => {
  const regex =
    /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(:\d+)?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/i;
  return regex.test(urlString);
};

export const isYoungerThan = (date: string, minAge: number) => {
  const dateToCheck = moment(date);

  const minAgeYearsAgo = moment().subtract(minAge, "years");

  // Check if the date is younger than min age
  return dateToCheck.isAfter(minAgeYearsAgo);
};

export const getFullUrl = (url: string) => {
  const urlToTest = url.toLowerCase();

  let prepend = "";

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    prepend = "https://";

    if (!url.startsWith("www.")) {
      prepend += `www.`;
    }

    return `${prepend}${urlToTest}`;
  }

  return url;
};
