import React, { useCallback } from "react";

import { Sort } from "@types";
import { getBulletinList, deleteBulletin } from "@api/Bulletins/Bulletins";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import CreateOrUpdateBulletinModal from "./components/CreateOrUpdateBulletinModal";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

const actions = {
  delete: async (id: number) => deleteBulletin(id),
};
const tableColumns = [
  {
    key: "name",
    label: "Title",
    isLink: true,
    linkURL: "/bulletins/{id}",
    isImage: false,
    imageType: "asset" as "asset" | "profile",
    imageKey: "image_url",
  },
  { key: "recipient_status", label: "State", isStateBubble: true },
  { nestedKey: "owner_user", key: "full_name", label: "Owner" },
  { key: "participant_count", label: "Participants", isNumber: true },
  { key: "start_date", label: "Start Date", isDate: true },
  { key: "created_at", label: "Created At", isDate: true },
];

export default function BrandBulletinsPage() {
  const { can } = useAuth();

  const filterOptions = [
    { label: "All", static: "Bulletins", value: null },
    { label: "Active", static: "Bulletins", value: "active" },
    { label: "Featured", static: "Bulletins", value: "featured" },
    { label: "Archived", static: "Bulletins", value: "archived" },
  ];

  const getBulletins = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const response = await getBulletinList(false, page, sort, {
        status_eq: filter,
        name_cont: search,
      });

      const { bulletins: items } = response.data;

      return {
        ...response.data,
        items: items.map((item) => ({
          ...item,
          participants_count: item.participant_count,
        })),
      };
    },
    []
  );

  const cardDropDownOptions = [
    { label: "Edit", value: "edit" },
    can(Permission.DESTROY_BULLETINS) && { label: "Archive", value: "delete" },
  ].filter(Boolean);

  return (
    <SinglePageLayoutWithData
      entityName="bulletin"
      pageTitle="Bulletins"
      pageName="Bulletins"
      cardType="asset"
      sortPathName="name"
      sortPathDate="date"
      filterOptions={filterOptions}
      filterPath="state"
      tableColumns={tableColumns}
      cardDropDownOptions={cardDropDownOptions}
      rowActions={cardDropDownOptions}
      asyncActions={actions}
      getItems={getBulletins}
      updateModal={CreateOrUpdateBulletinModal}
      defaultGrid
    />
  );
}
