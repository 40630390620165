import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { CampaignContext } from "@contexts/index";
import { Link } from "react-router-dom";
import FohrLogo from "@components/FohrLogo";
import FIcon from "@components/FIcon";
import UserMenu from "@components/FSidebar/UserMenu";
import useAuth from "@hooks/useAuth";
import { CREATOR_REVIEW_STATUSES } from "@pages/campaigns/participant-review/constants";

export default function EventSideNav({
  eventPageTitle,
  selectedApprovalState,
  setSelectedApprovalState,
}) {
  const { user } = useAuth();

  const { campaign } = useContext(CampaignContext);
  const { campaign_id } = useParams<{
    campaign_id: string;
  }>();

  const { participants } = campaign;

  const { first_name, last_name } = user;

  const contentStates = [
    { label: "All", value: null },
    ...CREATOR_REVIEW_STATUSES,
  ];

  return (
    <div className="fixed left-0 z-50 h-screen bg-light_red_opacity w-[245px] border-r border-light_border flex flex-col justify-between backdrop-blur-sm">
      <Link
        to="/"
        className="flex flex-row items-center justify-center w-full p-[24px]"
      >
        <FohrLogo />
      </Link>

      <h1 className="text-[46px] px-[24px] text-center leading-[54px]">
        {eventPageTitle}
      </h1>
      <Link
        to={`/campaigns/${campaign_id}/participants`}
        className="text-[15px] px-[24px] text-center hover:underline"
      >
        {campaign?.title}
      </Link>
      <div className="flex flex-col text-[15px] gap-2 items-start justify-start w-full p-[24px]">
        {contentStates.map((state, index) => {
          let totalCount;
          if (state.value === null) {
            totalCount = participants.length;
          } else {
            totalCount = participants.filter(
              (participant) => participant.status === state.value
            ).length;
          }
          return (
            <div
              key={index}
              onClick={() => setSelectedApprovalState(state.value)}
              className={`text-left w-full ${
                selectedApprovalState === state.value
                  ? "text-dark_night_sky opacity-100"
                  : "opacity-50"
              } cursor-pointer hover:opacity-75`}
            >
              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-left justify-start w-[47px]">
                  {selectedApprovalState === state.value && (
                    <FIcon
                      icon="sort-up"
                      size={14}
                      color="#000F45"
                      className="rotate-90"
                    />
                  )}
                </div>
                <div className="flex flex-row gap-2 items-center justify-center w-full">
                  <p>{state.label}</p>
                  <p>{totalCount}</p>
                </div>

                <div className="flex flex-row items-center justify-end w-[47px]">
                  {selectedApprovalState === state.value && (
                    <FIcon
                      icon="sort-up"
                      size={14}
                      color="#000F45"
                      className="-rotate-90"
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <UserMenu
        user={user}
        collapsed={false}
        menu={{
          title: `${first_name} ${last_name}`,
          subtitle: "Brand",
          items: [
            {
              name: "Home",
              icon: { name: "home", color: "black" },
              link: "/",
            },
          ],
        }}
      />
    </div>
  );
}
