import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { Report, Sort } from "@types";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { getSearchString } from "@utils/history";

const getReportsList = async (
  admin?: boolean,
  page?: number,
  sort?: Sort,
  params?: object
): Promise<ApiResponse<{ reports: []; total_items: number }>> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);
  const endpoint = admin ? `/admin/reports` : `/reports`;
  return apiWrapper(`${endpoint}?${queryParams.toString()}`, "GET");
};

const getReport = async (report_id: number): Promise<ApiResponse<Report>> => {
  return apiWrapper(`/reports/${report_id}`, "GET");
};

const getSearchParts = (searchTerm: string) => {
  const hashtags = [];
  const mentions = [];
  const keywords = [];

  if (searchTerm) {
    searchTerm.split(" ").forEach((word) => {
      if (word.startsWith("#")) {
        hashtags.push(word.slice(1));
      } else if (word.startsWith("@")) {
        mentions.push(word.slice(1));
      } else {
        keywords.push(word);
      }
    });
  }

  return {
    hashtags,
    mentions,
    keywords,
  };
};

const createReport = (
  title: string,
  params: {
    searchTerm?: string;
    creator_ids?: number[];
    platforms?: string[];
    categories?: string[];
    p?: number;
    page_size?: number;
    per_page?: number;
    post_type?: string;
    sort?: string;
    sponsored?: boolean;
    date_from?: string;
    date_to?: string;
  }
): Promise<
  ApiResponse<{
    results: [];
    total: number;
  }>
> => {
  const { searchTerm, ...rest } = params;

  const { hashtags, mentions, keywords } = getSearchParts(searchTerm);

  const paramsToUse = {
    ...rest,
    hashtags,
    mentions,
    any: keywords,
  };
  return apiWrapper(
    `/reports?title=${title}&${getSearchString(paramsToUse)}`,
    "POST"
  );
};

const editReport = (
  report_id: string | number,
  title: string
): Promise<
  ApiResponse<{
    results: [];
    total: number;
  }>
> => {
  return apiWrapper(`/reports/${report_id}?title=${title}`, "PUT");
};

const deleteReport = async (
  report_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/reports/${report_id}`, "DELETE");
};

const deleteReportContent = async (
  content_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/report_contents/${content_id}`, "DELETE");
};

const deleteReportCreator = async (
  creator_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/report_participants/${creator_id}`, "DELETE");
};

const getReportSummary = async (
  reportId?: string | number,
  bonus?: boolean | false
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/reports/summary?id=${reportId}&bonus=${bonus}`, "GET");
};

const getReportContentSummary = async (
  reportId?: string | number,
  bonus?: boolean | undefined
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/reports/content_summary?id=${reportId}&bonus=${bonus}`,
    "GET"
  );
};

const getReportInfluencerBreakdown = async (
  reportId?: string | number,
  bonus?: boolean | undefined
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/reports/influencer_breakdown?id=${reportId}}&bonus=${bonus}`,
    "GET"
  );
};

const getCreators = async (
  reportId?: string | number,
  page?: number,
  sort?: Sort,
  params?: object
): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(
    `/reports/creators?id=${reportId}&${queryParams.toString()}`,
    "GET"
  );
};

const getReportContent = async (
  reportId?: string | number,
  page?: number,
  sort?: Sort,
  bonus?: boolean,
  params?: object
): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(
    `/reports/content_overview?id=${reportId}&bonus=${bonus}&${queryParams.toString()}`,
    "GET"
  );
};

const getReportPerformanceGraphs = async (
  reportId?: string | number,
  bonus?: boolean | undefined
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/reports/performance_tiles?id=${reportId}&bonus=${bonus}`,
    "GET"
  );
};

const getPostsEngagementGraph = async (
  reportId: string | number,
  bonus?: boolean | undefined,
  platform?: string,
  dateRange?: string,
  includePaidMedia?: boolean
): Promise<ApiResponse<any>> => {
  const queryParams = new URLSearchParams({
    id: reportId.toString(),
    bonus: bonus?.toString() || "false",
    platform: platform || "",
    date_range: dateRange || "",
    include_paid_media: includePaidMedia?.toString() || "false",
  });

  return apiWrapper(
    `/reports/posts_engagements_graph/?${queryParams.toString()}`,
    "GET"
  );
};

const getReportsAudienceData = async (
  reportId: string | number,
  platform: string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/reports/audience_demographics?id=${reportId}&platform=${platform}`,
    "GET"
  );
};

const downloadReportCSV = async (
  reportId: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/reports/csv?id=${reportId}`, "GET");
};

export const getRevenueRecognitionReport = (params: {
  start_date: string;
  end_date: string;
}) =>
  apiWrapper(`/reports/revenue_recognition?${getSearchString(params)}`, "GET");

export {
  getReportsList,
  getReport,
  createReport,
  editReport,
  deleteReport,
  getReportSummary,
  getReportPerformanceGraphs,
  getPostsEngagementGraph,
  getReportContentSummary,
  getCreators,
  getReportContent,
  getReportInfluencerBreakdown,
  getReportsAudienceData,
  downloadReportCSV,
  deleteReportContent,
  deleteReportCreator,
};
