import { Content, Profile, ViewsData } from "@types";
import PlatformStats from "./components/PlatformStats";
import KPIWithSparkline from "@components/Dashboard/KPIWithSparkline";
import PieChart from "./components/PieChart";

import { useContext, useEffect, useState } from "react";
import { ProfileContext } from "@contexts/index";
import { POST_CATEGORIES } from "@api/Search";
import { AutoTextSize } from "auto-text-size";
import { FTooltip } from "@components/FTooltip";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCategoryBreakdown,
  getOptimizedColors,
  CHART_COLORS,
} from "@utils/ProfileCalculations";

import TierPerformanceKPISkeleton from "@components/skeleton/TierPerformanceKPISkeleton";

// Helper function to bucket timeseries data into 10 points
const bucketTimeseriesData = (
  data: { date: Date; value: number }[],
  numBuckets = 10
) => {
  if (data.length <= numBuckets) return data;

  // Sort data by date first
  const sortedData = [...data].sort(
    (a, b) => a.date.getTime() - b.date.getTime()
  );

  const buckets: { date: Date; value: number }[] = [];
  const pointsPerBucket = Math.ceil(data.length / numBuckets);

  for (let i = 0; i < numBuckets; i++) {
    const start = i * pointsPerBucket;
    const end = Math.min(start + pointsPerBucket, data.length);
    const bucketData = sortedData.slice(start, end);

    if (bucketData.length === 0) break;

    // Calculate average value for the bucket
    const avgValue =
      bucketData.reduce((sum, point) => sum + point.value, 0) /
      bucketData.length;
    // Use the middle date from the bucket
    const middleIndex = Math.floor(bucketData.length / 2);
    const date = bucketData[middleIndex].date;

    buckets.push({ date, value: avgValue });
  }

  return buckets;
};

const getKpis = (
  stats: any,
  viewsData: ViewsData,
  content: Content[],
  tierPerformance: number
) => {
  if (!stats?.top_stats) {
    return [];
  }

  const { top_stats, follower_growth = [], engagement_growth = [] } = stats;

  // Convert follower growth data to timeseries format and bucket
  const followerTimeSeries = bucketTimeseriesData(
    follower_growth.map(({ date, followers_avg }) => ({
      date: new Date(date),
      value: followers_avg,
    }))
  );

  // Convert engagement growth data to timeseries format and bucket
  const engagementTimeSeries = bucketTimeseriesData(
    engagement_growth.map(({ date, engagement_avg }) => ({
      date: new Date(date),
      value: engagement_avg,
    }))
  );

  // Create views timeseries from content and bucket
  const viewsTimeSeries = bucketTimeseriesData(
    content
      // Filter out stories and ensure we have valid data
      .filter(
        (post) =>
          post.media_type !== "Story" && post.published_at && post.views_count
      )
      // Sort by date first to ensure we're showing chronological progression
      .sort(
        (a, b) =>
          new Date(a.published_at).getTime() -
          new Date(b.published_at).getTime()
      )
      // Take only the most recent posts to avoid overcrowding
      .slice(-30)
      .map((post) => ({
        date: new Date(post.published_at),
        value: parseInt(post.views_count, 10) || 0,
      }))
  );

  const kpis = [];
  // Only add tier performance if it's available
  if (top_stats.tier_performance) {
    kpis.push({
      name: "Tier Performance",
      value: top_stats.tier_performance,
      valueType: "tier" as const,
      // tier: top_stats.follower_tier,
      timeSeriesData: [],
    });
  }

  kpis.push(
    {
      name: "Total Followers",
      value: top_stats.followers,
      valueType: "number" as const,
      timeSeriesData: followerTimeSeries,
    },
    {
      name: "Total Views",
      value: top_stats?.views || 0,
      valueType: "number" as const,
      timeSeriesData: viewsTimeSeries,
    },
    {
      name: "Avg Engagement",
      value: top_stats.engagement_rate?.value || 0,
      valueType: "percent" as const,
      timeSeriesData: engagementTimeSeries,
    }
  );

  return kpis;
};

interface ProfileOverviewProps {
  content: Content[];
  profile: Profile;
  viewsData: ViewsData;
}

const ProfileOverview = ({
  content,
  profile,
  viewsData,
}: ProfileOverviewProps) => {
  const { setSelectedCategoryId, stats, tierPerformance } =
    useContext(ProfileContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { participant_id } = useParams();

  // Store the section to scroll to after navigation
  const [pendingScroll, setPendingScroll] = useState<string | null>(null);

  // Hide KPIs section when on stats page
  const showKPIs = !location.pathname.includes("/stats");

  const scrollToSection = (sectionName: string) => {
    // Navigate to stats page and store the section to scroll to
    // Get the current path parts and replace the last segment with 'stats'
    const pathParts = location.pathname.split("/");
    pathParts[pathParts.length - 1] = "stats";
    const statsPath = pathParts.join("/");

    navigate(statsPath);
    setPendingScroll(sectionName);
  };

  // Effect to handle scrolling after navigation
  useEffect(() => {
    if (location.pathname.includes("/stats") && pendingScroll) {
      const element = document.querySelector(
        `[data-section="${pendingScroll}"]`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setPendingScroll(null);
    }
  }, [location, pendingScroll]);

  const {
    first_name,
    last_name,
    location: profileLocation,
    platforms,
    tagline,
  } = profile;

  const fullName = `${first_name} ${last_name}`;

  const kpis = getKpis(stats, viewsData, content, tierPerformance);

  const categoryBreakdown = getCategoryBreakdown(profile, content);
  const optimizedColors = categoryBreakdown
    ? getOptimizedColors(categoryBreakdown)
    : CHART_COLORS;

  const handleCategoryClick = (categoryName: string) => {
    const categoryId = Object.keys(POST_CATEGORIES).find(
      (key) => POST_CATEGORIES[key] === categoryName
    );

    setSelectedCategoryId(parseInt(categoryId, 10));
  };

  const isParticipantProfile = !!participant_id;

  return (
    <div
      className={`c-lg:h-full ${isParticipantProfile ? "c-lg:px-[30px]" : "c-lg:px-[60px]"}  py-[40px] overflow-auto`}
    >
      <div className="flex items-center max-w-[80%] relative">
        <AutoTextSize
          className="text-[40px]"
          maxFontSizePx={isParticipantProfile ? 50 : 100}
          minFontSizePx={isParticipantProfile ? 14 : 20}
        >
          {fullName}
        </AutoTextSize>
        <img
          className="absolute top-[14%] right-[-8%] h-[16px] w-[16px]"
          src="/images/verified_check.png"
        />
      </div>
      <p className="mb-[40px] opacity-70 text-black text-md">
        {profileLocation?.replace(/, US(A)?$/, "")}
      </p>
      {tagline ? (
        <div className="mb-[40px] h-full max-h-[150px] w-[400px] opacity-70 text-black">
          <AutoTextSize mode="box" maxFontSizePx={50} minFontSizePx={16}>
            <p className="line-clamp-[5] text-ellipsis overflow-hidden leading-[120%]">
              {tagline}
            </p>
          </AutoTextSize>
        </div>
      ) : null}
      <div className="mb-[30px]">
        <PlatformStats platforms={platforms} />
      </div>

      {showKPIs && (
        <div className="grid grid-cols-2 gap-8">
          {!stats ? (
            // Show skeletons while loading
            <>
              <TierPerformanceKPISkeleton />
              <TierPerformanceKPISkeleton />
              <TierPerformanceKPISkeleton />
              <TierPerformanceKPISkeleton />
            </>
          ) : (
            kpis.map(({ name, value, valueType, timeSeriesData }) => {
              if (valueType === "tier") {
                return (
                  <div
                    key={name}
                    className="flex flex-col text-left cursor-pointer rounded-lg transition-colors"
                    onClick={() => scrollToSection("stats")}
                  >
                    <span className="text-sm text-black">{name}</span>
                    <div className="flex flex-row gap-1">
                      <span
                        className={`text-2xl font-bold mt-[5px] ${(value || 0) > 1 ? "text-[#000]" : ""}`}
                      >
                        {(value || 0).toFixed(1)}
                      </span>
                      <span
                        className={`text-sm mt-auto mb-1 ${(value || 0) > 1 ? "text-[#000]" : "text-neutral_600"}`}
                      >
                        x Avg.
                      </span>
                    </div>
                  </div>
                );
              }

              const getSectionName = (metricName: string) => {
                switch (metricName) {
                  case "Total Followers":
                    return "followers";
                  case "Total Views":
                    return "core-metrics";
                  case "Avg Engagement":
                    return "engagement";
                  default:
                    return null;
                }
              };

              return (
                <KPIWithSparkline
                  key={name}
                  noPadding
                  title={name}
                  value={value}
                  valueType={valueType}
                  timeSeriesData={timeSeriesData}
                  color="#7AC9E2"
                  small
                  onClick={() => {
                    const section = getSectionName(name);
                    if (section) scrollToSection(section);
                  }}
                />
              );
            })
          )}
        </div>
      )}
      <div className="pt-[40px] mt-[40px] border-t border-light_border">
        <p className="mb-3 opacity-20 text-black">Posts About</p>
        <div className="flex gap-6 items-center">
          {categoryBreakdown ? (
            <div className="h-[56px] w-[56px]">
              <PieChart
                colors={optimizedColors}
                data={categoryBreakdown}
                onCategoryClick={handleCategoryClick}
              />
            </div>
          ) : null}
          <div className="flex flex-1 flex-wrap gap-[10px] items-center">
            {categoryBreakdown?.map(({ name, percentage }, index) => (
              <FTooltip
                key={name}
                label={`${Math.round(percentage)}%`}
                style={{ backgroundColor: optimizedColors[index] }}
              >
                <div className="flex gap-[5px] hover:underline items-center cursor-pointer">
                  <div
                    className="h-[5px] w-[5px] rounded-full -mt-[2px]"
                    style={{
                      backgroundColor: optimizedColors[index],
                    }}
                  />
                  <span className="text-sm">
                    {name}
                    {index !== (categoryBreakdown?.length || 0) - 1 ? "" : ""}
                  </span>
                </div>
              </FTooltip>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
