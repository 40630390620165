import React, { useState, useEffect, ReactNode } from "react";

import { debounce, mapValues } from "lodash";
import Container from "@components/Container";
import FSearch from "@components/FSearch";
import ResultsGrid from "@components/ResultsGrid";
import BlankState from "@components/BlankState";
import Modal from "@components/Modals/Modal";
import FInput from "@components/FInputs/FInput";
import toast from "react-hot-toast";
import Heading from "@components/Heading";
import FButton from "@components/FButton";

import LoadingSpinner from "@components/LoadingSpinner";
import useSortAndFilter from "@hooks/useSortAndFilter";
import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";
import FPagination from "@components/FPagination";
import resolvePath from "@utils/resolvePath";
import Table from "@components/Table";

import { Pagination, Sort } from "@types";

interface Column {
  key: string;
  label: string;
  className?: string;
  isLink?: boolean;
  linkURL?: string;
  isStateBubble?: boolean;
  isImage?: boolean; // New property to determine if the column should render as an image
  imageType?: "asset" | "profile"; // New property to specify the image type
  imageKey?: string; // Specifies which data property contains the image URL
  nestedKey?: string; // Optional property to handle nested data
}

// TODO: Implement generics <T> here at a certain point
export interface SinglePageLayoutProps {
  pageName: string;
  numColumns?: number;
  postApproval?: boolean;
  addAssetCard?: boolean;
  inlineLayout?: boolean;
  decorationPath?: string;
  pageIcon?: string;
  cardType?: "individual" | "entity" | "asset" | "participant" | "content";
  pageTitle?: string;
  tableColumns?: Column[];
  cardData?: any[];
  filterOptions?: { label: string; static?: string; value: string | boolean }[];
  className?: string;
  cardDropDownOptions?: { label: string; value: string }[];
  showTotals?: boolean;
  actions?: {
    create?: (name: string) => Promise<any>;
    update?: (id: number, data: any) => Promise<any>;
    read?: (state: string) => Promise<any>;
    delete?: (id: number, pageName: string) => Promise<any>;
  };
  hideCreateNewButton?: boolean;
  createWithEntityCrud?: (value: string, id: number | string) => void;
  customFilter?: React.ReactNode;
  defaultGrid?: boolean;
  filterPath?: string; // Where in the data we want to filter sent as a string like "data.state"
  loading?: boolean;
  onChangeSelectedItems?: (ids: any[]) => void;
  selectedItems?: any[];
  onOptionSelect?: (value: string, id: number) => void;
  onTableRowDropDownSelect?: (value: string, id: number) => void;
  onTableCellClick?: (item: any, columnKey: string) => void;
  onCardClick?: (item: any) => void;
  rowActions?: { label: string; value: string | boolean }[];
  getRowActions?: (id: number) => { label: string; value: string | boolean }[];
  searchPathName?: string;
  sortPathDate?: string;
  sortPathName?: string;
  subNavMainButton?: React.ReactNode;
  tableOnly?: boolean;
  lastRowNoBorder?: boolean;
  tableRowDropDownOptions?: { label: string; value: string }[];
  pagination?: Pagination;
  onPageChange?: (page: number) => void;
  onSearchChange?: (term: string) => void;
  onSortChange?: (sort: Sort) => void;
  onFilterChange?: (filter: string) => void;
  externalSort?: Sort;
  headerButtons?: React.ReactNode;
  customBlankState?: React.ReactNode;
  hasTopPadding?: boolean;
  highLevel?: {
    icon: string;
    name: string;
    value: string;
  }[];
  pageSwitch?: React.ReactNode;
  blankStateIcon?: string;
  onExpandRows?: (isExpanded: boolean) => void;
  areRowsExpanded?: boolean;
  topLeftTableContent?: ReactNode;
  summaryContent?: ReactNode;
  customFiltersContent?: ReactNode;
  numCardColumns?: number;
  totalItemsLabel?: string;
  activeSearchColumnOptions?: { label: string; value: string }[];
  activeSearchColumnValue?: string;
  onActiveSearchColumnChange?: (value: string) => void;
}

export default function SinglePageLayout({
  actions,
  inlineLayout,
  addAssetCard,
  cardData,
  cardDropDownOptions,
  cardType,
  className,
  hideCreateNewButton,
  createWithEntityCrud,
  decorationPath,
  pageIcon,
  pageName,
  pageTitle,
  rowActions,
  getRowActions,
  tableColumns,
  tableOnly,
  customFilter,
  sortPathName,
  subNavMainButton,
  sortPathDate,
  showTotals,
  searchPathName,
  defaultGrid,
  filterOptions,
  filterPath,
  loading,
  onChangeSelectedItems,
  onTableRowDropDownSelect,
  onTableCellClick,
  onCardClick,
  pagination,
  onPageChange,
  onSearchChange,
  onSortChange,
  onFilterChange,
  lastRowNoBorder = false,
  selectedItems,
  externalSort,
  headerButtons,
  customBlankState,
  pageSwitch,
  highLevel,
  blankStateIcon,
  onExpandRows,
  hasTopPadding,
  areRowsExpanded,
  topLeftTableContent,
  summaryContent,
  customFiltersContent,
  numCardColumns,
  totalItemsLabel,
  activeSearchColumnOptions,
  activeSearchColumnValue,
  onActiveSearchColumnChange,
}: SinglePageLayoutProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [gridView, setGridView] = useState(
    defaultGrid === undefined ? true : defaultGrid
  );
  const [isSearchEnabled, setSearchEnabled] = useState(true);

  const {
    data: filteredData,
    setSortCriteria,
    setSelectedFilter,
    sortCriteria, // Now using sortCriteria from the hook
    selectedFilter, // Now using selectedFilter from the hook
  } = useSortAndFilter(
    cardData,
    filterOptions,
    sortPathName,
    sortPathDate,
    filterPath,
    onSortChange
  );

  const handleToggleItemSelection = (id) => {
    let newSelectedItems = selectedItems || [];

    if (selectedItems.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedItems, id];
    }

    onChangeSelectedItems(newSelectedItems);
  };

  const handleModalAction = async () => {
    if (inputValue.trim() === "") {
      setInputError("Please enter a name");
    } else {
      try {
        const result = await actions.create(inputValue.trim());
        if (result.success) {
          setModalOpen(false);
          setInputError("");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setInputError("An error occurred while creating the item.");
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setInputError("");
    setInputValue("");
  };
  const filteredSearch = onSearchChange
    ? filteredData
    : filteredData.filter((item) => {
        if (!isSearchEnabled) {
          // If search is disabled, return all items
          return true;
        } else {
          // Determine the path to use for searching

          const pathToUse = searchPathName || sortPathName;

          const searchField = resolvePath(item, pathToUse);

          // Guard against items without data in the resolved path
          if (!searchField) return false;

          // Convert searchField to lowercase for case-insensitive comparison
          const searchFieldLower = searchField.toLowerCase();

          // Check if the search term is included in the search field
          const matchesSearchTerm = searchFieldLower.includes(
            searchTerm.toLowerCase()
          );

          return matchesSearchTerm;
        }
      });

  function renderContent() {
    if (filteredSearch.length === 0) {
      return customBlankState ? (
        customBlankState
      ) : (
        <BlankState
          title={`No ${pageName.toLowerCase()} found`}
          subtitle={`Add ${pageName.toLowerCase()} Here`}
          icon={blankStateIcon || "user"}
          onActionClick={() =>
            createWithEntityCrud
              ? createWithEntityCrud("create", "create")
              : setModalOpen(true)
          }
          actionLabel={createWithEntityCrud !== null && "Create New"}
        />
      );
    }

    const { currentPage, pageSize, total } = pagination || {};

    return (
      <div>
        {gridView && !tableOnly ? (
          <ResultsGrid
            page={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={onPageChange}
            cardData={onSortChange ? cardData : filteredSearch}
            addAssetCard={addAssetCard}
            decorationPath={decorationPath}
            cardType={cardType}
            onToggleItemSelection={
              onChangeSelectedItems && handleToggleItemSelection
            }
            selectedItems={selectedItems}
            cardDropDownOptions={cardDropDownOptions}
            onTableRowDropDownSelect={onTableRowDropDownSelect}
            onCardClick={onCardClick}
            numColumns={numCardColumns}
          />
        ) : (
          <div className="mt-10">
            <Table
              showTotals={showTotals}
              data={onSortChange ? cardData : filteredSearch}
              columns={tableColumns}
              onSortChange={onSortChange}
              lastRowNoBorder={lastRowNoBorder}
              sort={externalSort}
              rowActions={rowActions}
              getRowActions={getRowActions}
              onOptionSelect={onTableRowDropDownSelect}
              onChangeSelectedItems={onChangeSelectedItems}
              onCellClick={onTableCellClick}
              selectedItems={selectedItems}
              onExpandRows={onExpandRows}
              areRowsExpanded={areRowsExpanded}
              topLeftContent={topLeftTableContent}
              hasTopPadding={hasTopPadding}
            />
          </div>
        )}
        {onPageChange && total > pageSize ? (
          <div className="mt-[28px]">
            <FPagination
              page={currentPage}
              pageSize={pageSize}
              total={total}
              onPageChange={onPageChange}
            />
          </div>
        ) : null}
        {totalItemsLabel ? (
          <div
            className={`flex items-center justify-end ${onPageChange && total > pageSize ? "" : "mt-[20px]"}`}
          >
            <span>{totalItemsLabel}</span>
          </div>
        ) : null}
      </div>
    );
  }

  useEffect(() => {
    const searchableFieldCount = filteredData.reduce((count, item) => {
      const hasSearchableField =
        item[sortPathName] || (item.data && item.data.search_cache);
      if (hasSearchableField) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);

    // We want to make sure over 50% of the data is searchable by the criteria on the page
    const percentageWithSearchableFields =
      (searchableFieldCount / filteredData.length) * 100;
    setSearchEnabled(percentageWithSearchableFields > 50);
  }, [filteredData, sortPathName]);

  const handleSearchChange = (query: string) => {
    if (onSearchChange) {
      onSearchChange(query);
    }
    setSearchTerm(query.toLowerCase());
  };

  const handleSortChange = (criteria) => {
    const newCriteria = {
      ...mapValues(sortCriteria, () => "none"),
      [criteria]:
        sortCriteria[criteria] === "asc"
          ? "desc"
          : sortCriteria[criteria] === "desc"
            ? "none"
            : "asc",
    };

    const sortProperty = Object.keys(newCriteria).find(
      (key) => newCriteria[key] !== "none"
    );

    const sort = sortProperty && {
      key: sortProperty,
      direction: newCriteria[sortProperty],
    };

    setSortCriteria(newCriteria);

    if (onSortChange) {
      onSortChange(sort);
    }
  };

  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);

    if (onFilterChange) {
      onFilterChange(newFilter);
    }
  };

  return (
    <div className={className}>
      {pageTitle && !inlineLayout && (
        <>
          <div
            className={`flex items-center justify-between pb-[${summaryContent ? 40 : 140}px]`}
          >
            <Heading
              pageIcon={pageIcon}
              title={pageTitle}
              highLevel={highLevel}
            />

            <div className="flex flex-row justify-end items-center gap-2">
              {headerButtons ? (
                headerButtons
              ) : (
                <>
                  {pageSwitch}
                  {!hideCreateNewButton &&
                  (onSearchChange ? cardData : filteredSearch).length ? (
                    <FButton
                      primary
                      onClick={() =>
                        createWithEntityCrud
                          ? createWithEntityCrud("create", "create")
                          : setModalOpen(true)
                      }
                      label={`Create ${pageName.endsWith("s") ? pageName.slice(0, -1) : pageName}`}
                      type="button"
                      iconLeft={{
                        name: "add",
                        size: 12,
                        color: "#fff",
                        className: "mr-2",
                      }}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
          {summaryContent}
        </>
      )}
      {isModalOpen && (
        <Modal
          title={`Create a New ${pageName}`}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onAction={handleModalAction}
          actionLabel="Submit"
        >
          <FInput
            label={`${pageName} Name`}
            value={inputValue}
            className="w-full"
            width="100%"
            type="text"
            error={inputError}
            onChange={(value) => setInputValue(value)}
            required
          />
        </Modal>
      )}
      <Container
        headerLeft={
          <div className="flex items-center gap-2">
            <FSearch
              disabled={!isSearchEnabled}
              onChange={debounce(handleSearchChange, 500)}
              className="max-w-[48%] min-w-[200px] w-full h-[40px]"
              placeholder={`Search...`}
            />

            {onActiveSearchColumnChange ? (
              <div className="flex items-center gap-2">
                <div className=" whitespace-nowrap">Search by:</div>
                <FDropdown
                  selectedValue={activeSearchColumnValue}
                  options={activeSearchColumnOptions}
                  onChange={(value) => onActiveSearchColumnChange(value)}
                />
              </div>
            ) : null}
          </div>
        }
        isInline={inlineLayout}
        title={pageTitle}
        className={pageName ? "pb-40" : ""}
        headerRight={
          <div className="flex gap-4">
            {filterOptions && (
              <FDropdown
                options={filterOptions}
                selectedValue={selectedFilter}
                onChange={handleFilterChange}
                titleDropDown={false}
                height={40}
                zIndex={9999}
                width={"250px"}
                className="font-sofia-pro h-[40px] text-sm text-dark tracking-[0.66px]"
              />
            )}
            {customFilter}
            {(sortPathName || sortPathDate) && (
              <div className="flex rounded-md items-center">
                {sortPathDate && (
                  <button
                    className="flex h-[40px] bg-white hover:border-neutral_600 hover:z-10 border rounded-l-md relative border-default_weak items-center space-x-1 p-2"
                    onClick={() => handleSortChange(sortPathDate)}
                  >
                    <FIcon icon="calendar" color={"#000F45"} size={16} />
                    <FIcon
                      icon={
                        sortCriteria[sortPathDate] === "asc"
                          ? "arrow-up"
                          : sortCriteria[sortPathDate] === "desc"
                            ? "arrow-down"
                            : "dash"
                      }
                      size={12}
                      color={"#000F45"}
                    />
                  </button>
                )}
                {sortPathName && (
                  <button
                    className="flex h-[40px]  bg-white hover:border-neutral_600 border rounded-r-md relative  border-default_weak items-center space-x-0 bg-gray-200 p-2 -ml-[1px]"
                    onClick={() => handleSortChange(sortPathName)}
                  >
                    <FIcon icon="alphabetical" color={"#000F45"} size={18} />
                    <FIcon
                      icon={
                        sortCriteria[sortPathName] === "asc"
                          ? "arrow-up"
                          : sortCriteria[sortPathName] === "desc"
                            ? "arrow-down"
                            : "dash"
                      }
                      size={12}
                      color={"#000F45"}
                    />
                  </button>
                )}
                {inlineLayout && headerButtons && (
                  <div className="">{headerButtons}</div>
                )}
              </div>
            )}
            {!tableOnly && (
              <div className="flex flex-row ">
                <div
                  onClick={() => setGridView(false)}
                  className={`${
                    !gridView ? "bg-highlight_red" : "bg-white "
                  } cursor-pointer rounded-l-md h-[40px] border border-default_weak w-[40px] relative hover:border-default_weak hover:z-10 flex items-center justify-center`}
                >
                  <FIcon
                    icon="table-view"
                    color={!gridView ? "#000F45" : "#000F45"}
                    size={16}
                  />
                </div>
                <div
                  onClick={() => setGridView(true)}
                  className={`${
                    gridView ? "bg-highlight_red" : "bg-white "
                  } cursor-pointer border rounded-r-md border-default_weak -ml-[1px] hover:border-default_weak relative  border-default_weak h-[40px] w-[40px] flex items-center justify-center`}
                >
                  <FIcon
                    icon="grid-view"
                    color={gridView ? "#000F45" : "#000F45"}
                    size={16}
                  />
                </div>
              </div>
            )}
            {subNavMainButton}
          </div>
        }
      >
        {customFiltersContent}
        {loading ? (
          <LoadingSpinner className="w-full h-full mt-[20%]" />
        ) : (
          renderContent()
        )}
      </Container>
    </div>
  );
}
