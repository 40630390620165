import React from "react";
import { EngagementGrowthDailyStats } from "@types";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { sortBy } from "lodash";

const OPTIONS = {
  chart: {
    spacingLeft: 10,
    spacingRight: 10,
    height: 300,
  },
  tooltip: {
    useHTML: true,
    headerFormat: "",
    pointFormatter: function () {
      return `<div style="text-align: center">
        <div style="font-weight: bold">Engagement: ${this.y.toFixed(2)}%</div>
        <div style="font-size: 0.9em; opacity: 0.8">${moment(this.x).format("MMM YY'")}</div>
      </div>`;
    },
  },
  xAxis: [
    {
      type: "datetime",
      labels: {
        formatter: function () {
          return moment(this.value).format("MMM");
        },
        autoRotation: [-45, 0],
        style: {
          fontSize: "11px",
        },
      },
      tickInterval: 2 * 30 * 24 * 3600 * 1000,
      startOnTick: true,
      endOnTick: true,
      minPadding: 0.025,
      maxPadding: 0.025,
    },
  ],
  yAxis: [
    {
      labels: {
        formatter: function () {
          return this.value.toFixed(2) + "%";
        },
      },
      minPadding: 0.1,
      maxPadding: 0.1,
      startOnTick: false,
      endOnTick: false,
    },
  ],
};

interface EngagementGrowthRateProps {
  data: EngagementGrowthDailyStats[];
  avgEngagementRate?: number;
}

const EngagementGrowthRate = ({
  data,
  avgEngagementRate,
}: EngagementGrowthRateProps) => {
  const dataSortedByTime = sortBy(data, ({ date }) => moment(date).valueOf());

  // Calculate y-axis margins
  const values = dataSortedByTime.map((d) => d.engagement_avg);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const valueRange = maxValue - minValue;
  const yAxisMin = minValue - valueRange * 0.2;
  const yAxisMax = maxValue + valueRange * 0.3; // Increased top buffer to 30%

  const lastItem = dataSortedByTime[dataSortedByTime.length - 1];

  // Calculate statistics
  const currentEngagement = lastItem?.engagement_avg;
  const averageEngagement = Number(
    (values.reduce((sum, val) => sum + val, 0) / values.length).toFixed(2)
  );

  // Calculate month-over-month growth
  const calculateMonthOverMonthGrowth = () => {
    const currentMonth = lastItem;
    const previousMonth = dataSortedByTime[dataSortedByTime.length - 2];

    if (!previousMonth) return 0;

    const growthPercent =
      ((currentMonth.engagement_avg - previousMonth.engagement_avg) /
        previousMonth.engagement_avg) *
      100;
    return Number(growthPercent.toFixed(2));
  };

  // Calculate 3-month growth
  const calculateThreeMonthGrowth = () => {
    const currentMonth = lastItem;
    const threeMonthsAgo = dataSortedByTime[dataSortedByTime.length - 4];

    if (!threeMonthsAgo) return 0;

    const growthPercent =
      ((currentMonth.engagement_avg - threeMonthsAgo.engagement_avg) /
        threeMonthsAgo.engagement_avg) *
      100;
    return Number(growthPercent.toFixed(2));
  };

  const monthOverMonthGrowth = calculateMonthOverMonthGrowth();
  const threeMonthGrowth = calculateThreeMonthGrowth();

  // Calculate total months growth
  const totalMonths = lastItem
    ? moment(lastItem.date).diff(moment(dataSortedByTime[0].date), "months")
    : 0;
  const totalGrowthPercent = Number(
    (
      ((currentEngagement - dataSortedByTime[0]?.engagement_avg) /
        dataSortedByTime[0]?.engagement_avg) *
      100
    ).toFixed(2)
  );

  const stats = [
    {
      name: "Average Engagement",
      value: avgEngagementRate || averageEngagement,
      isPercentage: true,
    },
    {
      name: "Last Month",
      value: monthOverMonthGrowth,
      isPercentage: true,
    },
    {
      name: "3 Month Growth",
      value: threeMonthGrowth,
      isPercentage: true,
    },
    {
      name: `${totalMonths} Month Growth`,
      value: totalGrowthPercent,
      isPercentage: true,
    },
  ];

  const options = {
    ...OPTIONS,
    xAxis: [
      {
        ...OPTIONS.xAxis[0],
        min: moment(dataSortedByTime[0]?.date).subtract(0.5, "month").valueOf(),
        max: moment(lastItem?.date).add(0.5, "month").valueOf(),
      },
    ],
    yAxis: [
      {
        ...OPTIONS.yAxis[0],
        min: yAxisMin,
        max: yAxisMax,
        minPadding: 0.1,
        maxPadding: 0.1,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 6, // Ensure we have enough ticks
      },
    ],
    series: [
      {
        data: dataSortedByTime.map(({ date, engagement_avg }) => [
          moment(date).valueOf(),
          engagement_avg,
        ]),
        name: "Engagement average",
        type: "line",
        color: "#7AC9E2",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className="bg-white rounded-lg border border-light_border shadow-sm overflow-hidden">
      <div className="flex items-center justify-between px-8 py-4 pb-3 border-b border-light_border">
        <div className="flex items-center gap-2">
          <h3 className="text-lg font-bold">Engagement</h3>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 px-4 bg-light_red">
        {stats.map((stat, index) => {
          const { name, value, isPercentage } = stat;
          let formattedValue = isPercentage
            ? `${name === "Average Engagement" ? "" : !isNaN(value) && value > 0 ? "+" : ""}${isNaN(value) ? "0.00" : value.toFixed(2)}%`
            : `${isNaN(value) ? "0.00" : value.toFixed(2)}%`;

          const textColor =
            isPercentage && name !== "Average Engagement"
              ? !isNaN(value) && value > 0
                ? "text-[#47B37F]"
                : !isNaN(value) && value < 0
                  ? "text-[#E47667]"
                  : ""
              : "";

          return (
            <div
              className="flex flex-col p-4 pb-3 rounded-lg bg-gray-50"
              key={index}
            >
              <div className="text-sm text-gray-600">{name}</div>
              <div className={`text-xl font-bold mt-1 ${textColor}`}>
                {formattedValue}
              </div>
            </div>
          );
        })}
      </div>
      <div className="px-4 py-6 border-t border-light_border">
        <div className="h-[300px]">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ className: "w-full h-full" }}
          />
        </div>
      </div>
    </div>
  );
};

export default EngagementGrowthRate;
