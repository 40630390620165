import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { AxiosHeaders } from "axios";
import axios from "axios";
import { LOCAL_API_ORIGIN } from "@constants/api";

export interface StripeSubscription {
  id: string;
  object: string;
  status:
    | "active"
    | "canceled"
    | "incomplete"
    | "incomplete_expired"
    | "past_due"
    | "trialing"
    | "unpaid";
  current_period_start: number;
  current_period_end: number;
  cancel_at_period_end: boolean;
  canceled_at?: number;
  cancellation_details?: {
    comment: string | null;
    feedback: string | null;
    reason: string | null;
  };
  created: number;
  currency: string;
  customer: string;
  default_payment_method?: {
    id: string;
    object: string;
    card: {
      brand: string;
      exp_month: number;
      exp_year: number;
      last4: string;
      country?: string;
      funding?: string;
    };
  };
  items: {
    object: string;
    data: Array<{
      id: string;
      price: {
        id: string;
        unit_amount: number;
        currency: string;
        recurring: {
          interval: string;
          interval_count: number;
        };
        product: string;
      };
    }>;
    has_more: boolean;
    total_count: number;
  };
  latest_invoice?: string;
  plan?: {
    id: string;
    object: string;
    amount: number;
    currency: string;
    interval: string;
    interval_count: number;
    product: string;
  };
  quantity: number;
  start_date: number;
  trial_end?: number | null;
  trial_start?: number | null;
}

export interface StripeInvoice {
  id: string;
  object: string;
  account_country: string;
  account_name: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  billing_reason: string;
  collection_method: string;
  created: number;
  currency: string;
  customer: string;
  customer_address: {
    city: string | null;
    country: string;
    line1: string | null;
    line2: string | null;
    postal_code: string;
    state: string | null;
  };
  customer_email: string;
  customer_name: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
  lines: {
    object: string;
    data: Array<{
      id: string;
      object: string;
      amount: number;
      currency: string;
      description: string;
      period: {
        end: number;
        start: number;
      };
      plan: {
        id: string;
        object: string;
        amount: number;
        currency: string;
        interval: string;
        interval_count: number;
        product: string;
      };
      price: {
        id: string;
        object: string;
        currency: string;
        unit_amount: number;
        recurring: {
          interval: string;
          interval_count: number;
        };
      };
      subscription: string;
    }>;
    has_more: boolean;
    total_count: number;
  };
  number: string;
  paid: boolean;
  payment_intent: string;
  period_end: number;
  period_start: number;
  status: "draft" | "open" | "paid" | "uncollectible" | "void";
  subscription: {
    id: string;
    object: string;
    status: string;
    current_period_end: number;
    current_period_start: number;
    plan: {
      id: string;
      amount: number;
      currency: string;
      interval: string;
      interval_count: number;
    };
  };
  subtotal: number;
  total: number;
}

export interface StripeSession {
  url: string;
  session_id: string;
}

export interface StripePortalSession {
  url: string;
}

export interface ListInvoicesRequest {
  customer_id?: string;
  status?: "draft" | "open" | "paid" | "uncollectible" | "void";
  limit?: number;
}

// Get current subscription status
const getCurrentSubscription = async (
  customerId: string
): Promise<ApiResponse<{ data: StripeSubscription[] }>> => {
  return apiWrapper("/stripe/subscriptions", "GET", {
    customer_id: customerId,
  });
};

// Get list of invoices
const getInvoices = async (
  customerId: string,
  params?: Omit<ListInvoicesRequest, "customer_id">
): Promise<ApiResponse<{ data: StripeInvoice[] }>> => {
  const queryParams = {
    customer_id: customerId,
    ...params,
  };

  return apiWrapper("/stripe/invoices", "GET", queryParams);
};

// Create checkout session for new subscription
const createCheckoutSession = async (
  priceId: string,
  customerId?: string
): Promise<ApiResponse<StripeSession>> => {
  try {
    const response = await axios.post<ApiResponse<StripeSession>>(
      `${LOCAL_API_ORIGIN}/api/v1/stripe/checkout`,
      {
        lookup_key: priceId,
        ...(customerId && { customer_id: customerId }),
      }
    );
    console.log("Checkout session response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Checkout session creation failed:", {
      error,
      requestData: {
        priceId,
        endpoint: "/stripe/checkout",
      },
    });
    throw error;
  }
};

// Create customer portal session
const createPortalSession = async (
  customerId: string
): Promise<ApiResponse<StripePortalSession>> => {
  return apiWrapper("/stripe/portal", "POST", { customer_id: customerId });
};

// Cancel subscription
const cancelSubscription = async (
  customerId: string,
  subscriptionId: string
): Promise<ApiResponse<StripeSubscription>> => {
  return apiWrapper(`/stripe/subscriptions/${subscriptionId}/cancel`, "POST", {
    customer_id: customerId,
  });
};

// Reactivate subscription
const reactivateSubscription = async (
  customerId: string,
  subscriptionId: string
): Promise<ApiResponse<StripeSubscription>> => {
  return apiWrapper(
    `/stripe/subscriptions/${subscriptionId}/reactivate`,
    "POST",
    {
      customer_id: customerId,
    }
  );
};

export {
  getCurrentSubscription,
  getInvoices,
  createCheckoutSession,
  createPortalSession,
  cancelSubscription,
  reactivateSubscription,
};
