import React, { useState, useCallback } from "react";

import { getAdminParticipantBriefs } from "@api/AdminParticipantBriefs/AdminParticipantBriefs";

import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

const tableColumns = [
  {
    key: "participant_name",
    label: "Participant name",
    isLink: true,
    linkURL: "{id}",
  },
  {
    key: "campaign_name",
    label: "Campaign Name",
    isLink: true,
    linkURL: "{id}",
  },
  {
    key: "project_number",
    label: "project number",
  },
];
const activeSearchColumnOptions = [
  { label: "Campaign Name", value: "campaignName" },
  { label: "Project Number", value: "projectNumber" },
];

function ParticipantBriefList() {
  const [activeSearchColumn, setActiveSearchColumn] = useState("campaignName");

  const getItems = useCallback(
    async (page, sort, params) => {
      const { search } = params;
      try {
        const result = await getAdminParticipantBriefs(
          page,
          search,
          activeSearchColumn
        );

        return {
          items: result.participant_briefs,
          total_items: result.total_items,
        };
      } catch (error) {
        console.error("Failed to fetch participant briefs:", error);

        return {
          items: [],
          total_items: 0,
        };
      }
    },
    [activeSearchColumn]
  );

  const handleCellClick = useCallback((item, columnKey) => {
    switch (columnKey) {
      case "participant_name":
        window.open(
          `/participant-brief/${item.campaign_id}/${item.participant_id}`,
          "_blank"
        );
        break;
      case "campaign_name":
        window.open(`/campaigns/${item.campaign_id}`, "_blank");
        break;
      default:
        break;
    }
  }, []);

  return (
    <SinglePageLayoutWithData
      entityName="participant-brief"
      pageTitle="Participant Briefs"
      pageName="Participant Briefs"
      sortPathName={activeSearchColumn}
      searchPathName={activeSearchColumn}
      tableColumns={tableColumns}
      tableOnly
      getItems={getItems}
      onTableCellClick={handleCellClick}
      hideCreateNewButton={true}
      activeSearchColumnOptions={activeSearchColumnOptions}
      activeSearchColumnValue={activeSearchColumn}
      onActiveSearchColumnChange={setActiveSearchColumn}
    />
  );
}

export default ParticipantBriefList;
