import FButton from "@components/FButton";

import Modal from "@components/Modals/Modal";
import { ContractTemplate } from "@types";

interface SaasContractTemplateModalProps {
  onClose: () => void;
  template: ContractTemplate;
}

const SaasContractTemplateModal = ({
  onClose,
  template,
}: SaasContractTemplateModalProps) => {
  return (
    <Modal
      title="SaaS Customer Contract Disclaimer"
      className="w-[501px]"
      hasForm
      onClose={onClose}
      isOpen
      zIndex={9999}
    >
      <div className="p-4">
        <div className="ql-editor">
          <div
            dangerouslySetInnerHTML={{
              __html: template.sections[0].body,
            }}
          />
        </div>
      </div>
      <div className="flex justify-end space-x-2">
        <FButton onClick={onClose} label="Accept" height="40px" />
      </div>
    </Modal>
  );
};

export default SaasContractTemplateModal;
