import { capitalize } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";

const ENTITIES = [
  {
    value: "accounts",
  },
  {
    value: "brands",
  },
  {
    value: "bulletins",
  },
  {
    value: "creators",
  },
  {
    value: "campaigns",
  },
  {
    value: "finance",
  },
  {
    value: "outreach",
  },
  {
    value: "groups",
  },
  {
    value: "reports",
  },
];

export default function Admin() {
  React.useEffect(() => {
    document.title = "Admin";
  });

  return (
    <div>
      <h1>Admin</h1>
      <div className="flex flex-col gap-8 mt-10 p-8 bg-white rounded-lg shadow-md">
        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-bold">Reports</h4>
          <ul className="list-disc list-inside text-[15px]">
            <li>
              <Link
                to="/admin/revenue_recognition_report"
                className="underline"
              >
                Revenue Recognition Report
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-bold">Briefs</h4>
          <ul className="list-disc list-inside text-[15px]">
            <li>
              <Link to="/admin/contracts" className="underline">
                Contract Templates
              </Link>
            </li>
            <li>
              <Link to="/admin/participant_briefs" className="underline">
                Participant Briefs
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-bold">Entities</h4>
          <ul className="list-disc list-inside text-[15px]">
            {ENTITIES.map((entity) => (
              <li>
                <Link to={`/admin/${entity.value}`} className="underline">
                  {capitalize(entity.value)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
