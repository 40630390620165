import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

const SubscriptionSuccess: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    // Show success message
    toast.success("Subscription activated successfully!");

    // Redirect to subscription page
    navigate("/settings/subscription", { replace: true });
  }, [navigate]);

  return null; // No need to render anything as we're redirecting
};

export default SubscriptionSuccess;
