import { times } from "lodash";

interface PaginationDotsProps {
  currentIndex: number;
  itemsCount: number;
  label?: string;
  onChange: (index: number) => void;
}

const PaginationDots = ({
  currentIndex,
  itemsCount,
  onChange,
  label,
}: PaginationDotsProps) => {
  if (itemsCount === 1) {
    return <div className="h-2" />;
  }

  return (
    <div className="flex items-center gap-4">
      {label ? <span className="text-sm">{label}</span> : null}
      <div className="bubbles flex flex-grow gap-[6px]">
        {times(itemsCount, (index) => (
          <div
            key={index}
            className={`bg-[#000000] cursor-pointer relative inline-block h-[6px] w-[6px] rounded-full ${
              index === currentIndex ? "opacity-100" : "opacity-20 "
            }`}
            onClick={() => onChange(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default PaginationDots;
