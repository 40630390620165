import React, { useCallback } from "react";
import { Sort } from "@types";
import { getCreators, deleteReportCreator } from "@api/Reports";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

const tableColumns = [
  {
    key: "name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{creator_slug}",
    isFirstColumn: true,
  },
  {
    key: "followers",
    label: "Followers",
    isNumber: true,
  },
  {
    key: "follower_tier",
    label: "Follower Tier",
    isStateBubble: true,
  },
  {
    key: "posts",
    label: "Posts",
    isNumber: true,
  },
  {
    key: "video_views",
    label: "Video Views",
    isNumber: true,
  },
  {
    key: "impressions",
    label: "Views",
    isNumber: true,
  },
  {
    key: "engagements",
    label: "Engagements",
    isNumber: true,
  },
  {
    key: "engagement_rate",
    label: "Engagement %",
  },
  {
    key: "viewership",
    label: "Viewership %",
  },
];

const tableRowDropDownOptions = [{ label: "Delete", value: "delete" }];

const actions = {
  delete: async (participant_id: number) => deleteReportCreator(participant_id),
};

export default function ReportCreatorsTable({ report_id }) {
  const getContent = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search } = params;

      if (sort?.key === "name") {
        sort.key = "creator_lastName";
      }

      const response = await getCreators(report_id, page, sort, {
        creator_firstName_or_creator_lastName_cont: search,
      });

      const { creators } = response.data;

      return {
        ...response.data,
        items: creators.map((creator) => ({
          ...creator,
          id: creator.participant_id,
        })),
      };
    },
    []
  );

  return (
    <div className="py-10">
      <SinglePageLayoutWithData
        entityName="creators"
        pageName="creators"
        tableColumns={tableColumns}
        tableOnly
        hideCreateNewButton
        getItems={getContent}
        asyncActions={actions}
        tableRowDropDownOptions={tableRowDropDownOptions}
        rowActions={tableRowDropDownOptions}
      />
    </div>
  );
}
