import { getRevenueRecognitionReport } from "@api/Reports";
import FButton from "@components/FButton";

import FRangePicker from "@components/FDatePicker/FRangePicker";
import Heading from "@components/Heading";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";

const DATE_FORMAT = "YYYY-MM-DD";

const RevenueRecognitionReport = () => {
  const [waiting, setWaiting] = useState(false);

  const [values, setValues] = useState({
    start_date: moment().format(DATE_FORMAT),
    end_date: moment().format(DATE_FORMAT),
  });

  const handleUpdate = (updates) =>
    setValues({
      ...values,
      ...updates,
    });

  const handleSubmit = async () => {
    const { start_date, end_date } = values;

    setWaiting(true);

    try {
      const response = await getRevenueRecognitionReport({
        start_date,
        end_date,
      });

      const { data } = response;

      if (data?.message) {
        toast.success(data.message);

        return;
      }

      const blob = new Blob([data], {
        type: "text/csv;charset=utf-8;",
      });

      const link = document.createElement("a");

      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);

      link.setAttribute(
        "download",
        `revenue_recognition_report_${start_date}_${end_date}`
      );

      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    } catch (e) {
      toast.error("Error generating report");
    } finally {
      setWaiting(false);
    }
  };

  return (
    <div className="w-[700px]">
      <Heading title="Revenue Recognition Report" />
      <p className="py-8">
        Select campaign flight dates to pull a CSV of all deliverables
        associated with campaigns with live dates within that range.
      </p>
      <div className="flex mb-8 w-full">
        <FRangePicker
          value={{
            from: values.start_date,
            to: values.end_date,
          }}
          onChange={({ from, to }) =>
            handleUpdate({
              start_date: from,
              end_date: to,
            })
          }
          fromLabel="Start Date"
          toLabel="End Date"
        />
      </div>
      <div className="flex justify-end">
        <FButton
          loading={waiting}
          onClick={handleSubmit}
          primary
          label="Download"
        />
      </div>
    </div>
  );
};

export default RevenueRecognitionReport;
