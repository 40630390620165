import React, { useContext } from "react";
import { UserContext } from "@contexts/index";
import FButton from "@components/FButton";
import toast from "react-hot-toast";
import {
  stopImpersonateUser
} from "@api/Admin/AdminBrands";



const ImpersonationBanner = () => {
  const { user } = useContext(UserContext);

  if (!user?.admin_impersonating) return null; // Hide if not impersonating
  const handleStopImpersonation = async () => {
    try {
      const response = await stopImpersonateUser(parseInt(user.membership_id));
  
      
      if (response.success) {
        toast.success("Impersonation ended.");
        setTimeout(() => window.location.reload(), 1000);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to stop impersonation.");
      }
    } catch (error) {
      toast.error("An error occurred while stopping impersonation.");
    }
  };
  return (
    <>
      {/* Banner */}
      <div className="sticky top-0 left-0 w-full bg-light_yellow  text-yellow-900 p-4 flex items-center justify-between z-50 h-16">
        {/* Centered Text */}
        <div className="text-lg font-medium text-center flex-1">
          ❗ You are currently impersonating {user.admin_impersonating_email} for {user.admin_impersonating_brand} ❗
        </div>

        {/* Button */}
        <div className="ml-4">
          <FButton
            onClick={handleStopImpersonation}
            label="Exit Impersonation"
          />
        </div>
      </div>

      {/* Spacer to Push Content Below the Banner */}
      <div className="h-16"></div>
    </>
  );
};

export default ImpersonationBanner;

