import React from "react";

import { useState } from "react";
import { createGroup } from "@api/Groups/Groups";
import { Group } from "@types";
import { GroupData } from "@apiTypes";
import { FInput } from "@components/FInputs";
import useAuth from "@hooks/useAuth";
import { toast } from "react-hot-toast";
import FButton from "@components/FButton";
import { isEmpty } from "lodash";

const CreateGroupForm = ({
  onCreateGroup,
}: {
  onCreateGroup?: (newGroup: GroupData) => void;
}) => {
  const [waitingCreateGroup, setWaitingCreateGroup] = useState(false);
  const [errorCreatingGroup, setErrorCreatingGroup] = useState<string | null>(
    null
  );

  const { user } = useAuth();

  const [groupDataValues, setGroupDataValues] = useState<Partial<Group>>({
    name: "",
    archived: false,
    featured: false,
  });

  const handleUpdateGroup = (updates: Partial<Group>) => {
    setGroupDataValues((values) => {
      const updatedGroupDataValues = { ...values, ...updates };
      return updatedGroupDataValues;
    });
  };

  const handleAddGroup = async () => {
    setWaitingCreateGroup(true);
    setErrorCreatingGroup(null);

    try {
      const { name, featured } = groupDataValues;
      const response = await createGroup({
        name,
        brand_id: user.brand_id,
        user_id: user.user_id,
        featured,
      });

      onCreateGroup(response.data);
      toast.success("Group Created");
    } catch (error) {
      setErrorCreatingGroup("Failed to create group. Please try again");
      toast.error(errorCreatingGroup);
    } finally {
      setGroupDataValues({
        name: "",
        archived: false,
        featured: false,
      });
      setWaitingCreateGroup(false);
    }
  };
  return (
    <div className="w-full mb-2 gap-4 flex items-center justify-end">
      <div className="flex flex-1 items-end gap-4">
        <FInput
          className="flex-1"
          name="name"
          onChange={(value) => handleUpdateGroup({ name: value })}
          value={groupDataValues.name}
          placeholder="Enter a group name"
        />
        <FButton
          type={"button"}
          loading={waitingCreateGroup}
          disabled={isEmpty(groupDataValues.name)}
          onClick={handleAddGroup}
          label="Create group"
          className="h-8 w-full"
        />
      </div>
      <p className="text-neutral_500 text-sm">
        Can't find your group? Create one here.
      </p>
    </div>
  );
};

export default CreateGroupForm;
