import React, { useContext, useEffect, useState } from "react";

import { platformList } from "@constants/constants";

import { AudienceDemographics } from "@types";
import { ProfileContext } from "@contexts/index";
import FDropdown from "@components/FDropdown";

import EthnicityBreakdown from "../../reports/components/charts/demographics/EthnicityBreakdownChart";
import ReligionBreakdown from "../../reports/components/charts/demographics/ReligionBreakdown";
import KidsBreakdown from "../../reports/components/charts/demographics/KidsBreakdown";
import MaritalStatusBreakdown from "../../reports/components/charts/demographics/MaritalStatusBreakdown";
import { getAudienceData } from "@api/Profile";
import toast from "react-hot-toast";
import LoadingSpinner from "@components/LoadingSpinner";

import CountriesBreakdown from "../../reports/components/charts/demographics/CountriesBreakdown";
import StatesBreakdown from "../../reports/components/charts/demographics/StatesBreakdown";
import useAuth from "@hooks/useAuth";
import ContentGuard from "../components/ContentGuard";
import AgeBreakdownChart from "../../reports/components/charts/demographics/AgeBreakdownChart";
import GenderBreakdownChart from "../../reports/components/charts/demographics/GenderBreakdownChart";
import { Role } from "@constants/roles";

// const ALL_PLATFORMS = { label: "All Platforms", value: "" };

export default function ProfileAudienceDemographics() {
  const { profile } = useContext(ProfileContext);

  const [loaded, setLoaded] = useState(false);

  const [selectedPlatform, setSelectedPlatform] = useState<string>("");

  const [audience, setAudience] = useState<AudienceDemographics>();

  const { hasRole, user } = useAuth();

  const isInfluencer = hasRole(Role.INFLUENCER);

  useEffect(() => {
    const fetchStats = async () => {
      setLoaded(false);

      if (!user) {
        setLoaded(true);

        setAudience({
          gender: [{ name: "", percentage: 0 }],
          age: [{ name: "", percentage: 0 }],
          countries: [{ name: "", percentage: 0 }],
          states: [{ name: "", percentage: 0 }],
          ethnicity: [{ name: "", percentage: 0 }],
          religion: [{ name: "", percentage: 0 }],
          parental: [
            { name: "", percentage: 0 },
            { name: "", percentage: 0 },
          ],
          marital: [{ name: "", percentage: 0 }],
        });
      }

      try {
        const audienceResponse = await getAudienceData(
          !isInfluencer && profile.slug,
          selectedPlatform || null
        );

        setAudience(audienceResponse.data);
      } catch (e) {
        toast.error("Error loading data");

        setAudience(null);
      } finally {
        setLoaded(true);
      }
    };

    fetchStats();
  }, [selectedPlatform, setAudience, profile.slug, user, isInfluencer]);

  if (!loaded) {
    return (
      <div className="mt-[200px]">
        <LoadingSpinner />
      </div>
    );
  }

  const availablePlatforms = platformList.filter((p) =>
    profile.platforms?.find(({ name }) => name === p.value)
  );

  return (
    <div className="relative py-[52px] w-full pr-[46px]">
      {/*  <div
        className="flex-col sm:flex-row gap-[24px] mt-[16px] sm:mt-[24px] hidden sm:flex"
        style={{
          filter: `blur(${user || isValidProfileHash ? 0 : 8}px)`,
        }}
      ></div> */}

      <div className="flex items-center justify-between mb-8 px-[2px]">
        <h2 className="text-2xl">Platform Statistics</h2>
        <FDropdown
          options={availablePlatforms}
          selectedValue={selectedPlatform}
          onChange={(value) => setSelectedPlatform(value as string)}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
        <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm h-[400px]">
          <AgeBreakdownChart
            items={
              audience.age
                ? audience.age.map((item) => ({
                    ...item,
                    name: item.name.split("Age ")[1],
                  }))
                : []
            }
          />
        </div>
        <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm h-[400px]">
          <GenderBreakdownChart items={audience.gender} />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
        <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm h-[400px]">
          <EthnicityBreakdown items={audience.ethnicity || []} />
        </div>
        <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm h-[400px]">
          <ReligionBreakdown items={audience.religion} />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
        <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm h-[400px]">
          <KidsBreakdown items={audience.parental} />
        </div>
        <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm h-[400px]">
          <MaritalStatusBreakdown items={audience.marital} />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
        <CountriesBreakdown items={audience.countries} />
        <StatesBreakdown items={audience.states} />
      </div>
      <ContentGuard />
    </div>
  );
}
