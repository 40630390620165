import { Role } from "@constants/roles";
import { UserContext } from "@contexts/index";
import { useContext } from "react";
import CreatorCampaignsPage from "./creator-view";
import { CampaignList } from ".";

const CampaignsPage = () => {
  const { hasRole } = useContext(UserContext);

  if (hasRole(Role.INFLUENCER)) {
    return <CreatorCampaignsPage />;
  }

  return <CampaignList />;
};

export default CampaignsPage;
