import { useState, useEffect } from "react";
import {
  getReport,
  getReportSummary,
  getReportPerformanceGraphs,
  getPostsEngagementGraph,
  getReportsAudienceData,
  getReportContentSummary,
  getCreators,
  getReportContent,
} from "@api/Reports";
import toast from "react-hot-toast";
import {
  Report,
  ReportMetrics,
  PerformanceGraphs,
  AudienceData,
  ContentSummary,
} from "types/types";

export const useReportData = (reportId: string | undefined, bonus: boolean | undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [metrics, setMetrics] = useState<ReportMetrics>({
    posts: 0,
    reach: 0,
    views_count: 0,
    engagement_rate: 0,
    creators: 0,
    emv: 0,
    interactions: 0,
    followers: 0,
    impressions: 0,
    engagement: 0,
    influencers_count: 0,
  });

  const [report, setReport] = useState<Report | null>(null);
  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [performanceGraphs, setPerformanceGraphs] = useState<PerformanceGraphs>(
    {}
  );
  const [engagementGraphData, setEngagementGraphData] = useState({
    posts: null,
    reach: null,
    views: null,
  });
  const [audience, setAudience] = useState<AudienceData>({} as AudienceData);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [audienceDataFetched, setAudienceDataFetched] = useState(false);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [contentSummary, setContentSummary] = useState<ContentSummary | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!reportId) {
        console.error("No report ID provided");
        toast.error("Report ID is missing");
        return;
      }

      setIsLoading(true);
      setIsPageLoading(true);

      try {
        // First get the report to determine if we're in a campaign context
        const reportResponse = await getReport(Number(reportId));

        if (!reportResponse?.data) {
          throw new Error("Failed to fetch report data");
        }

        // Determine the correct report ID for subsequent calls
        const isCampaignContext =
          window.location.pathname.includes("campaigns");
        const effectiveReportId =
          isCampaignContext && reportResponse.data.reports?.[0]?.id
            ? reportResponse.data.reports[0].id.toString()
            : reportId;

        if (!effectiveReportId) {
          throw new Error("Could not determine effective report ID");
        }
        // Make all other API calls with the effective report ID
        const [
          summaryResponse,
          performanceResponse,
          engagementResponse,
          contentSummaryResponse,
          creatorsResponse,
        ] = await Promise.all([
          getReportSummary(effectiveReportId, bonus),
          getReportPerformanceGraphs(effectiveReportId, bonus),
          getPostsEngagementGraph(effectiveReportId, bonus),
          getReportContentSummary(effectiveReportId, bonus),
          getCreators(effectiveReportId),
        ]);

        // Update report with content summary
        const updatedReport = {
          ...reportResponse.data,
          content_summary: contentSummaryResponse?.data || null,
        };
        setReport(updatedReport);
        setContentSummary(contentSummaryResponse?.data || null);

        if (reportResponse.data.platforms?.length > 0) {
          const allPlatforms = reportResponse.data.platforms.map(
            (platform) => ({
              label: platform,
              value: platform,
            })
          );
          setAvailablePlatforms(allPlatforms);
          setSelectedPlatform(allPlatforms[0].value);
        }

        if (summaryResponse?.success) {
          setMetrics({
            posts: summaryResponse.data.posts || 0,
            reach: summaryResponse.data.reach || 0,
            views_count: summaryResponse.data.views_count || 0,
            engagement_rate: summaryResponse.data.engagement_rate || 0,
            creators: creatorsResponse?.data?.total_items || 0,
            influencers_count:
              contentSummaryResponse.data.influencers_count || 0,
            emv: summaryResponse.data.emv || 0,
            interactions: summaryResponse.data.interactions || 0,
            followers: summaryResponse.data.followers || 0,
            impressions: summaryResponse.data.impressions || 0,
            engagement: summaryResponse.data.engagement || 0,
          });
        }

        if (performanceResponse?.data) {
          setPerformanceGraphs(performanceResponse.data);
        }

        if (engagementResponse?.data) {
          setEngagementGraphData(engagementResponse.data);
        }
      } catch (error) {
        console.error("Error fetching report data:", error);
        toast.error("Error loading report data");
      } finally {
        setIsLoading(false);
        setIsPageLoading(false);
      }
    };

    fetchData();
  }, [reportId]);

  // Separate effect for audience data that runs independently
  useEffect(() => {
    const fetchAudienceData = async () => {
      if (!reportId) return;

      try {
        setAudienceDataFetched(false);
        // Use the same report ID logic for audience data
        const isCampaignContext =
          window.location.pathname.includes("campaigns");
        const effectiveReportId =
          isCampaignContext && report?.reports?.[0]?.id
            ? report.reports[0].id.toString()
            : reportId;

        const audienceResponse = await getReportsAudienceData(
          effectiveReportId,
          selectedPlatform || ""
        );
        if (audienceResponse?.data) {
          setAudience(audienceResponse.data);
          setAudienceDataFetched(true);
        }
      } catch (error) {
        console.error("Error fetching audience data:", error);
        setAudienceDataFetched(true);
      }
    };

    fetchAudienceData();
  }, [reportId, selectedPlatform, report]);

  return {
    isLoading,
    setIsLoading,
    isPageLoading,
    setIsPageLoading,
    metrics,
    setMetrics,
    report,
    setReport,
    performanceGraphs,
    engagementGraphData,
    audience,
    selectedPlatform,
    setSelectedPlatform,
    audienceDataFetched,
    availablePlatforms,
    activePost,
    setActivePost,
    contentModalOpen,
    setContentModalOpen,
    contentSummary,
  };
};
