import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@components/Modals/Modal";
import GenerateFormFields from "@components/Generators/GenerateFormFields";
import ConfirmModal from "@components/Modals/ConfirmModal";
import { findNestedChanges, reformData } from "@utils/CampaignUtils";
import { toast } from "react-hot-toast";
import apiClient from "@apiClient";
import { UserContext } from "@contexts/index";

interface EntityAdminProps {
  apiNamespace?: string;
  pageName?: string;
  entityData: any[];
  setEntityData: (entities: any[]) => void;
  isArchiveNotDelete?: boolean;
  editFormFields: any[];
  createFormFields?: any[];
  entityName: string;
  includeUserId?: boolean;
  includeBrandId?: boolean;
  entitySelected: { id: string | number; value: string | number | boolean };
  navigateToCreated?: string;
  setEntitySelected: (selectedEntity: {
    id: string;
    value: string | boolean;
  }) => void;
}

const EntityCRUD = ({
  apiNamespace,
  pageName,
  entityData,
  setEntityData,
  isArchiveNotDelete,
  editFormFields,
  navigateToCreated,
  createFormFields,
  entityName,
  includeUserId,
  includeBrandId,
  entitySelected,
  setEntitySelected,
}: EntityAdminProps) => {
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [renamedEntity, setRenamedEntity] = useState<string>("");
  const [entityToEdit, setEntityToEdit] = useState<any>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (
      entitySelected &&
      entitySelected.id === "create" &&
      entitySelected.value === "create"
    ) {
      setEntityToEdit(true);
      setIsEditModalOpen(true);
    } else if (entitySelected) {
      const selectedEntity = entityData.find(
        (entity) => entity.id === entitySelected.id
      );
      if (selectedEntity) {
        setEntityToEdit(selectedEntity);
        const fullName = getNestedValue(selectedEntity, entityName);
        setRenamedEntity(fullName);

        if (entitySelected.value === "edit") {
          setIsEditModalOpen(true);
        } else if (
          entitySelected.value === "delete" ||
          entitySelected.value === "archive"
        ) {
          setIsDeleteModalOpen(true);
          setEntityToDelete(selectedEntity);
        }
      }
    }
  }, [entitySelected, entityData, entityName]);

  const getNestedValue = (obj: any, path: string): string => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj) || "";
  };

  const { create, update, delete: deleteEntity } = apiClient[apiNamespace];

  const removeEntity = async (entity_id) => {
    const response = await deleteEntity(entity_id);
    if (response.success) {
      setEntityData(entityData.filter((d) => d.id !== entity_id));
      modalClose();
      toast.success("Deleted");
    } else {
      toast.error(response.message);
    }
  };

  const handleSubmit = (values) => {
    if (entitySelected.id === "create") {
      if (includeBrandId) {
        values = { ...values, brand_id: user.id };
      }
      if (includeUserId) {
        values = { ...values, user_id: user.id };
      }
      createEntity(values);
    } else {
      if (entityToEdit) {
        updateEntity(values);
      } else {
        console.error("No entity to edit");
        toast.error("Error: No entity to edit");
      }
    }
  };

  const createEntity = async (values) => {
    setLoading(true);
    try {
      const response = await create(values);

      if (response.success) {
        setEntityData([
          ...entityData,
          { ...response.data, id: response.data.id },
        ]);
        toast.success("Created");
        modalClose();
        navigateToCreated &&
          navigate(navigateToCreated.replace("{id}", response.data.id));
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("An error occurred while creating the entity.");
    } finally {
      setLoading(false);
    }
  };

  const updateEntity = async (values) => {
    const reformedValues = reformData(values);

    if (entityToEdit) {
      const originalEntity = entityData.find((p) => p.id === entityToEdit.id);
      const changedValues = {};

      findNestedChanges(reformedValues, originalEntity, changedValues);

      if (Object.keys(changedValues).length > 0) {
        const response = await update(entityToEdit.id, changedValues);

        if (response.success) {
          const updatedEntities = entityData.map((p) =>
            p.id === entityToEdit.id ? { ...p, ...changedValues } : p
          );
          setEntityData(updatedEntities);
          modalClose();
          toast.success("Saved");
        } else {
          toast.error(response.message);
        }
      } else {
        modalClose();
        toast.success("No changes");
      }
    } else {
      const response = await create(reformedValues);
      if (response.success) {
        setEntityData([
          ...entityData,
          { ...reformedValues, id: response.data.id },
        ]);
        toast.success("Saved");
        modalClose();
      } else {
        toast.error(response.message);
      }
    }
  };

  const modalClose = () => {
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
    setEntitySelected(null);
    setRenamedEntity("");
    setEntityToEdit(null);
    setEntityToDelete(null);
  };

  return (
    <>
      {entityToEdit && (
        <>
          <Modal
            hasForm
            title={
              entitySelected.id === "create"
                ? `Create ${pageName.endsWith("s") ? pageName.slice(0, -1) : pageName}`
                : `Edit ${renamedEntity}`
            }
            isOpen={isEditModalOpen}
            onClose={modalClose}
            onAction={() => formRef.current && formRef.current.submitForm()}
            actionLabel={entitySelected.id === "create" ? "Add" : "Save"}
            className="w-[650px]"
            zIndex={9999}
          >
            <div className="max-h-[60vh] overflow-y-auto">
              <GenerateFormFields
                onClose={modalClose}
                tableColumns={
                  entitySelected.id === "create"
                    ? createFormFields
                    : editFormFields
                }
                initialData={entityToEdit}
                entityId={entityToEdit && entityToEdit.id}
                formRef={formRef}
                loading={loading}
                onSubmit={handleSubmit}
                data={entityData}
                rootURL={`/${apiNamespace}`}
              />
            </div>
          </Modal>

          <ConfirmModal
            title={isArchiveNotDelete ? "Archive" : "Delete"}
            isOpen={isDeleteModalOpen}
            onClose={modalClose}
            className="z-[9000]"
            onAction={() => removeEntity(entityToDelete.id)}
            actionLabel={isArchiveNotDelete ? "Archive" : "Delete"}
            subtitle={`Are you sure you want to ${
              isArchiveNotDelete ? "archive" : "delete"
            } ${renamedEntity}?`}
          />
        </>
      )}
    </>
  );
};

export default EntityCRUD;
