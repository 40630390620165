export enum Role {
  SUPER_ADMIN = "Super Admin",
  FOHR_ADMIN = "Fohr Admin",
  BRAND_OWNER = "Brand Owner",
  BRAND_MANAGER = "Brand Manager - Managed Services",
  BRAND_USER = "Brand User - Managed Services",
  BRAND_MANAGER_SAAS = "Brand Manager - SAAS",
  BRAND_USER_SAAS = "Brand User - SAAS",
  INFLUENCER = "Influencer",
  INFLUENCER_REP = "Iinfluencer Rep",
  OPEN_LINKS = "Open Links",
  FREEMIUM = "Freemium",
}

export enum Permission {
  ARCHIVE_GROUPS_USERS = "archive_groups_users",
  CREATE_AMBASSADORS_ONBOARDING_SETTINGS = "create_ambassadors_onboarding_settings",
  CREATE_AMBASSADORS_QUESTIONS = "create_ambassadors_questions",
  CREATE_BRAND_SETTINGS = "create_brand_settings",
  CREATE_BULLETINS = "create_bulletins",
  CREATE_CAMPAIGN_BRIEFS_ABOUT_SECTIONS = "create_campaign_briefs_about_sections",
  CREATE_CAMPAIGN_BRIEFS_CONTRACTS = "create_campaign_briefs_contracts",
  CREATE_CAMPAIGN_BRIEFS_CREATIVE_DIRECTION_SECTIONS = "create_campaign_briefs_creative_direction_sections",
  CREATE_CAMPAIGN_BRIEFS_DELIVERABLES = "create_campaign_briefs_deliverables",
  CREATE_CAMPAIGN_BRIEFS_OVERALL = "create_campaign_briefs_overall",
  CREATE_CAMPAIGN_BRIEFS_PRODUCTS = "create_campaign_briefs_products",
  CREATE_CAMPAIGNS = "create_campaigns",
  CREATE_CONTENT = "create_content",
  CREATE_CONTENT_ADMIN_REVIEW = "create_content_admin_review",
  CREATE_CONTENT_ATTRIBUTION = "create_content_attribution",
  CREATE_CONTENT_BRAND_APPROVED = "create_content_brand_approved",
  CREATE_CONTENT_CLIENT_REVIEW = "create_content_client_review",
  CREATE_CONTENT_COMMENTS = "create_content_comments",
  CREATE_CONTENT_FEEDBACK = "create_content_feedback",
  CREATE_CONTENT_OVERALL_APPROVED = "create_content_overall_approved",
  CREATE_CONTENT_REJECTED = "create_content_rejected",
  CREATE_GROUPS = "create_groups",
  CREATE_INFLUENCER_PROFILES = "create_influencer_profiles",
  CREATE_OUTREACH = "create_outreach",
  CREATE_PARTICIPANT_CONTENTS = "create_participant_contents",
  CREATE_PARTICIPANT_GROUPS = "create_participant_groups",
  CREATE_PARTICIPANTS = "create_participants",
  CREATE_SAMPLE_CONTENT = "create_sample_content",
  CREATE_REPORTS = "create_reports",
  DESTROY_AMBASSADORS_APPLICANTS = "destroy_ambassadors_applicants",
  DESTROY_AMBASSADORS_ONBOARDING_SETTINGS = "destroy_ambassadors_onboarding_settings",
  DESTROY_AMBASSADORS_QUESTIONS = "destroy_ambassadors_questions",
  DESTROY_BRAND_SETTINGS = "destroy_brand_settings",
  DESTROY_BULLETINS = "destroy_bulletins",
  DESTROY_CAMPAIGN_BRIEFS_ABOUT_SECTIONS = "destroy_campaign_briefs_about_sections",
  DESTROY_CAMPAIGN_BRIEFS_CONTRACTS = "destroy_campaign_briefs_contracts",
  DESTROY_CAMPAIGN_BRIEFS_CREATIVE_DIRECTION_SECTIONS = "destroy_campaign_briefs_creative_direction_sections",
  DESTROY_CAMPAIGN_BRIEFS_DELIVERABLES = "destroy_campaign_briefs_deliverables",
  DESTROY_CAMPAIGN_BRIEFS_OVERALL = "destroy_campaign_briefs_overall",
  DESTROY_CAMPAIGN_BRIEFS_PRODUCTS = "destroy_campaign_briefs_products",
  DESTROY_CAMPAIGNS = "destroy_campaigns",
  DESTROY_CONTENT = "destroy_content",
  DESTROY_CONTENT_ADMIN_REVIEW = "destroy_content_admin_review",
  DESTROY_CONTENT_ATTRIBUTION = "destroy_content_attribution",
  DESTROY_CONTENT_BRAND_APPROVED = "destroy_content_brand_approved",
  DESTROY_CONTENT_CLIENT_REVIEW = "destroy_content_client_review",
  DESTROY_CONTENT_COMMENTS = "destroy_content_comments",
  DESTROY_CONTENT_FEEDBACK = "destroy_content_feedback",
  DESTROY_CONTENT_OVERALL_APPROVED = "destroy_content_overall_approved",
  DESTROY_CONTENT_REJECTED = "destroy_content_rejected",
  DESTROY_INFLUENCER_PROFILES = "destroy_influencer_profiles",
  DESTROY_OUTREACH = "destroy_outreach",
  DESTROY_PARTICIPANT_CONTENTS = "destroy_participant_contents",
  DESTROY_PARTICIPANT_GROUPS = "destroy_participant_groups",
  DESTROY_PARTICIPANTS = "destroy_participants",
  DESTROY_REPORTS = "destroy_reports",
  FEATURE_GROUPS = "feature_groups",
  INVITE_USERS = "invite_users",
  SEARCH_AMBASSADORS_CONTENT = "search_ambassadors_content",
  SEARCH_INFLUENCERS = "search_influencers",
  UPDATE_AMBASSADORS_ACCEPTED = "update_ambassadors_accepted",
  UPDATE_AMBASSADORS_APPLICANTS = "update_ambassadors_applicants",
  UPDATE_AMBASSADORS_ONBOARDING_SETTINGS = "update_ambassadors_onboarding_settings",
  UPDATE_AMBASSADORS_QUESTIONS = "update_ambassadors_questions",
  UPDATE_BRAND_SETTINGS = "update_brand_settings",
  UPDATE_BULLETINS = "update_bulletins",
  UPDATE_CAMPAIGN_BRIEFS_ABOUT = "update_campaign_briefs_about",
  UPDATE_CAMPAIGN_BRIEFS_CONTRACTS = "update_campaign_briefs_contracts",
  UPDATE_CAMPAIGN_BRIEFS_CREATIVE_DIRECTION = "update_campaign_briefs_creative_direction",
  UPDATE_CAMPAIGN_BRIEFS_DELIVERABLES = "update_campaign_briefs_deliverables",
  UPDATE_CAMPAIGN_BRIEFS_GUIDELINES = "update_campaign_briefs_guidelines",
  UPDATE_CAMPAIGN_BRIEFS_OVERALL = "update_campaign_briefs_overall",
  UPDATE_CAMPAIGN_BRIEFS_PAYMENT_TERMS = "update_campaign_briefs_payment_terms",
  UPDATE_CAMPAIGN_BRIEFS_PRODUCTS = "update_campaign_briefs_products",
  UPDATE_CAMPAIGN_BRIEFS_PRODUCTS_SECTION = "update_campaign_briefs_products_section",
  UPDATE_CAMPAIGNS = "update_campaigns",
  UPDATE_CAMPAIGNS_REPORTS = "update_campaigns_reports",
  UPDATE_CONTENT = "update_content",
  UPDATE_CONTENT_ADMIN_REVIEW = "update_content_admin_review",
  UPDATE_CONTENT_ATTRIBUTION = "update_content_attribution",
  UPDATE_CONTENT_BRAND_APPROVED = "update_content_brand_approved",
  UPDATE_CONTENT_CLIENT_REVIEW = "update_content_client_review",
  UPDATE_CONTENT_COMMENTS = "update_content_comments",
  UPDATE_CONTENT_FEEDBACK = "update_content_feedback",
  UPDATE_CONTENT_OVERALL_APPROVED = "update_content_overall_approved",
  UPDATE_CONTENT_REJECTED = "update_content_rejected",
  UPDATE_DELIVERABLES = "update_deliverables",
  UPDATE_GROUPS = "update_groups",
  UPDATE_INFLUENCER_PROFILES = "update_influencer_profiles",
  UPDATE_NOTIFICATIONS = "update_notifications",
  UPDATE_OUTREACH = "update_outreach",
  UPDATE_PARTICIPANT_BRIEFS = "update_participant_briefs",
  UPDATE_PARTICIPANT_CONTENTS = "update_participant_contents",
  UPDATE_PARTICIPANT_GROUPS = "update_participant_groups",
  UPDATE_PARTICIPANT_OFFERS = "update_participant_offers",
  UPDATE_PARTICIPANT_REVIEW_STATUS = "update_participant_review_status",
  UPDATE_PARTICIPANTS = "update_participants",
  UPDATE_PRODUCTS = "update_products",
  UPDATE_REPORTS = "update_reports",
  VIEW_AMBASSADORS_ACCEPTED = "view_ambassadors_accepted",
  VIEW_AMBASSADORS_APPLICANTS = "view_ambassadors_applicants",
  VIEW_AMBASSADORS_ONBOARDING_SETTINGS = "view_ambassadors_onboarding_settings",
  VIEW_AMBASSADORS_QUESTIONS = "view_ambassadors_questions",
  VIEW_BRAND_HELP = "view_brand_help",
  VIEW_BRAND_SETTINGS = "view_brand_settings",
  VIEW_BULLETINS = "view_bulletins",
  VIEW_CAMPAIGN_BRIEFS_ABOUT = "view_campaign_briefs_about",
  VIEW_CAMPAIGN_BRIEFS_CONTRACTS = "view_campaign_briefs_contracts",
  VIEW_CAMPAIGN_BRIEFS_CREATIVE_DIRECTION = "view_campaign_briefs_creative_direction",
  VIEW_CAMPAIGN_BRIEFS_DELIVERABLES = "view_campaign_briefs_deliverables",
  VIEW_CAMPAIGN_BRIEFS_GUIDELINES = "view_campaign_briefs_guidelines",
  VIEW_CAMPAIGN_BRIEFS_OVERALL = "view_campaign_briefs_overall",
  VIEW_CAMPAIGN_BRIEFS_PAYMENT_TERMS = "view_campaign_briefs_payment_terms",
  VIEW_CAMPAIGN_BRIEFS_PRODUCTS_SECTION = "view_campaign_briefs_products_section",
  VIEW_CAMPAIGNS = "view_campaigns",
  VIEW_CAMPAIGNS_OVERVIEW = "view_campaigns_overview",
  VIEW_CAMPAIGNS_REPORTS = "view_campaigns_reports",
  VIEW_CONTENT = "view_content",
  VIEW_CONTENT_ADMIN_REVIEW = "view_content_admin_review",
  VIEW_CONTENT_ATTRIBUTION = "view_content_attribution",
  VIEW_CONTENT_BRAND_APPROVED = "view_content_brand_approved",
  VIEW_CONTENT_CLIENT_REVIEW = "view_content_client_review",
  VIEW_CONTENT_COMMENTS = "view_content_comments",
  VIEW_CONTENT_FEEDBACK = "view_content_feedback",
  VIEW_CONTENT_OVERALL_APPROVED = "view_content_overall_approved",
  VIEW_CONTENT_REJECTED = "view_content_rejected",
  VIEW_DELIVERABLES = "view_deliverables",
  VIEW_EDUCATION_CENTER = "view_education_center",
  VIEW_FOHR_HELP = "view_fohr_help",
  VIEW_GROUPS = "view_groups",
  VIEW_INFLUENCER_HELP = "view_influencer_help",
  VIEW_INFLUENCER_PROFILES = "view_influencer_profiles",
  VIEW_NOTIFICATIONS = "view_notifications",
  VIEW_OUTREACH = "view_outreach",
  VIEW_PARTICIPANT_BRIEFS = "view_participant_briefs",
  VIEW_PARTICIPANT_CONTENTS = "view_participant_contents",
  VIEW_PARTICIPANT_GROUPS = "view_participant_groups",
  VIEW_PARTICIPANT_OFFERS = "view_participant_offers",
  VIEW_PARTICIPANT_REVIEW = "view_participant_review",
  VIEW_PARTICIPANTS = "view_participants",
  VIEW_PRODUCTS = "view_products",
  VIEW_REPORTS = "view_reports",
  VIEW_TOGGLE_BRANDS_ACCOUNTS = "view_toggle_brands_accounts",
}
