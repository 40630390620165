import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "@apiClient";
import LoadingSpinner from "@components/LoadingSpinner";
import Modal from "@components/Modals/Modal";
import { toast } from "react-hot-toast";

import ConfirmModal from "@components/Modals/ConfirmModal";
import Table from "@components/Table";
import { generateTableColumns } from "../components/Generators/GenerateTableColumns";
import FButton from "@components/FButton";
import Heading from "@components/Heading";
import GenerateFormFields from "@components/Generators/GenerateFormFields";
import { findNestedChanges, reformData } from "@utils/CampaignUtils";

interface EntityAdminProps {
  apiNamespace?: string;
  pageName?: string;
}

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const EntityAdminLayout: React.FC<EntityAdminProps> = () => {
  const formRef = useRef(null);

  const { entity_name } = useParams();
  const [entityData, setEntityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(null);
  const [entityToEdit, setEntityToEdit] = useState(null);

  const {
    create,
    index,
    update,
    delete: deleteEntity,
  } = apiClient[entity_name];

  useEffect(() => {
    const fetchEntity = async () => {
      try {
        const response = await index();

        const items = response.data[entity_name] || response.data;

        setEntityData(items);
      } catch (error) {
        console.error("Error fetching entity:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntity();
  }, []);

  useEffect(() => {
    const makeTableColumns = () => {
      const columns = generateTableColumns(entityData, `/${entity_name}`);
      setTableColumns(columns);
      return columns; // Return the generated columns
    };

    if (entityData.length > 0) {
      makeTableColumns();
    }
  }, [entityData, entity_name]);

  const handleSubmit = (values) => {
    updateEntity(values);
    setTimeout(() => setIsModalOpen(false), 1000);
  };

  const modalClose = () => {
    setIsDeleteModalOpen(false);
    setIsModalOpen(false);
    setEntityToEdit(null);
    setEntityToDelete(null);
  };

  const removeEntity = async (entity_id) => {
    const response = await deleteEntity(entity_id);
    if (response.success) {
      setEntityData(entityData.filter((d) => d.id !== entity_id));
      setIsDeleteModalOpen(false);
      setEntityToDelete(null);
      toast.success("Deleted");
    } else {
      toast.error(response.message);
    }
  };

  const searchEntityToEdit = ({ id, value }) => {
    setEntityToEdit(entityData.find((d) => d.id === id));
    if (value === "edit") {
      setIsModalOpen(true);
    } else if (value === "delete") {
      setIsDeleteModalOpen(true);
    }
  };

  const updateEntity = async (values) => {
    setWaiting(true);

    const reformedValues = reformData(values);

    if (entityToEdit) {
      const originalEntity = entityData.find((p) => p.id === entityToEdit.id);
      const changedValues = {};

      findNestedChanges(reformedValues, originalEntity, changedValues);

      if (Object.keys(changedValues).length > 0) {
        const response = await update(entityToEdit.id, changedValues);

        const updatedEntities = entityData.map((p) =>
          p.id === entityToEdit.id ? { ...p, ...changedValues } : p
        );
        toast.success("Saved");
        setEntityData(updatedEntities);
      } else {
        toast.success("No changes");
      }
    } else {
      const response = await create(reformedValues);

      setEntityData([
        ...entityData,
        { ...reformedValues, id: response.data.id },
      ]);
      toast.success("Saved");
    }

    setWaiting(false);
  };

  if (loading || !entityData)
    return (
      <div>
        <LoadingSpinner className="w-full h-full mt-[20%]" />.
      </div>
    );

  return (
    <div className="w-full pb-[100px]">
      {entity_name && (
        <div className="flex items-center justify-between pb-[140px]">
          <Heading title={entity_name} />
          <div className="flex flex-row justify-end items-center gap-2">
            <FButton
              primary={true}
              onClick={() => setIsModalOpen(true)}
              label={`+ ${entity_name}`}
              type="button"
            />
          </div>
        </div>
      )}

      <Table
        columns={tableColumns}
        data={entityData}
        rowActions={cardDropDownOptions}
        onOptionSelect={(value, id) => searchEntityToEdit({ id, value })}
      />

      <Modal
        hasForm
        title={entityToEdit ? `Edit ${entity_name}` : `Add ${entity_name}`}
        isOpen={isModalOpen}
        onClose={modalClose}
        onAction={() => formRef.current && formRef.current.submitForm()}
        actionLabel={entityToEdit ? "Save" : "Add"}
        className="w-[650px] z-[9999]"
      >
        <div className="max-h-[60vh] overflow-y-auto">
          <GenerateFormFields
            onClose={modalClose}
            tableColumns={tableColumns}
            initialData={entityToEdit}
            entityId={entityToEdit && entityToEdit.id}
            formRef={formRef}
            onSubmit={handleSubmit}
            data={entityData}
            rootURL={`/${entity_name}`}
            loading={waiting}
          />
        </div>
      </Modal>

      <ConfirmModal
        title={`Delete ${entity_name}`}
        isOpen={isDeleteModalOpen}
        onClose={modalClose}
        onAction={() => removeEntity(entityToDelete.id)}
        actionLabel="Delete"
        subtitle={`Are you sure you want to delete this ${entity_name}?`}
      />
    </div>
  );
};

export default EntityAdminLayout;
