import { getPdfFromHtml } from "@api/api2pdf";
import { getContractTemplate } from "@api/ContractTemplates/ContractTemplates";
import { getParticipantOffers } from "@api/ParticipantBriefs/ParticipantOffers";
import {
  BriefSection,
  Campaign,
  CampaignBrief,
  ContractTemplate,
  Participant,
  ParticipantOffer,
  User,
} from "@types";
import { downloadDataAsFile } from "@utils/download";
import { isEmpty, partition } from "lodash";

import moment from "moment";

export const getContractSections = (
  brief: CampaignBrief,
  contractTemplate: ContractTemplate
): BriefSection[] => {
  if (!contractTemplate) {
    return [];
  }

  return (contractTemplate.sections || []).map((section) => {
    const { id, kind, updated_at } = section;

    const name = section.name || contractTemplate.name;

    if (!isEmpty(brief[kind])) {
      return {
        body: brief[kind].free_form,
        id,
        kind,
        name,
        updated_at,
      } as BriefSection;
    }

    return {
      ...section,
      name,
    };
  });
};

const getReplacements = (
  campaign: Campaign,
  participant: Participant,
  user: User,
  participantOffers: ParticipantOffer[],
  brief: CampaignBrief
): Record<string, string> => {
  const { title } = campaign;

  const {
    creator,
    deliverables = [],
    representative_entity_name,
  } = participant || {};

  const { go_live_start } = brief;

  const { socials = {} } = creator;

  const offerPrice = participantOffers && participantOffers[0]?.selected_price;

  const { brand_name: brandName } = user;

  const { username = "" } = socials[deliverables[0]?.platform] || {};

  const result = {
    CAMPAIGN_NAME: title,
    BRAND_NAME: brandName,
    PRODUCTS: null,
    INFLUENCER_NAME: `${creator?.firstName} ${creator?.lastName}`,
    INFLUENCER_EMAIL: creator?.email,
    REPRESENTATIVE: representative_entity_name,
    CAMPAIGN_URL: `${window.location.host}/campaigns/5257/brief/9820`,
    DELIVERABLES: deliverables.map(({ name }) => name).join("\n"),
    ["influencer handle"]: username || "",
  };

  if (offerPrice) {
    result["XXXXX.XX"] = `${offerPrice.toLocaleString()}`;
    result["COMPENSATION"] = `$${offerPrice.toLocaleString()}`;
  }

  if (go_live_start) {
    result["SERVICES_START_DATE"] =
      moment(go_live_start).format("MMM DD, YYYY");
  }

  return result;
};

export const getContractInstance = (
  campaign: Campaign,
  participant: Participant,
  user: User,
  participantOffers: ParticipantOffer[],
  brief: CampaignBrief,
  contractBriefSection: BriefSection
) => {
  const replacements = getReplacements(
    campaign,
    participant,
    user,
    participantOffers,
    brief
  );

  let result = contractBriefSection.body;

  Object.keys(replacements).forEach((key) => {
    const keysWithoutBrackets = ["influencer handle", "XXXXX.XX"];

    result = result.replace(
      keysWithoutBrackets.includes(key) ? key : `[[${key}]]`,
      replacements[key]
    );
  });

  return result;
};

export const exportBriefContract = async (
  campaign: Campaign,
  brief: CampaignBrief,
  user: User,
  contractTemplate: ContractTemplate
) => {
  let template = contractTemplate;

  if (!template) {
    const response = await getContractTemplate(
      campaign.contract_template_id || 1
    );

    template = response.data;
  }

  const sections = getContractSections(brief, template);

  const [dealMemo, rest] = partition(
    sections,
    ({ kind }) => kind === "deal_memo"
  );

  let fullHtml = dealMemo[0].body;

  rest.forEach((section) => {
    fullHtml += `<div style="break-after:page"></div> ${section.body}`;
  });

  await exportBriefContractSection(campaign, brief, user, {
    body: fullHtml,
    name: "Contract",
  });
};

export const exportBriefContractSection = async (
  campaign: Campaign,
  brief: CampaignBrief,
  user: User,
  section: { body: string; name: string }
) => {
  const { participants } = campaign;

  if (!brief.participant_id) {
    const fileData = await getPdfFromHtml(section.body);

    downloadDataAsFile(
      fileData,
      `${section.name}_template_${campaign.title}`,
      "application/pdf"
    );

    return;
  }

  const participant = participants.find(
    ({ id }) => id === brief.participant_id
  );

  const participantOffers = await getParticipantOffers(brief.participant_id);

  const contract = getContractInstance(
    campaign,
    participant,
    user,
    participantOffers,
    brief,
    section
  );

  const fileData = await getPdfFromHtml(contract);

  const { firstName, lastName } = participant?.creator || {};

  downloadDataAsFile(
    fileData,
    `Contract_${section.name}_${firstName} ${lastName}`,
    "application/pdf"
  );
};
