import { useEffect, useState } from "react";
import CampaignPageHeader from "../components/CampaignPageHeader";
import { getParticipantRecord } from "@api/creator_view";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "@components/LoadingSpinner";
import FSubNav from "@components/FSubNav";
import { capitalize } from "lodash";
import { LazyRouteContent } from "@components/navigation/LazyRouteContent";
import BriefPreviewContent from "../brief-preview/BriefPreviewContent";
import ProfileContentReview from "./ProfileContentReview";
import { ParticipantRecord } from "types/creator-view";
import { Participant } from "@types";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";

const TABS = [
  {
    value: "brief",
  },
  {
    value: "content",
  },
];

const CreatorCampaignPage = () => {
  const { subpage } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [participantRecord, setParticipantRecord] =
    useState<ParticipantRecord>(null);
  const { participant_id } = useParams();
  const participantId = parseInt(participant_id, 10);
  const { trackEvent, trackButtonClick } = useMixpanelTracking();

  useEffect(() => {
    const loadCampaign = async () => {
      try {
        const response = await getParticipantRecord(participantId);
        const { name, status, campaign } = response.data;

        const participant = {
          ...response.data,
          creator: { firstName: name, full_name: name },
          id: participantId,
          status,
        };

        setParticipantRecord(participant);

        // Set document title
        document.title = `Campaign: ${campaign?.title || "View Campaign"}`;

        // Track campaign view for influencer
        trackEvent(EVENTS.CAMPAIGN.VIEWED, {
          campaign_id: campaign?.id,
          campaign_name: campaign?.title,
          participant_id: participantId,
          status: status,
          view_type: "influencer",
        });
      } catch (error) {
        console.error("Error loading campaign:", error);

        // Track error
        trackEvent("Error", {
          error_type: "Campaign Load Error",
          participant_id: participantId,
          error_message: error?.message || "Unknown error",
        });
      } finally {
        setLoading(false);
      }
    };

    loadCampaign();
  }, [participantId, trackEvent]);

  const handleNavChange = (newValue: string) => {
    const item = TABS.find((item) => item.value === newValue);

    if (item && item.value !== location.pathname) {
      // Track tab change
      trackButtonClick("Campaign Tab", {
        tab_name: capitalize(item.value),
        campaign_id: participantRecord?.campaign?.id,
        campaign_name: participantRecord?.campaign?.title,
        participant_id: participantId,
      });

      if (subpage) {
        navigate(`../${item.value}`, { relative: "path" });
      } else {
        navigate(item.value);
      }

      // Track content submission view if navigating to content tab
      if (item.value === "content") {
        trackEvent(EVENTS.PAGE_VIEW.CONTENT_SUBMISSION, {
          campaign_id: participantRecord?.campaign?.id,
          campaign_name: participantRecord?.campaign?.title,
          participant_id: participantId,
        });
      }

      // Track brief view if navigating to brief tab
      if (item.value === "brief") {
        trackEvent(EVENTS.PAGE_VIEW.CAMPAIGN_BRIEF_VIEWED, {
          campaign_id: participantRecord?.campaign?.id,
          campaign_name: participantRecord?.campaign?.title,
          participant_id: participantId,
        });
      }
    }
  };

  const selectedNav =
    TABS.find(({ value }) => value === subpage)?.value || TABS[0].value;

  // Track content submission view on initial load if that's the selected tab
  useEffect(() => {
    if (!loading && selectedNav === "content" && participantRecord) {
      trackEvent(EVENTS.PAGE_VIEW.CONTENT_SUBMISSION, {
        campaign_id: participantRecord.campaign?.id,
        campaign_name: participantRecord.campaign?.title,
        participant_id: participantId,
      });
    }

    // Track brief view on initial load if that's the selected tab
    if (!loading && selectedNav === "brief" && participantRecord) {
      trackEvent(EVENTS.PAGE_VIEW.CAMPAIGN_BRIEF_VIEWED, {
        campaign_id: participantRecord.campaign?.id,
        campaign_name: participantRecord.campaign?.title,
        participant_id: participantId,
      });
    }
  }, [loading, selectedNav, participantRecord, trackEvent, participantId]);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  const { campaign, brief } = participantRecord;

  return (
    <div className="h-[calc(100vh-40px)] overflow-auto flex flex-col w-full">
      <CampaignPageHeader
        campaign={{ ...campaign, brief }}
        participant={participantRecord as unknown as Participant}
      />
      <FSubNav
        className=""
        items={TABS.map((item) => ({
          ...item,
          label: capitalize(item.value),
        }))}
        onChange={handleNavChange}
        selectedValue={selectedNav}
      />
      <LazyRouteContent isActive={selectedNav === "brief"}>
        <BriefPreviewContent
          campaign={{
            ...campaign,
            brief,
            participants: [participantRecord as unknown as Participant],
          }}
          className="pt-8"
          hideHeader
          participant_id={participantId}
        />
      </LazyRouteContent>
      <LazyRouteContent isActive={selectedNav === "content"}>
        <ProfileContentReview participantRecord={participantRecord} />
      </LazyRouteContent>
    </div>
  );
};

export default CreatorCampaignPage;
