import React, { useContext, useEffect } from "react";

import { Role } from "@constants/roles";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { UserContext } from "@contexts/index";
import BrandBulletinsPage from "./BrandBulletinList";
import InfluencerBulletinsPage from "./InfluencerBulletinList";

export default function BulletinsPage() {
  const { trackEvent } = useContext(MixpanelContext);
  const { hasRole } = useContext(UserContext);

  trackEvent(EVENTS.PAGE_VIEW.BULLETINS);

  useEffect(() => {
    document.title = "Bulletins";
  }, []);

  if (hasRole(Role.INFLUENCER)) {
    return <InfluencerBulletinsPage />;
  } else {
    return <BrandBulletinsPage />;
  }
}
