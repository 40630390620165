import { useState } from "react";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";
import FButton from "@components/FButton";

import toast from "react-hot-toast";

import { updateProfile } from "@api/Profile";
import { Profile } from "@types";

import { isEmpty } from "lodash";

import PersonalInfoForm from "./components/PersonalInfoForm";
import moment from "moment";
import { isYoungerThan } from "@utils/validation";

const DEFAULT_VALUES = {
  first_name: "",
  last_name: "",
  address: "",
  country: "United States of America",
  state: "",
  city: "",
  zipcode: "",
  gender: [],
  ethnicity: [],
  birthday: moment().subtract(20, "years").format("YYYY-MM-DD"),
};

interface AddPersonalInfoProps {
  action?: string;
  isBrand?: boolean;
  profile: Partial<Profile>;
  onSubmitted?: () => void;
}

const AddPersonalInfo = ({
  action,
  isBrand,
  profile,
  onSubmitted,
}: AddPersonalInfoProps) => {
  const { profile_image_url, country, birthday, ...rest } = profile;
  const { trackEvent } = useMixpanelTracking();

  const [values, setValues] = useState<Partial<Profile>>({
    ...rest,
    country: country || DEFAULT_VALUES.country,
    birthday: birthday || DEFAULT_VALUES.birthday,
  });

  const [image, setImage] = useState<{ file: File; url: string }>(
    profile_image_url ? { file: null, url: profile_image_url } : null
  );

  const [isSaving, setSaving] = useState(false);

  const [isSubmitted, setSubmitted] = useState(false);

  const [validationError, setValidationError] = useState(null);

  const handleSave = async () => {
    const { birthday, ...rest } = values;

    setValidationError(null);

    if (Object.keys(rest).find((key) => !values[key] || isEmpty(values[key]))) {
      setSubmitted(true);

      return;
    }

    if (isYoungerThan(birthday, 14)) {
      setValidationError("You have to be at least 14 years old");

      return;
    }

    setSaving(true);

    const params = image?.file
      ? { avatar: image?.file, birthday, ...rest }
      : { birthday, ...rest };

    try {
      await updateProfile(params);

      // Track personal info update
      trackEvent(EVENTS.SETTINGS.PERSONAL_INFO_UPDATED, {
        has_avatar: !!image?.file,
        has_birthday: !!birthday,
        is_onboarding: !onSubmitted,
        fields_updated: Object.keys(params),
      });

      toast.success("Changes saved");

      if (onSubmitted) {
        onSubmitted();
      }
    } catch (e) {
      console.error("E", e);
      toast.error("Error updating creator", e.response?.data);

      // Track error
      trackEvent("Error", {
        error_type: "Personal Info Update Error",
        error_message: e.response?.data || "Unknown error",
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <PersonalInfoForm
        values={values}
        onUpdate={setValues}
        image={image}
        onUpdateImage={setImage}
        isSubmitted={isSubmitted}
        isBrand={isBrand}
      />
      {validationError ? (
        <p className="mb-4 text-error_red text-sm font-normal">
          {validationError}
        </p>
      ) : null}
      <FButton
        loading={isSaving}
        height="48px"
        width="100%"
        primary
        label={action || "Continue"}
        onClick={handleSave}
      />
    </>
  );
};

export default AddPersonalInfo;
