import { ApiResponse, BulletinResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

const getBulletinResponse = async (
  bulletin_response_id: string | number
): Promise<ApiResponse<BulletinResponse>> => {
  return await apiWrapper(`/bulletin_responses/${bulletin_response_id}`, "GET");
};

const getBulletinResponseList = async (
  admin?: boolean,
  page?: number,
  sort?: Sort,
  params?: object
): Promise<
  ApiResponse<{
    bulletin_responses: BulletinResponse[];
    response_creator_count: number;
    total_items: number;
    current_page: number;
    page_count: number;
  }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  // Check if params.admin is true
  if (admin === true) {
    return apiWrapper(
      `/admin/bulletin_responses?${queryParams.toString()}`,
      "GET"
    );
  } else {
    return apiWrapper(`/bulletin_responses?${queryParams.toString()}`, "GET");
  }
};

export const createBulletinResponse = async (
  params: Partial<BulletinResponse>
): Promise<ApiResponse<BulletinResponse>> => {
  return apiWrapper("/bulletin_responses", "POST", {
    bulletin_response: params,
  });
};

export const updateBulletinResponse = async (
  bulletin_id: string | number,
  params: Partial<BulletinResponse>
): Promise<ApiResponse<BulletinResponse>> => {
  return apiWrapper(`/bulletin_responses/${bulletin_id}`, "PUT", params);
};

const deleteBulletinResponse = async (bulletin_id: string | number) => {
  return apiWrapper(`/bulletin_responses/${bulletin_id}`, "DELETE");
};

export const downloadCsv = async (bulletin_id: string | number) => {
  return apiWrapper(`/bulletin_responses/csv_download?bulletin_id=${bulletin_id}`, "GET")
};

export { getBulletinResponse, getBulletinResponseList, deleteBulletinResponse };

