import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { AudienceDemographics, Profile, ProfileStats, ViewsData } from "@types";
import { isArray } from "lodash";
import { searchPosts } from "./Search";

const getOverviewData = async (slug: string): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `${slug ? `/creator_profiles/${slug}` : "/creator_view/profile"}/user_details`,
    "GET"
  );
};

const getAudienceData = async (
  slug: string,
  platform: string
): Promise<ApiResponse<AudienceDemographics>> => {
  return apiWrapper(
    `${slug ? `/creator_profiles/${slug}` : "/creator_view/profile"}/audience_demographics?platform=${platform}`,
    "GET"
  );
};

const getTopContent = async (slug: string): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `${slug ? `/creator_profiles/${slug}` : "/creator_view/profile"}/top_content`,
    "GET"
  );
};

const getAllContent = async (slug: string): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `${slug ? `/creator_profiles/${slug}` : "/creator_view/profile"}/all_content`,
    "GET"
  );
};

const getRecentSponsoredContent = async (
  slug: string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `${slug ? `/creator_profiles/${slug}` : "/creator_view/profile"}/recent_sponsored_content`,
    "GET"
  );
};

export const getViewsData = async (
  slug: string,
  platform: string
): Promise<ApiResponse<ViewsData>> => {
  return apiWrapper(
    `${slug ? `/creator_profiles/${slug}` : "/creator_view/profile"}/views_data${platform ? `?platform=${platform}` : ""}`,
    "GET"
  );
};

const getStats = async (
  slug: string,
  platform: string,
  timePeriod: string
): Promise<ApiResponse<ProfileStats>> => {
  return apiWrapper(
    `${slug ? `/creator_profiles/${slug}` : "/creator_view/profile"}/top_stats?platform=${platform}&time_period=${timePeriod}`,
    "GET"
  );
};

export const getStatsWithPerformanceTiers = async (slug: string) => {
  const response = await getStats(slug, null, "90");

  const statsData = response.data;

  // Calculate tier performance
  if (!statsData?.top_stats) {
    return null;
  }

  const topStats = statsData.top_stats;
  const ratios = [];

  const metrics = ["engagement_rate"];

  metrics.forEach((metric) => {
    const { value, tier_average } = topStats[metric];

    if (
      value !== undefined &&
      tier_average !== undefined &&
      tier_average !== 0
    ) {
      ratios.push(value / tier_average);
    }
  });

  const avgTierPerformance = ratios.length
    ? ratios.reduce((acc, curr) => acc + curr, 0) / ratios.length
    : 1;

  return {
    statsData,
    tierPerformance: avgTierPerformance,
  };
};

export const getProfileData = async (slug?: string) => {
  const overviewResponse = await getOverviewData(slug);

  let topContent;

  try {
    const topContentResponse = await getTopContent(slug);

    topContent = topContentResponse.data;
  } catch {
    topContent = [];
  }

  let viewsData;

  try {
    const viewsDataResponse = await getViewsData(slug, "");

    viewsData = viewsDataResponse.data;
  } catch {
    viewsData = {};
  }

  const profile = overviewResponse.data;

  let postsResponse;

  try {
    const response = await searchPosts({
      creator_ids: [profile.id],
      page_size: 10000,
    });

    postsResponse = response.data;
  } catch {
    postsResponse = null;
  }

  return {
    contentHighlight: topContent,
    content: {
      content: postsResponse?.results || [],
      total: postsResponse?.total || 0,
    },
    profile,
    viewsData,
  };
};

export const updateProfile = async (
  params: Partial<Profile>
): Promise<ApiResponse<Profile>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (isArray(value)) {
      value.forEach((member) => {
        formData.append(`profile[${key}][]`, member);
      });
    } else {
      formData.append(`profile[${key}]`, value);
    }
  });

  return apiWrapper(`/profiles`, "POST", formData);
};

export {
  getOverviewData,
  getAudienceData,
  getAllContent,
  getTopContent,
  getRecentSponsoredContent,
  getStats,
};
