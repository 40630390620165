import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Content, Profile } from "@types";

import ContentPreview from "./ContentPreview";
import PaginationDots from "./PaginationDots";

const DEFAULT_LIMIT = 5;

interface ContentHighlightProps {
  limit?: number;
  data: Partial<Content>[];
  profile: Profile;
  width?: string;
}

const ContentHighlight: React.FC<ContentHighlightProps> = ({
  limit = DEFAULT_LIMIT,
  data = [],
  profile,
  width,
}) => {
  const [isPaused, setIsPaused] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [currentSlide, setCurrentSlide] = useState(0);

  const videoPosts =
    limit === null
      ? data
      : data.filter((item) => item.video_url).slice(0, limit);

  const limitedData = useMemo(() => {
    return limit && videoPosts.length < limit
      ? [
          ...videoPosts,
          ...data
            .filter((item) => !item.video_url)
            .sort((a, b) => b.views_count - a.views_count)
            .slice(0, limit - videoPosts.length),
        ]
      : videoPosts;
  }, [data, limit, videoPosts]);

  useEffect(() => {
    const content = limitedData[0];

    if (content && !content.video_url) {
      setTimeout(() => {
        nextSlide();
      }, 3000);
    }
  }, []);

  const nextSlide = useCallback(() => {
    setCurrentSlide((prevSlide) => {
      const nextSlideIndex = (prevSlide + 1) % limitedData.length; // Calculate the next index
      const content = limitedData[nextSlideIndex]; // Get the content for the next slide

      // Check if the content has a video_url
      if (!content.video_url) {
        // Set a timeout to go to the next slide after 3 seconds
        setTimeout(() => {
          nextSlide();
        }, 3000);
      }

      setIsPaused(false); // Resume playback
      return nextSlideIndex; // Return the new slide index
    });
  }, [limitedData]);

  const prevSlide = useCallback(() => {
    setCurrentSlide(
      (currentSlide - 1 + limitedData.length) % limitedData.length
    );
    setIsPaused(false);
  }, [limitedData.length, currentSlide]);

  const togglePlayPause = useCallback(() => {
    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
  }, [isPaused]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        // case " ":
        // 	event.preventDefault();
        // 	togglePlayPause();
        // 	break;
        case "ArrowRight":
          nextSlide();
          break;
        case "ArrowLeft":
          prevSlide();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPaused, currentSlide, togglePlayPause, nextSlide, prevSlide]);

  const content = limitedData[currentSlide] || {};

  return (
    <div className="2xl:h-full 2xl:pb-0 w-full">
      <div className="mb-[14px] h-[24px]">
        <PaginationDots
          currentIndex={currentSlide}
          itemsCount={limitedData.length}
          label="Top Content"
          onChange={setCurrentSlide}
        />
      </div>
      <ContentPreview
        content={content}
        isPaused={isPaused}
        videoRef={videoRef}
        onVideoEnded={nextSlide}
        key={currentSlide}
        profile={profile}
        width={width}
      />
    </div>
  );
};

export default ContentHighlight;
