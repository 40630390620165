import React from "react";

import IndividualCard from "./VerticalCardTypes/IndividualCard";
import ParticipantCard from "./VerticalCardTypes/ParticipantCard";
import ContentCard from "./VerticalCardTypes/ContentCard";
import PostCard from "./VerticalCardTypes/PostCard";

const VerticalCard = (props) => {
  const updatedProps = { ...props, isVertical: true };
  if (updatedProps.cardType === "individual") {
    return <IndividualCard {...updatedProps} />;
  } else if (updatedProps.cardType === "content") {
    return <ContentCard {...updatedProps} />;
  } else if (updatedProps.cardType === "participant") {
    return <ParticipantCard {...updatedProps} />;
  } else if (updatedProps.cardType === "contentReview") {
    return <PostCard {...updatedProps} />;
  } else {
    return <div>Unsupported card type</div>;
  }
};

export default VerticalCard;
