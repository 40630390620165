import React, { useState, useEffect, useRef, useContext } from "react";
import { FDropdownItem, User } from "../../types/types";
import FIcon from "../FIcon";
import { signOut } from "@api/user";
import { UserContext } from "@contexts/index";
import { changeMembership } from "@api/user";
import { Link } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const listItemClassName = "py-[10px]";

interface Props {
  profile_photo?: string;
  menu?: {
    title: string;
    subtitle: string;
    items: FDropdownItem[];
  };
  user: User;
  collapsed: boolean;
}

export default function UserMenu({ user, collapsed, menu }: Props) {
  const { setUser } = useContext(UserContext);
  const { trackEvent } = useContext(MixpanelContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { hasRole } = useAuth();

  const getHelpDeskUrl = (role: string): string => {
    // SaaS Users
    if (role.includes("SAAS") || role === "Freemium" || role === "Open Link") {
      return "https://fohr-brand-user-help-center.helpscoutdocs.com/";
    }

    // Managed Service Users
    if (role.includes("Managed Services") || role === "Brand Owner") {
      return "https://fohr-managed-campaign-help-center.helpscoutdocs.com/";
    }

    // Influencer Users
    if (["Influencer", "Influencer Rep"].includes(role)) {
      return "https://fohr-influencer-help-center.helpscoutdocs.com/";
    }

    // Fohr Users (default)
    return "https://fohr-user-help-center.helpscoutdocs.com/";
  };

  const handleMembershipChange = async (membership) => {
    try {
      const result = await changeMembership({ id: membership.id });
      if (result.data.brand_id === membership.brand_id) {
        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        window.location.reload();
      }
    } catch (error) {
      console.error("Error changing membership:", error);
    }
  };

  const handleSignOut = () => {
    // Track sign-out event before actually signing out
    trackEvent(EVENTS.AUTH.SIGNED_OUT, {
      user_id: user?.id,
      brand_id: user?.brand_id,
      user_type: user?.email?.endsWith("fohr.co")
        ? "fohr"
        : user?.brand_id
          ? "brand"
          : "creator",
      role: user?.role,
    });

    // Proceed with sign-out
    signOut();
  };

  const defaultItems: FDropdownItem[] = [
    {
      name: "Settings",
      icon: { name: "settings", color: "black" },
      link: "/settings",
      onClick: () => {
        trackEvent(EVENTS.USER_MENU.SETTINGS_CLICKED);
      },
    },
    !hasRole(Role.INFLUENCER)
      ? {
          name: "Education Center",
          icon: { name: "star", color: "black" },
          link: "https://fast.wistia.com/embed/channel/tpj8ksqyb0",
          target: "_blank",
          onClick: () => {
            trackEvent(EVENTS.USER_MENU.EDUCATION_CENTER_CLICKED);
          },
        }
      : null,
    {
      name: "Help Desk",
      icon: { name: "help", color: "black" },
      link: getHelpDeskUrl(user?.role || ""),
      target: "_blank",
      onClick: () => {
        trackEvent(EVENTS.USER_MENU.HELP_DESK_CLICKED, {
          help_desk_url: getHelpDeskUrl(user?.role || ""),
          user_role: user?.role,
        });
      },
    },
    {
      name: "Logout",
      icon: { name: "sign_out", color: "red" },
      onClick: handleSignOut,
    },
  ].filter(Boolean);

  const items = [...(menu?.items || []), ...defaultItems];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent click from propagating to the document
    setMenuOpen(!menuOpen);
  };

  return (
    <div
      className="flex flex-row items-center justify-between border-t border-light_border  cursor-pointer relative mt-[12px] px-[18px] py-[20px] w-full "
      onClick={toggleDropdown}
      ref={dropdownRef}
    >
      <div className="flex flex-row items-center">
        <div className="w-7 h-7 rounded-full font-sofia_pro flex items-center justify-center border border-dark_night_sky">
          <span className="text-[12px] font-sofia_pro text-dark_night_sky mt-[2px]">
            {user.first_name ? user.first_name[0] : ""}
          </span>
          <span className="text-[12px] font-sofia_pro text-dark_night_sky mt-[2px]">
            {user.last_name ? user.last_name[0] : ""}
          </span>
        </div>

        {!collapsed && (
          <div className="ml-2">
            <span className="font-sofia_pro text-[14px] font-normal text-dark_night_sky">
              {user.first_name} {user.last_name}
            </span>
            <div className="text-[12px] text-gray-500">{user.brand_name}</div>
          </div>
        )}
      </div>

      {!collapsed && (
        <div className="relative z-50">
          <div className="flex flex-col gap-[4px]">
            <div className={"bg-dark_night_sky w-[14px] h-[1px]"}></div>
            <div className={"bg-dark_night_sky w-[14px] h-[1px] "}></div>
            <div className={"bg-dark_night_sky w-[14px] h-[1px]"}></div>
          </div>
        </div>
      )}
      {menuOpen && (
        <div className="absolute -right-[230px] bottom-10 mb-2 bg-white shadow-lg rounded-md z-110 w-[284px]">
          <div className="px-[16px] py-[12px] pb-[8px] border-b border-light_border">
            <p className="text-lg font-medium truncate">{menu.title}</p>
            <div className="relative">
              <div className="flex items-center">
                <div className="absolute left-0 pointer-events-none">
                  <FIcon
                    icon="store"
                    color="#000"
                    size={14}
                    className="-mt-[2px]"
                  />
                </div>
                <select
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const membership = user.memberships.find(
                      (m) => m.id === parseInt(e.target.value)
                    );
                    if (membership) handleMembershipChange(membership);
                  }}
                  value={
                    user.memberships.find((m) => m.brand_id === user.brand_id)
                      ?.id
                  }
                  className="w-full appearance-none bg-transparent pl-6 pr-6 py-1 text-sm cursor-pointer focus:outline-none"
                >
                  {user.memberships.map((membership) => (
                    <option
                      key={membership.id}
                      value={membership.id}
                      className="text-dark_night_sky"
                    >
                      {membership.name}
                    </option>
                  ))}
                </select>
                <div className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none">
                  <FIcon icon="sort-down" size={12} color="#000" />
                </div>
              </div>
            </div>
          </div>
          <ul className="mt-[5px] list-unstyled">
            {items.map((item, index) => (
              <li
                key={index}
                className={`${listItemClassName} relative ${
                  index === items.length - 1
                    ? "border-t border-light_border mt-[5px]"
                    : ""
                } ${!item.children ? "cursor-pointer hover:bg-neutral_100" : ""}`}
                onClick={item.onClick}
              >
                {item.link && !item.target ? (
                  <Link to={item.link} className="absolute inset-0" />
                ) : (
                  <a
                    href={item.link}
                    target={item.target}
                    className="absolute inset-0"
                  />
                )}

                <div className="flex flex-row items-center gap-[10px] px-[16px]">
                  <FIcon
                    icon={item.icon.name}
                    color={item.icon.color}
                    size={14}
                    className="-mt-[2px]"
                  />
                  <p className="text-[14px]">{item.name}</p>
                </div>
                {item.children ? (
                  <ul className="list-unstyled max-h-[300px] overflow-auto">
                    {item.children.map((child, index) => (
                      <li
                        key={index}
                        className={`${listItemClassName} flex items-center gap-1 hover:bg-neutral_100 cursor-pointer pl-[40px] pr-[16px] ${index === 0 ? "mt-[10px]" : 0}`}
                        onClick={child.onClick}
                      >
                        {child.icon ? (
                          <FIcon
                            icon={child.icon.name}
                            color={child.icon.color}
                            size={14}
                          />
                        ) : null}
                        <p className="text-[14px]">{child.name}</p>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
