import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { CampaignBrief } from "@types";

const getParticipantBriefs = async (params: {
  participant_id_eq?: number;
  contract_template_id_eq?: number;
  title_eq?: string;
  invoice_email_eq?: string;
  participant_campaign_id_eq?: number;
}): Promise<CampaignBrief[]> => {
  const queryParams = new URLSearchParams();

  queryParams.append("per_page", "0");

  if (params.participant_id_eq) {
    queryParams.append(
      "q[participant_id_eq]",
      params.participant_id_eq.toString()
    );
  }
  if (params.contract_template_id_eq) {
    queryParams.append(
      "q[contract_template_id_eq]",
      params.contract_template_id_eq.toString()
    );
  }
  if (params.title_eq) {
    queryParams.append("q[title_eq]", params.title_eq);
  }
  if (params.invoice_email_eq) {
    queryParams.append("q[invoice_email_eq]", params.invoice_email_eq);
  }
  if (params.participant_campaign_id_eq) {
    queryParams.append(
      "q[participant_campaign_id_eq]",
      params.participant_campaign_id_eq.toString()
    );
  }

  const response = await apiWrapper(
    `/participant_briefs?${queryParams.toString()}`,
    "GET"
  );

  return response.data.participant_briefs;
};
const getParticipantBrief = async (
  id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participant_briefs/${id}`, "GET");
};

const updateParticipantBrief = async (
  id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participant_briefs/${id}`, "PUT", params);
};

export const uploadParticipantBriefFiles = async (
  id: number,
  formData: FormData
) => {
  formData.append("go_live_start", "2024-01-01");
  formData.append("go_live_end", "2024-02-02");

  const response = await apiWrapper(
    `/participant_briefs/${id}`,
    "PUT",
    formData
  );

  return response.data;
};

export { getParticipantBriefs, getParticipantBrief, updateParticipantBrief };
