import React, { ReactNode, useEffect, useContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import { UserContext } from "@contexts/index";

// Define app version - should be updated with each release
const APP_VERSION = import.meta.env.VITE_APP_VERSION || "1.0.0";

interface MixpanelProviderProps {
  children: ReactNode;
  userProperties?: Record<string, any>;
}

export const MixpanelContext = React.createContext<{
  trackEvent: (
    eventName: string,
    eventProperties?: Record<string, any>
  ) => void;
  isInitialized: boolean;
  debugMode?: boolean;
  toggleDebugMode?: () => void;
} | null>(null);

export const MixpanelProvider = ({
  children,
  userProperties,
}: MixpanelProviderProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const [isInitialized, setIsInitialized] = useState(false);
  const [debugMode, setDebugMode] = useState(
    import.meta.env.DEV || localStorage.getItem("mp_debug") === "true"
  );

  const toggleDebugMode = () => {
    const newDebugMode = !debugMode;
    setDebugMode(newDebugMode);
    localStorage.setItem("mp_debug", newDebugMode.toString());
    mixpanel.set_config({ debug: newDebugMode });
  };

  const trackEvent = (
    eventName: string,
    eventProperties: Record<string, any> = {}
  ) => {
    if (!isInitialized) {
      console.warn("Mixpanel is not initialized yet.");
      return;
    }

    // Add event deduplication ID
    const dedupId = `${eventName}_${Date.now()}`;
    const lastEvent = localStorage.getItem(`last_event_${eventName}`);

    // Prevent duplicate events within 2 seconds
    if (lastEvent && Date.now() - parseInt(lastEvent) < 2000) {
      console.debug(`Prevented duplicate event: ${eventName}`);
      return;
    }

    localStorage.setItem(`last_event_${eventName}`, Date.now().toString());

    const userType = user?.email?.endsWith("fohr.co")
      ? "fohr"
      : user?.brand_id
        ? "brand"
        : "creator";

    const propertiesWithMetadata = {
      ...eventProperties,
      brand: user?.brand_name || null,
      user_type: userType,
      user_id: user?.id || null,
      dedup_id: dedupId,
      app_version: APP_VERSION,
      timestamp: new Date().toISOString(),
    };

    if (debugMode) {
      console.log(`[Mixpanel] ${eventName}`, propertiesWithMetadata);
    }

    mixpanel.track(eventName, propertiesWithMetadata);
  };

  useEffect(() => {
    const mixpanelProjectToken = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;

    if (!mixpanelProjectToken) {
      console.error("Mixpanel project token is missing!");
      return;
    }

    // Initialize Mixpanel
    mixpanel.init(mixpanelProjectToken, {
      debug: debugMode,
      persistence: "localStorage",
    });

    // Mark initialization as complete
    setIsInitialized(true);

    // Identify user if `user` exists
    if (user?.id) {
      const mixpanelId = user.brand_id
        ? `${user.id}_${user.brand_id}`
        : user.id;

      const userType = user.email.endsWith("fohr.co")
        ? "fohr"
        : user.brand_id
          ? "brand"
          : "creator";

      const userAttributes: Record<string, any> = {
        ...userProperties,
        name:
          user.first_name && user.last_name
            ? `${user.first_name} ${user.last_name}`
            : user.email,
        id: mixpanelId,
        user_type: userType,
        $email: user.email,
      };

      if (user.brand_name) {
        userAttributes.brand = user.brand_name;
      }

      mixpanel.identify(mixpanelId);
      mixpanel.people.set(userAttributes);

      // Track session start after user has been identified
      trackEvent("Session Started", {
        user_id: mixpanelId,
        user_type: userType,
      });
    }
  }, [user, userProperties, debugMode]);

  return (
    <MixpanelContext.Provider
      value={{
        trackEvent,
        isInitialized,
        debugMode,
        toggleDebugMode,
      }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};
