import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import FDropdown from "@components/FDropdown";
import { ProfileContext } from "@contexts/index";
import FollowersGrowthRate from "./fragments/FollowersGrowthRate";
import EngagementGrowthRate from "./fragments/EngagementGrowthRate";
import useAuth from "@hooks/useAuth";
import ContentGuard from "../components/ContentGuard";
import FIcon from "@components/FIcon";
import PlatformFilter from "./fragments/PlatformFilter";
import Stats from "./fragments/Stats";
import { getStats, getViewsData } from "@api/Profile";
import CoreMetricsSection from "../components/CoreMetricsSection";
import { numberWithKMBFormat } from "@utils/number_utilities";
import { TopStats } from "@types";
import ProfileStatsSkeleton from "@components/skeleton/ProfileStatsSkeleton";

interface StatsValue {
  value: number;
  tier_average?: number;
}

const TIME_PERIODS = [
  { label: "Past Month", value: 30 },
  { label: "Past 60 days", value: 60 },
  { label: "Past 90 days", value: 90 },
];

export default function ProfileStatsPage() {
  const {
    profile,
    stats,
    viewsData,
    content,
    setStats,
    setTierPerformance,
    setViewsData,
  } = useContext(ProfileContext);
  const [timePeriod, setTimePeriod] = useState(90);
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [postCount, setPostCount] = useState(30);
  const { user } = useAuth();

  // Add refs for scroll targets
  const statsRef = useRef<HTMLDivElement>(null);
  const coreMetricsRef = useRef<HTMLDivElement>(null);
  const followersRef = useRef<HTMLDivElement>(null);
  const engagementRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Calculate tier performance by comparing each rate to its tier average
  const avgTierPerformance = useMemo(() => {
    if (!stats?.top_stats) return 0;
    const topStats = stats.top_stats;

    const ratios = [];

    // For each rate metric, calculate how many times above/below average it is
    if (
      topStats.engagement_rate?.value !== undefined &&
      topStats.engagement_rate?.tier_average !== undefined &&
      topStats.engagement_rate.tier_average !== 0
    ) {
      ratios.push(
        topStats.engagement_rate.value / topStats.engagement_rate.tier_average
      );
    }

    if (
      topStats.reels_engagement_rate?.value !== undefined &&
      topStats.reels_engagement_rate?.tier_average !== undefined &&
      topStats.reels_engagement_rate.tier_average !== 0
    ) {
      ratios.push(
        topStats.reels_engagement_rate.value /
          topStats.reels_engagement_rate.tier_average
      );
    }

    if (
      topStats.reels_views_rate?.value !== undefined &&
      topStats.reels_views_rate?.tier_average !== undefined &&
      topStats.reels_views_rate.tier_average !== 0
    ) {
      ratios.push(
        topStats.reels_views_rate.value / topStats.reels_views_rate.tier_average
      );
    }

    if (
      topStats.stories_views_rate?.value !== undefined &&
      topStats.stories_views_rate?.tier_average !== undefined &&
      topStats.stories_views_rate.tier_average !== 0
    ) {
      ratios.push(
        topStats.stories_views_rate.value /
          topStats.stories_views_rate.tier_average
      );
    }

    return ratios.length
      ? ratios.reduce((acc, curr) => acc + curr, 0) / ratios.length
      : 1;
  }, [stats]);

  // Update context with tier performance
  useEffect(() => {
    if (avgTierPerformance) {
      setTierPerformance(avgTierPerformance);
    }
  }, [avgTierPerformance]);

  const handleTimeChange = async (value: number) => {
    setTimePeriod(value);
    setLoading(true);
    try {
      const response = await getStats(
        profile.slug,
        selectedPlatform || null,
        value.toString()
      );
      setStats(response.data);
    } catch (error) {
      console.error("Error fetching stats:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlatformChange = async (platform: string) => {
    setSelectedPlatform(platform);
    setLoading(true);

    try {
      const response = await getStats(
        profile.slug,
        platform || null,
        timePeriod.toString()
      );
      setStats(response.data);

      const viewsDataResponse = await getViewsData(
        profile.slug,
        platform || null
      );
      setViewsData(viewsDataResponse.data);
    } catch (error) {
      console.error("Error fetching stats:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!stats || loading) {
    return <ProfileStatsSkeleton />;
  }

  const {
    engagement_growth: engagementGrowth = [],
    follower_growth: followerGrowth = [],
    monthly_stats,
    sponsored,
    top_stats: topStats,
  } = stats || {};

  return (
    <div
      data-testid="profile-stats"
      className="relative py-[52px] pr-[40px] pb-[52px] h-full overflow-auto w-full"
    >
      <div className="">
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between mb-5 px-[2px]">
            <h2 className="text-2xl">Platform Statistics</h2>
            <PlatformFilter
              profile={profile}
              selectedPlatform={selectedPlatform}
              onSelectedPlatform={handlePlatformChange}
            />
          </div>

          <div className="grid grid-cols-4 gap-4 rounded-lg  mb-8">
            <div
              onClick={() => scrollToSection(statsRef)}
              className="flex flex-col text-left p-4 bg-white shadow-sm border border-highlight_red_opacity cursor-pointer rounded-lg transition-colors"
            >
              <span className="text-sm text-black">Tier Performance</span>
              <div className="flex flex-row gap-1">
                <span
                  className={`text-2xl font-bold mt-[5px] ${topStats.tier_performance > 1 ? "text-[#47B37F]" : ""}`}
                >
                  {topStats.tier_performance.toFixed(1)}
                </span>
                <span
                  className={`text-sm mt-auto mb-1 ${topStats.tier_performance > 1 ? "text-[#47B37F]" : "text-neutral_600"}`}
                >
                  x Avg.
                </span>
              </div>
            </div>

            <div
              onClick={() => scrollToSection(coreMetricsRef)}
              className="flex flex-col text-left p-4 bg-light_red hover:bg-highlight_red_opacity cursor-pointer  rounded-lg transition-colors"
            >
              <span className="text-sm text-black">Total Views</span>
              <span className="text-2xl font-bold mt-2">
                {numberWithKMBFormat(topStats?.views || 0)}
              </span>
            </div>

            <div
              onClick={() => scrollToSection(followersRef)}
              className="flex flex-col text-left p-4 cursor-pointer hover:bg-highlight_red_opacity rounded-lg transition-colors"
            >
              <span className="text-sm text-black">Total Followers</span>
              <span className="text-2xl font-bold mt-2">
                {numberWithKMBFormat(topStats?.followers || 0)}
              </span>
            </div>

            <div
              onClick={() => scrollToSection(engagementRef)}
              className="flex flex-col text-left p-4 cursor-pointer hover:bg-highlight_red_opacity rounded-lg transition-colors"
            >
              <span className="text-sm text-black">Avg Engagement Rate</span>
              <span className="text-2xl font-bold mt-2">
                {(topStats?.engagement_rate?.value || 0).toFixed(2)}%
              </span>
            </div>
          </div>
        </div>

        {stats ? (
          <div className="flex flex-col gap-8">
            <div
              ref={statsRef}
              className="scroll-mt-[100px]"
              data-section="stats"
            >
              <Stats
                profileStats={topStats}
                timePeriod={timePeriod}
                onTimeChange={handleTimeChange}
              />
            </div>
            <div
              ref={coreMetricsRef}
              className="scroll-mt-[100px]"
              data-section="core-metrics"
            >
              <CoreMetricsSection
                viewsData={viewsData}
                content={content}
                selectedPlatform={selectedPlatform}
                platforms={profile?.platforms || []}
                postCount={postCount}
                onPostCountChange={setPostCount}
              />
            </div>
            <div
              ref={followersRef}
              className="scroll-mt-[100px]"
              data-section="followers"
            >
              <FollowersGrowthRate
                data={followerGrowth}
                totalFollowers={topStats?.followers}
              />
            </div>
            <div
              ref={engagementRef}
              className="scroll-mt-[100px]"
              data-section="engagement"
            >
              <EngagementGrowthRate
                data={engagementGrowth}
                avgEngagementRate={topStats?.engagement_rate?.value}
              />
            </div>
            <ContentGuard />
          </div>
        ) : null}
      </div>
    </div>
  );
}
