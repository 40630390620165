import React from "react";
import { FInput } from "@components/FInputs";
import FDropdown from "@components/FDropdown";
import { Bulletin, UploadedFile } from "@types";
import RichTextEditor from "@components/RichTextEditor";
import FormField from "@components/Form/FormField";
import moment from "moment";
import FRangePicker from "@components/FDatePicker/FRangePicker";
import FFileUpload from "@components/FFileUpload/FFileUpload";
import { capitalize } from "lodash";

const CATEGORIES = ["survey", "opportunity", "gifting", "event"];

interface BulletinContentFormProps {
  values: Partial<Bulletin>;
  isSubmitted: boolean;
  handleUpdate: (updates: Partial<Bulletin>) => void;
  uploadedImage: UploadedFile;
  setUploadedImage: (image: UploadedFile) => void;
}

export default function BulletinContentForm({
  values,
  isSubmitted,
  handleUpdate,
  uploadedImage,
  setUploadedImage,
}: BulletinContentFormProps) {
  return (
    <div className="flex flex-col gap-6 w-full max-w-[800px]">
      <FInput
        name="title"
        onChange={(value) => handleUpdate({ name: value })}
        label="Title"
        value={values.name}
        required
        validate={isSubmitted}
        width="100%"
      />
      <FormField label="Body" required width="100%">
        <RichTextEditor
          value={values.description}
          id="bulletin_description"
          onChange={(value) => handleUpdate({ description: value })}
        />
      </FormField>
      <div className="flex gap-4">
        <FDropdown
          label="Category"
          options={CATEGORIES.map((category) => ({
            label: capitalize(category),
            value: category,
          }))}
          required
          selectedValue={values.category}
          onChange={(value) => handleUpdate({ category: value as string })}
          height="40"
          validate={isSubmitted}
          width="100%"
        />
      </div>
      <FRangePicker
        minDate={moment().format()}
        value={{
          from: values.start_date,
          to: values.end_date,
        }}
        onChange={({ from, to }) =>
          handleUpdate({
            start_date: from,
            end_date: to,
          })
        }
      />
      <FormField label="Cover image" width="100%">
        <FFileUpload
          className="w-full"
          onUpload={(files) => setUploadedImage(files[0])}
          uploadedFiles={[uploadedImage]}
          onDelete={() => setUploadedImage(null)}
        />
      </FormField>
    </div>
  );
}
