import FButton from "@components/FButton";
import FIcon from "@components/FIcon";
import { isEmpty } from "lodash";
import React, { useState } from "react";

import { QuestionnaireQuestion, FieldType } from "types/questionnaire";

const defaultQuestion = {
  question: "",
  required: true,
  position: 1,
  data: {
    options: [],
  },
  field_type: FieldType.SINGLE_SELECT,
};

interface QuestionCardProps {
  onDelete: (question: QuestionnaireQuestion) => void;
  onEdit: (question: QuestionnaireQuestion) => void;
  question: QuestionnaireQuestion;
  hideChildQuestions?: boolean;
}

const QuestionCard = ({
  question: questionnaireQuestion,
  onDelete,
  onEdit,
  hideChildQuestions,
}: QuestionCardProps) => {
  const [isExpanded, setExpanded] = useState(false);

  const {
    conditional_answer: conditionalAnswer,
    id,
    question,
    question_id,
    data,
    required,
    field_type,
    children,
  } = questionnaireQuestion;

  const { options } = data || {};

  const handleAddChildQuestion = () => {
    onEdit({
      ...defaultQuestion,
      question_id: id,
    });
  };

  const isChild = !!question_id;

  return (
    <div className="border border-neutral_400 overflow-hidden rounded-lg group hover:border-default_weak">
      <div
        className="cursor-pointer flex items-center justify-between gap-2 p-4"
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!isExpanded);
        }}
      >
        <p className="font-medium">{question}</p>

        <div className="flex items-center gap-4">
          <div className="flex gap-2 group-hover:opacity-100 opacity-0">
            <div
              onClick={(e) => {
                e.stopPropagation();
                onEdit(questionnaireQuestion);
              }}
              className="cursor-pointer w-[32px] h-[32px] rounded-lg border flex all-center"
            >
              <FIcon icon="edit" color="#000000" size={16} />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                onDelete(questionnaireQuestion);
              }}
              className="cursor-pointer bg-light_red w-[32px] h-[32px] rounded-lg border border-red flex all-center"
            >
              <FIcon icon="delete" color="#E47667" size={16} />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium text-red">
              {required ? "Required*" : ""}
            </span>
          </div>
          <FIcon
            icon="chevron"
            size={11}
            color="#000F45"
            className={`-mt-[4px] ${isExpanded ? "rotate-180" : ""}`}
          />
        </div>
      </div>
      {isExpanded ? (
        <div className="bg-light_red p-4 border-t border-neutral_400">
          <div className="flex items-center justify-between gap-2">
            <div className="flex flex-col gap-2">
              {conditionalAnswer ? (
                <span>Conditional answer: {conditionalAnswer}</span>
              ) : null}
              {[FieldType.SINGLE_SELECT, FieldType.MULTI_SELECT].includes(
                field_type
              ) ? (
                <div className="flex items-center gap-2">
                  <span className="font-medium">Answers:</span>{" "}
                  {options ? (
                    <div className="flex flex-wrap gap-2">
                      {options.map((option, index) => (
                        <span
                          key={index}
                          className="px-2 pt-1 pb-0.5 bg-white border border-neutral_400 rounded-md text-sm"
                        >
                          {option}
                        </span>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}

              <div className="flex items-center gap-2">
                <span className="font-medium ">Field type:</span>
                <span className="capitalize">
                  {" "}
                  {field_type.toLowerCase().split("_").join(" ")}
                </span>
              </div>
            </div>
          </div>
          {!isEmpty(children) && options ? (
            <div className="mt-4 pl-16">
              <h2 className="text-[20px] font-medium mb-4">Child questions</h2>
              <div className="flex flex-col gap-8">
                {options.map((option) => {
                  const childQuestions = children[option];

                  if (isEmpty(childQuestions)) {
                    return null;
                  }

                  return childQuestions.map((childQuestion, index) => (
                    <QuestionCard
                      key={index}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      question={childQuestion}
                    />
                  ));
                })}
              </div>
            </div>
          ) : null}
          {[FieldType.SINGLE_SELECT, FieldType.MULTI_SELECT].includes(
            field_type
          ) &&
          !isChild &&
          !hideChildQuestions ? (
            <div className="flex mb-2 justify-end w-full">
              <FButton
                iconLeft={{
                  name: "add",
                  size: 12,
                  color: "#000721",
                  className: "mr-2",
                }}
                className="mb-1 mt-4"
                onClick={handleAddChildQuestion}
                label="Add child question"
              ></FButton>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default QuestionCard;
