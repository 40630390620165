import React from "react";
import FIcon from "@components/FIcon";
import { useNavigate } from "react-router-dom";

const ACCOUNT_TYPES = [
  {
    name: "Influencer",
    icon: "skincare",
  },
  {
    name: "Brand - App",
    icon: "store",
  },
  {
    name: "Brand - Managed Service",
    icon: "briefs",
  },
];

const AccountTypeBox = ({ icon, name, onSelected }) => (
  <div
    className="cursor-pointer flex flex-col items-center justify-center w-[212px] h-[236px] border border-neutral_400 hover:border-dark_border"
    onClick={onSelected}
  >
    <div className="flex-1 flex items-center justify-center">
      <FIcon icon={icon} size={40} />
    </div>
    <div className="flex-1 flex items-center justify-center">
      <p className="text-2xl text-center">
        {name.includes(" - ") ? (
          <>
            {name.split(" - ")[0]}<br />
            {name.split(" - ")[1]}
          </>
        ) : (
          name
        )}
      </p>
    </div>
    <div className="flex-1 flex items-center justify-center">
      <FIcon color="#000721" icon="next-arrow" size={24} />
    </div>
  </div>
);

export default function Login() {
  const navigate = useNavigate();

  const handleSelected = (accountType) => {
    if (accountType === "Brand - Managed Service") {
      window.location.href = "https://www.fohr.co/form";
    } else if (accountType === "Brand - App") {
      navigate('/signup/brand')
    }
    else {
      navigate(`/signup/${accountType.toLowerCase()}`);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <p className="text-3xl leading-[30px] mb-4 text-center">
        Welcome to Fohr
      </p>
      <p className="mb-14 text-center">Select your account type</p>
      <div className="flex items-center gap-8 mb-6">
        {ACCOUNT_TYPES.map(({ icon, name }) => (
          <AccountTypeBox
            key={name}
            icon={icon}
            name={name}
            onSelected={() => handleSelected(name)}
          />
        ))}
      </div>
    </div>
  );
}
