import React, { useRef, useState, useContext } from "react";
import { FInput } from "@components/FInputs";
import FButton from "@components/FButton";
import { getFullUrl, isValidUrl } from "@utils/validation";

import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { finishSignupBrand, signupBrand } from "@api/brands";
import FDropdown from "@components/FDropdown";
import { Captcha, CaptchaError, CaptchaInstance } from "@components/Captcha";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const BUDGET_OPTIONS = ["< $50K", "$50K - $200K", "$200K - $500K", "$500K+"];

export default function SignUpBrands() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { trackEvent } = useContext(MixpanelContext);

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    brand_name: "",
    brand_website: "",
    brand_budget: null,
    hear_us: null,
    password: "",
    password_confirmation: "",
  });

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const captchaRef = useRef<CaptchaInstance>();

  const handleUpdate = (updates) => {
    setValues({ ...values, ...updates });
  };

  const ref = searchParams.get("ref");
  const token = searchParams.get("token");

  const handleSubmit = async () => {
    setSubmitted(true);

    const validWebsiteUrl = getFullUrl(values.brand_website);

    if (
      !token &&
      (!values.first_name ||
        !values.last_name ||
        !values.email ||
        !values.brand_name ||
        !values.brand_website)
    ) {
      return;
    }

    setError("");
    setWaiting(true);

    try {
      if (token) {
        if (values.password !== values.password_confirmation) {
          setError("Passwords must match");
          return;
        }

        const { password, password_confirmation } = values;

        const data = await finishSignupBrand({
          ref,
          token,
          password,
          password_confirmation,
        });

        if (data.errors) {
          setError(data.errors.message);
          trackEvent(EVENTS.ERROR.AUTH_ERROR, {
            error_type: "password_setup",
            error_message: data.errors.message,
            signup_stage: "final",
          });
          return;
        }

        // Track successful brand account setup completion
        trackEvent(EVENTS.AUTH.BRAND_CREATED, {
          signup_stage: "completed",
          referral_code: ref || null,
        });

        navigate(`/`);
      } else {
        const captchaToken = captchaRef.current?.getToken();

        await signupBrand(
          { ...values, brand_website: validWebsiteUrl },
          captchaToken
        );

        // Track successful initial brand signup
        trackEvent(EVENTS.AUTH.BRAND_CREATED, {
          signup_stage: "initial",
          company_budget: values.brand_budget,
          brand_source: values.hear_us,
          email_domain: values.email.split("@")[1],
        });

        setSuccess(true);
      }
    } catch (e) {
      if (e instanceof CaptchaError) {
        setError(e.message);
        trackEvent(EVENTS.ERROR.AUTH_ERROR, {
          error_type: "captcha",
          error_message: e.message,
          signup_stage: token ? "final" : "initial",
        });
      } else {
        const { data } = e.response || {};

        setError(
          data?.errors ? Object.values(data?.errors).join(", ") : data?.message
        );
        captchaRef.current?.reset();
        trackEvent(EVENTS.ERROR.AUTH_ERROR, {
          error_type: "signup",
          error_message: e.response?.data?.message || "Unknown signup error",
          status_code: e.response?.status,
          signup_stage: token ? "final" : "initial",
        });
      }
    } finally {
      setWaiting(false);
    }
  };

  return (
    <>
      <p className="text-3xl leading-[30px] mb-4 text-center">
        {token ? "Create Password" : "Brand Sign Up"}
      </p>
      <div className="h-[calc(100vh-200px)] overflow-auto">
        {!token ? (
          <>
            <p className="mb-8 text-center">
              Influencer marketing solutions for the world’s best brands.
            </p>
            <p className="mb-8 text-center">
              Already have a Fohr account?{" "}
              <Link className="underline" to="/login">
                Login
              </Link>
            </p>
          </>
        ) : null}
        {token ? (
          <>
            <FInput
              className="mb-8"
              value={values.password}
              onChange={(value) => handleUpdate({ password: value })}
              type="password"
              label="Password"
              required
              width="100%"
            />
            <FInput
              className="mb-8"
              value={values.password_confirmation}
              onChange={(value) =>
                handleUpdate({ password_confirmation: value })
              }
              type="password"
              label="Confirm Password"
              required
              width="100%"
            />
          </>
        ) : (
          <>
            <FInput
              className="mb-8"
              value={values.first_name}
              onChange={(value) => handleUpdate({ first_name: value })}
              type="text"
              required
              validate={isSubmitted}
              label="First Name"
              width="100%"
            />
            <FInput
              className="mb-8"
              value={values.last_name}
              onChange={(value) => handleUpdate({ last_name: value })}
              type="text"
              required
              validate={isSubmitted}
              label="Last Name"
              width="100%"
            />
            <FInput
              className="mb-8"
              value={values.email}
              onChange={(value) => handleUpdate({ email: value })}
              type="text"
              required
              validate={isSubmitted}
              label="Email"
              width="100%"
            />
            <FInput
              className="mb-8"
              value={values.brand_name}
              onChange={(value) => handleUpdate({ brand_name: value })}
              type="text"
              required
              validate={isSubmitted}
              label="Company Name"
              width="100%"
            />
            <FInput
              className="mb-8"
              value={values.brand_website}
              onChange={(value) => handleUpdate({ brand_website: value })}
              type="text"
              required
              error={
                isSubmitted &&
                values.brand_website &&
                !isValidUrl(values.brand_website)
                  ? "Please input a valid URL, e.g: https://www.fohr.co"
                  : ""
              }
              validate={isSubmitted}
              label="Company Website"
              width="100%"
            />
            <FDropdown
              className="mb-8"
              label="Annual Influencer Budget"
              options={BUDGET_OPTIONS.map((option) => ({
                label: option,
                value: option,
              }))}
              selectedValue={values.brand_budget}
              onChange={(value) => handleUpdate({ brand_budget: value })}
              width="100%"
            />
            <FInput
              className="mb-8"
              value={values.hear_us}
              onChange={(value) => handleUpdate({ hear_us: value })}
              type="text"
              required
              label="How Did You Hear About Us"
              width="100%"
            />
            <Captcha ref={captchaRef} className="mb-8" action="sign-up-brand" />
          </>
        )}
        <div className="sticky bottom-0 mt-8">
          <FButton
            primary
            label={token ? "Confirm" : "Sign Up"}
            onClick={handleSubmit}
            loading={waiting}
            height="48px"
            width="100%"
            type="submit"
          />
          {success && (
            <p className={`mt-8 text-green text-center`}>
              Confirmation email sent to {values.email}
            </p>
          )}
        </div>
      </div>
      {error ? <p className={"text-8 mt-8 text-red"}>{error}</p> : null}
    </>
  );
}
