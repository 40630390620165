import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";

const getAdminParticipantBriefs = async (
  page = 1,
  searchTerm = "",
  searchKeyInitial = "campaignName"
) => {
  let searchKey;
  if (searchKeyInitial === "campaignName") {
    searchKey = "participant_campaign_title";
  }
  if (searchKeyInitial === "projectNumber") {
    searchKey = "participant_campaign_salesforce_project_number";
  }

  const params = {
    [`${searchKey}_cont`]: searchTerm,
  };

  const queryParams = getQueryParamsForSearchRequest(page, undefined, params);

  const response = await apiWrapper(
    `/admin/participant_briefs?${queryParams.toString()}`,
    "GET"
  );

  return response.data;
};

export { getAdminParticipantBriefs };
