import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";
import Heading from "@components/Heading";
import { allSearchCategories } from "@constants/constants";
import React, { useEffect, useState, useRef, useContext } from "react";
import { Role } from "@constants/roles";
import { capitalize, isEmpty, size } from "lodash";

import { CreatorFromSearch } from "@apiTypes";
import ResultsGrid from "@components/ResultsGrid";
import { searchCreators, searchPosts } from "@api/Search";

import { CREATOR_CATEGORIES, POST_CATEGORIES } from "@api/Search";
import useDebouncedValue from "@hooks/useDebouncedValue";

import FPagination from "@components/FPagination";

import GeneralPostModal from "@components/Modals/GeneralPostModal";
import { useNavigate } from "react-router-dom";

import BlankState from "@components/BlankState";
import FButton from "@components/FButton";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import { Content } from "@types";
import CreateReportModal from "@components/Modals/CreateReportModal";
import useAuth from "@hooks/useAuth";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { Permission } from "@constants/roles";
import { FCheckbox } from "@components/FInputs";
import AdvancedCreatorSearchModal from "./advanced/AdvancedCreatorSearchModal";
import { AdvancedSearchParams } from "./types";
import { getSearchString } from "@utils/history";
import AdvancedContentSearchModal from "./advanced/AdvancedContentSearchModal";
import SearchResultsSkeleton from "@components/Search/SearchResultsSkeleton";
import { categoryImages, contentCategoryImages } from "./constants";
import toast from "react-hot-toast";
import {
  createBulletinRecipient,
  createBulletinRecipientFromSearch,
} from "@api/Bulletins/BulletinRecipients";
import PlatformSelect from "./advanced/components/PlatformSelect";
import { formatNumber } from "@utils/number_utilities";
import {
  MEDIA_PRODUCT_TYPES,
  CONTENT_SORT_OPTIONS,
  CREATOR_SORT_OPTIONS,
} from "@constants/content";
import {
  DEFAULT_ADVANCED_FILTERS,
  DEFAULT_AUDIENCE_FILTERS,
  DEFAULT_FILTERS,
  getDefaultFilters,
  areAdvancedFiltersEmpty,
} from "./utils";

const getOptions = (categories: {
  [key: number]: string;
}): { value: string; label: string }[] =>
  Object.keys(categories).map((key) => ({
    value: key,
    label: categories[key],
    icon: allSearchCategories.find((category) => category.value === `${key}`)
      ?.icon,
  }));

// Add this helper function to reorder categories
const reorderCategories = (
  categories: typeof allSearchCategories,
  selectedValue: string
) => {
  const category = categories.find((c) => c.value === selectedValue);
  if (!category) return categories;

  const otherCategories = categories.filter((c) => c.value !== selectedValue);
  return [category, ...otherCategories];
};

export default function Search() {
  const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [creators, setCreators] = useState<CreatorFromSearch[]>([]);
  const [posts, setPosts] = useState<Content[]>([]);

  const [selectedCreatorIds, setSelectedCreatorIds] = useState<number[]>([]);
  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);
  const [isCreateReportModalVisible, setCreateReportModalVisible] =
    useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  const [addToBulletinId] = useState(searchParams.get("addToBulletinId"));
  const [isAddingToBulletin, setAddingToBulletin] = useState(false);

  const [
    isAdvancedCreatorSearchModalVisible,
    setAdvancedCreatorSearchModalVisible,
  ] = useState(false);

  const [
    isAdvancedContentSearchModalVisible,
    setAdvancedContentSearchModalVisible,
  ] = useState(false);

  const [total, setTotal] = useState(null);

  const [areAllCreatorsSelected, setAreAllCreatorsSelected] = useState(false);

  const navigate = useNavigate();

  const { can, hasRole, user } = useAuth();

  let resources = [];

  if (!isAddingToBulletin) {
    resources = [{ label: "Content", icon: "content", value: "content" }];
  }

  if (can(Permission.SEARCH_INFLUENCERS)) {
    resources.push({ label: "Creators", icon: "audience", value: "creators" });
  }

  const defaultFilters = getDefaultFilters(searchParams, DEFAULT_FILTERS);

  if (!resources.find(({ value }) => value === defaultFilters.resource)) {
    defaultFilters.resource = resources[0].value;
  }

  const [filters, setFilters] = useState(defaultFilters);

  const [advancedFilters, setAdvancedFilters] = useState(
    getDefaultFilters(searchParams, DEFAULT_ADVANCED_FILTERS)
  );

  const {
    resource,
    searchTerm,
    selectedCategories,
    selectedPlatforms,
    page,
    media_product_type,
  } = filters;

  const debouncedSearchTerm = useDebouncedValue(
    searchTerm !== null ? searchTerm : searchParams.get("searchTerm"),
    500
  );

  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const { trackEvent } = useContext(MixpanelContext);

  // Add state for reordered categories
  const [orderedCategories, setOrderedCategories] =
    useState(allSearchCategories);

  useEffect(() => {
    trackEvent(EVENTS.PAGE_VIEW.SEARCH);

    document.title = "Search";
  }, []);

  // Track resource type changes (content vs creators)
  const handleResourceChange = (newResource) => {
    // If resource hasn't changed, don't do anything
    if (newResource === resource) {
      return;
    }

    // Track search type selection event
    trackEvent(EVENTS.SEARCH.TYPE_SELECTED, {
      previous_type: resource,
      new_type: newResource,
      has_search_term: !!debouncedSearchTerm,
      filters_count: selectedCategories?.length || 0,
      has_advanced_filters: !areAdvancedFiltersEmpty(advancedFilters),
    });

    // Update filters with new resource type

    setFilters({
      media_product_type: null,
      searchTerm: null,
      selectedPlatforms: [],
      page: 1,
      resource: newResource,
      selectedCategories: [],
      sort: null,
      sort_order: "desc",
    });
  };

  useEffect(() => {
    if (
      hasRole(Role.FREEMIUM) &&
      (user?.actions_performed?.searches_performed || 0) >= 20
    ) {
      setTimeout(() => navigate("/upgrade_membership"), 100);
    }
  }, [hasRole, user, navigate]);

  useEffect(() => {
    const handleSearch = async () => {
      const queryParams = getSearchString({
        searchTerm: debouncedSearchTerm,
        page,
        selectedPlatforms,
        selectedCategories,
        media_product_type,
        resource,
        sort: filters.sort,
        sort_order: filters.sort_order,
        ...advancedFilters,
      });
      navigate(`/search?${queryParams.toString()}`);

      setLoading(true);

      if (
        !debouncedSearchTerm &&
        isEmpty(selectedCategories) &&
        areAdvancedFiltersEmpty(advancedFilters) &&
        isEmpty(selectedPlatforms)
      ) {
        setCreators([]);
        setPosts([]);

        setLoading(false);

        return;
      }

      const commonFilters = {
        p: page,
        searchTerm: debouncedSearchTerm,
        platforms: selectedPlatforms,
        media_product_type,
      };

      if (resource === "content") {
        const response = await searchPosts({
          ...commonFilters,
          categories: selectedCategories,
          media_product_type,
          sort: filters.sort,
          sort_order: filters.sort_order,
          p: page,
          ...advancedFilters,
        });

        const { results, total } = response.data;

        setPosts(results);
        setTotal(total);

        setLoading(false);
        trackEvent("Search Performed", {
          search_type: resource,
          total_results: total,
        });

        // Track search results viewed event
        if (results.length > 0) {
          trackEvent(EVENTS.SEARCH.RESULTS_VIEWED, {
            search_type: resource,
            results_count: results.length,
            total_results: total,
            search_term: debouncedSearchTerm,
            filter_count: selectedCategories?.length || 0,
            platform_filters: selectedPlatforms,
            advanced_filters: !areAdvancedFiltersEmpty(advancedFilters),
            page_number: page,
          });
        }

        return;
      }

      const response = await searchCreators({
        ...advancedFilters,
        ...commonFilters,
        tags: selectedCategories,
        media_product_type,
        sort: filters.sort,
        sort_order: filters.sort_order,
      });
      const { results, total } = response.data;

      setCreators(results);
      setTotal(total);

      setLoading(false);
      trackEvent("Search Performed", {
        search_type: resource,
        total_results: total,
      });

      // Track search results viewed event
      if (results.length > 0) {
        trackEvent(EVENTS.SEARCH.RESULTS_VIEWED, {
          search_type: resource,
          results_count: results.length,
          total_results: total,
          search_term: debouncedSearchTerm,
          filter_count: selectedCategories?.length || 0,
          platform_filters: selectedPlatforms,
          advanced_filters: !areAdvancedFiltersEmpty(advancedFilters),
          page_number: page,
        });
      }
    };

    handleSearch();
  }, [
    debouncedSearchTerm,
    resource,
    selectedPlatforms,
    selectedCategories,
    page,
    advancedFilters,
    media_product_type,
    filters.sort,
    filters.sort_order,
    navigate,
    trackEvent,
  ]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  // Update the searchCategories calculation
  const searchCategories = getOptions(
    resource === "content" ? POST_CATEGORIES : CREATOR_CATEGORIES
  ).sort((a, b) => {
    // Keep selected items at the front
    const aSelected = selectedCategories.includes(a.value);
    const bSelected = selectedCategories.includes(b.value);
    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return 0;
  });

  const handleContentModalClick = (content) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  const handleToggleItemSelection = (id) => {
    let newSelectedItems = selectedCreatorIds || [];

    if (selectedCreatorIds.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedCreatorIds, id];
    }

    setSelectedCreatorIds(newSelectedItems);
  };

  const handleAddToGroup = () => setAddToGroupModalVisible(true);

  const handleAddToBulletin = async () => {
    try {
      setAddingToBulletin(true);

      if (areAllCreatorsSelected) {
        await createBulletinRecipientFromSearch({
          ...advancedFilters,
          bulletin_id: parseInt(addToBulletinId, 10),
          resource,
          searchTerm,
          platforms: selectedPlatforms,
          tags: selectedCategories,
        });
      } else {
        const promises = selectedCreatorIds.map((creator_id) => {
          return createBulletinRecipient({
            bulletin_id: parseInt(addToBulletinId, 10),
            creator_id,
          });
        });

        await Promise.all(promises);
      }

      setAddingToBulletin(false);

      toast.success("Added");

      navigate(`/bulletins/${addToBulletinId}/recipients`);
    } catch (e) {
      toast.error("Error adding to bulletin");
    } finally {
      setAddingToBulletin(false);
    }
  };

  const isEmptySearch =
    !debouncedSearchTerm &&
    isEmpty(selectedCategories) &&
    areAdvancedFiltersEmpty(advancedFilters) &&
    isEmpty(selectedPlatforms);

  const showAPIConnection =
    selectedPlatforms.length > 0 || resource === "content";

  const isInstagram =
    size(selectedPlatforms) === 1 && selectedPlatforms[0] === "instagram";

  console.log(filters);

  return (
    <div className="pb-16">
      <div className="w-full mb-20">
        <Heading
          title={"Search & Discover"}
          className="pb-8"
          headerButtons={
            <div className="flex gap-4">
              {selectedCreatorIds.length > 0 &&
              resource === "creators" &&
              !addToBulletinId ? (
                <FButton
                  onClick={handleAddToGroup}
                  label={`(${selectedCreatorIds.length}) Add to group`}
                  width="max-content"
                ></FButton>
              ) : null}

              {posts.length > 0 ? (
                <FButton
                  onClick={() => setCreateReportModalVisible(true)}
                  label={`Create Report`}
                  width="max-content"
                ></FButton>
              ) : null}
              <FButton
                label="Advanced Search"
                primary
                iconLeft={{ name: "filter", size: 14, color: "#fff" }}
                onClick={() =>
                  resource === "creators"
                    ? setAdvancedCreatorSearchModalVisible(true)
                    : setAdvancedContentSearchModalVisible(true)
                }
              />
              {addToBulletinId ? (
                <FButton
                  label={`Add to Bulletin ${areAllCreatorsSelected ? `(${total})` : selectedCreatorIds.length ? `(${selectedCreatorIds.length})` : ""}`}
                  disabled={
                    !areAllCreatorsSelected && isEmpty(selectedCreatorIds)
                  }
                  primary
                  onClick={handleAddToBulletin}
                  loading={isAddingToBulletin}
                />
              ) : null}
            </div>
          }
        />
      </div>
      <div className="flex items-center border-b border-b-dark gap-[12px] pb-[10px]">
        <div className="flex items-center">
          <FIcon
            icon={resources.find(({ value }) => value === resource).icon}
            size={18}
            color="#000F45"
            className="mr-[10px] -mt-[2px]"
          />
          <FDropdown
            options={resources}
            transparent
            height={30}
            width="110px"
            className="text-[18px] w-auto"
            selectedValue={resource}
            titleDropDown={true}
            onChange={handleResourceChange}
          />
        </div>
        {/* search */}
        <div className="w-[1px] h-[30px] bg-dark_night_sky" />
        <div className="flex items-center w-full">
          <FIcon icon="search" size={20} color="#000F45" className="mb-2" />
          <input
            ref={searchInputRef}
            className="pl-[16px] bg-transparent border-none w-full h-[30px] text-[20px] outline-none "
            type="text"
            value={searchTerm}
            onChange={(e) =>
              setFilters({
                ...filters,
                page: 1,
                searchTerm: e.target.value,
              })
            }
            placeholder="Search for one or more keywords, hashtags, or mentions, and any matching results will appear."
          />
          {searchTerm || selectedCategories.length > 0 ? (
            <div
              onClick={() => {
                setFilters({
                  ...filters,
                  page: 1,
                  searchTerm: "",
                  selectedCategories: [],
                });
              }}
              className="cursor-pointer h-[30px] w-auto px-4 gap-1 flex items-center justify-center mr-4 bg-highlight_red rounded-full"
            >
              <FIcon icon="exit" size={12} color="#000" className="-mt-[1px]" />
              <p className="text-[12px] text-dark_night_sky">Clear</p>
            </div>
          ) : null}
          <div className="w-[1px] h-[30px] bg-dark_night_sky ml-2 mr-[4px]" />
        </div>
        {/* platforms */}
        <PlatformSelect
          selectedPlatforms={selectedPlatforms}
          onChange={(newSelectedPlatforms) => {
            setFilters({
              ...filters,
              page: 1,
              selectedPlatforms: newSelectedPlatforms,
              media_product_type: null,
            });

            if (isEmpty(newSelectedPlatforms)) {
              setAdvancedFilters({
                ...advancedFilters,
                ...DEFAULT_AUDIENCE_FILTERS,
              });
            }
          }}
        />
      </div>
      <div className="relative mt-[16px]">
        <div
          className={`flex flex-wrap items-center gap-[10px] ${
            !categoriesExpanded ? "h-[35px]" : "h-full"
          } overflow-hidden w-[calc(100%-40px)]`}
        >
          <FIcon
            icon="tag"
            size={18}
            color="#000721"
            className="mr-[8px] -mt-[2px]"
          />
          {searchCategories.map((category) => {
            return (
              <p
                className={`cursor-pointer border border-neutral_400 rounded-full px-[13px] pb-[2px] pt-[4px] text-[14px]
                ${selectedCategories.indexOf(category.value) !== -1 ? "bg-dark_night_sky text-white border-dark_night_sky" : ""}`}
                key={category.value}
                onClick={() => {
                  let newSelectedCategories;

                  if (selectedCategories.indexOf(category.value) !== -1) {
                    newSelectedCategories = selectedCategories.filter(
                      (c) => c !== category.value
                    );
                  } else {
                    newSelectedCategories = [
                      category.value,
                      ...selectedCategories,
                    ];
                    // Reorder categories when selecting a new one
                    setOrderedCategories(
                      reorderCategories(orderedCategories, category.value)
                    );
                  }

                  setFilters({
                    ...filters,
                    page: 1,
                    selectedCategories: newSelectedCategories,
                  });
                }}
              >
                {category.label}
              </p>
            );
          })}
          <div
            className="absolute right-1 top-[7px] cursor-pointer transition duration-300"
            style={{
              transform: categoriesExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={() => setCategoriesExpanded(!categoriesExpanded)}
          >
            <FIcon icon="chevron" size={14} color="#000721" />
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center space-x-8">
          {showAPIConnection ? (
            <div className="flex items-center justify-center gap-4 mt-[30px]">
              {selectedPlatforms.length > 0 ? (
                <div className="flex items-center">
                  <FCheckbox
                    label="API Connected"
                    id="apiConnected"
                    onChange={(value) =>
                      setAdvancedFilters({
                        ...advancedFilters,
                        api_connected: value || undefined,
                      })
                    }
                    checked={advancedFilters.api_connected}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="flex justify-end items-center mt-4 space-x-8">
          {isInstagram && (
            <FDropdown
              options={[
                { label: "All product types", value: null },
                ...MEDIA_PRODUCT_TYPES.Instagram.map((value) => ({
                  value,
                  label: capitalize(value),
                })),
              ]}
              selectedValue={filters.media_product_type}
              label={"Product Type"}
              onChange={(value) =>
                setFilters({
                  ...filters,
                  media_product_type: value,
                })
              }
            />
          )}
          {(!isEmpty(selectedCategories) ||
            !isEmpty(selectedPlatforms) ||
            !isEmpty(searchTerm) ||
            advancedFilters !== DEFAULT_ADVANCED_FILTERS) && (
            <FDropdown
              label={"Sort by"}
              options={
                resource === "content"
                  ? CONTENT_SORT_OPTIONS.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }))
                  : CREATOR_SORT_OPTIONS.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }))
              }
              selectedValue={
                filters.sort_order === "asc"
                  ? `${filters.sort}_asc`
                  : filters.sort
              }
              onChange={(value) => {
                if (value === null) {
                  setFilters({
                    ...filters,
                    sort: null,
                    sort_order: "desc",
                  });
                } else if (typeof value === "string") {
                  const isAscending = value.endsWith("_asc");
                  setFilters({
                    ...filters,
                    sort: value.replace(/_asc$/, ""),
                    sort_order: isAscending ? "asc" : "desc",
                  });
                }
              }}
            />
          )}
        </div>
      </div>
      {/* results */}
      {loading ? (
        <SearchResultsSkeleton
          type={resource === "content" ? "content" : "creators"}
        />
      ) : (
        <>
          <div>
            {resource === "content" ? (
              !isEmpty(posts) ? (
                <ResultsGrid
                  cardData={posts.map((post, index) => {
                    if (index === 0) {
                      return { ...post, stats: null };
                    }

                    return post;
                  })}
                  cardType="content"
                  numColumns={6}
                  onCardClick={(content) => handleContentModalClick(content)}
                />
              ) : isEmptySearch ? (
                <div className="mt-10 h-full overflow-hidden">
                  <div className="flex gap-2 pb-4">
                    {contentCategoryImages.map((category, index) => (
                      <div
                        key={index}
                        className="cursor-pointer flex-1 min-h-[50vh] relative group overflow-hidden rounded-lg shadow-lg bg-dark_night_sky"
                        onClick={() => {
                          // Track search panel clicked event
                          trackEvent(EVENTS.SEARCH.PANEL_CLICKED, {
                            panel_name: category.label,
                            panel_position: index + 1,
                            search_type: resource,
                            has_advanced_params: !!category.advanced_params,
                          });

                          if (category.advanced_params) {
                            setAdvancedFilters({
                              ...category.advanced_params,
                            });
                          }
                        }}
                      >
                        <img
                          src={category.image}
                          alt="Category"
                          className="w-full h-full object-cover transition-transform duration-700 ease-out group-hover:scale-105 group-hover:opacity-50"
                        />
                        <div className="absolute z-0 inset-x-0 bottom-0 h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0.3)] to-transparent" />
                        <div className="absolute z-10 bottom-0 left-0 right-0 p-4 text-white text-lg font-semibold capitalize group-hover:opacity-0">
                          {category.label}
                        </div>
                        <div className="absolute z-10 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-out">
                          <p className="text-white text-center px-4">
                            {category.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <BlankState
                  title={`No content found`}
                  subtitle="Try updating your search or create one"
                  icon="content"
                />
              )
            ) : !isEmpty(creators) ? (
              <>
                {addToBulletinId ? (
                  <>
                    <div className="mt-4" />
                    <FCheckbox
                      checked={areAllCreatorsSelected}
                      id="select-all-rows"
                      label="Add all creators from this search"
                      onChange={setAreAllCreatorsSelected}
                    />
                  </>
                ) : null}
                <ResultsGrid
                  cardData={creators}
                  cardType="individual"
                  isProfile={false}
                  numColumns={6}
                  selectedItems={
                    areAllCreatorsSelected
                      ? creators.map(({ id }) => id)
                      : selectedCreatorIds
                  }
                  onToggleItemSelection={handleToggleItemSelection}
                />
              </>
            ) : isEmptySearch ? (
              <div className="mt-10 h-full overflow-hidden">
                <div className="flex gap-2 pb-4">
                  {(resource === "content"
                    ? contentCategoryImages
                    : categoryImages
                  ).map((category, index) => (
                    <div
                      key={index}
                      className="cursor-pointer flex-1 min-h-[50vh] relative group overflow-hidden rounded-lg shadow-lg bg-dark_night_sky"
                      onClick={() => {
                        // Track search panel clicked event
                        trackEvent(EVENTS.SEARCH.PANEL_CLICKED, {
                          panel_name: category.label,
                          panel_position: index + 1,
                          search_type: resource,
                          has_advanced_params: !!category.advanced_params,
                        });

                        if (category.advanced_params) {
                          setAdvancedFilters({
                            ...category.advanced_params,
                          });
                        }
                      }}
                    >
                      <img
                        src={category.image}
                        alt="Category"
                        className="w-full h-full object-cover transition-transform duration-700 ease-out group-hover:scale-105 group-hover:opacity-50"
                      />
                      <div className="absolute z-0 inset-x-0 bottom-0 h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0.3)] to-transparent" />
                      <div className="absolute z-10 bottom-0 left-0 right-0 p-4 text-white text-lg font-semibold capitalize group-hover:opacity-0">
                        {category.label}
                      </div>
                      <div className="absolute z-10 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-out">
                        <p className="text-white text-center px-4">
                          {category.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <BlankState
                title="No creators found"
                subtitle="Can't find the creator you're looking for? Add them via their Instagram or TikTok handle."
                icon="user"
                actionLabel="Add Creator"
                onActionClick={() => navigate("/off-platform-creator")}
              />
            )}
          </div>
          {total &&
          total > 0 &&
          total > (resource === "content" ? posts.length : creators.length) &&
          (resource === "content" ? posts.length : creators.length) > 0 ? (
            <div className="flex flex-col items-center mt-[28px]">
              <FPagination
                page={page}
                pageSize={24}
                total={total}
                onPageChange={(page) =>
                  setFilters({
                    ...filters,
                    page,
                  })
                }
              />
              <p>
                {formatNumber(total)} {resource} found
              </p>
            </div>
          ) : null}
          {resource === "creators" ? (
            <div className="flex flex-row items-center gap-3 mt-8 ">
              <button
                className="text-dark_night_sky px-4 pt-[6px] pb-[4px] m-auto border border-red_highlight rounded-full opacity-40 hover:opacity-100 transition-all duration-300 ease-out"
                onClick={() => navigate("/off-platform-creator")}
              >
                Can't find the creator you're looking for? Add them via their
                Instagram or TikTok handle.
              </button>
            </div>
          ) : null}
          {activePost && (
            <GeneralPostModal
              post={activePost}
              isOpen={contentModalOpen}
              onClose={handleContentModalClose}
            />
          )}
          {isAddToGroupModalVisible ? (
            <AddCreatorsToGroupModal
              creatorIds={selectedCreatorIds}
              onClose={() => setAddToGroupModalVisible(false)}
            />
          ) : null}
          {isCreateReportModalVisible ? (
            <CreateReportModal
              filters={{
                searchTerm: debouncedSearchTerm,
                platforms: selectedPlatforms,
                categories: selectedCategories,
                media_product_type,
                p: page,
                ...advancedFilters,
              }}
              isOpen={isCreateReportModalVisible}
              onClose={() => setCreateReportModalVisible(false)}
            />
          ) : null}
        </>
      )}
      {isAdvancedCreatorSearchModalVisible ? (
        <AdvancedCreatorSearchModal
          filters={advancedFilters as unknown as AdvancedSearchParams}
          onClose={() => {
            setAdvancedCreatorSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          onUpdateFilters={(filters) => {
            setAdvancedFilters(filters);
            setAdvancedCreatorSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          selectedPlatforms={selectedPlatforms}
        />
      ) : null}
      {isAdvancedContentSearchModalVisible ? (
        <AdvancedContentSearchModal
          showViralCheckbox={true}
          basicFilters={filters}
          advancedFilters={advancedFilters as unknown as AdvancedSearchParams}
          onClose={() => {
            setAdvancedContentSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          onUpdateFilters={(filters) => {
            setAdvancedFilters(filters);
            setAdvancedContentSearchModalVisible(false);

            searchInputRef.current?.focus();
          }}
          selectedPlatforms={selectedPlatforms}
        />
      ) : null}
    </div>
  );
}
