import { useNavigate } from "react-router-dom";
import FButton from "@components/FButton";
import useAuth from "@hooks/useAuth";

const UpgradeMembership = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-blue-50 p-6">
      <div className="relative bg-white rounded-3xl p-16 max-w-4xl text-center">
  
        <h1 className="text-5xl font-extrabold text-gray-900">
          ✨ Upgrade Your Membership ✨
        </h1>

        <p className="mt-6 text-xl text-gray-700">
          You've made the most of your Freemium perks! Upgrade now to unlock powerful tools, deeper insights, and exclusive features
        </p>

        <div className="mt-10 bg-gradient-to-r from-yellow-200 to-orange-300 rounded-xl p-8 shadow-lg">
          <h2 className="text-3xl font-bold text-gray-800">Why Upgrade?</h2>
          <ul className="mt-5 text-gray-800 text-left space-y-4 text-lg">
            <li>🌟 Advanced Search – Discover exactly what you're looking for with precision.</li>
            <li>🔍 Expanded Profile Access – View and explore without restrictions.</li>
            <li>📊 In-Depth Reports – Gain meaningful data and analytics to fuel your strategy.</li>
            <li>🚀 Unlimited Campaigns – Launch and manage multiple campaigns effortlessly.</li>
            <li>💌 Send Bulletins – Keep your audience engaged with personalized updates.</li>
            <li>💬 Priority Support – Get expert assistance when you need it.</li>
            <li>🎈 Exclusive Features – Unlock premium tools.</li>
          </ul>
        </div>

        {/* Upgrade Button */}
        {/* <div className="mt-10 flex justify-center">
          <FButton
          label="Upgrade Now"
          primary
          onClick={() => navigate(`/insert_stripe_link_here?brand_id=${user.brand_id}`)}
          >
          </FButton>
        </div> */}

        {/* Support Link */}
        <p className="mt-8 text-gray-500 text-lg">
          <a href="mailto:support@fohr.co" className="text-blue-500 underline hover:text-blue-700">Email Us</a>
        </p>

      </div>
    </div>
  );
};

export default UpgradeMembership;
