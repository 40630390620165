import React, { useContext, useEffect, useRef, useState } from "react";
import { FCheckbox, FInput } from "@components/FInputs";
import FButton from "@components/FButton";

import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { create as createUser, getMe, signUp } from "@api/user";
import { Captcha, CaptchaError, CaptchaInstance } from "@components/Captcha";
import { UserContext } from "@contexts/index";
import {
  createBrandAmbassador,
  getBrandBySlugForAmbassadorOnboarding,
  signUpAmbassador,
} from "@api/brands";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

export default function SignUpInfluencers() {
  const { brand: brandName } = useParams<{ brand: string }>();
  const [searchParams] = useSearchParams();

  const { setUser } = useContext(UserContext);
  const { trackEvent } = useContext(MixpanelContext);

  const [brand, setBrand] = useState(null);

  const navigate = useNavigate();

  const [values, setValues] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    const loadBrand = async () => {
      setBrand(await getBrandBySlugForAmbassadorOnboarding(brandName));
    };

    if (brandName) {
      loadBrand();
    }
  }, [brandName]);

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);

  const [isSubmitted, setSubmitted] = useState(false);

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  const ref = searchParams.get("ref");
  const token = searchParams.get("token");

  const captchaRef = useRef<CaptchaInstance>();

  const handleSubmit = async () => {
    setSubmitted(true);

    if (!hasAcceptedTerms) {
      return;
    }

    setWaiting(true);
    setError("");

    try {
      if (token) {
        if (values.password !== values.password_confirmation) {
          setError("Passwords must match");

          trackEvent(EVENTS.ERROR.AUTH_ERROR, {
            error_type: "password_mismatch",
            signup_type: brandName ? "ambassador" : "influencer",
            signup_stage: "final",
          });
          return;
        }

        const { email, password, password_confirmation } = values;

        const params = {
          email,
          password,
          password_confirmation,
          token,
          ref,
        };

        if (brand) {
          await createBrandAmbassador(brand.id, params);
        } else {
          await createUser(params);
        }

        // Fetch the user data after creation
        const userData = await getMe();

        // Track successful account creation
        trackEvent(EVENTS.AUTH.INFLUENCER_CREATED, {
          signup_stage: "completed",
          signup_type: brandName ? "ambassador" : "influencer",
          referral_code: ref || null,
        });

        // Set the user with proper User type
        setUser(userData);

        navigate(
          brandName
            ? `/${brandName}/ambassador-onboarding/add-personal-info`
            : `/signup/influencer/connect-socials`
        );
      } else {
        const captchaToken = captchaRef.current?.getToken();

        if (brandName) {
          await signUpAmbassador(brand.id, values.email, captchaToken);

          // Track ambassador signup
          trackEvent(EVENTS.AUTH.INFLUENCER_CREATED, {
            signup_stage: "initial",
            signup_type: "ambassador",
            brand_id: brand?.id,
            brand_name: brand?.name,
            email_domain: values.email.split("@")[1],
          });
        } else {
          await signUp(values.email, captchaToken);

          // Track regular influencer signup
          trackEvent(EVENTS.AUTH.INFLUENCER_CREATED, {
            signup_stage: "initial",
            signup_type: "influencer",
            email_domain: values.email.split("@")[1],
          });
        }

        setSuccess(true);
      }
    } catch (e) {
      if (e instanceof CaptchaError) {
        setError(e.message);
        trackEvent(EVENTS.ERROR.AUTH_ERROR, {
          error_type: "captcha",
          error_message: e.message,
          signup_type: brandName ? "ambassador" : "influencer",
          signup_stage: token ? "final" : "initial",
        });
      } else {
        setError(e.response?.data?.message);
        captchaRef.current?.reset();
        trackEvent(EVENTS.ERROR.AUTH_ERROR, {
          error_type: "signup",
          error_message: e.response?.data?.message || "Unknown signup error",
          status_code: e.response?.status,
          signup_type: brandName ? "ambassador" : "influencer",
          signup_stage: token ? "final" : "initial",
        });
      }
    } finally {
      setWaiting(false);
    }
  };

  return (
    <div className="flex flex-col h-full justify-center">
      <p className="text-3xl leading-[30px] mb-4 text-center">
        {token
          ? "Create Password"
          : !brandName
            ? "Sign Up as an Influencer"
            : ""}
      </p>
      {!token && !brandName ? (
        <>
          <p className="mb-12 text-center">
            Join the world's OG influencer community
          </p>
        </>
      ) : null}
      {token ? (
        <>
          <FInput
            className="mb-8"
            value={values.password}
            onChange={(value) => handleUpdate({ password: value })}
            type="password"
            label="Password"
            required
            width="100%"
          />
          <FInput
            className="mb-8"
            value={values.password_confirmation}
            onChange={(value) => handleUpdate({ password_confirmation: value })}
            type="password"
            label="Confirm Password"
            required
            width="100%"
          />
        </>
      ) : (
        <>
          <FInput
            className="mb-8"
            value={values.email}
            onChange={(value) => handleUpdate({ email: value })}
            type="text"
            required
            validate={isSubmitted}
            label="Email"
            width="100%"
          />
          <Captcha
            ref={captchaRef}
            className="mb-8"
            action="sign-up-influencer"
          />
        </>
      )}
      <div className="mt-8">
        <FButton
          primary
          height="48px"
          label={token ? "Confirm" : "Sign Up"}
          onClick={handleSubmit}
          loading={waiting}
          width="100%"
          type="submit"
        />
        {success && (
          <p className={`mt-8 text-green text-center`}>
            Confirmation email sent to {values.email}
          </p>
        )}
      </div>
      {error ? <p className={"text-8 mt-8 text-red"}>{error}</p> : null}
      {!token ? (
        <p className="mb-8 mt-4 text-center">
          Already have a Fohr account?{" "}
          <Link
            className="underline"
            to={`/login?${brandName ? `returnTo=${window.location.pathname.replace(/\/$/, "")}/add-personal-info` : ""}`}
          >
            Login
          </Link>
        </p>
      ) : null}
      {!token ? (
        <div className="flex gap-2 mt-8">
          <FCheckbox
            id="terms"
            checked={hasAcceptedTerms}
            onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
          />
          <div className="flex flex-wrap items-center gap-2">
            I accept the{" "}
            <a
              href="https://www.fohr.co/terms"
              target="_blank"
              className="underline w-max"
            >
              Terms & Conditions
            </a>{" "}
            and the{" "}
            <a
              href="https://www.fohr.co/privacy"
              target="_blank"
              className=" underline"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
}
