const IconWarning = ({ size = 10 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2025_1292)">
      <path
        d="M5 9.64265C7.56422 9.64265 9.64286 7.56401 9.64286 4.99979C9.64286 2.43561 7.56422 0.356934 5 0.356934C2.43583 0.356934 0.357147 2.43561 0.357147 4.99979C0.357147 7.56401 2.43583 9.64265 5 9.64265Z"
        stroke="#B04739"
        strokeWidth="0.714286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.17857 2.85693V5.17836"
        stroke="#B04739"
        strokeWidth="0.714286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.17857 7.14279C5.07995 7.14279 5 7.06284 5 6.96422C5 6.86559 5.07995 6.78564 5.17857 6.78564"
        stroke="#B04739"
        strokeWidth="0.714286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.17857 7.14279C5.2772 7.14279 5.35715 7.06284 5.35715 6.96422C5.35715 6.86559 5.2772 6.78564 5.17857 6.78564"
        stroke="#B04739"
        strokeWidth="0.714286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2025_1292">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconWarning;
