import React, { useContext, useEffect, useState } from "react";

import BlankState from "@components/BlankState";
import Container from "@components/Container";

import { ProfileContext } from "@contexts/index";
import apiClient from "@apiClient";

import LoadingSpinner from "@components/LoadingSpinner";
import { isEmpty } from "lodash";

const { ambassador_responses } = apiClient;

const ProfileResponses: React.FC = () => {
  const { responses, setResponses } = useContext(ProfileContext);

  const { profile } = useContext(ProfileContext);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const ambassadorResponsesResponse = await ambassador_responses.index({
          creator_id_eq: profile.id,
        });

        setResponses(ambassadorResponsesResponse.data.ambassador_responses);
      } catch (e) {
        setResponses([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [profile.id]);

  return (
    <Container title="Ambassador Responses" className="mt-[52px] pr-[46px]">
      {isLoading && isEmpty(responses) ? (
        <LoadingSpinner className="h-[400px]" />
      ) : !isEmpty(responses) ? (
        <div className="space-y-4 mt-8">
          {responses.map((response) => (
            <div
              key={response.id}
              className="flex items-center border border-light_border justify-between bg-white rounded-lg p-4 "
            >
              <div className="flex flex-col items-start w-full">
                <div className="flex flex-row justify-between w-full">
                  <div className="text-gray-600 text-sm mb-1">
                    {response.question}
                  </div>
                  <div className="text-gray-600 text-xs text-right border border-light_border rounded-full px-3 py-1">
                    {response.updated_at}
                  </div>
                </div>
                <div className="text-dark_night_sky font-semibold">
                  {response.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <BlankState
          title={"No responses here"}
          subtitle={"This creator didn't submit any responses yet"}
          icon={""}
          onActionClick={function (): void {
            throw new Error("Function not implemented.");
          }}
          actionLabel={""}
        />
      )}
    </Container>
  );
};

export default ProfileResponses;
