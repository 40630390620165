import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCurrentSubscription,
  getInvoices,
  createCheckoutSession,
  createPortalSession,
  cancelSubscription,
  reactivateSubscription,
  StripeSubscription,
  StripeInvoice,
} from "@api/Stripe/Stripe";
import LoadingSpinner from "@components/LoadingSpinner";
import { UserContext } from "@contexts/index";
import SubscriptionPlans from "@components/subscription/SubscriptionPlans";
import SubscriptionStatus from "@components/subscription/SubscriptionStatus";
import SubscriptionInvoices from "@components/subscription/SubscriptionInvoices";
import FButton from "@components/FButton";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const SubscriptionSettings: React.FC = () => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState<StripeSubscription | null>(
    null
  );
  const [invoices, setInvoices] = useState<{ data: StripeInvoice[] }>({
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  // When we want to implement the subscription page
  // just disable this and remove the salesPage render

  const salesPage = true;

  const { user } = useContext(UserContext);
  const { trackEvent } = useContext(MixpanelContext);

  console.log(user, "user");

  useEffect(() => {
    // Track subscription page view
    trackEvent(EVENTS.SETTINGS.SUBSCRIPTION_PAGE_VIEWED, {
      user_id: user?.id,
      brand_id: user?.brand_id,
      has_stripe_customer_id: !!user?.stripe_customer_id,
    });

    if (user) {
      if (!user.stripe_customer_id) {
        setError("No Stripe customer ID found. Please contact support.");
        setLoading(false);
        return;
      }
      loadSubscriptionData();
    }
  }, [user, trackEvent]);

  const loadSubscriptionData = async () => {
    if (!user.stripe_customer_id) {
      setError("No Stripe customer ID found. Please contact support.");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const [subResponse, invoicesResponse] = await Promise.all([
        getCurrentSubscription(user.stripe_customer_id),
        getInvoices(user.stripe_customer_id, {
          limit: 10,
          status: "paid",
        }),
      ]);

      const activeSubscription = subResponse.data.data[0];
      setSubscription(activeSubscription || null);
      setInvoices(invoicesResponse.data);
    } catch (err) {
      setError("Failed to load subscription data");
      console.error("Error loading subscription data:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleNewSubscription = async (priceId: string) => {
    if (!user.stripe_customer_id) {
      setError("No Stripe customer ID found. Please contact support.");
      return;
    }

    try {
      setLoading(true);
      const response = await createCheckoutSession(
        user.stripe_customer_id,
        priceId
      );
      window.location.href = response.data.url;
    } catch (err) {
      setError("Failed to create checkout session");
    } finally {
      setLoading(false);
    }
  };

  const handlePortalAccess = async () => {
    if (!user.stripe_customer_id) {
      setError("No Stripe customer ID found. Please contact support.");
      return;
    }

    try {
      setLoading(true);
      const response = await createPortalSession(user.stripe_customer_id);
      window.location.href = response.data.url;
    } catch (err) {
      setError("Failed to access customer portal");
    } finally {
      setLoading(false);
    }
  };

  const handleSubscriptionAction = async () => {
    if (!subscription) return;
    setIsModalOpen(true);
  };

  const handleConfirmedAction = async () => {
    if (!subscription) return;
    if (!user.stripe_customer_id) {
      setError("No Stripe customer ID found. Please contact support.");
      return;
    }

    try {
      setIsActionLoading(true);
      if (
        subscription.status === "active" &&
        !subscription.cancel_at_period_end
      ) {
        await cancelSubscription(user.stripe_customer_id, subscription.id);
      } else {
        await reactivateSubscription(user.stripe_customer_id, subscription.id);
      }
      await loadSubscriptionData();
    } catch (err) {
      setError("Failed to update subscription");
    } finally {
      setIsActionLoading(false);
      setIsModalOpen(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full">
        <div className="py-[40px]">
          <h1 className="text-[24px] mb-[24px]">Subscription</h1>
          <div className="flex items-center justify-center h-[200px]">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (salesPage) {
    return (
      <div className="max-w-[800px]">
        <div className="py-[40px]">
          <h1 className="text-[24px] mb-[24px]">Subscription</h1>
          <div className="flex flex-col gap-[24px] p-[24px] bg-white rounded-lg border border-highlight_red shadow-sm">
            <div className="flex flex-col gap-[12px]">
              <h2 className="text-[18px] font-semibold">Subscribe to Fohr</h2>
              <p className="text-[14px] text-neutral_600 mb-[24px]">
                Subscribe to Fohr to get access to all of our features
              </p>
              <FButton
                primary
                label="Contact Sales"
                onClick={() => {
                  // Track contact sales click
                  trackEvent(EVENTS.SETTINGS.CONTACT_SALES_CLICKED, {
                    user_id: user?.id,
                    brand_id: user?.brand_id,
                  });
                  window.location.href = `mailto:sales@fohr.co?subject=${encodeURIComponent("Sign up for Fohr Platform")}`;
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-[800px]">
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Subscription</h1>

        {/* {error && (
          <div className="border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )} */}

        <div className="bg-white rounded-lg mb-6 border border-highlight_red shadow-sm max-w-[800px]">
          <div className="flex items-center justify-between mb-6 p-6 border-b border-light_border">
            <h2 className="text-xl font-semibold">
              {subscription ? "Current Plan" : "You're on the free plan"}
            </h2>
            {subscription && (
              <span
                className={`px-3 pt-1 pb-[3px] text-sm rounded-full text-xs font-bold uppercase ${
                  subscription.status === "active" &&
                  !subscription.cancel_at_period_end
                    ? "bg-light_green border border-green text-green"
                    : subscription.cancel_at_period_end
                      ? "bg-light_yellow border border-yellow text-black"
                      : "bg-neutral_200 border border-default_border text-black"
                }`}
              >
                {subscription.status === "active" &&
                subscription.cancel_at_period_end
                  ? "Canceled (Active until period end)"
                  : subscription.status.charAt(0).toUpperCase() +
                    subscription.status.slice(1)}
              </span>
            )}
          </div>
          {subscription ? (
            <SubscriptionStatus
              subscription={subscription}
              onPortalAccess={handlePortalAccess}
              onSubscriptionAction={handleSubscriptionAction}
              isModalOpen={isModalOpen}
              onModalClose={() => setIsModalOpen(false)}
              onConfirmedAction={handleConfirmedAction}
              isActionLoading={isActionLoading}
            />
          ) : (
            <SubscriptionPlans onSelectPlan={handleNewSubscription} />
          )}
        </div>

        <SubscriptionInvoices invoices={invoices.data} />
      </div>
    </div>
  );
};

export default SubscriptionSettings;
