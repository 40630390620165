import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { FCheckbox, FInput } from "@components/FInputs";

import FDropdown from "@components/FDropdown";
import { AdminBrand } from "@types";
import { createAdminBrand, updateAdminBrand } from "@api/Admin/AdminBrands";
import useAuth from "@hooks/useAuth";

import Modal from "@components/Modals/Modal";

import moment from "moment";
import FDatePicker from "@components/FDatePicker/FDatePicker";

const KINDS = ["paid", "gifting"];

interface Props {
  entity?: AdminBrand;
  onClose: () => void;
  onUpdated?: (brand: AdminBrand) => void;
  onCreated?: (brand: AdminBrand) => void;
}

export default function CreateOrUpdateBrandModal({
  onClose,
  onUpdated,
  onCreated,
  entity,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<Partial<AdminBrand>>({
    name: "",
    slug: "",
    parent_brand_id: null,
    instagram_handle: "",
    competitor_instagram_handles: "",
    tiktok_handle: "",
    youtube_handle: "",
    facebook_handle: "",
    contract_start: moment().format(),
    contract_expiration: moment().format(),
    created_at: moment().format(),
    expires_at: moment().format(),
    sales_contact: "",
    email_contact: "",
    trial: false,
    lite: false,
    has_reports: false,
    has_explore: false,
    has_opportunities: false,
    amp: false,
    accepting_applications: false,
    pixel: false,
  });

  const { user } = useAuth();

  useEffect(() => {
    if (entity) {
      setValues((values) => ({ ...values, ...entity }));
    }
  }, [entity]);

  const handleUpdate = (updates: Partial<AdminBrand>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const { name, slug } = values;

    if (!name || !slug) {
      toast.error("Brand name and slug cannot be blank");
      return;
    }

    setLoading(true);

    try {
      if (entity) {
        const response = await updateAdminBrand(entity.id, values);

        onUpdated(response.data);

        toast.success("Brand Updated");
      } else {
        const response = await createAdminBrand(values);

        onCreated(response.data);
        toast.success("Brand Created");
      }
    } catch (e) {
      toast.error(`Error ${entity ? "updating" : "creating"} brand`);
    } finally {
      setLoading(false);
    }
  };

  const { brand_name } = user;

  return (
    <Modal
      actionLabel={entity ? "Update Brand" : "Create Brand"}
      className="w-[700px] h-[700px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title={entity ? "Update Brand" : "Create Brand"}
      waiting={loading}
    >
      <div className="flex flex-col gap-6 m-auto w-full">
        {/* TODO: Add parent brand values constant */}
        <FDropdown
          hasNoDefault
          label="Parent Brand"
          options={[]}
          selectedValue={values.parent_brand_id}
          onChange={(value) =>
            handleUpdate({ parent_brand_id: value as number })
          }
          validate={isSubmitted}
          height="40"
          width="100%"
        />
        <FInput
          name="name"
          onChange={(value) => handleUpdate({ name: value })}
          label="Brand Name"
          value={values.name}
          required
          validate={isSubmitted}
          width="100%"
        />
        <FInput
          name="slug"
          onChange={(value) => handleUpdate({ slug: value })}
          label="Brand Slug"
          value={values.slug}
          required
          validate={isSubmitted}
          width="100%"
        />
        <FInput
          name="instagram_handle"
          onChange={(value) => handleUpdate({ instagram_handle: value })}
          label="Instagram Handle"
          value={values.instagram_handle}
          validate={isSubmitted}
          width="100%"
        />
        <FInput
          name="tiktok_handle"
          onChange={(value) => handleUpdate({ tiktok_handle: value })}
          label="TikTok Handle"
          value={values.tiktok_handle}
          validate={isSubmitted}
          width="100%"
        />
        <FInput
          name="youtube_handle"
          onChange={(value) => handleUpdate({ youtube_handle: value })}
          label="Youtube Handle"
          value={values.youtube_handle}
          validate={isSubmitted}
          width="100%"
        />
        <FInput
          name="email_contact"
          onChange={(value) => handleUpdate({ email_contact: value })}
          label="Email Contact"
          value={values.email_contact}
          validate={isSubmitted}
          width="100%"
        />
        <FDatePicker
          label="Expires At"
          value={values.expires_at}
          onSelectedValue={(value) => handleUpdate({ expires_at: value })}
        />
        <div className="flex gap-4">
          <div className="flex flex-col gap-6 m-auto w-full">
            <FCheckbox
              className="w-full"
              label="Pixel"
              id="pixel"
              onChange={(value) =>
                handleUpdate({
                  pixel: value,
                })
              }
              checked={values.pixel}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
