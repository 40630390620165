import React, { useContext } from "react";
import { Bulletin, UploadedFile } from "@types";
import { useParams } from "react-router-dom";
import FIcon from "@components/FIcon";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

interface BulletinPreviewProps {
  values: Partial<Bulletin>;
  uploadedImage: UploadedFile;
}

export default function BulletinPreview({
  values,
  uploadedImage,
}: BulletinPreviewProps) {
  const { bulletin_id } = useParams();
  const { trackEvent } = useContext(MixpanelContext);

  const handlePreviewClick = () => {
    // Track when the preview is clicked
    trackEvent(EVENTS.BULLETIN.PREVIEW_CLICKED, {
      bulletin_id: bulletin_id,
      bulletin_name: values.name,
      bulletin_category: values.category,
    });
  };

  return (
    <div className="h-fit sticky m-auto top-[100px] max-w-[400px] min-w-[400px] relative">
      <p className="text-[15px] mb-4">Preview</p>
      <div className="pb-[20px] relative bg-light_red rounded-xl shadow-md group">
        <div
          style={{
            backgroundImage: `url(${uploadedImage?.url})`,
          }}
          className="w-full h-[200px] bg-cover bg-center z-0 inset-0 relative rounded-t-xl"
        >
          <div className="absolute inset-0 bg-gradient-to-t from-[rgba(255,250,249,1)] via-[rgba(255,250,249,0.8)] to-[rgba(255,250,249,0.1)]" />
        </div>
        <div className="max-w-[80%] m-auto mt-[-20%] z-10 relative p-6 bg-white rounded-xl shadow-md">
          <p className="text-[8px] capitalize mb-2 opacity-50">
            {values.category}
          </p>
          <h4 className="text-[16px] font-bold mb-2">{values.name}</h4>
          <p
            className="text-[8px]"
            dangerouslySetInnerHTML={{ __html: values.description }}
          />
          <div className="flex flex-row gap-2 justify-center mt-4">
            <div className="text-[8px] bg-white text-black border border-black px-2 pt-1 pb-0.5 rounded-md">
              Not Interested
            </div>
            <div className="text-[8px] bg-black text-white px-2 pt-1 pb-0.5 rounded-md">
              Continue
            </div>
          </div>
        </div>
        <div className="absolute z-[20] h-full w-full bottom-0 left-0 right-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded-xl">
          <a
            href={`/bulletins/${bulletin_id}/preview`}
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 pt-1 pb-0.5 flex items-center gap-2 bg-black text-white rounded-md hover:bg-gray-100 transition-colors"
            onClick={handlePreviewClick}
          >
            <FIcon icon="eye" size={16} color="#fff" className="-mt-1" />
            View Preview
          </a>
        </div>
      </div>
    </div>
  );
}
