import React, { useState, useContext } from "react";

import { toast } from "react-hot-toast";

import { Bulletin, UploadedFile } from "@types";
import { createBulletin } from "@api/Bulletins/Bulletins";
import useAuth from "@hooks/useAuth";
import Modal from "@components/Modals/Modal";

import BulletinContentForm from "./BulletinContentForm";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const CATEGORIES = ["survey", "opportunity", "gifting", "event"];

interface Props {
  entity?: Bulletin;
  onClose: () => void;
  onUpdated?: (campaign: Bulletin) => void;
  onCreated?: (campaign: Bulletin) => void;
}

export default function CreateOrUpdateBulletinModal({
  onClose,
  onCreated,
  entity,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useContext(MixpanelContext);

  const { image_url } = entity;

  const hasCoverImage = image_url && !image_url.endsWith("missing_default.png");

  const [uploadedImage, setUploadedImage] = useState<UploadedFile>(
    hasCoverImage
      ? {
          file: null,
          url: image_url,
        }
      : null
  );

  const [isSubmitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<Partial<Bulletin>>({
    name: "",
    description: "",
    category: CATEGORIES[0],
    start_date: null,
    end_date: null,
    brand: {
      id: null,
    },
    ...(entity || {}),
  });

  const { user } = useAuth();

  const handleUpdate = (updates: Partial<Bulletin>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const { name } = values;

    if (!name) {
      return;
    }

    setLoading(true);

    try {
      const params = {
        ...values,
        user_id: user.id,
        brand_id: user.brand_id,
        image: uploadedImage?.file || null,
      };

      delete params.brand;

      const response = await createBulletin(params);

      // Track bulletin creation
      trackEvent(EVENTS.BULLETIN.CREATED, {
        bulletin_id: response.data.id,
        bulletin_name: response.data.name,
        bulletin_category: response.data.category,
        has_image: !!uploadedImage?.file,
        has_dates: !!(values.start_date && values.end_date),
      });

      onCreated(response.data);
    } catch (e) {
      toast.error(`Error creating bulletin`);

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "bulletin_creation",
        error_message: e?.response?.data?.message || `Error creating bulletin`,
        bulletin_name: values.name,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="w-[800px] h-[660px] overflow-auto z-[1000]"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title={`${entity?.id ? "Edit" : "Create"} Bulletin`}
      waiting={loading}
    >
      <BulletinContentForm
        values={values}
        isSubmitted={isSubmitted}
        handleUpdate={handleUpdate}
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
      />
    </Modal>
  );
}
