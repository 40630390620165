import React, { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import MultiPageLayout from "@layouts/MultiPageLayout";

import { BrandEditContext, UserContext } from "@contexts/index";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";

const SettingsWrapper = () => {
  const [brandSettings, setBrandSettings] = useState(null);
  const { user } = useContext(UserContext);
  const isNewBrand = new Date(user?.brand_created_at) > new Date("2025-02-22");

  const { hasRole } = useAuth();

  const SUB_NAV_ITEMS = [
    {
      label: "Personal",
      value: "personal",
      link: "personal",
    },
    {
      label: "Brand",
      value: "brand",
      link: "brand",
    },
    hasRole(Role.INFLUENCER) || !isNewBrand
      ? null
      : {
          label: "Subscription",
          value: "subscription",
          link: "subscription",
        },
    hasRole(Role.INFLUENCER)
      ? {
          label: "Social connections",
          value: "social-connections",
          link: "social-connections",
        }
      : null,
    {
      label: "Data Privacy",
      value: "data privacy",
      link: "privacy",
    },
  ].filter(Boolean);

  useEffect(() => {
    document.title = "Settings";
  }, []);

  const subNavItems = SUB_NAV_ITEMS.filter(
    ({ value }) => !hasRole(Role.INFLUENCER) || value !== "brand"
  );

  return (
    <MultiPageLayout pageName="Settings" subNavItems={subNavItems}>
      <BrandEditContext.Provider
        value={{
          brandSettings,
          setBrandSettings,
        }}
      >
        <div className="pt-[40px]">
          <Outlet />
        </div>
      </BrandEditContext.Provider>
    </MultiPageLayout>
  );
};

export default SettingsWrapper;
