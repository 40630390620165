import React, { useCallback } from "react";

import { Column, Sort } from "@types";
import { Creator } from "@apiTypes";
import { getCreatorList } from "@api/Creators/Creators";
import { getOffPlatformCollaboratorsList } from "@api/Collaborators";
import SelectEntitiesTable from "@components/SelectEntities/SelectEntitiesTable";
import FButton from "@components/FButton";

const TABLE_COLUMNS = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isImage: true,
    imageType: "profile" as "asset" | "profile",
    imageKey: "profile_image_url",
    isFirstColumn: true,
    linkTarget: "_blank",
  },
  {
    key: "email",
    label: "Email",
  },
];

interface SelectCreatorsTableProps {
  excludeIds?: number[];
  extraColumns?: Column[];
  onChangeSelectedItems?: (creators: Creator[]) => void;
  selectedItems?: Creator[];
  showJustSelected?: boolean;
  offPlatform?: boolean;
}

export default function SelectCreatorsTable({
  excludeIds,
  extraColumns = [],
  onChangeSelectedItems,
  selectedItems,
  showJustSelected,
  offPlatform,
}: SelectCreatorsTableProps) {
  const tableColumns = [...TABLE_COLUMNS, ...extraColumns];

  const getCreators = useCallback(
    async (page?: number, sort?: Sort, params?: object) => {
      let sortToUse;

      if (!sort) {
        sortToUse = { key: "firstName", direction: "asc" };
      } else {
        sortToUse =
          sort.key === "full_name" ? { ...sort, key: "firstName" } : sort;
      }

      const response = await getCreatorList(false, page, sortToUse, {
        ...params,
        off_platform_eq: "false",
      });

      const { creators: items } = response.data;

      return {
        ...response.data,
        items: items.map((item) => ({
          ...item,
          name: `${item.firstName} ${item.lastName}`,
        })),
      };
    },
    []
  );

  const getOffPlatformCreators = useCallback(
    async (page?: number, sort?: Sort, params?: { search: string }) => {
      let sortToUse;

      if (!sort) {
        sortToUse = { key: "firstName", direction: "asc" };
      } else {
        sortToUse =
          sort.key === "full_name" ? { ...sort, key: "firstName" } : sort;
      }

      const response = await getCreatorList(false, page, sortToUse, {
        ...params,
        off_platform_eq: "true",
      });

      return {
        ...response.data.creators,
        items: response.data.creators.map((item) => ({
          ...item,
          name: `${item.firstName} ${item.lastName}`,
        })),
      };
    },
    []
  );

  return (
    <div className="h-full relative">
      <SelectEntitiesTable
        excludeIds={excludeIds}
        tableColumns={tableColumns}
        onChangeSelectedItems={onChangeSelectedItems}
        selectedItems={selectedItems}
        showJustSelected={showJustSelected}
        getItems={offPlatform ? getOffPlatformCreators : getCreators}
        topRightContent={
          offPlatform ? (
            <FButton
              label="Create Off-Platform"
              width="200px"
              link="/off-platform-creator"
              target="_blank"
            />
          ) : null
        }
      />
    </div>
  );
}
