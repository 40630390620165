import FormField from "@components/Form/FormField";
import LocationFilter from "./LocationFilter";
import GroupFilter from "./GroupFilter";
import ModalSection from "./ModalSection";
import { FCheckbox, FRadioGroup } from "@components/FInputs";
import FMultiSelect from "@components/FMultiSelect";
import { MANAGED_OPTIONS, TARGET_OPPORTUNITIES } from "@pages/search/constants";
import { AdvancedSearchParams } from "@pages/search/types";

interface InfluencerDetailsProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
  hideLocation?: boolean;
}

const InfluencerDetails = ({
  filters,
  onFiltersUpdate,
  hideLocation,
}: InfluencerDetailsProps) => (
  <ModalSection icon="user" title="Influencer Details">
    {!hideLocation ? (
      <LocationFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
    ) : null}

    <div className="flex mt-8 gap-4 w-full">
      <GroupFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
      <GroupFilter
        isExclude
        filters={filters}
        onFiltersUpdate={onFiltersUpdate}
      />
    </div>
    <div className="flex items-start mt-8 gap-8 justify-between w-full">
      <FormField label="Contracted">
        <FCheckbox
          label="Previously Contracted Only"
          id="contractedFor"
          onChange={(value) =>
            onFiltersUpdate({ previously_contracted: value || undefined })
          }
          checked={!!filters.previously_contracted}
        />
      </FormField>
      <FormField label="Managed">
        <FRadioGroup
          direction="column"
          options={MANAGED_OPTIONS}
          name="Managed"
          value={filters.managed}
          onChange={(value) => onFiltersUpdate({ managed: value })}
        />
      </FormField>
      <FMultiSelect
        width="250px"
        label="Available For"
        selected={filters.target_opportunities}
        options={TARGET_OPPORTUNITIES.map(([value, label]) => ({
          value,
          label,
        }))}
        onChange={(value) => onFiltersUpdate({ target_opportunities: value })}
      />
    </div>
  </ModalSection>
);

export default InfluencerDetails;
