import { capitalize, last, sortBy } from "lodash";
import ContentCard from "./ContentCard";
import { ContentReviewContent } from "@types";
import FButton from "@components/FButton";
import { isVideoByExtension } from "@utils/file";

const getPost = (content: ContentReviewContent) => {
  const { campaignParticipant, deliverable, rounds } = content;

  const { assets } = last(rounds);

  const [asset] = sortBy(assets, "position");

  const { filename, file_url, position, transcoded_thumbnail, video_length } =
    asset || {};

  const { media_type, name, platform } = deliverable;

  const isVideo = isVideoByExtension(file_url, filename) || video_length;

  const { creator, full_name } = campaignParticipant || {};

  const { profile_image_url } = creator || {};

  return {
    ...content,
    photo_url: file_url,
    position,
    content_type: media_type,
    full_name: full_name || creator?.full_name,
    platform,
    title: name,
    imageUrl: transcoded_thumbnail || file_url,
    image_content_type: (isVideo && "video/*") || "",
    profile_image: profile_image_url,
    file_url,
    rounds,
  };
};

const PostCard = (content: ContentReviewContent) => {
  const post = getPost(content);

  const { deliverable, rounds, onDelete, ...rest } = post;

  const stage = last(rounds).stage;

  const props = { deliverable, rounds, ...rest };

  return (
    <div className="border border-neutral_400 rounded-xl overflow-hidden relative">
      <ContentCard
        {...props}
        className="border-none rounded-none"
        customStats={
          <>
            <p className="text-xs">{deliverable.name}</p>
            <p className="text-xs text-default_weak">
              {capitalize(deliverable.media_type)}
            </p>
          </>
        }
      />
      {onDelete ? (
        <div className="absolute right-0 top-[0px] z-[100]">
          <FButton
            icon={{ color: "#FFFFFF", name: "delete", size: 20 }}
            iconButton
            onClick={() => onDelete(post.id, last(rounds).id)}
          />
        </div>
      ) : null}
      {stage ? (
        <div className="absolute right-[8px] bottom-[48px] flex items-center justify-center border border-default_weak  h-[24px] w-[24px] rounded-full z-[10]">
          <span className="text-xs">R{stage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default PostCard;
