import React, { useCallback, useContext } from "react";
import SinglePageLayoutWithData from "../../../src/layouts/SinglePageLayoutWithData";
import { getBulletinCollaboratorsList } from "../../api/Collaborators";
import { Sort } from "@types";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import BlankState from "@components/BlankState";
import { useNavigate } from "react-router-dom";

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isFirstColumn: true,
    linkTarget: "_blank",
  },
  {
    key: "bulletin_name",
    label: "Bulletin Title",
    isLink: true,
    linkURL: "/bulletins/{bulletin_id}",
    linkTarget: "_blank",
  },
  {
    key: "status",
    label: "Status",
    isStateBubble: true,
  },
  {
    key: "email_last_sent_at",
    label: "Email sent on",
    isDate: true,
  },
];

const BulletinCollaboratorsList = () => {
  const { trackEvent } = useContext(MixpanelContext);

  const navigate = useNavigate();

  const fetchCollaborators = useCallback(
    async (page?: number, sort?: Sort, params?: { search?: string }) => {
      const { search } = params;
      const response = await getBulletinCollaboratorsList(page, sort, {
        creator_firstName_or_creator_lastName_cont: search,
      });
      const { recipients: items } = response.data;

      trackEvent(EVENTS.PAGE_VIEW.COLLABORATORS, { section: "bulletins" });

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <div>
      <SinglePageLayoutWithData
        entityName="recipients"
        pageName="Bulletin Recipients"
        sortPathName="creator_lastName"
        sortPathDate="email_last_sent_at"
        filterPath="state"
        tableColumns={tableColumns}
        tableOnly
        hideCreateNewButton
        getItems={fetchCollaborators}
        customBlankState={
          <BlankState
            title="No Bulletin Recipients Found"
            subtitle="Cast gifting and paid, events, and survey opportunities, and have creators register their interest today."
            icon="user"
            actionLabel="Create New"
            onActionClick={() => navigate("/bulletins")}
          />
        }
      />
    </div>
  );
};

export default BulletinCollaboratorsList;
