import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Role } from "@constants/roles";
import { ProfileContext } from "@contexts/index";
import useAuth from "@hooks/useAuth";
import LoadingSpinner from "@components/LoadingSpinner";
import PROFILE_ROUTES from "./routes";
import ProfileLayout from "@layouts/profile-layout";
import { getStatsWithPerformanceTiers, getProfileData } from "@api/Profile";
import { ViewsData, ProfileStats } from "@types";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";

interface ProfileProps {
  isMe?: boolean;
}

export default function Profile({ isMe }: ProfileProps) {
  const { slug } = useParams<{ slug: string }>();
  const [profile, setProfile] = useState(null);
  const [contentHighlight, setContentHighlight] = useState(null);
  const [content, setContent] = useState({ content: [], total: 0 });
  const [viewsData, setViewsData] = useState<ViewsData>(null);
  const [responses, setResponses] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [stats, setStats] = useState<ProfileStats>(null);
  const [tierPerformance, setTierPerformance] = useState<number>(null);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const { user, hasRole } = useAuth();
  const { trackEvent } = useMixpanelTracking();

  useEffect(() => {
    if (
      hasRole(Role.FREEMIUM) &&
      (user?.actions_performed?.profiles_viewed || 0) >= 20
    ) {
      setTimeout(() => navigate("/upgrade_membership"), 100);
    }
  }, [hasRole, user, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);

      try {
        const { contentHighlight, content, profile, viewsData } =
          await getProfileData(!isMe && slug);

        setViewsData(viewsData);
        setContentHighlight(contentHighlight);
        setContent(content);
        setProfile(profile);

        // Track dashboard/profile view
        if (isMe) {
          // This is the influencer dashboard view
          trackEvent(EVENTS.PAGE_VIEW.INFLUENCER_DASHBOARD, {
            content_count: content?.total || 0,
            has_content_highlight: !!contentHighlight,
            follower_count: profile?.overall_followers || 0,
            engagement_rate: profile?.overall_engagement_rate || 0,
          });

          // Set document title
          document.title = "My Dashboard";
        } else {
          // This is viewing someone else's profile
          trackEvent(EVENTS.PAGE_VIEW.PROFILE, {
            profile_id: profile?.id,
            profile_slug: profile?.slug,
            content_count: content?.total || 0,
            follower_count: profile?.overall_followers || 0,
          });

          // Set document title
          document.title = profile?.full_name
            ? `${profile.full_name} | Profile`
            : "Profile";
        }

        setLoaded(true);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        trackEvent("Error", {
          error_type: "Profile Data Load Error",
          is_my_profile: isMe,
          profile_slug: slug,
          error_message: error?.message || "Unknown error",
        });
        setLoaded(true);
      }
    };

    fetchData();
  }, [slug, isMe, trackEvent]);

  useEffect(() => {
    const fetchStats = async () => {
      if (!user || !profile) {
        return;
      }

      try {
        const result = await getStatsWithPerformanceTiers(
          !isMe && profile.slug
        );

        if (result) {
          const { statsData, tierPerformance } = result;

          setStats(statsData);
          setTierPerformance(tierPerformance);

          // Track performance stats loaded (for influencer dashboard)
          if (isMe) {
            trackEvent("Performance Stats Loaded", {
              tier_performance: tierPerformance,
              has_stats: !!statsData,
              stats_summary: statsData
                ? Object.keys(statsData).join(",")
                : "none",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
        trackEvent("Error", {
          error_type: "Stats Load Error",
          is_my_profile: isMe,
          profile_id: profile?.id,
          error_message: error?.message || "Unknown error",
        });
      }
    };

    fetchStats();
  }, [profile, user, isMe, trackEvent]);

  return loaded ? (
    <ProfileContext.Provider
      value={{
        profile,
        responses,
        contentHighlight,
        content,
        viewsData,
        stats,
        tierPerformance,
        setTierPerformance,
        setStats,
        setProfile,
        setContent,
        setResponses,
        selectedCategoryId,
        setSelectedCategoryId,
        setViewsData,
      }}
    >
      <ProfileLayout
        subNavItems={
          user
            ? PROFILE_ROUTES
            : PROFILE_ROUTES.filter(({ label }) => label !== "Responses")
        }
        user={user}
      ></ProfileLayout>
    </ProfileContext.Provider>
  ) : (
    <LoadingSpinner className="h-screen w-full" />
  );
}
