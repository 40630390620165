import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import WorldTopoData from "world-atlas/countries-110m.json";
import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";
import getFillColor from "./map-utils";
import CountriesTable from "@components/Tables/CountriesTable";
import WidgetContainer from "./WidgetContainer";

interface CountriesBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function CountriesBreakdown({ items }: CountriesBreakdownProps) {
  const maxValueItem = maxBy(items, "percentage");

  return (
    <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm">
      <div className="p-4 flex justify-between items-center border-b border-highlight_red_opacity">
        <h3 className="text-lg font-medium">Top Countries</h3>
        <p className="text-lg opacity-50">{maxValueItem?.name}</p>
      </div>
      <div className="p-4">
        <ComposableMap projection="geoMercator">
          <Geographies geography={WorldTopoData}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const value = items?.find((c) =>
                  c.name
                    .toLowerCase()
                    .includes(geo.properties.name.toLowerCase())
                )?.percentage;

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={getFillColor(value)}
                    stroke="#fff"
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        <div className="mt-4 border-t border-light_border p-4 pb-0">
          <CountriesTable countries={items} />
        </div>
      </div>
    </div>
  );
}
