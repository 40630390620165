import React, { useEffect, useState, useContext } from "react";
import { getReportInfluencerBreakdown } from "@api/Reports";
import { formatNumber } from "@utils/number_utilities";
import { NewReportContext } from "@contexts/index";
import { NewReportContextType } from "@contexts/types";

interface TierData {
  tier: string;
  followers: string;
  impressions: number;
  engagements: number;
  engagement_rate: number;
  engagement_average: number;
  viewership_rate: number;
  viewership_average: number;
}

const formatFollowerRange = (rangeString: string) => {
  // Split the range by '..' and convert to numbers
  const [start, end] = rangeString.split("..").map(Number);

  // Function to format a number to K or M
  const formatNumber = (num: number) => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(0)}K`;
    }
    return num.toString();
  };

  return `${formatNumber(start)}-${formatNumber(end)}`;
};

const SuperlativePill = ({ text }: { text: string }) => (
  <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
    <div className="px-3 pt-[5px] pb-[3px] bg-red text-white text-xs font-medium rounded-full whitespace-nowrap">
      {text}
    </div>
  </div>
);

const InfluencerTiers = ({
  report_id: propReportId,
}: {
  report_id?: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<TierData[]>([]);
  const context = useContext(NewReportContext);
  const report = context?.report;

  // Use prop report_id if provided, otherwise check URL and use appropriate ID
  const report_id = window.location.pathname.includes("campaigns")
    ? report?.reports?.[0]?.id?.toString()
    : propReportId || report?.id?.toString();
    const bonus = location.href.includes('bonus')

  useEffect(() => {
    const fetchOverview = async () => {
      if (!report_id) return;
      setLoading(true);
      try {
        const response = await getReportInfluencerBreakdown(report_id, bonus);
        setStats(response.data.filter((stat) => stat.impressions > 0));
      } catch (error) {
        console.error("Error fetching influencer breakdown:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOverview();
  }, [report_id]);

  if (loading || !stats.length) return null;

  // Calculate performance metrics
  const getBestPerformer = () => {
    return stats.reduce((best, current) => {
      const bestScore = best.engagement_rate / best.engagement_average;
      const currentScore = current.engagement_rate / current.engagement_average;
      return currentScore > bestScore ? current : best;
    });
  };

  const getMostEfficient = () => {
    return stats.reduce((best, current) => {
      const bestScore = best.viewership_rate / best.viewership_average;
      const currentScore = current.viewership_rate / current.viewership_average;
      return currentScore > bestScore ? current : best;
    });
  };

  const bestPerformer = getBestPerformer();
  const mostEfficient = getMostEfficient();

  const getPerformanceIndicator = (value: number, average: number) => {
    const ratio = value / average;

    // Calculate percentage relative to average (capped at 10x for visual purposes)
    const maxRatio = 10; // Cap at 10x average
    const normalizedRatio = Math.min(Math.max(ratio, 0), maxRatio);
    const percentage = (normalizedRatio / maxRatio) * 100;

    // For values above average, extend right from center
    // For values below average, extend left from center
    const isAboveAverage = ratio >= 1;
    const barWidth = `${Math.abs(percentage / 2)}%`;

    return {
      ratio: ratio.toFixed(1),
      width: barWidth,
      left: isAboveAverage ? "50%" : `calc(50% - ${barWidth})`,
      color: isAboveAverage ? "#47B37F" : "#E47667",
      textColor: isAboveAverage ? "text-green" : "text-red",
      borderRadius: isAboveAverage ? "0 9999px 9999px 0" : "9999px 0 0 9999px",
      transform: isAboveAverage ? "none" : "none",
    };
  };

  const MetricBar = ({
    value,
    average,
    label,
  }: {
    value: number;
    average: number;
    label: string;
  }) => {
    const { ratio, width, left, color, textColor, borderRadius, transform } =
      getPerformanceIndicator(value, average);

    return (
      <div>
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-neutral_600">{label}</span>
          <span className="text-sm font-medium text-neutral_800">{value}%</span>
        </div>
        <div className="relative h-2 bg-highlight_red_opacity rounded-full overflow-hidden">
          {/* Center marker */}
          <div className="absolute top-0 left-1/2 w-px h-full bg-neutral_400 z-10" />

          {/* Performance bar */}
          <div
            className="absolute top-0 h-full transition-all duration-500"
            style={{
              width,
              left,
              backgroundColor: color,
              borderRadius,
              transform,
            }}
          />
        </div>
        <div className="flex justify-between text-sm mt-1">
          <span className="text-neutral_600">(-)</span>
          <span className={`font-medium ${textColor}`}>{ratio}x Avg.</span>
          <span className="text-neutral_600">(+)</span>
        </div>
      </div>
    );
  };

  return (
    <div className="border border-light_border rounded-lg bg-white p-6 pt-10 shadow-md">
      <div
        className={`grid gap-6 ${
          stats.length === 1
            ? "grid-cols-1"
            : stats.length === 2
              ? "grid-cols-2"
              : stats.length === 3
                ? "grid-cols-3"
                : stats.length === 4
                  ? "grid-cols-4"
                  : "grid-cols-5"
        }`}
      >
        {stats.map((stat) => (
          <div
            key={stat.tier}
            className={`relative p-6 rounded-lg border shadow-md border-light_border ${
              stat === bestPerformer || stat === mostEfficient
                ? "bg-white"
                : "bg-light_red"
            }`}
          >
            {stat === bestPerformer && (
              <SuperlativePill text="Best Engagement" />
            )}
            {stat === mostEfficient && (
              <SuperlativePill text="Most Efficient" />
            )}

            {/* Tier Header */}
            <div className="text-center mb-6">
              <h3 className="text-lg font-medium capitalize mb-1">
                {stat.tier}
              </h3>
              <p className="text-sm text-neutral_600">
                {formatFollowerRange(stat.followers)} followers
              </p>
            </div>

            {/* Views Count */}
            <div className="text-center mb-6">
              <p className="text-2xl font-semibold text-neutral_800">
                {formatNumber(stat.impressions)}
              </p>
              <p className="text-sm text-neutral_600">Views</p>
            </div>

            {/* Metrics */}
            <div className="space-y-6">
              <MetricBar
                value={stat.engagement_rate}
                average={stat.engagement_average}
                label="Engagement"
              />
              <MetricBar
                value={stat.viewership_rate}
                average={stat.viewership_average}
                label="Viewership"
              />
            </div>
          </div>
        ))}
      </div>

      {/* Key Insights */}
      <div className="pt-8 space-y-4 border-t border-light_border mt-8">
        <div className="flex items-start space-x-6 p-4">
          <div className="flex-1 pr-2">
            <h4 className="text-lg font-medium mb-1">Best Engagement</h4>
            <p className="text-neutral_700">
              <span className="capitalize">{bestPerformer.tier}</span> tier
              shows{" "}
              {(
                bestPerformer.engagement_rate / bestPerformer.engagement_average
              ).toFixed(1)}
              x higher engagement than average, achieving{" "}
              {bestPerformer.engagement_rate}% engagement rate
            </p>
          </div>

          <div className="flex-1 pr-2">
            <h4 className="text-lg font-medium mb-1">Most Efficient Reach</h4>
            <p className="text-neutral_700">
              <span className="capitalize">{mostEfficient.tier}</span> tier
              reaches {formatNumber(mostEfficient.impressions)} viewers with{" "}
              {(
                mostEfficient.viewership_rate / mostEfficient.viewership_average
              ).toFixed(1)}
              x higher viewership rate
            </p>
          </div>

          <div className="flex-1 pr-2">
            <h4 className="text-lg font-medium mb-1">Overall Impact</h4>
            <p className="text-neutral_700">
              Total reach across all tiers:{" "}
              {formatNumber(
                stats.reduce((sum, stat) => sum + stat.impressions, 0)
              )}{" "}
              views
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerTiers;
