import axios from "axios";
import { ApiResponse, UserData } from "../types/apiTypes";
import {
  LOCAL_API_ORIGIN,
  getQueryParamsForSearchRequest,
} from "../constants/api";
import { Membership, Sort, User } from "@types";
import apiWrapper from "@apiWrapper";

export const getMe = () =>
  axios
    .get<User>(`${LOCAL_API_ORIGIN}/api/v1/memberships/me`)
    .then(({ data }) => ({
      ...data,
      ...data.user,
    }));

export const getAccountList = (
  page: number,
  sort: Sort,
  params: {
    email_address_cont: string;
  }
) => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(`/accounts?${queryParams.toString()}`, "GET").then(
    ({ data }) => data
  );
};

export const getAccount = (id: number): Promise<User> =>
  apiWrapper(`/accounts/${id}`, "GET").then(({ data }) => data);

export const toggleMembershipPermission = (
  membershipId: number,
  permission: string
): Promise<void> =>
  apiWrapper(`/memberships/${membershipId}/permissions/toggle`, "PUT", {
    permissions: [permission],
  }).then(({ data }) => data);

export const getMemberships = (params: {
  brand_id: number;
  permissions?: string;
}): Promise<
  ApiResponse<{ memberships: Membership[]; total_items: number }>
> => {
  const queryParams = getQueryParamsForSearchRequest(null, null, params);

  return axios.get(
    `${LOCAL_API_ORIGIN}/api/v1/memberships?${queryParams.toString()}`
  );
};

export const changeMembership = (params: {
  id: number;
}): Promise<ApiResponse<any>> => {
  return axios.get(
    `${LOCAL_API_ORIGIN}/api/v1/memberships/change_me/${params.id}}`
  );
};

export const getCampaignManagers = (
  brandId: number
): Promise<ApiResponse<{ memberships: Membership[]; total_items: number }>> => {
  const params = {
    brand_id: brandId,
    custom_permissions_eq: 4096,
    per_page: 0,
  };

  return getMemberships(params);
};

export const forgotPassword = (email: string, captchaToken: string) =>
  axios.get(`${LOCAL_API_ORIGIN}/api/v1/reset_passwords/new?email=${email}`, {
    headers: {
      "Fohr-Captcha-Token": captchaToken,
    },
  });

export const resetPassword = (email, password, token, ref) =>
  axios.post(`${LOCAL_API_ORIGIN}/api/v1/reset_passwords`, {
    email,
    password,
    password_confirmation: password,
    token,
    ref,
  });

const create = async (values): Promise<ApiResponse<UserData>> =>
  axios.post(`${LOCAL_API_ORIGIN}/api/v1/sign_ups`, values);

const signUp = async (
  email: string,
  captchaToken: string
): Promise<ApiResponse<string>> =>
  await axios.get(
    `${LOCAL_API_ORIGIN}/api/v1/sign_ups/new?email=${encodeURIComponent(email)}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Fohr-Captcha-Token": captchaToken,
      },
    }
  );

/*   message: `Confirmation email sent to ${email}`, */

const signIn = async (
  email: string,
  password: string,
  captchaToken: string
): Promise<ApiResponse<UserData>> => {
  return axios.post(
    `${LOCAL_API_ORIGIN}/api/v1/sign_in`,
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Fohr-Captcha-Token": captchaToken,
      },
    }
  );
};

export const signOut = () =>
  axios
    .delete(`${LOCAL_API_ORIGIN}/api/v1/sign_out`)
    .then(() => (window.location.href = "/login"));

export const getEmailPreferences = (): Promise<EmailPreferences> =>
  apiWrapper(`/email_preferences`, "GET").then(({ data }) => data);

export interface EmailPreferences {
  auth_error?: boolean;
  brand_mentions?: boolean;
  brand_views?: boolean;
  bulletin?: boolean;
  general?: boolean;
  messages?: boolean;
  new_influencers?: boolean;
  outreach?: boolean;
}

export const saveEmailPreferences = (params: EmailPreferences) =>
  apiWrapper(`/email_preferences`, "PUT", params).then(({ data }) => data);

export { create, signUp, signIn };
