import React from "react";

const StatCardSkeleton = () => (
  <div className="flex flex-col text-left p-4 bg-white shadow-sm border border-light_border rounded-lg animate-pulse">
    <div className="h-4 w-24 bg-neutral_200 rounded mb-2"></div>
    <div className="h-8 w-16 bg-neutral_200 rounded"></div>
  </div>
);

const TopStatsSkeleton = () => (
  <div className="bg-white rounded-lg border border-light_border shadow-sm overflow-hidden animate-pulse">
    <div className="flex items-center justify-between px-8 py-4 pb-3 border-b border-light_border">
      <div className="h-6 w-32 bg-neutral_200 rounded"></div>
    </div>
    <div className="grid grid-cols-2 gap-4 p-6">
      {[1, 2, 3, 4].map((index) => (
        <div
          key={index}
          className="flex flex-col gap-2 p-4 bg-light_red rounded-lg"
        >
          <div className="h-4 w-24 bg-neutral_200 rounded"></div>
          <div className="h-6 w-16 bg-neutral_200 rounded"></div>
          <div className="h-4 w-32 bg-neutral_200 rounded mt-2"></div>
        </div>
      ))}
    </div>
  </div>
);

const CoreMetricsSkeleton = () => (
  <div className="bg-white rounded-lg border border-light_border shadow-sm overflow-hidden animate-pulse">
    <div className="flex items-center justify-between px-8 py-4 pb-3 border-b border-light_border">
      <div className="h-6 w-32 bg-neutral_200 rounded"></div>
    </div>
    <div className="p-6">
      <div className="h-[300px] bg-neutral_200 rounded"></div>
      <div className="grid grid-cols-4 gap-4 mt-6">
        {[1, 2, 3, 4].map((index) => (
          <div key={index} className="h-20 bg-neutral_200 rounded"></div>
        ))}
      </div>
    </div>
  </div>
);

const GrowthChartSkeleton = () => (
  <div className="bg-white rounded-lg border border-light_border shadow-sm overflow-hidden animate-pulse">
    <div className="flex items-center justify-between px-8 py-4 pb-3 border-b border-light_border">
      <div className="h-6 w-32 bg-neutral_200 rounded"></div>
    </div>
    <div className="grid grid-cols-4 gap-4 px-4 bg-light_red">
      {[1, 2, 3, 4].map((index) => (
        <div
          key={index}
          className="flex flex-col p-4 pb-3 rounded-lg bg-gray-50"
        >
          <div className="h-4 w-24 bg-neutral_200 rounded mb-2"></div>
          <div className="h-6 w-16 bg-neutral_200 rounded"></div>
        </div>
      ))}
    </div>
    <div className="px-4 py-6 border-t border-light_border">
      <div className="h-[300px] bg-neutral_200 rounded"></div>
    </div>
  </div>
);

export const ProfileStatsSkeleton = () => {
  return (
    <div className="relative py-[52px] pr-[75px] pb-[52px] h-full overflow-auto w-full">
      <div className="px-[30px] rounded-[12px]">
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between mb-5 px-[2px]">
            <div className="h-8 w-48 bg-neutral_200 rounded"></div>
            <div className="h-10 w-32 bg-neutral_200 rounded"></div>
          </div>

          <div className="grid grid-cols-4 gap-4 rounded-lg mb-8">
            {[1, 2, 3, 4].map((index) => (
              <StatCardSkeleton key={index} />
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <TopStatsSkeleton />
          <CoreMetricsSkeleton />
          <GrowthChartSkeleton />
          <GrowthChartSkeleton />
        </div>
      </div>
    </div>
  );
};

export default ProfileStatsSkeleton;
