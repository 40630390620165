import React from "react";
import { groupBy } from "lodash";
import BulletinResponseCard from "./BulletinResponseCard";
import { BulletinResponse } from "@apiTypes";

interface BulletinResponseGridProps {
  responses: BulletinResponse[];
  selectedCreatorIds?: number[];
  onSelectCreator?: (creatorId: number) => void;
}

export const BulletinResponseGrid: React.FC<BulletinResponseGridProps> = ({
  responses,
  selectedCreatorIds = [],
  onSelectCreator,
}) => {
  // Group responses by creator
  const responsesByCreator = groupBy(responses, "creator_id");

  return (
    <div className="flex flex-col gap-4 max-w-full mx-auto">
      {Object.values(responsesByCreator).map((creatorResponses) => (
        <BulletinResponseCard
          key={creatorResponses[0].creator_id}
          responses={creatorResponses}
          onSelect={onSelectCreator}
          isSelected={selectedCreatorIds.includes(
            creatorResponses[0].creator_id
          )}
        />
      ))}
    </div>
  );
};

export default BulletinResponseGrid;
