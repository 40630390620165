import FIcon from "@components/FIcon";
import Modal from "@components/Modals/Modal";
import VideoPlayer from "@components/Post/VideoPlayer";
import { Content } from "@types";
import { RefObject, useState } from "react";
import ContentHighlight from "../content-highlight";
import FButton from "@components/FButton";

interface ContentPreviewProps {
  content: Partial<Content>;
  isPaused?: boolean;
  onTogglePlayPause?: () => void;
  onVideoEnded?: () => void;
  showVideoControls?: boolean;
  showFullAssetInModal?: boolean;
  hidePlatformIcon?: boolean;
  videoRef?: RefObject<HTMLVideoElement>;
}

const ContentPreview = ({
  content,
  isPaused,
  onVideoEnded,
  onTogglePlayPause,
  showFullAssetInModal,
  hidePlatformIcon,
  videoRef,
  showVideoControls,
}: ContentPreviewProps) => {
  const [showAssetModal, setShowAssetModal] = useState(false);

  const { isRawVideo, photo_url, permalink, video_url } = content;

  return (
    <>
      {video_url && onTogglePlayPause && !permalink && (
        <div
          className={`absolute top-3 ${permalink ? "right-12" : "right-3"} z-10 bg-dark_night_sky opacity-50 hover:opacity-100 p-2 rounded-full cursor-pointer`}
          onClick={onTogglePlayPause}
        >
          {isPaused ? (
            <FIcon icon="play-button" color="white" size={12} />
          ) : (
            <FIcon icon="pause" color="white" size={12} />
          )}
        </div>
      )}
      <a
        className="h-full w-full block relative"
        href={permalink}
        target="_blank"
      >
        <div
          className={`slide relative h-full ${showVideoControls ? "pb-[70px]" : ""} ${showFullAssetInModal ? "cursor-pointer" : ""}`}
          onClick={showFullAssetInModal ? () => setShowAssetModal(true) : null}
        >
          {isRawVideo ? (
            <video
              ref={videoRef}
              src={video_url}
              autoPlay={!isPaused}
              muted
              onEnded={onVideoEnded}
              className="w-full h-full object-cover"
              controls={showVideoControls}
            />
          ) : video_url ? (
            <VideoPlayer
              className="bg-white justify-center w-full"
              hidePlatformIcon={hidePlatformIcon}
              post={content}
            />
          ) : (
            <div
              className="w-full h-full bg-cover bg-center flex justify-center items-center"
              style={{
                backgroundImage: `url(${photo_url})`,
              }}
            />
          )}
          {isPaused && onTogglePlayPause && (
            <div className="absolute inset-0 flex justify-center items-center bg-light_red bg-opacity-30">
              <FIcon icon="pause" color="white" size={32} />
            </div>
          )}
        </div>
      </a>
      {showAssetModal ? (
        <Modal
          title="Preview"
          className="w-[501px]"
          hasForm
          onClose={() => setShowAssetModal(false)}
          isOpen
          zIndex={9999}
        >
          <div className="flex flex-col gap-4 p-4">
            <ContentHighlight
              data={[content]}
              currentSlide={0}
              highlightRight={false}
              isStatic
              onSlideChange={null}
              width={401}
              height="400px"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <FButton
              onClick={() => setShowAssetModal(false)}
              label="Cancel"
              height="40px"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default ContentPreview;
