import React, { useEffect, useState } from "react";

import EditMessageForm from "./EditMessageForm";

import { useNavigate, useParams } from "react-router-dom";
import {
  getOutreachTemplate,
  updateOutreachTemplate,
} from "@api/Outreach/OutreachTemplates";
import LoadingSpinner from "@components/LoadingSpinner";
import Heading from "@components/Heading";
import FButton from "@components/FButton";
import toast from "react-hot-toast";
import { OutreachTemplate } from "@types";
import { FInput } from "@components/FInputs";

const OutreachTemplatePage = () => {
  const { id } = useParams();
  const [outreachTemplate, setOutreachTemplate] = useState<
    Partial<OutreachTemplate>
  >({});

  const [values, setValues] = useState<Partial<OutreachTemplate>>({});

  const [loading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const loadTemplate = async () => {
      const outreachTemplate = await getOutreachTemplate(id);

      setLoading(false);

      setOutreachTemplate(outreachTemplate);

      setValues({ ...outreachTemplate });
    };

    loadTemplate();
  }, [id]);

  const handleSave = async () => {
    const { assets: originalAssets = [] } = outreachTemplate;
    const { assets = [], body, subject, name } = values;

    if (!body || !subject || !name) {
      setSubmitted(true);

      return;
    }

    setSaving(true);

    const removedAssets = assets.filter(
      (asset) => !originalAssets.find(({ id }) => asset.id === id)
    );

    const addedAssets = assets.filter((asset) => !asset.id);

    try {
      await updateOutreachTemplate(
        outreachTemplate.id,
        values,
        addedAssets,
        removedAssets
      );

      navigate("/outreach/templates");

      toast.success("Saved");
    } catch (e) {
      toast.error("Error saving template");
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <LoadingSpinner className="h-screen" />;
  }

  const headerButtons = (
    <div className="flex flex-row justify-end items-center gap-2">
      <FButton
        label="Back To Templates"
        onClick={() => navigate("/outreach/templates")}
      />
      <FButton
        primary
        iconLeft={
          outreachTemplate.id ? null : { name: "add", size: 14, color: "white" }
        }
        loading={isSaving}
        onClick={handleSave}
        label="Save"
      />
    </div>
  );

  const { assets, body, subject } = values;

  return (
    <div>
      <Heading
        title={outreachTemplate.name || "New Template"}
        headerButtons={headerButtons}
      />
      <div className="mt-[80px]">
        <FInput
          className="max-w-[800px] mb-4 mx-auto"
          width="100%"
          label="Template name"
          required
          value={values.name}
          validate={isSubmitted}
          onChange={(value) => setValues({ ...values, name: value })}
        />
        <EditMessageForm
          isTemplate
          isSubmitted={isSubmitted}
          outreach={{ assets, message: { body, subject } }}
          onUpdate={(updates) => {
            const { message, ...rest } = updates;

            setValues({ ...values, ...message, ...rest });
          }}
        />
      </div>
    </div>
  );
};

export default OutreachTemplatePage;
