import React from "react";
import FIcon from "@components/FIcon";

export default function BulletinsEmptyState() {
  return (
    <div className="flex flex-col items-center justify-center p-20 mt-10 gap-[80px] bg-highlight_red rounded-xl">
      <h1 className="mb-2">No Bulletin found</h1>
      <div className="max-w-[1400px] w-full">
        <div className="flex flex-row gap-[70px] justify-left">
          <div className="flex flex-col justify-left w-[65%] flex-shrink-0 gap-[2em] text-[18px]">
            <h2 className="font-bold text-[24px]">What is Bulletin?</h2>
            <p>Bulletin is a dashboard that you can leverage to discover Surveys, Initiatives and Potential Gifting Opportunities, and register your interest and responses. These are published both by For and also by For subscription clients directly. If you see a Bulletin post, it means you fit the influencer following tier, vertical, location and content style the brand is looking for in a partner.</p>
            <p>Bulletin is non-binding, and obligation-free - registering your interest in an opportunity is like 'raising your hand' so to speak, so please note that, similar to applying for a job, thousands of influencers who use our platform are registering too. While it is very common to not hear back from brands, Bulletin offers a great way for you to introduce yourself, and get your name in front of their team for review.</p>
            <p>While the majority of opportunities posted are not monetarily compensated or are gifting-based, please note that there is no need for you to register or participate in an initiative if you do not wish to based on good-will. Gifting opportunities are a great way to establish a connection with a brand or cause you hope to work with in the future.</p>
          </div>

          <div className="flex flex-col gap-2">
            <div>
              <h2 className="flex flex-row items-start font-bold">
                <FIcon icon="light_bulb" size={20} color="#000F45" className="mr-2" />
                <span>The Benefits of Bulletin</span>
              </h2>
              <ul>
                <li>Discovering new brands and opportunities</li>
                <li>Getting your name in front of brands and agencies for relevant initiatives via registering</li>
                <li>Apply and work to form relationships with brands or causes you organically love</li>
                <li>Participation in surveys that ultimately help us serve you and the rest of the influencer community as best we can</li>
              </ul>
            </div>

            <div>
              <h2 className="flex flex-row items-start font-bold">
                <FIcon icon="light_bulb" size={20} color="#000F45" className="mr-2" />
                Examples of Bulletin posts:
              </h2>
              <ul>
                <li>Surveys: "Do you Wear Contact Lenses?" "Are you publishing a holiday gift guide"</li>
                <li>Potential Gifting Opportunities: "Fall Launch: Autumn Skincare Set"</li>
                <li>Social Good and Pro Bono: "#AdoptDontShop: Spread The Shelter Pet Love"</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
