import { StripeSubscription, StripeInvoice } from "@api/Stripe/Stripe";
import { startBrowserTracingNavigationSpan } from "@sentry/react";

export interface SubscriptionPlan {
  id: string;
  name: string;
  description: string;
  priceId: string;
  interval: "month" | "year";
  amount: number;
}

const getEnvironment = () => {
  const url = window.location.hostname;
  if (url.includes("localhost") || url.includes("127.0.0.1")) {
    return "local";
  }
  if (url.includes("staging") || url.includes("test")) {
    return "staging";
  }
  return "production";
};

const SUBSCRIPTION_PLANS_CONFIG = {
  production: [
    {
      id: "prod_RntsKfjrGKRe9T",
      name: "Monthly Subscription",
      description: "Monthly Subscription to the Fohr Product",
      priceId: "price_0QuI4PoO7aG151HIrjDXOzaU",
      interval: "month",
      amount: 2000.0, // $2000/month
    },
    {
      id: "prod_RntsKfjrGKRe9T",
      name: "Yearly Subscription",
      description: "Yearly subscription to the Fohr product",
      priceId: "price_0QuI4PoO7aG151HIlu5etAsY",
      interval: "year",
      amount: 24000.0, // $24000/year
    },
  ],
  staging: [
    {
      id: "prod_RmC7ImFF3qkxvP",
      name: "Monthly Subscription",
      description: "Monthly Subscription to the Fohr Product",
      priceId: "price_0QsdiyoO7aG151HIvgvi2JPh",
      interval: "month",
      amount: 2000.0, // $2000/month
    },
    {
      id: "prod_RmC738jxCqI9vc",
      name: "Yearly Subscription",
      description: "Yearly subscription to the Fohr product",
      priceId: "price_0QsdjNoO7aG151HImAIMzBd2",
      interval: "year",
      amount: 24000.0, // $24000/year
    },
  ],
  local: [
    {
      id: "prod_RmC7ImFF3qkxvP",
      name: "Monthly Subscription",
      description: "Monthly Subscription to the Fohr Product",
      priceId: "price_0QsdiyoO7aG151HIvgvi2JPh",
      interval: "month",
      amount: 2000.0, // $2000/month
    },
    {
      id: "prod_RmC738jxCqI9vc",
      name: "Yearly Subscription",
      description: "Yearly subscription to the Fohr product",
      priceId: "price_0QsdjNoO7aG151HImAIMzBd2",
      interval: "year",
      amount: 24000.0, // $24000/year
    },
  ],
} as const;

export const SUBSCRIPTION_PLANS = SUBSCRIPTION_PLANS_CONFIG[getEnvironment()];

// on production
// Monthly $2,000/month
// product id  prod_RntsKfjrGKRe9T
// {
//   "id": "price_0QuI4PoO7aG151HIrjDXOzaU",
//   "object": "price",
//   "active": true,
//   "currency": "usd",
//   "custom_unit_amount": null,
//   "nickname": null,
//   "recurring": {
//     "aggregate_usage": null,
//     "interval": "month",
//     "interval_count": 1,
//     "trial_period_days": null,
//     "usage_type": "licensed"
//   },
//   "tiers_mode": null,
//   "transform_quantity": null,
//   "unit_amount": 200000,
//   "unit_amount_decimal": "200000"
// }

// Yearly $24,000/year
// product id  prod_RntsKfjrGKRe9T
// {
//   "id": "price_0QuI4PoO7aG151HIlu5etAsY",
//   "object": "price",
//   "active": true,
//   "currency": "usd",
//   "custom_unit_amount": null,
//   "nickname": null,
//   "recurring": {
//     "aggregate_usage": null,
//     "interval": "year",
//     "interval_count": 1,
//     "trial_period_days": null,
//     "usage_type": "licensed"
//   },
//   "tiers_mode": null,
//   "transform_quantity": null,
//   "unit_amount": 2400000,
//   "unit_amount_decimal": "2400000"
// }

// on TEST or staging
// Montly $2,000/mo
// product Id prod_RmC7ImFF3qkxvP
// {
//   "object": {
//     "id": "price_0QsdiyoO7aG151HIvgvi2JPh",
//     "object": "price",
//     "active": true,
//     "billing_scheme": "per_unit",
//     "created": 1739596532,
//     "currency": "usd",
//     "custom_unit_amount": null,
//     "livemode": false,
//     "lookup_key": null,
//     "metadata": {
//     },
//     "nickname": null,
//     "product": "prod_RmC7ImFF3qkxvP",
//     "recurring": {
//       "aggregate_usage": null,
//       "interval": "month",
//       "interval_count": 1,
//       "meter": null,
//       "trial_period_days": null,
//       "usage_type": "licensed"
//     },
//     "tax_behavior": "unspecified",
//     "tiers_mode": null,
//     "transform_quantity": null,
//     "type": "recurring",
//     "unit_amount": 200000,
//     "unit_amount_decimal": "200000"
//   }
// }

// Yearly $24,000/year
// product Id prod_RmC738jxCqI9vc
// {
//   "object": {
//     "id": "price_0QsdjNoO7aG151HImAIMzBd2",
//     "object": "price",
//     "active": true,
//     "billing_scheme": "per_unit",
//     "created": 1739596557,
//     "currency": "usd",
//     "custom_unit_amount": null,
//     "livemode": false,
//     "lookup_key": null,
//     "metadata": {
//     },
//     "nickname": null,
//     "product": "prod_RmC738jxCqI9vc",
//     "recurring": {
//       "aggregate_usage": null,
//       "interval": "year",
//       "interval_count": 1,
//       "meter": null,
//       "trial_period_days": null,
//       "usage_type": "licensed"
//     },
//     "tax_behavior": "unspecified",
//     "tiers_mode": null,
//     "transform_quantity": null,
//     "type": "recurring",
//     "unit_amount": 2400000,
//     "unit_amount_decimal": "2400000"
//   }
// }

export type { StripeSubscription, StripeInvoice };
