import React, { useState, useContext } from "react";

import FButton from "@components/FButton";
import { toast } from "react-hot-toast";

import { GroupData } from "@apiTypes";
import SelectGroupsTable from "@components/Creator/SelectGroupsTable";

import apiClient from "@apiClient";
import { isEmpty } from "lodash";
import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";
import { bulkCreate } from "@api/Groups/Groups";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const { add_creator } = apiClient.groups;

interface Props {
  creatorIds?: number[];
  onClose: () => void;
  resource?: {
    id: number;
    type: string;
  };
}

function outOfTheGroupCreators(group: GroupData, creatorIds: number[]) {
  const { creators } = group;

  const outOfTheGroup = creatorIds.filter((creatorId) => {
    return !creators.some((creator) => creator.id === creatorId);
  });

  return outOfTheGroup;
}

const AddCreatorsToGroupModal = ({ creatorIds, onClose, resource }: Props) => {
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);
  const { trackEvent } = useContext(MixpanelContext);

  const [selectedGroups, setSelectedGroups] = useState<GroupData[]>([]);

  const handleSubmit = async () => {
    setWaiting(true);
    setError(null);

    if (resource) {
      for (const group of selectedGroups) {
        try {
          await bulkCreate({
            resource_id: resource.id,
            resource_type: resource.type,
            group_id: group.id,
          });

          // Track bulk addition to group
          trackEvent(EVENTS.GROUP.INFLUENCER_ADDED, {
            group_id: group.id,
            group_name: group.name,
            resource_id: resource.id,
            resource_type: resource.type,
            is_bulk_add: true,
          });
          toast.success(
            `Added creator${creatorIds.length === 1 ? "" : "s"} to group${selectedGroups.length === 1 ? "" : "s"}`
          );
          onClose();
        } catch (error) {
          console.error("Failed to add creators to group:", error.response);
          setError("Failed to add creators");

          // Track error
          trackEvent(EVENTS.ERROR.API_ERROR, {
            error_type: "add_creators_to_group",
            error_message:
              error?.response?.data?.message ||
              "Failed to add creators from resource to group",
            group_id: group.id,
            group_name: group.name,
            resource_id: resource.id,
            resource_type: resource.type,
            is_bulk_add: true,
          });
        }
      }
    } else if (creatorIds && selectedGroups.length > 0) {
      const selectedGroupsCreatorArray: {
        group_id: number;
        creatorIds: number[];
      }[] = [];

      selectedGroups.forEach((group) => {
        const outOfTheGroup = outOfTheGroupCreators(group, creatorIds);
        if (outOfTheGroup.length > 0) {
          selectedGroupsCreatorArray.push({
            group_id: group.id,
            creatorIds: outOfTheGroup,
          });
        }
      });
      for (const groupCreatorPair of selectedGroupsCreatorArray) {
        try {
          await add_creator(
            groupCreatorPair.group_id,
            groupCreatorPair.creatorIds
          );
          const groupInfo = selectedGroups.find(
            (g) => g.id === groupCreatorPair.group_id
          );

          trackEvent(EVENTS.GROUP.INFLUENCER_ADDED, {
            group_id: groupCreatorPair.group_id,
            group_name: groupInfo?.name || "Unknown",
            creators_count: groupCreatorPair.creatorIds.length,
            creators_ids: groupCreatorPair.creatorIds,
          });
        } catch (error) {
          console.error(
            `Failed to add creators to group ${groupCreatorPair.group_id}`,
            error
          );

          trackEvent(EVENTS.ERROR.API_ERROR, {
            error_type: "add_creators_to_group",
            error_message:
              error?.response?.data?.message ||
              "Failed to add creators to group",
            group_id: groupCreatorPair.group_id,
            creators_count: groupCreatorPair.creatorIds.length,
            creators_ids: groupCreatorPair.creatorIds,
          });
        }
      }
      toast.success(
        `Added creator${creatorIds.length === 1 ? "" : "s"} to group${selectedGroups.length === 1 ? "" : "s"}`
      );
      onClose();
      setWaiting(false);
    }
  };

  return (
    <SelectEntitiesModal
      title="Add to group"
      onClose={onClose}
      error={error}
      actionButton={
        <FButton
          loading={waiting}
          disabled={isEmpty(selectedGroups)}
          onClick={handleSubmit}
          primary
          label="Save"
          width="100%"
          height="40px"
        />
      }
    >
      <div className="h-[calc(100%-50px)]">
        <SelectGroupsTable
          enableGroupCreateForm={true}
          hasSingleSelection={false}
          selectedItems={selectedGroups}
          onChangeSelectedItems={setSelectedGroups}
        />
      </div>
    </SelectEntitiesModal>
  );
};

export default AddCreatorsToGroupModal;
