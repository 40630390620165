import React, { useContext, useEffect, useState } from "react";

import FButton from "@components/FButton";

import { copyToClipboard } from "@utils/copy_utilities";
import { ProfileContext } from "@contexts/index";
import BlankState from "@components/BlankState";
import { getTestimonialsList } from "@api/Testimonial";
import { Testimonial } from "@types";
import { isEmpty } from "lodash";
import FIcon from "@components/FIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import moment from "moment";
import useAuth from "@hooks/useAuth";
import ContentGuard from "./components/ContentGuard";

const ProfileTestimonials = () => {
  const { profile } = useContext(ProfileContext);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const getTestimonials = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      const response = await getTestimonialsList(profile.id);
      setTestimonials(response.data.testimonials);
      setLoading(false);
    };

    getTestimonials();
  }, [profile.id, user]);

  const highlightedTestimonial = testimonials.find(
    ({ highlighted }) => !!highlighted
  );

  if (loading) {
    return (
      <div className="mt-[200px]">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="py-[52px] pr-[46px] relative">
      <div className="flex justify-between items-center flex-col sm:flex-row mb-3">
        <p>{`Testimonials${isEmpty(testimonials) ? "" : ` (${testimonials.length})`}`}</p>
        <div className="items-center gap-[8px] hidden sm:flex">
          <FButton
            label="Testimonial Link"
            onClick={() => copyToClipboard(`${window.location.origin}/card/${profile.slug}/testimonials/new`)}
            primary
          />
        </div>
      </div>
      {!isEmpty(testimonials) ? (
        <>
          {highlightedTestimonial ? (
            <div className="px-[24px] py-[32px] text-center">
              <FIcon icon="star" size={22} color="#FBD45B" />
              <p className="text-default_weak mt-2 mb-4">
                Featured Testimonial
              </p>
              <p className="text-xl">
                “{highlightedTestimonial.data.follow_reason}“
              </p>
              <div className="text-default_weak mt-2 mb-4">
                <p>{highlightedTestimonial.instagram_handle}</p>
                <p>
                  {moment(highlightedTestimonial.created_at).format(
                    "MMMM D, YYYY"
                  )}
                </p>
              </div>
            </div>
          ) : null}
          <div className="flex flex-col gap-4">
            {testimonials
              .filter(
                (t) =>
                  t.id !== highlightedTestimonial?.id && t.data.follow_reason
              )
              .map((testimonial, i) => (
                <div
                  className="border border-neutral_400 rounded-lg px-4 py-6"
                  key={i}
                >
                  <p className="mb-4">“{testimonial.data.follow_reason}“</p>
                  <div className="flex justify-between">
                    <div className="text-default_weak mt-2 mb-4">
                      <p>{highlightedTestimonial?.instagram_handle}</p>
                      <p>
                        {moment(highlightedTestimonial?.created_at).format(
                          "MMMM D, YYYY"
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <FIcon icon="star" size={18} color="#000721" />
                      <div
                        className="cursor-pointer"
                        onClick={() => copyToClipboard(location.href)}
                      >
                        <FIcon icon="share" size={18} color="#000721" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <BlankState
          title="No testimonials yet"
          subtitle="You will see their testimonials here once they are submitted"
          icon="audience"
        />
      )}
      <ContentGuard />
    </div>
  );
};

export default ProfileTestimonials;
