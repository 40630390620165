import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Legend from "./components/Legend";
import { maxBy } from "lodash";
import { useEffect, useRef } from "react";

const getOptions = (showCounts) => ({
  tooltip: {
    pointFormatter: function () {
      return `<b>${this.y}%</b>${showCounts ? ` (${this.count} user${this.count === 1 ? "" : "s"})` : ``}`;
    },
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      colorByPoint: true,
      type: "pie",
      size: "100%",
      innerSize: "70%",
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      type: "pie",
    },
  ],
});

export type DataItem = {
  name: string;
  percentage: number;
  count?: number;
};

interface DoughnutGraphProps {
  data: DataItem[];
  entityName: string;
  colors: string[];
  showLegend?: boolean;
  height?: number | string;
  width?: number | string;
  subtitle?: string;
  showCounts?: boolean;
}

export default function DoughnutGraph({
  data = [],
  entityName,
  colors = [],
  height,
  width,
  showLegend,
  showCounts,
}: DoughnutGraphProps) {
  const chartRef = useRef(null);

  const OPTIONS = getOptions(showCounts);

  useEffect(() => {
    if (!data || !chartRef.current) {
      return;
    }

    const maxValueItem = maxBy(data, "percentage");

    if (!maxValueItem) {
      return;
    }

    const chart = chartRef.current.chart;

    chart.tooltipStr.attr({
      text: `${maxValueItem.percentage}%`,
    });
  }, [data]);

  const options = {
    ...OPTIONS,
    chart: {
      height: height || 206,
      width: width || 202,
    },
    colors,
    series: [
      {
        ...OPTIONS.series[0],
        data: data.map((item) => ({ ...item, y: item.percentage })),
        name: entityName,
      },
    ],
  };

  const handleCallback = (chart) => {
    const x = chart.chartWidth / 2;
    const y = chart.chartHeight / 2;

    const maxValueItem = maxBy(chart.series[0].data, "y") as unknown as {
      y: number;
    };

    if (!maxValueItem) {
      return;
    }

    chart.tooltipStr = chart.renderer.text(`${maxValueItem.y}%`, x, y);

    chart.tooltipStr
      .attr({
        align: "center",
        fill: colors[0],
      })
      .css({
        fontSize: "22px",
      })
      .add();
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={handleCallback}
        ref={chartRef}
      />
      {showLegend ? (
        <Legend
          labels={data.map((item, index) => ({
            color: colors[index],
            label: `${item.percentage}% ${item.name}`,
          }))}
        />
      ) : null}
    </div>
  );
}
