import FSubNav from "@components/FSubNav";
import Heading from "@components/Heading";
import AmbassadorQuestions from "@pages/ambassadors/ambassador-questions";
import AmbassadorsOnboardingBrandSettings from "./Branding";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { capitalize } from "lodash";
import FIcon from "@components/FIcon";
import AmbassadorsEmailSettings from "./AmbassadorsEmail";
import { useContext, useEffect } from "react";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { AmbassadorsContext } from "@contexts/index";

const NAV_ITEMS = [
  {
    value: "branding",
  },
  {
    value: "questions",
  },
  {
    value: "email",
  },
];

const AmbassadorsOnboardingSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { trackEvent } = useContext(MixpanelContext);
  const { brand } = useContext(AmbassadorsContext);

  // Track settings viewed on initial load
  useEffect(() => {
    const currentSection = location.pathname.includes("questions")
      ? "questions"
      : location.pathname.includes("email")
        ? "email"
        : "branding";

    trackEvent(EVENTS.AMBASSADOR.SETTINGS_VIEWED, {
      brand_id: brand?.id,
      settings_section: currentSection,
    });
  }, []);

  const handleNavChange = (newValue: string) => {
    // Track when user navigates to a different settings section
    trackEvent(EVENTS.AMBASSADOR.SETTINGS_VIEWED, {
      brand_id: brand?.id,
      settings_section: newValue,
      previous_section: selectedNav,
    });

    navigate(
      `/ambassadors/onboarding-settings${newValue === NAV_ITEMS[0].value ? "" : `/${newValue}`}`
    );
  };

  const selectedNav =
    NAV_ITEMS.find(({ value }) => location.pathname.includes(value))?.value ||
    NAV_ITEMS[0].value;

  return (
    <div>
      <Link className="flex items-center mb-4" to={`/ambassadors`}>
        <FIcon icon="back" size={18} color="#000F45" />
        Go back to ambassadors
      </Link>
      <div className="flex items-center justify-between mb-8">
        <Heading title="Ambassador onboarding settings" />
      </div>
      <FSubNav
        items={NAV_ITEMS.map(({ value }) => ({
          label: capitalize(value),
          value,
          active: value === selectedNav,
        }))}
        selectedValue={selectedNav}
        onChange={handleNavChange}
      />
      <div className="pt-8">
        <Routes>
          <Route index element={<AmbassadorsOnboardingBrandSettings />} />
          <Route
            path="/questions/:question_id?"
            element={<AmbassadorQuestions />}
          />
          <Route path="/email" element={<AmbassadorsEmailSettings />} />
        </Routes>
      </div>
    </div>
  );
};

export default AmbassadorsOnboardingSettings;
