import React from "react";
import { Outreach } from "@types";
import { sumBy } from "lodash";

const getStats = (outreach) => {
  const { recipients } = outreach;

  const totalParticipants = recipients.length;

  const deliveredCount = recipients.filter(({ delivered }) => delivered).length;
  const totalOpens = sumBy(recipients, "opens");
  const totalClicks = sumBy(recipients, "clicks");

  return [
    {
      label: "Influencers",
      value: totalParticipants,
    },
    {
      label: "Delivered",
      value: deliveredCount,
      percentage: Math.round((100 * deliveredCount) / totalParticipants),
    },
    {
      label: "Opened",
      value: totalOpens,
    },
    {
      label: "Clicked",
      value: totalClicks,
    },
  ];
};

const MessageView = ({ outreach }: { outreach: Outreach }) => {
  return (
    <div className="bg-white max-w-[800px] p-10 border border-light_border rounded-lg m-auto mt-8">
      <div className="flex flex-row justify-between mb-10 border-b border-light_border pb-10">
        <div className="flex flex-col gap-4 w-full">
          <h2 className="text-xl font-bold">Message Sent</h2>
          <div className="flex gap-4 w-full">
            {getStats(outreach).map(({ value, label, percentage }) => (
              <div
                className="text-center bg-highlight_red w-full rounded-lg px-4 py-2"
                key={label}
              >
                <p className="text-sm text-dark_night_sky font-medium">
                  {label}
                </p>
                {percentage ? (
                  <p className="text-lg font-bold text-dark_night_sky">
                    {percentage}%
                  </p>
                ) : (
                  <p className="text-lg font-bold text-dark_night_sky">
                    {value}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-medium text-neutral_700">
            Reply To Address
          </h3>
          <p className="text-dark_night_sky">{outreach.reply_to || "N/A"}</p>
        </div>

        {outreach.cc_emails && (
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-medium text-neutral_700">
              CC Recipients
            </h3>
            <p className="text-dark_night_sky">{outreach.cc_emails}</p>
          </div>
        )}

        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-medium text-neutral_700">Subject</h3>
          <p className="text-dark_night_sky">
            {outreach.message.subject || "N/A"}
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-medium text-neutral_700">Body</h3>
          <div
            className="text-dark_night_sky prose max-w-none"
            dangerouslySetInnerHTML={{
              __html: outreach.message.body || "N/A",
            }}
          />
        </div>
      </div>

      {outreach.assets?.length > 0 && (
        <div className="mt-10 pt-4 border-t border-light_border">
          <h3 className="text-sm font-medium text-neutral_700 mb-4">
            Attachments
          </h3>
          <div className="flex flex-wrap gap-2">
            {outreach.assets.map((attachment, index) => (
              <div
                className="flex w-fit flex-row items-center gap-2 px-4 py-2 justify-between relative bg-highlight_red rounded-lg"
                key={index}
              >
                <a
                  className="text-[15px] text-dark_night_sky underline font-medium"
                  href={attachment.file_url}
                  target="_blank"
                >
                  {attachment.file_name}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageView;
