import { Content, Profile } from "@types";
import { isEmpty, sortBy, sumBy } from "lodash";

const NUM_TIME_BUCKETS = 15; // Number of points we want on our graph

export const CHART_COLORS = [
  "#7AC9E2", // Light Blue
  "#F4B95A", // Warm Yellow
  "#E67E8B", // Soft Pink
  "#6B8CC7", // Muted Purple
  "#F39C6B", // Soft Orange
  "#9B8BC3", // Lavender
  "#68C091", // Sage Green
  "#5DADE2", // Sky Blue
  "#E6A091", // Peach
  "#78C4BA", // Aqua
  "#8599B3", // Steel Blue
];

export const getOptimizedColors = (
  data: { name: string; percentage: number }[]
) => {
  // Sort data by percentage in descending order
  const sortedData = [...data].sort((a, b) => b.percentage - a.percentage);

  // Map each data point to a color from our fixed order
  return sortedData.map(
    (_, index) => CHART_COLORS[index % CHART_COLORS.length]
  );
};

export const parsePublishedDate = (dateStr: string) => {
  // Convert "Nov 19, 2024" format to Date object
  return new Date(dateStr);
};

const createTimeBuckets = (posts: Content[]) => {
  if (posts.length === 0) return [];

  // Sort posts by date
  const sortedPosts = [...posts].sort(
    (a, b) =>
      parsePublishedDate(a.published_at).getTime() -
      parsePublishedDate(b.published_at).getTime()
  );

  const startDate = parsePublishedDate(sortedPosts[0].published_at).getTime();
  const endDate = parsePublishedDate(
    sortedPosts[sortedPosts.length - 1].published_at
  ).getTime();
  const timeRange = endDate - startDate;
  const bucketSize = timeRange / (NUM_TIME_BUCKETS - 1);

  // Create buckets
  const buckets: { date: Date; posts: Content[] }[] = [];
  for (let i = 0; i < NUM_TIME_BUCKETS; i++) {
    const bucketDate = new Date(startDate + bucketSize * i);
    buckets.push({
      date: bucketDate,
      posts: [],
    });
  }

  // Distribute posts into buckets
  posts.forEach((post) => {
    const postDate = parsePublishedDate(post.published_at).getTime();
    const bucketIndex = Math.min(
      Math.floor(((postDate - startDate) / timeRange) * (NUM_TIME_BUCKETS - 1)),
      NUM_TIME_BUCKETS - 1
    );
    buckets[bucketIndex].posts.push(post);
  });

  return buckets;
};

export const processTimeSeriesData = (content: Content[]) => {
  // First, get posts that have engagement_rate
  const postsWithEngagement = content
    .filter((post) => {
      const hasEngagement =
        "engagement_rate" in post.stats &&
        (post.stats as any).engagement_rate > 0;
      return hasEngagement;
    })
    .sort(
      (a, b) =>
        parsePublishedDate(b.published_at).getTime() -
        parsePublishedDate(a.published_at).getTime()
    )
    .slice(0, 50);

  if (postsWithEngagement.length === 0) return [];

  // Create time buckets for engagement data
  const engagementBuckets = createTimeBuckets(postsWithEngagement);

  // Calculate metrics for each bucket
  const timeSeriesData = engagementBuckets.map((bucket) => {
    const { date, posts } = bucket;

    // Calculate average engagement for the bucket
    const avgEngagement =
      posts.length > 0
        ? posts.reduce(
            (sum, post) => sum + (post.stats as any).engagement_rate,
            0
          ) / posts.length
        : null;

    return {
      date,
      avgEngagement: {
        date,
        value: avgEngagement ? avgEngagement * 100 : null,
      },
      totalReach: {
        date,
        value: posts.reduce((sum, post) => sum + (post.stats.reach || 0), 0),
      },
      viewership: {
        date,
        value: posts.reduce(
          (sum, post) => sum + (post.stats.views_count || 0),
          0
        ),
      },
      totalViews: {
        date,
        value: posts.reduce(
          (sum, post) => sum + (post.stats.impressions || 0),
          0
        ),
      },
    };
  });

  // Filter out empty buckets
  const finalTimeSeriesData = timeSeriesData.filter(
    (data) =>
      data.avgEngagement.value !== null ||
      data.totalReach.value > 0 ||
      data.viewership.value > 0 ||
      data.totalViews.value > 0
  );

  return finalTimeSeriesData;
};

export const calculateCurrentMetrics = (content: Content[]) => {
  // Sort and take only the 50 most recent posts
  const recentContent = [...content]
    .sort(
      (a, b) =>
        parsePublishedDate(b.published_at).getTime() -
        parsePublishedDate(a.published_at).getTime()
    )
    .slice(0, 50);

  // For engagement, only use posts that have positive engagement_rate
  const postsWithEngagement = recentContent.filter(
    (post) =>
      "engagement_rate" in post.stats && (post.stats as any).engagement_rate > 0
  );

  // Calculate average engagement rate from all available engagement data
  const avgEngagement =
    postsWithEngagement.length > 0
      ? (postsWithEngagement.reduce(
          (sum, post) => sum + (post.stats as any).engagement_rate,
          0
        ) /
          postsWithEngagement.length) *
        100 // Convert to percentage
      : 0;

  // Other metrics now use the recent content
  const postsWithReach = recentContent.filter((post) => post.stats.reach);
  const postsWithViewership = recentContent.filter(
    (post) => post.stats.views_count
  );
  const postsWithViews = recentContent.filter((post) => post.stats.impressions);

  return {
    avgEngagement,
    totalReach: postsWithReach.reduce((sum, post) => sum + post.stats.reach, 0),
    viewership: postsWithViewership.reduce(
      (sum, post) => sum + post.stats.views_count,
      0
    ),
    totalViews: postsWithViews.reduce(
      (sum, post) => sum + post.stats.impressions,
      0
    ),
  };
};

export const getCategoryBreakdown = (profile: Profile, content: Content[]) => {
  if (isEmpty(content)) {
    return null;
  }

  const allCategories = content.reduce(
    (result, { categories }) => [...result, ...categories.split(",")],
    []
  );

  const categoriesByValue = allCategories.reduce((result, category) => {
    const key = category.trim();
    const valuesCount = result[key] || 0;
    return {
      ...result,
      [key]: valuesCount + 1,
    };
  }, {});

  const totalCategories = allCategories.length;
  const orderedCategories = sortBy(
    Object.keys(categoriesByValue).map((category) => ({
      label: category,
      value: categoriesByValue[category],
    })),
    "value"
  ).reverse();

  const topCategories = orderedCategories
    .filter(({ label }) => label !== "")
    .slice(0, 6);

  const other = {
    label: "Mixed",
    id: null,
    value: totalCategories - sumBy(topCategories, "value"),
  };

  return [...topCategories, other].map(({ label, value }) => ({
    name: label || "Unknown",
    percentage: (100 * value) / totalCategories,
  }));
};
