export const EVENTS = {
  PAGE_VIEW: {
    CAMPAIGNS: "Campaigns Page Viewed",
    CAMPAIGN: "Campaign Page Viewed",
    REPORTS: "Reports Page Viewed",
    REPORT: "Report Page Viewed",
    SEARCH: "Search Page Viewed",
    GROUPS: "Groups Page Viewed",
    GROUP: "Group Page Viewed",
    BULLETINS: "Bulletins Page Viewed",
    BULLETIN: "Bulletin Page Viewed",
    OUTREACHES: "Outreaches Page Viewed",
    OUTREACH: "Outreach Page Viewed",
    PROFILE: "Profile Page Viewed",
    COLLABORATORS: "Collaborators Page Viewed",
    AMBASSADORS: "Ambassadors Page Viewed",
    NOTIFICATIONS: "Notifications Page Viewed",
    DASHBOARD: "Dashboard Page Viewed",
    NOT_FOUND: "404 Page Viewed",

    // Influencer-specific views
    INFLUENCER_DASHBOARD: "Influencer Dashboard Viewed",
    CAMPAIGN_OPPORTUNITIES: "Campaign Opportunities Viewed",
    CAMPAIGN_APPLICATION: "Campaign Application Viewed",
    CAMPAIGN_BRIEF_VIEWED: "Campaign Brief Viewed",
    CAMPAIGN_BRIEF_SUBTAB_VIEWED: "Campaign Brief Subtab Viewed",
    CAMPAIGN_CONTRACT_VIEWED: "Campaign Contract Viewed",
    CONTENT_SUBMISSION: "Content Submission Page Viewed",
  },

  CAMPAIGN: {
    VIEWED: "Campaign Viewed",
    CREATED: "Campaign Created",
    UPDATED: "Campaign Updated",
    BRIEF_UPDATED: "Campaign Brief Updated",
    LAUNCHED: "Campaign Launched",
    COMPLETED: "Campaign Completed",
    DUPLICATED: "Campaign Duplicated",
    ARCHIVED: "Campaign Archived",
    PREVIEW_CLICKED: "Campaign Preview Clicked",
    SETTINGS_ACCESSED: "Campaign Settings Accessed",
    BRIEF_MASS_UPDATE: "Campaign Brief Mass Update",
    PARTICIPANTS_ADDED: "Campaign Participants Added",
    PARTICIPANT_BULK_ACTION: "Campaign Participant Bulk Action",
    PARTICIPANT_REVIEW_VIEWED: "Campaign Participant Review Viewed",

    // Influencer-specific actions
    APPLIED: "Campaign Applied",
    APPLICATION_ACCEPTED: "Campaign Application Accepted",
    APPLICATION_REJECTED: "Campaign Application Rejected",
    CONTENT_SUBMITTED: "Campaign Content Submitted",
    CONTENT_APPROVED: "Campaign Content Approved",
    CONTENT_REJECTED: "Campaign Content Rejected",
    PAYMENT_RECEIVED: "Campaign Payment Received",
    CONTRACT_VIEWED: "Campaign Contract Viewed",
    NOT_INTERESTED: "Campaign Not Interested",
  },

  SEARCH: {
    PERFORMED: "Search Performed",
    FILTERED: "Search Filtered",
    RESULTS_VIEWED: "Search Results Viewed",
    SAVED: "Search Saved",
    TYPE_SELECTED: "Search Type Selected",
    PANEL_CLICKED: "Search Panel Clicked",
  },

  GROUP: {
    CREATED: "Group Created",
    UPDATED: "Group Updated",
    DELETED: "Group Deleted",
    INFLUENCER_ADDED: "Influencer Added to Group",
    INFLUENCER_REMOVED: "Influencer Removed from Group",
    ALL_VIEWED: "All Groups Viewed",
    FEATURED_VIEWED: "Featured Groups Viewed",
    ARCHIVED_VIEWED: "Archived Groups Viewed",
    ADDED_TO_CAMPAIGN: "Group Added to Campaign",
    DUPLICATED: "Group Duplicated",
    COMBINED: "Group Combined",
    DOWNLOADED: "Group Downloaded to CSV",
    MEMBER_MESSAGED: "Group Member Messaged",
    MEMBER_ADDED_TO_CAMPAIGN: "Group Member Added to Campaign",
    MEMBER_REMOVED: "Group Member Removed",
  },

  WORKFLOW: {
    START: "Workflow Started",
    STEP_COMPLETED: "Workflow Step Completed",
    COMPLETED: "Workflow Completed",
    ABANDONED: "Workflow Abandoned",
  },

  BULLETIN: {
    CREATED: "Bulletin Created",
    UPDATED: "Bulletin Updated",
    DELETED: "Bulletin Deleted",
    CONTENT_UPDATED: "Bulletin Content Updated",
    PREVIEW_CLICKED: "Bulletin Preview Clicked",
    QUESTIONS_UPDATED: "Bulletin Questions Updated",
    QUESTION_UPDATED: "Bulletin Question Updated",
    SETTINGS_UPDATED: "Bulletin Settings Updated",
    RECIPIENTS_ADDED: "Bulletin Recipients Added",
    RECIPIENTS_DOWNLOADED: "Bulletin Recipients Downloaded",
    RECIPIENTS_ADDED_TO_GROUP: "Bulletin Recipients Added To Group",
    EMAIL_SENT: "Bulletin Email Sent",
    TEST_EMAIL_SENT: "Bulletin Test Email Sent",
    ARCHIVED: "Bulletin Archived",

    // Influencer-specific actions
    CONTINUE_CLICKED: "Bulletin Continue Clicked",
    QUESTION_ANSWERED: "Bulletin Question Answered",
    FORM_SUBMITTED: "Bulletin Form Submitted",
  },

  AUTH: {
    SIGNED_IN: "User Signed In",
    SIGNED_OUT: "User Signed Out",
    BRAND_CREATED: "Brand Created",
    BRAND_USER_CREATED: "Brand User Created",
    INFLUENCER_CREATED: "Influencer Created",
    PASSWORD_RESET_REQUESTED: "Password Reset Requested",
    PASSWORD_RESET_COMPLETED: "Password Reset Completed",
    SOCIAL_ACCOUNT_CONNECTED: "Social Account Connected",
    ACCOUNT_SETTINGS_UPDATED: "Account Settings Updated",
  },

  ERROR: {
    API_ERROR: "API Error Occurred",
    FORM_ERROR: "Form Submission Error",
    AUTH_ERROR: "Authentication Error",
    PERMISSION_ERROR: "Permission Denied Error",
  },

  SUPPORT: {
    REQUESTED: "Support Requested",
    HELP_CLICKED: "Help & Support Clicked",
  },

  DASHBOARD: {
    BRAND_SWITCHED: "Brand Switched",
    DATE_RANGE_CHANGED: "Date Range Changed",
    TOP_CONTENT_CLICKED: "Top Content Clicked",
    CAMPAIGN_CONTENT_CLICKED: "Campaign Content Clicked",
    NOTIFICATION_CLICKED: "Notification Clicked",
    NOTIFICATIONS_CLEARED: "Notifications Cleared",
    SECTION_EXPANDED: "Dashboard Section Expanded",
    SECTION_COLLAPSED: "Dashboard Section Collapsed",
    EXPORT_CLICKED: "Dashboard Export Clicked",
  },

  DELIVERABLE: {
    ADDED: "Deliverable Added",
    UPDATED: "Deliverable Updated",
    REMOVED: "Deliverable Removed",
  },

  PRODUCT: {
    ADDED: "Product Added",
    UPDATED: "Product Updated",
    REMOVED: "Product Removed",
  },

  OUTREACH: {
    CREATED: "Outreach Created",
    UPDATED: "Outreach Updated",
    DUPLICATED: "Outreach Duplicated",
    DELETED: "Outreach Deleted",
    MESSAGE_UPDATED: "Outreach Message Updated",
    TEMPLATE_APPLIED: "Outreach Template Applied",
    SEND_ACTION: "Outreach Sent",
    TEST_EMAIL_SENT: "Outreach Test Email Sent",
    RECIPIENTS_ADDED: "Outreach Recipients Added",
    RECIPIENTS_REMOVED: "Outreach Recipients Removed",
    TEMPLATES_VIEWED: "Outreach Templates Viewed",
    TEMPLATE_CREATED: "Outreach Template Created",
    TEMPLATE_UPDATED: "Outreach Template Updated",
    TEMPLATE_DELETED: "Outreach Template Deleted",
    TEMPLATE_DUPLICATED: "Outreach Template Duplicated",
    ATTACHMENT_ADDED: "Outreach Attachment Added",
    ATTACHMENT_REMOVED: "Outreach Attachment Removed",
  },

  REPORT: {
    VIEWED: "Report Viewed",
    DOWNLOADED_CSV: "Report CSV Downloaded",
    CONTENT_VIEWED: "Report Content Viewed",
    CREATORS_VIEWED: "Report Creators Viewed",
    COMBINED: "Reports Combined",
    DELETED: "Report Deleted",
    CREATED: "Report Created",
  },

  CONTENT_REVIEW: {
    COMMENT_ADDED: "Content Review Comment Added",
    COMMENT_VIEWED: "Content Review Comment Viewed",
    REVISION_SUBMITTED: "Content Revision Submitted",
    REQUEST_RESPONDED: "Content Request Responded",
    TAB_CHANGED: "Content Review Tab Changed",
    STATUS_FILTERED: "Content Review Status Filtered",
    SORTED: "Content Review Filtered",
    INITIAL_REVIEW_COMPLETED: "Initial Content Review Completed",
    CLIENT_REVIEW_COMPLETED: "Client Content Review Completed",
    FINAL_APPROVAL: "Final Content Approval",
  },

  CONTENT: {
    UPLOADED: "Content Uploaded",
    RENAMED: "Content Renamed",
    REORDERED: "Content Reordered",
    SUBMITTED_FOR_REVIEW: "Content Submitted For Review",
  },

  OFF_PLATFORM_CREATOR: {
    BUTTON_CLICKED: "Add Off Platform Creator Clicked",
    CREATED: "Off Platform Creator Added",
    FORM_ABANDONED: "Off Platform Creator Form Abandoned",
  },

  AMBASSADOR: {
    PAGE_SHARED: "Ambassador Page Shared",
    SHARE_COMPLETED: "Ambassador Share Completed",
    SETTINGS_VIEWED: "Ambassador Settings Viewed",
    SETTINGS_UPDATED: "Ambassador Settings Updated",
    QUESTION_UPDATED: "Ambassador Question Updated",
    EMAIL_UPDATED: "Ambassador Email Template Updated",
    RESPONSE_FILTERED: "Ambassador Response Filtered",
    ADVANCED_SEARCH: "Ambassador Advanced Search Used",
    BULK_ADD_TO_GROUP: "Ambassador Bulk Add To Group",
    BULK_ACTION: "Ambassador Bulk Action Performed",
  },

  USER_MENU: {
    SETTINGS_CLICKED: "Settings Clicked",
    EDUCATION_CENTER_CLICKED: "Education Center Clicked",
    HELP_DESK_CLICKED: "Help Desk Clicked",
  },

  SETTINGS: {
    SUBSCRIPTION_PAGE_VIEWED: "Subscription Page Viewed",
    CONTACT_SALES_CLICKED: "Contact Sales Clicked",
    EMAIL_PREFERENCES_UPDATED: "Email Preferences Updated",
    BRAND_PREFERENCES_UPDATED: "Brand Preferences Updated",
    CONTENT_RECORD_REQUESTED: "Content Record Requested",
    PERSONAL_INFO_UPDATED: "Personal Information Updated",
    SOCIAL_CONNECTION_UPDATED: "Social Connection Updated",
  },
};
