import BlankState from "@components/BlankState";
import Table from "@components/Table";
import { Campaign, CampaignDeliverable } from "@types";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const TABLE_COLUMNS = [
  {
    key: "name",
    label: "Name",
    isLink: true,
    linkURL: "/campaigns/{campaign_id_actual}/deliverables",
    isFirstColumn: true,
  },
  {
    key: "platform",
    label: "Platform",
    type: "platform",
  },
  {
    key: "submission_due_date",
    label: "Due date",
    isDate: true,
  },
  {
    key: "hashtags",
    label: "Hashtags",
  },
  {
    key: "mentions",
    label: "Mentions",
  },
  {
    key: "updated_at",
    label: "Modified",
    isDate: true,
  },
];

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

interface CampaignParticipantDeliverablesProps {
  campaign: Campaign;
  onEdit: (deliverable: CampaignDeliverable) => void;
  onDelete: (deliverable: CampaignDeliverable) => void;
  selectedItems: number[];
  onChangeSelectedItems: (items: number[]) => void;
}

const CampaignParticipantDeliverables = ({
  campaign,
  onEdit,
  onDelete,
  selectedItems,
  onChangeSelectedItems,
}: CampaignParticipantDeliverablesProps) => {
  const { participants } = campaign;

  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-8">
      {participants.map((participant) => {
        const { deliverables, id } = participant;

        const {
          creator: { firstName, lastName, profile_image_url },
        } = participant;

        const handleTableRowDropdownSelect = (value: string, id: number) => {
          const deliverable = deliverables.find((item) => item.id === id);

          if (value === "edit") {
            onEdit(deliverable);
          } else if (value === "delete") {
            onDelete(deliverable);
          }
        };

        if (isEmpty(deliverables)) {
          // TODO: Think whether we should not render a participant or show a blank state here if there are no deliverables
          return null;
        }

        return (
          <div
            className="border border-light_border rounded-lg bg-white shadow-sm"
            key={id}
          >
            <div className="border-b border-light_border flex items-center p-7">
              <div className="flex items-center">
                <div
                  className={`bg-center bg-cover w-[46px] h-[46px] mr-3 rounded-full`}
                  style={{
                    backgroundImage: `url(${profile_image_url})`,
                  }}
                />
                <div>
                  <p className="text-lg ">
                    {firstName} {lastName}
                  </p>
                  <p className="text-default_weak flex flex-row gap-2 text-sm">
                    <span className="">
                      {deliverables.length} Deliverable
                      {deliverables.length === 1 ? "" : "s"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              {isEmpty(deliverables) ? (
                <BlankState
                  title="No Deliverables Here"
                  subtitle="Add deliverables on the participant brief"
                  icon={null}
                  noMarginTop
                  actionLabel="Add a deliverable"
                  onActionClick={() =>
                    navigate(
                      `/campaigns/${participant.campaign_id}/participants/${participant.id}`
                    )
                  }
                />
              ) : (
                <Table
                  lastRowNoBorder
                  columns={TABLE_COLUMNS}
                  data={deliverables}
                  rowActions={cardDropDownOptions}
                  onOptionSelect={handleTableRowDropdownSelect}
                  onChangeSelectedItems={onChangeSelectedItems}
                  selectedItems={selectedItems}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CampaignParticipantDeliverables;
