import React, { useCallback, useContext, useEffect, useState } from "react";

import { Bulletin, Sort } from "@types";
import {
  getBulletinRecipientList,
  deleteBulletinRecipient,
  sendEmailToAllRecipients,
} from "@api/Bulletins/BulletinRecipients";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import useAuth from "@hooks/useAuth";
import { Permission, Role } from "@constants/roles";
import { useNavigate, useParams } from "react-router-dom";
import {
  downloadRecipients,
  getBulletin,
  updateBulletin,
} from "@api/Bulletins/Bulletins";
import FButton from "@components/FButton";
import AddRecipientsToBulletinModal from "./components/AddRecipientsToBulletinModal";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

import BlankState from "@components/BlankState";
import toast from "react-hot-toast";
import ConfirmModal from "@components/Modals/ConfirmModal";

import FilterSummary from "./search-filter-history/FilterSummary";
import TestEmailModal from "./components/TestEmailModal";
import Modal from "@components/Modals/Modal";
import moment from "moment";
import { downloadDataAsCSVFile } from "@utils/download";

const filterOptions = [
  { label: "All", static: "Status", value: null },
  { label: "New", static: "Status", value: "new" },
  { label: "Completed", static: "Status", value: "completed" },
  { label: "Declined", static: "Status", value: "declined" },
];

const actions = {
  delete: async (id: number) => deleteBulletinRecipient(id),
};

const tableColumns = [
  {
    key: "creator_full_name",
    label: "Name",
    isLink: false,
    isImage: true,
    imageType: "profile",
    imageKey: "creator_profile_image_url",
    isFirstColumn: true,
  },
  { key: "status", label: "Status", isStateBubble: true },
  { key: "email_last_sent_at", label: "Email Last Sent At", isDate: true },
];

export default function BulletinRecipientListPage() {
  const [bulletin, setBulletin] = useState<Bulletin>(null);
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false);
  const [showTestEmailModal, setShowTestEmailModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const { bulletin_id } = useParams<{ bulletin_id: string }>();
  const { can, hasRole } = useAuth();
  const { trackEvent } = useContext(MixpanelContext);

  const [isDownloading, setDownloading] = useState(false);

  const navigate = useNavigate();

  const [isConfirmPublishModalVisible, setConfirmPublishModalVisible] =
    useState(false);

  useEffect(() => {
    getBulletin(bulletin_id).then((response) => {
      setBulletin(response.data);
    });
  }, []);

  const getBulletinRecipients = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const response = await getBulletinRecipientList(false, page, sort, {
        bulletin_id_eq: bulletin_id,
        status_eq: filter,
        creator_full_name_cont: search,
      });

      const { bulletin_recipients: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  const handleEmailRecipients = async () => {
    setWaiting(true);

    try {
      // Track email sent event
      trackEvent(EVENTS.BULLETIN.EMAIL_SENT, {
        bulletin_id: bulletin.id,
        bulletin_name: bulletin.name,
        recipient_count: bulletin.recipients_count || 0,
      });

      await sendEmailToAllRecipients(bulletin.id);

      toast.success("Email sent to all recipients");
    } catch (e) {
      toast.error("Error sending email");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "bulletin_email_error",
        bulletin_id: bulletin.id,
        error_message: e?.message || "Error sending email",
      });
    } finally {
      setWaiting(false);
    }
  };

  const handleDownload = async () => {
    setDownloading(true);

    try {
      // Track recipients download event
      trackEvent(EVENTS.BULLETIN.RECIPIENTS_DOWNLOADED, {
        bulletin_id: bulletin_id,
        bulletin_name: bulletin?.name,
        format: "csv",
      });

      const response = await downloadRecipients(bulletin_id);

      downloadDataAsCSVFile(response.data, `bulletin_recipients.csv`);

      toast.success("Recipients exported to CSV");
    } catch (e) {
      toast.error("Failed to download CSV");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "bulletin_recipients_download_error",
        bulletin_id: bulletin_id,
        error_message: e?.message || "Failed to download CSV",
      });
    }

    setDownloading(false);
  };

  const handlePublish = async () => {
    setWaiting(true);

    const updates = {
      status: "active",
    };

    try {
      await updateBulletin(bulletin.id, updates);

      setBulletin({
        ...bulletin,
        ...updates,
      });

      setConfirmPublishModalVisible(false);

      toast.success("Bulletin published");
    } catch (e) {
      toast.error("Error publishing bulleting");
    } finally {
      setWaiting(false);
    }
  };

  const cardDropDownOptions = [
    can(Permission.DESTROY_BULLETINS) && { label: "Delete", value: "delete" },
  ].filter(Boolean);

  const isActive = bulletin?.status === "active";
  const { filter_history = [] } = bulletin || {};
  const currentFilter = filter_history[0]?.filters;

  const handleAddRecipientsClick = () => {
    // Track recipient add action
    trackEvent(EVENTS.BULLETIN.RECIPIENTS_ADDED, {
      bulletin_id: bulletin_id,
      bulletin_name: bulletin?.name,
      action: "add_recipients_started",
    });

    navigate(`/search?addToBulletinId=${bulletin_id}`);
  };

  const handleAddToGroupClick = () => {
    // Track add to group action
    trackEvent(EVENTS.BULLETIN.RECIPIENTS_ADDED_TO_GROUP, {
      bulletin_id: bulletin_id,
      bulletin_name: bulletin?.name,
      action: "add_to_group_started",
    });

    setShowAddToGroupModal(true);
  };

  return (
    <div className="w-full mt-[40px] z-10">
      <SinglePageLayoutWithData
        entityName="bulletin_recipient"
        pageTitle="Recipients"
        pageName="Bulletin Recipients"
        sortPathName="creator_full_name"
        sortPathDate="email_last_sent_at"
        filterOptions={filterOptions}
        filterPath="status"
        tableColumns={tableColumns}
        cardDropDownOptions={cardDropDownOptions}
        rowActions={cardDropDownOptions}
        asyncActions={actions}
        getItems={getBulletinRecipients}
        summaryContent={<div />}
        tableOnly
        headerButtons={
          <div className="flex gap-2 ml-4">
            {bulletin?.filter_history?.length > 0 && (
              <FButton
                onClick={() => setShowHistoryModal(true)}
                label="History"
                iconLeft={{
                  name: "filter",
                  size: 12,
                  color: "#000F45",
                  className: "mr-1",
                }}
              />
            )}
            <FButton
              loading={isDownloading}
              label="Download"
              onClick={handleDownload}
            />
            <FButton
              primary={true}
              onClick={handleAddRecipientsClick}
              label="Add Recipients"
              iconLeft={{
                name: "add",
                size: 12,
                color: "#fff",
                className: "mr-1",
              }}
              width="max-content"
            />
          </div>
        }
        customBlankState={
          <BlankState
            title="No Recipients"
            subtitle="Add recipients to the bulletin to get started."
            icon="user"
            onActionClick={handleAddRecipientsClick}
            actionLabel="Add Creators"
          />
        }
      />
      {showAddToGroupModal && (
        <AddRecipientsToBulletinModal
          onClose={() => setShowAddToGroupModal(false)}
        />
      )}
      {isConfirmPublishModalVisible ? (
        <ConfirmModal
          title="Publish bulletin"
          isOpen
          onClose={() => setConfirmPublishModalVisible(false)}
          onAction={handlePublish}
          actionLabel="Confirm"
          isWaiting={waiting}
          subtitle={`Are you sure you want to publish this bulletin?`}
        />
      ) : null}
      {showHistoryModal && (
        <Modal
          title="Filter History"
          isOpen
          onClose={() => setShowHistoryModal(false)}
          hasForm
          width={800}
        >
          <div className="flex flex-col gap-6 max-h-[600px] overflow-y-auto">
            {bulletin.filter_history.map((item, index) => (
              <div
                key={index}
                className="flex flex-col gap-2 p-4 border border-light_border rounded-xl"
              >
                <div className="flex justify-between items-center">
                  <span className="font-medium">{item.added_by}</span>
                  <span className="text-sm text-default_weak">
                    {moment(item.added_at).format("MMM DD, YYYY")}
                  </span>
                </div>
                <FilterSummary filters={item.filters} />
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
}
