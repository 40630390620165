import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import MultiPageLayout from "@layouts/MultiPageLayout";
import FButton from "@components/FButton";
import toast from "react-hot-toast";
import AmbassadorsList from "./AmbassadorsList";
import { AmbassadorsContext } from "@contexts/index";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { AMBASSADOR_REVIEW_STATUSES } from "./constants";
import { has } from "lodash";
import { formatNumber } from "@utils/number_utilities";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

const subNavItems = AMBASSADOR_REVIEW_STATUSES.map((status) => ({
  ...status,
  label: status.value === "review" ? "Applicants" : status.label,
  link: status.value,
}));

const AmbassadorsContent = () => {
  const { brand, overallTotals } = useContext(AmbassadorsContext);
  const { trackEvent } = useContext(MixpanelContext);

  const navigate = useNavigate();

  const { can } = useAuth();

  useEffect(() => {
    document.title = "Ambassadors";
  }, []);

  const handleShareLandingPage = () => {
    // Track the share action
    trackEvent(EVENTS.AMBASSADOR.PAGE_SHARED, {
      brand_id: brand?.id,
      brand_slug: brand?.slug,
      share_method: "clipboard",
      landing_page_url: `${window.location.host}/${brand.slug}/ambassador-onboarding`,
    });

    navigator.clipboard
      .writeText(`${window.location.host}/${brand.slug}/ambassador-onboarding`)
      .then(
        () => {
          toast.success("URL copied to clipboard!");

          // Track successful share
          trackEvent(EVENTS.AMBASSADOR.SHARE_COMPLETED, {
            brand_id: brand?.id,
            brand_slug: brand?.slug,
            share_method: "clipboard",
            success: true,
            landing_page_url: `${window.location.host}/${brand.slug}/ambassador-onboarding`,
          });
        },
        (err) => {
          toast.error("Failed to copy URL: ", err);

          // Track failed share
          trackEvent(EVENTS.ERROR.API_ERROR, {
            error_type: "clipboard_copy_error",
            error_message: err?.message || "Failed to copy URL",
            brand_id: brand?.id,
            brand_slug: brand?.slug,
            landing_page_url: `${window.location.host}/${brand.slug}/ambassador-onboarding`,
          });
        }
      );
  };

  const subNavItemsWithCounts = subNavItems.map((navItem) => {
    const { label, value } = navItem;

    const total = has(overallTotals, value) ? overallTotals[value] : null;

    return {
      ...navItem,
      label: total === null ? label : `${label} (${formatNumber(total)})`,
    };
  });

  return (
    <MultiPageLayout
      pageName="Ambassadors"
      subNavItems={subNavItemsWithCounts}
      headerButtons={
        <div className="flex flex-row gap-2">
          {brand?.amp_description ? (
            <>
              {can(Permission.CREATE_AMBASSADORS_ONBOARDING_SETTINGS) ? (
                <FButton
                  iconLeft={{ name: "settings", size: 20, color: "black" }}
                  label="Settings"
                  onClick={() => {
                    navigate("/ambassadors/onboarding-settings");
                  }}
                />
              ) : null}
              <FButton
                primary
                label="Share Your Page"
                width="w-[140px]"
                onClick={handleShareLandingPage}
              />
            </>
          ) : null}
        </div>
      }
    >
      <div className="pt-[40px]">
        <Routes>
          <Route index element={<Navigate replace to="review" />} />
          {subNavItems.map((item) => (
            <Route
              key={item.value}
              path={`${item.value}/:ambassador_id?`}
              element={<AmbassadorsList key={item.value} status={item.value} />}
            />
          ))}
        </Routes>
      </div>
    </MultiPageLayout>
  );
};

export default AmbassadorsContent;
