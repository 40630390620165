import ProfileAudienceDemographics from "./audience/ProfileAudienceDemographics";
import ProfileBasics from "./Basics";
import ProfileContent from "./content";
import ProfileDetails from "./ProfileDetails";
import ProfileTestimonials from "./ProfileTestimonials";
import ProfileStatsPage from "./stats/ProfileStats";

const PROFILE_ROUTES = [
  {
    label: "Basics",
    value: "basics",
    component: ProfileBasics,
  },
  {
    label: "Content",
    value: "content",
    component: ProfileContent,
  },

  { label: "Stats", value: "stats", component: ProfileStatsPage },
  {
    label: "Audience",
    value: "audience_demographics",
    component: ProfileAudienceDemographics,
  },
  {
    label: "Details",
    value: "details",
    component: ProfileDetails,
  },
  {
    label: "Testimonials",
    value: "testimonials",
    link: `testimonials`,
    component: ProfileTestimonials,
  },
];

export default PROFILE_ROUTES;
