import React, { useContext, useEffect, useState } from "react";

import { DragDropContext, Draggable } from "react-beautiful-dnd";

import QuestionFormModal from "./QuestionFormModal";
import {
  QuestionnaireQuestion,
  QuestionnaireQuestionRequest,
  FieldType,
} from "types/questionnaire";
import QuestionCard from "./QuestionCard";
import Heading from "@components/Heading";
import FButton from "@components/FButton";
import LoadingSpinner from "@components/LoadingSpinner";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

import toast from "react-hot-toast";
import ConfirmModal from "@components/Modals/ConfirmModal";

import Droppable from "@components/drag-and-drop/Droppable";
import { getItemStyle, getListStyle, reorder } from "@components/drag-and-drop";
import useAuth from "@hooks/useAuth";
import {
  getQuestionsWithChildren,
  QuestionnaireQuestionWithChildren,
} from "@utils/questions";
import { ApiResponse } from "@apiTypes";
import BlankState from "@components/BlankState";
import { sortBy } from "lodash";

const defaultQuestion = {
  question: "",
  required: true,
  position: 1,
  data: {
    options: [],
  },
  field_type: FieldType.SINGLE_SELECT,
};

interface ManageQuestionsProps {
  entityName: string;
  listQuestions: (params: object) => Promise<QuestionnaireQuestion[]>;
  createQuestion: (
    question: QuestionnaireQuestionRequest
  ) => Promise<ApiResponse<QuestionnaireQuestion>>;
  updateQuestion: (
    id: number,
    updates: Partial<QuestionnaireQuestionRequest>
  ) => Promise<ApiResponse<QuestionnaireQuestion>>;
  deleteQuestion: (id: number) => Promise<ApiResponse<any>>;
  hideChildQuestions?: boolean;
  hideVisibilitySettings?: boolean;
  updatedQuestionEventName: string;
  updatedSettingsEventName?: string;
}

const ManageQuestions = ({
  entityName,
  listQuestions,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  hideChildQuestions,
  hideVisibilitySettings,
  updatedQuestionEventName,
  updatedSettingsEventName,
}: ManageQuestionsProps) => {
  const [questions, setQuestions] = useState<
    QuestionnaireQuestionWithChildren[]
  >([]);

  const { user } = useAuth();
  const { trackEvent } = useContext(MixpanelContext);

  const [questionToDelete, setQuestionToDelete] =
    useState<QuestionnaireQuestion>(null);

  const [isDeleting, setDeleting] = useState(false);

  const [waiting, setWaiting] = useState(false);
  const [hasOrderChanges, setHasOrderChanges] = useState(false);
  const [isSavingOrdering, setIsSavingOrdering] = useState(false);

  const [activeQuestion, setActiveQuestion] =
    useState<QuestionnaireQuestion>(null);

  const [isLoading, setIsLoading] = useState(true);

  const handleEditQuestion = (question: QuestionnaireQuestion) => {
    trackEvent(updatedQuestionEventName, {
      brand_id: user?.brand_id,
      question_id: question.id,
      action: "edit_started",
      question_type: question.field_type,
    });

    setActiveQuestion(question);
  };

  const handleAddQuestion = (parentQuestionId?: number) => {
    trackEvent(updatedQuestionEventName, {
      brand_id: user?.brand_id,
      action: "add_started",
      has_parent: !!parentQuestionId,
      parent_question_id: parentQuestionId || null,
    });

    setActiveQuestion({
      ...defaultQuestion,
      ...(parentQuestionId ? { question_id: parentQuestionId } : {}),
    });
  };

  const handleCloseQuestionModal = () => {
    setActiveQuestion(null);
  };

  useEffect(() => {
    const loadQuestions = async () => {
      setIsLoading(true);

      try {
        const questions = await listQuestions({
          q: { brand_id_eq: user.brand_id },
        });
        setQuestions(getQuestionsWithChildren(questions));
      } finally {
        setIsLoading(false);
      }
    };

    loadQuestions();
  }, []);

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      order: index,
    }));

    setQuestions(
      newQuestions.map((question, index) => ({
        ...question,
        position: index + 1,
      }))
    );

    setHasOrderChanges(true);
  };

  const handleSaveOrdering = async () => {
    setIsSavingOrdering(true);

    trackEvent(updatedSettingsEventName, {
      brand_id: user?.brand_id,
      settings_section: "questions",
      update_type: "reorder",
      question_count: questions.length,
    });

    try {
      for (const question of questions) {
        await updateQuestion(question.id, {
          position: question.position,
        });
      }

      toast.success("Order saved");

      trackEvent(updatedQuestionEventName, {
        brand_id: user?.brand_id,
        action: "reorder_completed",
        success: true,
        question_count: questions.length,
      });
    } catch (error) {
      toast.error("Failed to save order");

      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "question_reorder_error",
        brand_id: user?.brand_id,
        error_message: error?.message || "Failed to save question order",
      });
    } finally {
      setIsSavingOrdering(false);
      setHasOrderChanges(false);
    }
  };

  const handleSave = async (newQuestion: QuestionnaireQuestionRequest) => {
    setWaiting(true);

    const isNewQuestion = !newQuestion.id;

    trackEvent(updatedQuestionEventName, {
      brand_id: user?.brand_id,
      action: isNewQuestion ? "create" : "update",
      question_id: newQuestion.id || null,
      question_type: newQuestion.field_type,
      is_required: newQuestion.required,
      has_parent: !!newQuestion.question_id,
    });

    try {
      if (isNewQuestion) {
        const response = await createQuestion({
          ...newQuestion,
          brand_id: user.brand_id,
        });

        const createdQuestion = response.data;

        const parentQuestionId = newQuestion.question_id;

        if (parentQuestionId) {
          const newQuestions = questions.map((question) => {
            if (question.id !== parentQuestionId) {
              return question;
            }

            const { children = {} } = question;

            const { conditional_answer } = newQuestion;

            const questionsForAnswer = children[conditional_answer] || [];

            return {
              ...question,
              children: {
                ...children,
                [conditional_answer]: [...questionsForAnswer, createdQuestion],
              },
            };
          });

          setQuestions(newQuestions);

          trackEvent(updatedSettingsEventName, {
            brand_id: user?.brand_id,
            settings_section: "questions",
            update_type: "create",
            success: true,
            question_type: newQuestion.field_type,
          });
        } else {
          setQuestions(sortBy([createdQuestion, ...questions], "position"));

          trackEvent(updatedSettingsEventName, {
            brand_id: user?.brand_id,
            settings_section: "questions",
            update_type: "create",
            success: true,
            question_type: newQuestion.field_type,
          });
        }
      } else {
        const response = await updateQuestion(
          newQuestion.id,
          newQuestion as QuestionnaireQuestionRequest
        );

        const updatedQuestion = response.data;

        const { conditional_answer, question_id: parentQuestionId } =
          updatedQuestion;

        const newQuestions = questions.map((question) => {
          if (parentQuestionId === question.id) {
            const oldConditionalAnswer = activeQuestion.conditional_answer;

            const questionsForAnswer =
              question.children[conditional_answer] || [];

            if (conditional_answer === oldConditionalAnswer) {
              const updatedQuestions = questionsForAnswer.map((question) => {
                if (question.id === updatedQuestion.id) {
                  return updatedQuestion;
                }

                return question;
              });

              return {
                ...question,
                children: {
                  ...question.children,
                  [conditional_answer]: updatedQuestions,
                },
              };
            } else {
              const questionsForOldConditionalAnswer =
                question.children[oldConditionalAnswer] || [];

              return {
                ...question,
                children: {
                  ...question.children,
                  [conditional_answer]: [
                    ...questionsForAnswer,
                    updatedQuestion,
                  ],
                  [oldConditionalAnswer]:
                    questionsForOldConditionalAnswer.filter(
                      (question) => question.id !== updatedQuestion.id
                    ),
                },
              };
            }
          }

          return question.id === updatedQuestion.id
            ? { ...question, ...updatedQuestion }
            : question;
        });

        setQuestions(newQuestions);

        trackEvent(updatedSettingsEventName, {
          brand_id: user?.brand_id,
          settings_section: "questions",
          update_type: "update",
          success: true,
          question_id: newQuestion.id,
          question_type: newQuestion.field_type,
        });
      }

      toast.success("Question created");
    } catch (error) {
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "question_save_error",
        brand_id: user?.brand_id,
        action: isNewQuestion ? "create" : "update",
        question_id: newQuestion.id || null,
        error_message: error?.message || "Failed to save question",
      });
    } finally {
      setActiveQuestion(null);

      setWaiting(false);
    }
  };

  const handleDelete = async () => {
    alert("handling delete!");
    setDeleting(true);

    try {
      await deleteQuestion(questionToDelete.id);

      setQuestionToDelete(null);

      if (questionToDelete.question_id) {
        setQuestions(
          questions.map((question) => {
            if (question.id !== questionToDelete.question_id) {
              return question;
            }

            const { conditional_answer } = questionToDelete;

            const questionsForConditionalAnswer =
              question.children[conditional_answer];

            const newQuestions = questionsForConditionalAnswer.filter(
              (question) => question.id !== questionToDelete.id
            );

            return {
              ...question,
              children: {
                ...question.children,
                [conditional_answer]: newQuestions,
              },
            };
          })
        );
      } else {
        setQuestions(
          questions.filter((question) => question.id !== questionToDelete.id)
        );
      }
    } catch (e) {
      toast.error("Error deleting question");
    } finally {
      setDeleting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[400px]">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="flex items-center justify-between">
          <Heading
            titleClass="!text-2xl"
            title={`Questions (${questions.length})`}
          />
          <div className="flex gap-4 items-center">
            {hasOrderChanges ? (
              <FButton
                loading={isSavingOrdering}
                onClick={handleSaveOrdering}
                label="Save ordering"
              />
            ) : null}
            <FButton
              primary={true}
              onClick={() => handleAddQuestion()}
              label="Add question"
              type="button"
              iconLeft={{
                name: "add",
                size: 12,
                color: "#fff",
                className: "mr-2",
              }}
            />
          </div>
        </div>
        {questions.length === 0 ? (
          <BlankState
            title="No questions found"
            subtitle="Add a question to get started"
            icon="question"
            onActionClick={() => handleAddQuestion()}
            actionLabel="Add question"
          />
        ) : (
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <div className="flex flex-col gap-8 mt-8 pb-16">
                  {questions.map((question, index) => (
                    <Draggable
                      key={`key-${question.id}`}
                      draggableId={`ID-${question.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div {...provided.dragHandleProps}>
                            <QuestionCard
                              question={question}
                              key={question.id}
                              onDelete={(question) =>
                                setQuestionToDelete(question)
                              }
                              onEdit={handleEditQuestion}
                              hideChildQuestions={hideChildQuestions}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
      {activeQuestion && (
        <QuestionFormModal
          onClose={handleCloseQuestionModal}
          entityName={entityName}
          question={activeQuestion}
          questions={questions}
          onSave={handleSave}
          waiting={waiting}
          hideVisibilitySettings={hideVisibilitySettings}
        />
      )}

      {questionToDelete && (
        <ConfirmModal
          title="Delete question"
          isOpen
          onClose={() => setQuestionToDelete(null)}
          onAction={handleDelete}
          actionLabel="Delete"
          isWaiting={isDeleting}
          subtitle="Are you sure you want to delete this question? This action cannot be undone."
        />
      )}
    </>
  );
};

export default ManageQuestions;
