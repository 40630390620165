import React, { useEffect, useState } from "react";
import {
  getUserNotificationList,
  updateUserNotification,
} from "@api/Notifications/UserNotifications";
import { UserNotification, UserNotificationStatus } from "@types";
import { groupBy } from "lodash";
import NotificationGroup from "./NotificationGroup";
import BlankState from "@components/BlankState";
import NotificationSkeleton from "./NotificationSkeleton";
import useAuth from "@hooks/useAuth";

interface NotificationSnapshotProps {
  maxNotifications?: number;
}

interface NotificationKey {
  participant_id: number;
  campaign_id: number;
  category: string;
  platform?: string;
  details: string;
}

function getNotificationKey(notification: UserNotification): string {
  // Create a unique key based on relevant notification properties
  const key: NotificationKey = {
    participant_id: notification.participant?.id,
    campaign_id: notification.campaign_id,
    category: notification.category,
    platform: notification.platform,
    details: notification.details,
  };
  return JSON.stringify(key);
}

export default function NotificationSnapshot({
  maxNotifications = 4,
}: NotificationSnapshotProps) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await getUserNotificationList(1, {
          status_eq: UserNotificationStatus.UNREAD,
          per_page: maxNotifications * 3, // Fetch more to account for duplicates
        });

        const allNotifications = response.data.user_notifications;

        // Filter notifications for user's current brand
        const filteredNotifications = allNotifications.filter(
          (notification) => notification.brand_id === user.brand_id
        );

        // Group notifications by their unique key
        const notificationGroups = new Map<string, UserNotification[]>();

        filteredNotifications.forEach((notification) => {
          const key = getNotificationKey(notification);
          if (!notificationGroups.has(key)) {
            notificationGroups.set(key, []);
          }
          notificationGroups.get(key)!.push(notification);
        });

        // For each group, keep the latest and mark others as read
        const latestNotifications: UserNotification[] = [];
        const markAsReadPromises: Promise<any>[] = [];

        notificationGroups.forEach((group) => {
          // Sort by created_at descending
          const sorted = group.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );

          // Keep the latest
          latestNotifications.push(sorted[0]);

          // Mark others as read
          const oldNotifications = sorted.slice(1);
          if (oldNotifications.length > 0) {
            oldNotifications.forEach((notification) => {
              markAsReadPromises.push(
                updateUserNotification(notification.id, {
                  status: UserNotificationStatus.READ,
                })
              );
            });
          }
        });

        // Execute all mark-as-read operations
        if (markAsReadPromises.length > 0) {
          await Promise.all(markAsReadPromises);
        }

        // Sort final notifications by date and limit to maxNotifications
        const finalNotifications = latestNotifications
          .sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .slice(0, maxNotifications);

        setNotifications(finalNotifications);
      } catch (err) {
        console.error("Error fetching notifications:", err);
        setError("Failed to load notifications");
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [maxNotifications]);

  const handleNotificationUpdate = (notificationId: number) => {
    // Remove the notification from the state
    setNotifications((currentNotifications) =>
      currentNotifications.filter(
        (notification) => notification.id !== notificationId
      )
    );
  };

  if (loading) {
    return <NotificationSkeleton />;
  }

  if (error) {
    return null; // Don't show errors on dashboard
  }

  if (!loading && notifications.length === 0) {
    return (
      <div className="pr-4">
        <h2 className="text-xl font-medium">Notifications</h2>
        <BlankState
          title="All caught up!"
          subtitle="You have no unread notifications"
          icon="clock"
          className="!mt-6 shadow-sm"
        />
      </div>
    );
  }

  const groupedNotifications = groupBy(notifications, "category");

  return (
    <div className="md:max-w-full lg:max-w-none">
      <div className="flex justify-between items-center mb-6 md:pr-4">
        <h2 className="text-xl font-medium">Notifications</h2>
        <span className="text-sm text-neutral_500">
          {notifications.length} unread
        </span>
      </div>
      <div className="space-y-6 pr-2 md:pr-4">
        {Object.entries(groupedNotifications).map(
          ([category, categoryNotifications]) => (
            <NotificationGroup
              key={category}
              category={category}
              notifications={categoryNotifications}
              onNotificationUpdate={handleNotificationUpdate}
            />
          )
        )}
      </div>
    </div>
  );
}
