import mixpanel from "mixpanel-browser";

export const startWorkflowTimer = (workflowName, properties = {}) => {
  mixpanel.time_event(`${workflowName} Completed`);
  mixpanel.track(`${workflowName} Started`, properties);
};

export const completeWorkflowTimer = (workflowName, properties = {}) => {
  mixpanel.track(`${workflowName} Completed`, properties);
};

export const trackStepInWorkflow = (
  workflowName,
  stepName,
  properties = {}
) => {
  mixpanel.track(`${workflowName} Step`, {
    step_name: stepName,
    ...properties,
  });
};
