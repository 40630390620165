import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { getCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import UploadDeliverableContentModal from "@pages/campaigns/brief/components/UploadDeliverableContentModal";
import {
  ContentReviewAsset,
  ContentReviewContent,
  ContentReviewDeliverable,
  ParticipantContentReview,
} from "@types";
import CmsBriefDeliverablePost from "@components/Campaign/CMS/DeliverablePost";
import LoadingSpinner from "@components/LoadingSpinner";
import FDropdown from "@components/FDropdown";
import BlankState from "@components/BlankState";

import ResultsGrid from "@components/ResultsGrid";
import FButton from "@components/FButton";

import {
  deleteContentReviewAsset,
  updateContentStatus,
} from "@api/creator_view";
import toast from "react-hot-toast";
import { CONTENT_REVIEW_STATUS } from "@pages/campaigns/content-review/statuses";
import ContentReviewStatusBadge from "@components/ContentReview/StatusBadge";
import FIcon from "@components/FIcon";
import ContentRejectedAlert from "../../profile/content-review/ContentRejectedAlert";
import UpdateUploadedAssetModal from "../../profile/content-review/UpdateUploadedAssetModal";
import { sortBy } from "lodash";
import { ParticipantRecord } from "types/creator-view";

import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";
import { trackStepInWorkflow } from "@utils/mixpanel_time";

const { ADMIN_CHANGES, ADMIN_REVIEW, APPROVED, INFLUENCER_CHANGES } =
  CONTENT_REVIEW_STATUS;

interface ProfileContentReviewProps {
  participantRecord: ParticipantRecord;
}

export default function CreatorContentReview({
  participantRecord,
}: ProfileContentReviewProps) {
  const { participant_id } = useParams();

  const [isSubmittingForReview, setSubmittingForReview] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedRounds, setSelectedRounds] = useState({});

  const [participantContentReview, setParticipantContentReview] =
    useState<ParticipantContentReview>(null);

  const [uploadingContentForDeliverable, setUploadingContentForDeliverable] =
    useState<ContentReviewDeliverable>(null);

  const [uploadingAssets, setUploadingAssets] = useState(false);

  const { deliverables = [] } = participantContentReview || {};

  const [assetToUpdate, setAssetToUpdate] = useState<ContentReviewAsset>(null);

  const { hasRole } = useAuth();
  const { trackEvent } = useMixpanelTracking();

  useEffect(() => {
    const initialize = (participant: ParticipantRecord) => {
      const {
        content_review = { deliverables: [] },
        creator = {},
        deliverables: participantDeliverables,
        campaign,
      } = participant;

      setParticipantContentReview({
        ...content_review,
        creator,
        deliverables: content_review.deliverables.map((deliverable) => {
          const participantDeliverable = participantDeliverables.find(
            ({ content_review_id }) => content_review_id === deliverable.id
          );

          if (participantDeliverable) {
            const { shorturl } = participantDeliverable;

            return {
              ...deliverable,
              shorturl,
            };
          }

          return deliverable;
        }),
      });

      // Track content review page view
      if (campaign) {
        trackEvent(EVENTS.PAGE_VIEW.CONTENT_SUBMISSION, {
          campaign_id: campaign.id,
          campaign_name: campaign.title,
          participant_id: participant_id,
          deliverables_count: participantDeliverables?.length || 0,
        });
      }
    };

    const loadParticipantForBrandView = async () => {
      setLoading(true);

      try {
        const participantResponse =
          await getCampaignParticipant(participant_id);
        initialize(participantResponse.data);
      } catch (error) {
        toast.error("Error loading content review data");
        trackEvent("Error", {
          error_type: "Content Review Load Error",
          participant_id: participant_id,
          error_message: error?.message || "Unknown error",
        });
      } finally {
        setLoading(false);
      }
    };

    if (hasRole(Role.INFLUENCER)) {
      initialize(participantRecord);
    } else {
      loadParticipantForBrandView();
    }
  }, []);

  const handleUpdateDeliverable = (
    deliverableId: number,
    content: ContentReviewContent
  ) => {
    const newDeliverables = deliverables.map((deliverable) => {
      if (deliverable.id === deliverableId) {
        return { ...deliverable, content };
      }

      return deliverable;
    });

    setParticipantContentReview({
      ...participantContentReview,
      deliverables: newDeliverables,
    });
  };

  const handleUploadNewRoundOfContent = (deliverable) => {
    // Track starting a new round of content upload
    trackEvent(EVENTS.WORKFLOW.START, {
      workflow_name: "Content Upload",
      campaign_id: participantRecord?.campaign?.id,
      campaign_name: participantRecord?.campaign?.title,
      participant_id: participant_id,
      deliverable_id: deliverable.id,
      deliverable_type: deliverable.deliverable_type,
    });

    setUploadingContentForDeliverable(deliverable);
  };

  const handleSubmitForReview = async (content: ContentReviewContent) => {
    setSubmittingForReview(true);

    try {
      await updateContentStatus(content.id, ADMIN_REVIEW);

      handleUpdateDeliverable(content.deliverable_id, {
        ...content,
        status: ADMIN_REVIEW,
      });

      // Track content submission event
      trackEvent(EVENTS.CAMPAIGN.CONTENT_SUBMITTED, {
        campaign_id: participantRecord?.campaign?.id,
        campaign_name: participantRecord?.campaign?.title,
        participant_id: participant_id,
        content_id: content.id,
        deliverable_id: content.deliverable_id,
      });

      // Track workflow step completion
      trackStepInWorkflow("Campaign Content", "Submitted for Review", {
        campaign_id: participantRecord?.campaign?.id,
        campaign_name: participantRecord?.campaign?.title,
        participant_id: participant_id,
        content_id: content.id,
      });

      toast.success("Content submitted for review");
    } catch (error) {
      toast.error("Error submitting content for review");

      // Track failure
      trackEvent("Error", {
        error_type: "Content Submission Error",
        campaign_id: participantRecord?.campaign?.id,
        participant_id: participant_id,
        content_id: content.id,
        error_message: error?.message || "Unknown error",
      });
    } finally {
      setSubmittingForReview(false);
    }
  };

  const handleDeleteAsset = async (
    assetId: number,
    roundId: number,
    deliverableId: number
  ) => {
    try {
      await deleteContentReviewAsset(assetId);

      const { content } = deliverables.find(({ id }) => id === deliverableId);
      const { rounds } = content;
      const newRounds = rounds.map((round) => {
        if (round.id !== roundId) {
          return round;
        }

        const { assets = [] } = round;
        const newAssets = assets.filter((asset) => asset.id !== assetId);

        return {
          ...round,
          assets: newAssets,
        };
      });

      const newContent = {
        ...content,
        rounds: newRounds,
      };

      handleUpdateDeliverable(deliverableId, newContent);

      // Track asset deletion
      trackEvent("Content Asset Deleted", {
        campaign_id: participantRecord?.campaign?.id,
        participant_id: participant_id,
        asset_id: assetId,
        deliverable_id: deliverableId,
      });
    } catch (error) {
      toast.error("Error deleting asset");

      // Track failure
      trackEvent("Error", {
        error_type: "Asset Deletion Error",
        campaign_id: participantRecord?.campaign?.id,
        participant_id: participant_id,
        asset_id: assetId,
        error_message: error?.message || "Unknown error",
      });
    }
  };

  const handleAssetUpdated = (asset: ContentReviewAsset, updates) => {
    const { deliverable_id } = asset;

    const { content } = deliverables.find(({ id }) => id === deliverable_id);
    const { rounds } = content;
    const newRounds = rounds.map((round) => {
      const { assets = [] } = round;
      const oldAsset = assets.find(({ id }) => id === asset.id);

      if (!oldAsset) {
        return round;
      }

      return {
        ...round,
        assets: assets.map((oldAsset) => {
          if (oldAsset.id === asset.id) {
            return {
              ...oldAsset,
              ...updates,
            };
          }

          return oldAsset;
        }),
      };
    });

    const newContent = {
      ...content,
      rounds: newRounds,
    };

    handleUpdateDeliverable(deliverable_id, newContent);

    // Track asset update
    trackEvent("Content Asset Updated", {
      campaign_id: participantRecord?.campaign?.id,
      participant_id: participant_id,
      asset_id: asset.id,
      deliverable_id: asset.deliverable_id,
      update_type: Object.keys(updates).join(","),
    });
  };

  if (loading) {
    return <LoadingSpinner className="mt-[200px]" />;
  }

  return (
    <div
      className={`pt-[52px] pb-8 ${hasRole(Role.INFLUENCER) ? "" : "pr-[40px]"}`}
    >
      <h2 className="mb-4 text-2xl leading-[32px] text-dark">Deliverables</h2>
      <div className="flex flex-col relative gap-12">
        {deliverables
          ?.sort(
            (a, b) =>
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
          )
          .map((deliverable, index) => {
            const { content } = deliverable;

            const { rounds: unorderedRounds = [], status: contentStatus } =
              content || {};

            const rounds = sortBy(unorderedRounds, "stage");

            const selectedRound =
              selectedRounds[deliverable.id] || rounds.length;

            const round = rounds[selectedRound - 1];

            const status =
              rounds.length > 1 && selectedRound < rounds.length
                ? INFLUENCER_CHANGES
                : contentStatus;

            const isRejected = status === INFLUENCER_CHANGES;

            const isOldRound =
              rounds.length > 1 && selectedRound < rounds.length;

            const isEditable = ![
              APPROVED,
              ADMIN_CHANGES,
              INFLUENCER_CHANGES,
            ].includes(status as CONTENT_REVIEW_STATUS);

            const { assets = [] } = round || {};

            return (
              <div
                className={`${index !== deliverables.length - 1 ? "border-b" : ""} border-default_weak pb-16`}
                key={index}
              >
                <CmsBriefDeliverablePost
                  deliverable={deliverable}
                  key={index}
                  isReadOnly
                />
                {isRejected ? (
                  <ContentRejectedAlert
                    round={round}
                    onAction={
                      isOldRound
                        ? null
                        : () => handleUploadNewRoundOfContent(deliverable)
                    }
                  />
                ) : null}
                {rounds.length > 0 ? (
                  <div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <h2 className="text-xl leading-[32px] text-dark">
                          Uploaded content
                        </h2>
                        <ContentReviewStatusBadge
                          status={
                            status === ADMIN_CHANGES ? ADMIN_REVIEW : status
                          }
                        />
                      </div>
                      <div className="flex items-center gap-4">
                        <FDropdown
                          options={rounds.map((round, index) => ({
                            value: index + 1,
                            label: `Round ${index + 1}`,
                          }))}
                          selectedValue={selectedRound}
                          onChange={(value) =>
                            setSelectedRounds({
                              ...selectedRounds,
                              [deliverable.id]: value,
                            })
                          }
                        />
                        {status === CONTENT_REVIEW_STATUS.NOT_SUBMITTED ? (
                          <FButton
                            loading={isSubmittingForReview}
                            onClick={() => handleSubmitForReview(content)}
                            primary
                            label="Submit for Review"
                          />
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="cursor-pointer flex items-center gap-2 mt-4"
                      onClick={
                        isEditable
                          ? () => {
                              setUploadingContentForDeliverable(deliverable);
                              setUploadingAssets(false);
                            }
                          : null
                      }
                    >
                      <h2 className="text-lg leading-[32px] text-dark">
                        {deliverable?.content?.caption}
                      </h2>
                      {isEditable ? (
                        <FIcon color="#000F45" icon="edit" size={14} />
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {content ? (
                  <ResultsGrid
                    cardData={assets.map((asset) => ({
                      ...content,
                      id: asset.id,
                      deliverable,
                      participant: {},
                      campaignParticipant: participantContentReview,
                      rounds: [{ assets: [asset], id: round.id }],
                    }))}
                    cardType="contentReview"
                    onDelete={(assetId, roundId) => {
                      handleDeleteAsset(assetId, roundId, deliverable.id);
                    }}
                    onAddNew={
                      isEditable
                        ? () => {
                            setUploadingContentForDeliverable(deliverable);

                            if (deliverable.content.caption) {
                              setUploadingAssets(true);
                            }
                          }
                        : null
                    }
                    onCardClick={(asset) => setAssetToUpdate(asset)}
                    addNewLabel={
                      assets.length > 0 ? "Upload more" : "Upload content"
                    }
                  />
                ) : (
                  <BlankState
                    title="No Content Uploaded"
                    subtitle=""
                    icon={"warning-2"}
                    onActionClick={() =>
                      setUploadingContentForDeliverable(deliverable)
                    }
                    actionLabel="Upload content"
                  />
                )}
              </div>
            );
          })}
      </div>
      {uploadingContentForDeliverable ? (
        <UploadDeliverableContentModal
          isUploadingAssets={uploadingAssets}
          deliverable={uploadingContentForDeliverable}
          onClose={() => setUploadingContentForDeliverable(null)}
          onContentUpdated={(content) => {
            handleUpdateDeliverable(uploadingContentForDeliverable.id, content);

            if (content.rounds.length > 1) {
              setSelectedRounds({
                ...selectedRounds,
                [uploadingContentForDeliverable.id]: content.rounds.length,
              });
            }

            setUploadingContentForDeliverable(null);
          }}
        />
      ) : null}
      {assetToUpdate ? (
        <UpdateUploadedAssetModal
          asset={assetToUpdate}
          onClose={() => setAssetToUpdate(null)}
          onUpdate={(updates) => {
            handleAssetUpdated(assetToUpdate, updates);

            setAssetToUpdate(null);
          }}
        />
      ) : null}
    </div>
  );
}
