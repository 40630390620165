import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  Admin,
  BriefPreviewPage,
  Bulletins,
  BulletinsProfile,
  BulletinRecipientListPage,
  DirectoryDashboard,
  ForgotPassword,
  Groups,
  Login,
  OutreachList,
  OutreachProfile,
  OutreachMessage,
  OutreachRecipients,
  OutreachTemplateList,
  Stats,
  CampaignDeliverables,
  CampaignProducts,
  CampaignProfile,
  CampaignParticipants,
  CampaignReports,
  GroupsProfile,
  GiftingTableList,
  GiftingTable,
  Notifications,
  ParticipantProfile,
  CampaignBrief,
  SignUp,
  ContractTemplates,
  ReportsList,
  ContractTemplate,
  ParticipantBriefs,
  BrandsAdmin,
  BrandAdmin,
  BulletinResponseListPage,
} from "./pages";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import SignedIn from "@components/SignedIn";
import {
  CollaboratorsWrapper,
  CampaignCollaboratorsList,
  OutreachCollaboratorsList,
  BulletinCollaboratorsList,
  OffPlatformCollaboratorsList,
} from "@pages/collaborators/index";

import { UserProvider } from "./hooks/UserProvider";
import { MixpanelProvider } from "@hooks/MixpanelProvider";
import NotificationSettings from "./pages/notifications/NotificationSettings";
import {
  ProfileSettings,
  BrandSettings,
  PrivacySettings,
  CreatorEdit,
  CreatorSettings,
  CreatorPrivacySettings,
  SettingsWrapper,
  SubscriptionSettings,
  SubscriptionSuccess,
  SubscriptionBilling,
} from "@pages/settings/index";
import EntityAdminLayout from "@layouts/EntityAdminLayout";
import ResetPassword from "@pages/auth/ResetPassword";

import CampaignContentReview from "@pages/campaigns/content-review";
import AmbassadorQuestions from "@pages/ambassadors/ambassador-questions";

import "./components/highcharts";

import SignUpInfluencers from "@pages/auth/influencer-onboarding/SignUpInfluencers";
import ConnectSocials from "@pages/auth/influencer-onboarding/ConnectSocials";
import ConfirmDetails from "@pages/auth/influencer-onboarding/ConfirmDetails";
import SignUpBrands from "@pages/auth/brand-onboarding/SignUpBrands";
import OutreachTemplate from "@pages/outreach/OutreachTemplate";
import CampaignsAdmin from "@pages/admin/pages/CampaignsAdmin";
import CampaignOverview from "@pages/campaigns/CampaignOverview";
import BulletinsAdmin from "@pages/admin/pages/BulletinsAdmin";
import CreatorsAdmin from "@pages/admin/pages/CreatorsAdmin";
import GroupsAdmin from "@pages/admin/pages/GroupsAdmin";
import OutreachAdmin from "@pages/admin/pages/OutreachAdmin";
import ReportsAdmin from "@pages/admin/pages/ReportsAdmin";

import Ambassadors from "@pages/ambassadors";

import AnswerQuestions from "@pages/ambassadors/onboarding/AnswerQuestions";
import AmbassadorOnboardingLayout from "@layouts/AmbassadorOnboardingLayout";
import CreateOffPlatformCreator from "@pages/off_platform/CreateOffPlatformCreator";
import Profile from "@pages/profile";
import ParticipantReview from "@pages/campaigns/participant-review";
import Search from "@pages/search/searchPage";

import Home from "@pages/dashboards/Home";
import DashboardWrap from "@pages/dashboards/DashboardWrap";
import Content from "@pages/dashboards/Content";

import {
  ReportOverview,
  ReportContent,
  ReportCreators,
  ReportWrap,
} from "@pages/reports/index";
import RevenueRecognitionReport from "@pages/reports/RevenueRecognitionReport";
import BulletinQuestions from "@pages/bulletins/BulletinQuestions";
import AccountsAdmin from "@pages/admin/pages/accounts";
import AccountPage from "@pages/admin/pages/accounts/AccountPage";
import CampaignFinanceApprovalPage from "@pages/campaigns/CampaignFinanceReviewPage";
import ApplicationSubmitted from "@pages/ambassadors/onboarding/ApplicationSubmitted";
import NewTestimonial from "@pages/testimonials/newTestimonial";
import ScrollToTop from "./components/ScrollToTop";
import ContentReview from "@pages/campaigns/content-review/ContentReview";
import ContentAttribution from "@pages/campaigns/content-review/ContentAttribution";
import AttributedContent from "@pages/campaigns/content-review/AttributedContent";
import BonusContent from "@pages/campaigns/content-review/BonusContent";
import CampaignsPage from "@pages/campaigns/CampaignsPage";
import CreatorCampaignPage from "@pages/campaigns/creator-view/CreatorCampaignPage";
import UpgradeMembership from "@pages/UpgradeMembership";
import AddPersonalInfoPage from "@pages/auth/influencer-onboarding/AddPersonalInfoPage";
import SocialConnectionsSettings from "@pages/settings/SocialConnectionsSettings";
import NotFound from "@pages/not-found";
import BulletinSetup from "@pages/bulletins/setup/BulletinSetup";
import BulletinSetupContent from "@pages/bulletins/setup/BulletinSetupContent";

import AutomatedEmail from "@pages/bulletins/setup/AutomatedEmail";
import BulletinPreviewPage from "@pages/bulletins/preview/BulletinPreviewPage";
import BulletinAnswerQuestionsPage from "@pages/bulletins/preview/BulletinAnswerQuestionsPage";
import BulletinPreviewContainer from "@pages/bulletins/preview/components/BulletinPreviewContainer";
import FinanceAdmin from "@pages/admin/pages/FinanceAdmin";

export const App = () => {
  return (
    <UserProvider>
      <MixpanelProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* signed out users */}
            <Route element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="/signup/brand" element={<SignUpBrands />} />
              <Route path="/signup/influencer" element="">
                <Route index element={<SignUpInfluencers />} />
                <Route path="connect-socials" element={<ConnectSocials />} />
                <Route
                  path="add-personal-info"
                  element={<AddPersonalInfoPage />}
                />
                <Route path="confirm-details" element={<ConfirmDetails />} />
              </Route>
              <Route path="signup" element={<SignUp />} />
              <Route path="sign_up" element={<SignUpInfluencers />} />
              <Route path="sign_up_brand" element={<SignUpBrands />} />
              <Route path="forgot_password" element={<ForgotPassword />} />
              <Route path="reset_password" element={<ResetPassword />} />
            </Route>
            <Route element={<AmbassadorOnboardingLayout />}>
              <Route path=":brand/ambassador-onboarding" element="">
                <Route index element={<SignUpInfluencers />} />
                <Route
                  path="add-personal-info"
                  element={<AddPersonalInfoPage />}
                />
                <Route path="confirm-details" element={<ConfirmDetails />} />
                <Route
                  path="submit-application"
                  element={<AnswerQuestions />}
                />
                <Route
                  path="application-submitted"
                  element={<ApplicationSubmitted />}
                />
              </Route>
              <Route
                path="brands/:brand/sign_up_ambassador"
                element={<SignUpInfluencers />}
              ></Route>
            </Route>
            <Route
              path="campaigns/:campaign_id/participant-review"
              element={<ParticipantReview />}
            >
              <Route index element={<ParticipantReview />} />
              <Route
                path=":participant_id/:subpage?"
                element={<ParticipantProfile />}
              />
            </Route>
            <Route path="/creators/:slug/:subpage?" element={<Profile />} />
            {/* signed in users */}
            <Route
              element={
                <SignedIn>
                  <MainLayout>
                    <Outlet />
                  </MainLayout>
                </SignedIn>
              }
            >
              <Route
                path="off-platform-creator"
                element={<CreateOffPlatformCreator />}
              />
              <Route path="/" element={<DashboardWrap />}>
                <Route index element={<Navigate replace to="home" />} />
                <Route path="home/:subpage?" element={<Home />} />
                <Route path="content" element={<Content />} />
                {/* Add more dashboard routes as needed */}
              </Route>
              <Route path="/card/:slug/:subpage?" element={<Profile />} />
              <Route path="reports/:report_id" element={<ReportWrap />}>
                <Route index element={<Navigate replace to="overview" />} />
                <Route path="overview" element={<ReportOverview />} />
                <Route path="content" element={<ReportContent />} />
                <Route path="bonus" element={<ReportOverview />} />
                <Route path="bonus-posts" element={<ReportContent />} />s
                <Route path="creators" element={<ReportCreators />} />
              </Route>

              <Route path="reports" element={<ReportsList />} />

              {/* for testing locally without changing user */}
              {location.host.includes("localhost") && (
                <>
                  {/* <Route path="/campaigns" element={<CampaignsDashboard />} /> */}
                  <Route path="/directory" element={<DirectoryDashboard />} />
                  {/* <Route path="/ambassadors" element={<Ambassadors />} /> */}
                </>
              )}
              <Route path="/stats" element={<Stats />} />
              <Route path="/notifications" element={<Notifications />} />

              <Route
                path="/notifications/settings"
                element={<NotificationSettings />}
              />
              <Route path="bulletins" element={<Bulletins />} />
              <Route
                path="bulletins/:bulletin_id"
                element={<BulletinsProfile />}
              >
                <Route
                  index
                  element={<Navigate replace to="setup/content" />}
                />
                <Route path="setup" element={<BulletinSetup />}>
                  <Route index element={<Navigate replace to="content" />} />
                  <Route path="content" element={<BulletinSetupContent />} />
                  <Route path="questions" element={<BulletinQuestions />} />
                  <Route path="invitation-email" element={<AutomatedEmail />} />
                </Route>
                <Route
                  path="responses"
                  element={<BulletinResponseListPage />}
                />
                <Route
                  path="recipients"
                  element={<BulletinRecipientListPage />}
                />
                <Route
                  path="questions/:question_id?"
                  element={<BulletinQuestions />}
                />
              </Route>
              <Route
                path="bulletins/:bulletin_id/preview/:subpage?"
                element={<BulletinPreviewContainer />}
              >
                <Route index element={<BulletinPreviewPage />} />
                <Route
                  path="questions"
                  element={<BulletinAnswerQuestionsPage />}
                ></Route>
              </Route>
              <Route path="/campaigns" element={<CampaignsPage />} />
              <Route
                path="/campaigns/:campaign_id/finance-approval"
                element={<CampaignFinanceApprovalPage />}
              />
              <Route
                path="campaigns/:campaign_id"
                element={<CampaignProfile />}
              >
                <Route index element={<Navigate replace to="overview" />} />
                <Route path="overview" element={<CampaignOverview />} />
                <Route path="participants" element={<CampaignParticipants />} />
                <Route path="reporting" element={<CampaignReports />} />
                <Route
                  path="participants/:participant_id/:subpage?"
                  element={<ParticipantProfile />}
                />
                <Route path="content" element={<CampaignContentReview />}>
                  <Route index element={<Navigate replace to="review" />} />
                  <Route path="review" element={<ContentReview />} />
                  <Route path="attribution" element={<ContentAttribution />} />
                  <Route path="bonus-content" element={<BonusContent />} />
                  <Route
                    path="attributed-content"
                    element={<AttributedContent />}
                  />
                </Route>
                <Route path="deliverables" element={<CampaignDeliverables />} />
                <Route path="products" element={<CampaignProducts />} />
                <Route
                  path="brief/:brief_id?/:settings?"
                  element={<CampaignBrief />}
                ></Route>
              </Route>
              <Route
                path="brief-preview/:campaign_id/:participant_group_id?"
                element={<BriefPreviewPage />}
              />
              <Route
                path="participant-brief/:campaign_id/:participant_id"
                element={<BriefPreviewPage />}
              />
              <Route
                path="campaign-view/:participant_id/:subpage?"
                element={<CreatorCampaignPage />}
              />
              <Route path="/collaborators" element={<CollaboratorsWrapper />}>
                <Route index element={<Navigate replace to="campaigns" />} />
                <Route
                  path="campaigns"
                  element={<CampaignCollaboratorsList />}
                />
                <Route
                  path="outreach"
                  element={<OutreachCollaboratorsList />}
                />
                <Route
                  path="bulletins"
                  element={<BulletinCollaboratorsList />}
                />
                <Route
                  path="offPlatform"
                  element={<OffPlatformCollaboratorsList />}
                />
              </Route>

              <Route path="/gifting" element={<GiftingTableList />} />
              <Route path="gifting/:table_id" element={<GiftingTable />}>
                <Route index element={<GiftingTableList />} />
              </Route>
              <Route path="/groups" element={<Groups />} />

              <Route path="/groups/:group_id" element={<GroupsProfile />} />

              <Route path="/search" element={<Search />} />
              <Route path="/outreach" element={<OutreachList />} />
              <Route
                path="/outreach/templates"
                element={<OutreachTemplateList />}
              />
              <Route
                path="/outreach/templates/:id"
                element={<OutreachTemplate />}
              />
              <Route path="/outreach/:id" element={<OutreachProfile />}>
                <Route index element={<Navigate replace to="message" />} />
                <Route path="message" element={<OutreachMessage />} />
                <Route path="recipients" element={<OutreachRecipients />} />
              </Route>
              {/* <Route path="/gifting" element={<Gifting />} /> */}
              {/*  <Route
                path="/ambassadors/:subpage?/:tab?/:question_id?"
                element={<Ambassadors />}
              /> */}
              <Route path="/ambassadors/*" element={<Ambassadors />} />
              <Route path="/settings" element={<SettingsWrapper />}>
                <Route index element={<Navigate replace to="personal" />} />
                <Route path="personal" element={<ProfileSettings />} />
                <Route path="privacy" element={<PrivacySettings />} />
                <Route path="brand" element={<BrandSettings />} />
                <Route path="subscription" element={<SubscriptionSettings />} />
                <Route path="billing" element={<SubscriptionBilling />} />
                <Route
                  path="subscription/success"
                  element={<SubscriptionSuccess />}
                />
                <Route
                  path="social-connections"
                  element={<SocialConnectionsSettings />}
                />
              </Route>
              <Route path="/creators" element={<CreatorEdit />}>
                <Route index element={<Navigate replace to="edit" />} />
                <Route path="edit" element={<CreatorSettings />} />
                <Route path="privacy" element={<CreatorPrivacySettings />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="admin"
              element={
                <MainLayout>
                  <Outlet />
                </MainLayout>
              }
            >
              <Route index element={<Admin />} />
              <Route
                path="revenue_recognition_report"
                element={<RevenueRecognitionReport />}
              />

              <Route path="accounts" element={<AccountsAdmin />} />
              <Route path="accounts/:id" element={<AccountPage />} />
              <Route path="brands" element={<BrandsAdmin />} />
              <Route path="brands/:brand_id" element={<BrandAdmin />} />
              <Route path="e/:entity_name" element={<EntityAdminLayout />} />
              <Route path="contracts" element={<ContractTemplates />} />
              <Route path="contracts/:id" element={<ContractTemplate />} />
              <Route
                path="participant_briefs"
                element={<ParticipantBriefs />}
              />
              <Route path="campaigns" element={<CampaignsAdmin />} />
              <Route path="bulletins" element={<BulletinsAdmin />} />
              <Route path="creators" element={<CreatorsAdmin />} />
              <Route path="finance" element={<FinanceAdmin />} />
              <Route path="groups" element={<GroupsAdmin />} />
              <Route path="outreach" element={<OutreachAdmin />} />
              <Route path="reports" element={<ReportsAdmin />} />
              <Route
                path="ambassador_questions"
                element={<AmbassadorQuestions />}
              />
              <Route
                path="ambassador_questions/:question_id"
                element={<AmbassadorQuestions />}
              />
            </Route>

            <Route
              path="card/:creator_slug/testimonials/new"
              element={<NewTestimonial />}
            />
            <Route path="upgrade_membership" element={<UpgradeMembership />} />
          </Routes>
        </BrowserRouter>
      </MixpanelProvider>
    </UserProvider>
  );
};
