import React from "react";
import { Link } from "react-router-dom";
import FIcon from "@components/FIcon";
import { FCheckbox } from "@components/FInputs";
import { BulletinResponse } from "@apiTypes";

interface BulletinResponseCardProps {
  responses: BulletinResponse[];
  onSelect?: (creatorId: number) => void;
  isSelected?: boolean;
}

export const BulletinResponseCard: React.FC<BulletinResponseCardProps> = ({
  responses,
  onSelect,
  isSelected,
}) => {
  // We assume all responses are from the same creator
  const creator = responses[0].creator;

  const handleCardClick = (e: React.MouseEvent) => {
    // Don't trigger selection if clicking on a link
    if ((e.target as HTMLElement).closest("a")) {
      return;
    }
    onSelect?.(creator.id);
  };

  return (
    <div
      onClick={handleCardClick}
      className={`bg-white rounded-lg border ${
        isSelected ? "border-black" : "border-light_border"
      } shadow-sm p-6 relative cursor-pointer hover:shadow-md transition-shadow duration-200`}
    >
      {onSelect && (
        <div className="absolute top-4 right-4">
          <FCheckbox
            checked={isSelected}
            onChange={() => onSelect(creator.id)}
            id={`creator-${creator.id}`}
            className="flex-shrink-0"
          />
        </div>
      )}

      <div className="flex gap-20">
        {/* Left Column - Creator Info */}
        <div className="w-[300px] flex-shrink-0">
          <div className="flex flex-col gap-4">
            <Link
              to={`/card/${creator.slug}`}
              target="_blank"
              className="block flex-shrink-0"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={creator.profile_image_url}
                alt={creator.full_name}
                className="w-12 h-12 rounded-full object-cover"
              />
            </Link>

            <div>
              <Link
                to={`/card/${creator.slug}`}
                target="_blank"
                className="hover:text-primary hover:underline w-fit block mb-2"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="font-medium text-lg">{creator.full_name}</h3>
              </Link>
              <div className="flex flex-col gap-2 text-sm text-gray-500">
                <span className="flex items-start gap-2">
                  <FIcon icon="business" size={14} className="mt-[2px]" />
                  {creator.location}
                </span>
                <span className="flex items-start gap-2">
                  <FIcon icon="audience" size={14} className="mt-[2px]" />
                  {creator.overall_followers.toLocaleString()} followers
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column - Responses */}
        <div className="flex-1 space-y-6 pr-4">
          {responses.map((response) => (
            <div key={response.id} className="first:border-t-0 first:pt-0">
              <h4 className="text-xs uppercase tracking-wider opacity-50 mb-1">
                {response.question}
              </h4>
              <p className="text-gray-900 text-base">
                {response.readable_answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BulletinResponseCard;
