import SocialMediaConnections from "@pages/auth/influencer-onboarding/SocialMediaConnections";
import { useState } from "react";

const SocialConnectionsSettings = () => {
  const [connections, setConnections] = useState({
    instagram: null,
    tiktok: null,
    youtube: null,
  });

  return (
    <div className="w-[400px]">
      <SocialMediaConnections
        connections={connections}
        source="settings"
        onUpdateConnections={(updates) =>
          setConnections({ ...connections, ...updates })
        }
      />
    </div>
  );
};

export default SocialConnectionsSettings;
