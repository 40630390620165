import React, { useEffect, useContext } from "react";
import { UserContext } from "@contexts/index";
import FIcon from "./FIcon";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";

declare global {
  interface Window {
    Beacon?: any;
    HSBeacon?: any;
  }
}

interface HelpScoutProps {
  collapsed: boolean;
}

const getBeaconId = (role: string): string => {
  // SaaS Users
  if (role.includes("SAAS") || role === "Freemium" || role === "Open Link") {
    return "32dde665-4ab6-4d6c-b9a1-52a8cdfb8299";
  }

  // Managed Service Users
  if (role.includes("Managed Services") || role === "Brand Owner") {
    return "f94b95ad-6815-4528-9cd0-8db717fa7001";
  }

  // Influencer Users
  if (["Influencer", "Influencer Rep"].includes(role)) {
    return "fd70ef4a-7de3-4f2c-b585-1dfa511ab9d1";
  }

  // Fohr Users (default)
  return "3a356136-c6aa-4e27-bf31-2613adebd7cf";
};

const getHelpDeskUrl = (role: string): string => {
  // SaaS Users
  if (role.includes("SAAS") || role === "Freemium" || role === "Open Link") {
    return "https://fohr-brand-user-help-center.helpscoutdocs.com/";
  }

  // Managed Service Users
  if (role.includes("Managed Services") || role === "Brand Owner") {
    return "https://fohr-managed-campaign-help-center.helpscoutdocs.com/";
  }

  // Influencer Users
  if (["Influencer", "Influencer Rep"].includes(role)) {
    return "https://fohr-influencer-help-center.helpscoutdocs.com/";
  }

  // Fohr Users (default)
  return "https://fohr-user-help-center.helpscoutdocs.com/";
};

// SaaS USER
// <script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});</script><script type="text/javascript">window.Beacon('init', '32dde665-4ab6-4d6c-b9a1-52a8cdfb8299')</script>
// MANAGED SERVICE USER
// <script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});</script><script type="text/javascript">window.Beacon('init', 'f94b95ad-6815-4528-9cd0-8db717fa7001')</script>
// INFLUENCER
// <script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});</script><script type="text/javascript">window.Beacon('init', 'fd70ef4a-7de3-4f2c-b585-1dfa511ab9d1')</script>
// FOHR USER
// <script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});</script><script type="text/javascript">window.Beacon('init', '3a356136-c6aa-4e27-bf31-2613adebd7cf')</script>

const HelpScout: React.FC<HelpScoutProps> = ({ collapsed }) => {
  const { user } = useContext(UserContext);
  const beaconId = getBeaconId(user?.role || "");
  const { trackEvent } = useMixpanelTracking();

  useEffect(() => {
    // Initialize Beacon
    if (!window.Beacon && !window.HSBeacon) {
      // Initialize Beacon queue
      window.Beacon = function (method: string, options?: any, data?: any) {
        window.Beacon.readyQueue.push({ method, options, data });
      };
      window.Beacon.readyQueue = [];

      // Create and load script
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://beacon-v2.helpscout.net";
      script.onload = () => {
        // Initialize Beacon with configuration
        window.Beacon("init", beaconId);

        // Configure Beacon
        window.Beacon("config", {
          display: {
            style: "manual",
            position: "right",
          },
          messaging: {
            chatEnabled: true,
            contactForm: {
              showName: false,
              showSubject: false,
            },
          },
        });

        // Identify the user if logged in
        if (user) {
          window.Beacon("identify", {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            company: user.brand_name,
          });
        }

        // Listen for close event
        window.Beacon("on", "close", () => {
          window.Beacon("close");
        });

        // Listen for error event
        window.Beacon("on", "error", (error: any) => {
          console.error("HelpScout: Beacon error", error);
        });

        // Initially close the Beacon
        window.Beacon("close");
      };

      script.onerror = (error) => {
        console.error("HelpScout: Error loading script", error);
      };

      document.head.appendChild(script);
    } else {
      if (user) {
        try {
          window.Beacon("identify", {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            company: user.brand_name,
          });
        } catch (error) {
          console.error("HelpScout: Error identifying user", error);
        }
      }
    }

    return () => {
      if (window.Beacon) {
        try {
          window.Beacon("destroy");
        } catch (error) {
          console.error("HelpScout: Error destroying Beacon", error);
        }
      }
    };
  }, [beaconId, user]);

  const handleClick = () => {
    // Track the Help & Support click
    trackEvent(EVENTS.SUPPORT.HELP_CLICKED, {
      user_role: user?.role,
      beacon_id: beaconId,
      help_desk_url: getHelpDeskUrl(user?.role || ""),
    });

    if (window.Beacon) {
      try {
        window.Beacon("open");
      } catch (error) {
        console.error("HelpScout: Error opening Beacon", error);
      }
    } else {
      console.error("HelpScout: Beacon not initialized");
    }
  };

  return (
    <div className="px-[12px]">
      <button
        onClick={handleClick}
        className="flex h-[40px] pl-[12px] items-center text-sm text-neutral_600 hover:text-neutral_800 transition-colors w-full"
      >
        <FIcon icon="help" size={16} color="#5C6276" className="mt-[-1px]" />
        {!collapsed && (
          <span className="text-sm ml-[12px] text-black opacity-40 hover:opacity-100 transition-colors">
            Help & Support
          </span>
        )}
      </button>
    </div>
  );
};

export default HelpScout;
