import React, { useCallback } from "react";

import { Sort } from "@types";
import { getCreatorList, deleteCreator } from "@api/Creators/Creators";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

const cardDropDownOptions = [{ label: "Delete", value: "delete" }];

const actions = {
  delete: async (id: number) => deleteCreator(id),
};
//TODO: confirm linking structure for creator --- "card/{slug}" or "creator/{id}"
const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
  },
  { key: "created_at", label: "Created", isDate: true },
  { key: "email", label: "Email" },
];

export default function CreatorsAdmin() {
  const getCreators = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search } = params;
      const response = await getCreatorList(true, page, sort, {
        name_cont: search,
      });

      const { creators: items } = response.data;
      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <SinglePageLayoutWithData
      entityName="creator"
      pageTitle="Creators"
      pageName="Creators"
      tableColumns={tableColumns}
      cardDropDownOptions={cardDropDownOptions}
      tableOnly
      rowActions={cardDropDownOptions}
      asyncActions={actions}
      getItems={getCreators}
      hideCreateNewButton
      // sortPathName="title"
      // sortPathDate="date"
      // filterOptions={filterOptions}
      // filterPath="state"
      // updateModal={CreateOrUpdateCreatorModal}
    />
  );
}
