import { useContext, useEffect } from "react";
import { MixpanelContext } from "./MixpanelProvider";
import { useLocation } from "react-router-dom";

export const useMixpanelTracking = () => {
  const { trackEvent, isInitialized } = useContext(MixpanelContext);
  const location = useLocation();

  // Track page views automatically
  useEffect(() => {
    if (isInitialized) {
      trackEvent("Page Viewed", {
        page_path: location.pathname,
        page_url: window.location.href,
        page_title: document.title,
      });
    }
  }, [location.pathname, isInitialized, trackEvent]);

  const trackWorkflowStart = (workflowName, properties = {}) => {
    trackEvent(`${workflowName} Started`, {
      timestamp_start: new Date().toISOString(),
      ...properties,
    });
  };

  const trackWorkflowComplete = (workflowName, properties = {}) => {
    trackEvent(`${workflowName} Completed`, {
      timestamp_end: new Date().toISOString(),
      ...properties,
    });
  };

  const trackButtonClick = (buttonName, properties = {}) => {
    trackEvent("Button Clicked", {
      button_name: buttonName,
      page_path: location.pathname,
      ...properties,
    });
  };

  const trackFormSubmission = (formName, properties = {}) => {
    trackEvent("Form Submitted", {
      form_name: formName,
      page_path: location.pathname,
      ...properties,
    });
  };

  return {
    trackEvent,
    trackButtonClick,
    trackFormSubmission,
    trackWorkflowStart,
    trackWorkflowComplete,
  };
};
