import React, { useEffect, useState } from "react";
import { FTextarea } from "@components/FInputs";
import FButton from "@components/FButton";

import { toast } from "react-hot-toast";
import { FTooltip } from "@components/FTooltip";
import { getCustomEmail, updateCustomEmail } from "@api/custom_emails";
import FSpinner from "@components/FSpinner";

const AutomatedEmail = () => {
  const emailKind = "bulletin_application";
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const [isSendingTestEmail, setTestingSendEmail] = useState(false);

  const [loading, setLoading] = useState(true);

  const [values, setValues] = useState({
    body: "",
  });

  useEffect(() => {
    getCustomEmail(emailKind)
      .then((response) => {
        const { body } = response.data;

        console.log("Response", response);
        setValues({ body });
      })
      .catch((e) => {
        console.error(e);
      })

      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleUpdate = (updates) => {
    setValues({ ...values, ...updates });
  };

  const handleSave = async () => {
    const { body } = values;

    if (!body) {
      setFormSubmitted(true);
      return;
    }

    setWaiting(true);

    try {
      await updateCustomEmail(emailKind, {
        body,
      });

      toast.success("Email saved");
    } catch (e) {
      toast.error("Error saving automated email");
    } finally {
      setWaiting(false);
    }
  };

  const handleTestEmail = () => {
    toast.success("Test email sent");
  };

  const submitted = isFormSubmitted;

  const subjectTooltip = (
    <p>
      Variables for the subject:
      <br />
      {"{FirstName}"}
      <br />
      {"{LastName}"}
      <br />
      {"{BulletinName}"}
      <br />
      {"{BrandName}"}
    </p>
  );

  const bodyTooltip = (
    <p>
      Variables for the body:
      <br />
      {"{FirstName}"}
      <br />
      {"{LastName}"}
      <br />
      {"{BulletinName}"}
      <br />
      {"{BrandName}"}
      <br />
      {"{BulletinLink}"}
      <br />
    </p>
  );

  if (loading) {
    return <FSpinner />;
  }

  return (
    <div className="bg-white max-w-[800px] p-10 border border-light_border rounded-lg m-auto shadow-sm">
      <div className="flex flex-col gap-4 mb-10 border-b border-light_border pb-10">
        <h2 className="text-xl font-bold">Invitation Email:</h2>
        <p className="text-sm text-neutral_700">
          Customize the email that will be sent when inviting influencers to
          your bulletin.
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <FTextarea
          error={!values.body && submitted && "Body is required"}
          label="Special note"
          required
          rows={10}
          onChange={(value) => handleUpdate({ body: value })}
          value={values.body}
          width="100%"
        />
      </div>

      <div className="flex justify-end items-center space-x-2 pb-3 z-50 pt-4 mt-10">
        <div className="flex gap-2">
          <FButton
            type="button"
            loading={isSendingTestEmail}
            label="Send Test Email"
            onClick={handleTestEmail}
          />
          <FButton
            type="submit"
            loading={waiting}
            label="Save"
            onClick={handleSave}
            primary
          />
        </div>
      </div>
    </div>
  );
};

export default AutomatedEmail;
