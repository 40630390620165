import { ApiResponse, CreatorBulletin } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { Bulletin, ContentReviewContent } from "@types";
import { ParticipantRecord } from "types/creator-view";

const BASE_URL = "/creator_view";

export const getParticipantRecords = async (): Promise<
  ApiResponse<ParticipantRecord[]>
> => apiWrapper(`${BASE_URL}/participants`, "GET");

export const getParticipantRecord = async (
  participantId
): Promise<ApiResponse<ParticipantRecord>> =>
  apiWrapper(`${BASE_URL}/participants/${participantId}`, "GET");

export const updateParticipantRecord = async (
  participantId,
  params: { status: string }
): Promise<ApiResponse<ParticipantRecord>> =>
  apiWrapper(`${BASE_URL}/participants/${participantId}`, "PUT", params);

export const getCreatorBulletins = async (): Promise<
  ApiResponse<CreatorBulletin[]>
> => apiWrapper("/creator_view/bulletins", "GET");

export const getCreatorBulletin = async (
  bulletinId: string
): Promise<ApiResponse<Bulletin>> =>
  apiWrapper(`/creator_view/bulletins/${bulletinId}`, "GET");

export const updateContentStatus = async (
  contentId: number,
  status: string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `${BASE_URL}/content_review/contents/${contentId}`,
    "PATCH",
    {
      content: { status },
    }
  );
};

export const deleteContentReviewAsset = async (
  assetId: number
): Promise<ApiResponse> =>
  apiWrapper(`${BASE_URL}/content_review/assets/${assetId}`, "DELETE");

export const addContent = async (params: {
  participant_id: number;
  deliverable_id: number;
  campaign_id: number;
  status: string;
  caption: string;
}): Promise<ApiResponse<ContentReviewContent>> =>
  apiWrapper(`${BASE_URL}/content_review/contents`, "POST", params);

export const updateContent = async (
  contentId: number,
  params: {
    caption: string;
  }
): Promise<ApiResponse<ContentReviewContent>> =>
  apiWrapper(
    `${BASE_URL}/content_review/contents/${contentId}`,
    "PATCH",
    params
  );

export const uploadContentReviewAsset = async (params: {
  content_id: number;
  file: string;
  filename: string;
  position?: number;
}): Promise<ApiResponse<string>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    formData.append(`asset[${key}]`, params[key]);
  });

  return apiWrapper(`${BASE_URL}/content_review/assets`, "POST", formData);
};

export const updateContentReviewAsset = async (
  assetId: number,
  params: {
    asset: { position: number };
  }
): Promise<ApiResponse> =>
  apiWrapper(`${BASE_URL}/content_review/assets/${assetId}`, "PATCH", params);

export const createRound = async (params: {
  content_id: number;
  caption: string;
  current: boolean;
}): Promise<ApiResponse<ContentReviewContent>> =>
  apiWrapper(`${BASE_URL}/content_review/rounds`, "POST", { round: params });
