import React, { useEffect } from "react";

import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";

import { isEmpty } from "lodash";

export default function SignedIn({ children }: { children: React.ReactNode }) {
  const { hasRole, user } = useAuth();

  const isInfluencer = hasRole(Role.INFLUENCER);

  useEffect(() => {
    if (!user) {
      window.location.href = "/login";
    }

    const checkForValidInfluencerProfile = () => {
      const { first_name, last_name, birthday, gender, platforms, ethnicity, address } = user;
      if (!first_name || !last_name || !birthday || !gender || !ethnicity || !address) {
        window.location.href = "/signup/influencer/add-personal-info";
      } else if (isEmpty(platforms)) {
        window.location.href = "/signup/influencer/connect-socials";
      }
    };

    if (isInfluencer && !user.email.endsWith("fohr.co")) {
      checkForValidInfluencerProfile();
    }
  }, [user, isInfluencer]);

  if (user) {
    return <>{children}</>;
  }
}
