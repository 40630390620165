import moment from "moment";
import React from "react";
import { useState, useContext } from "react";

import { Campaign, Participant } from "@types";
import placeholderImage from "@images/image_placeholder.png";

import FDropdown from "@components/FDropdown";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import { toast } from "react-hot-toast";
import apiClient from "@apiClient";
import CreateOrUpdateCampaignModal from "./CreateOrUpdateCampaignModal";
import FIcon from "@components/FIcon";
import { isEmpty } from "lodash";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const { campaigns } = apiClient;

interface CampaignPageHeaderProps {
  campaign: Campaign;
  centered?: boolean;
  participant?: Participant;
}

export default function CampaignPageHeader({
  campaign,
  centered,
  participant,
}: CampaignPageHeaderProps) {
  const { brand_name: brandName, brief, title, owner_user: manager } = campaign;
  const { can } = useAuth();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { trackEvent } = useContext(MixpanelContext);

  const { cover_image, go_live_start, go_live_end } = brief;

  const { large, original, thumb } = cover_image || {};

  const coverImageSrc = original || large || thumb || placeholderImage;

  const cardDropDownOptions = [
    can(Permission.UPDATE_CAMPAIGNS) ? { label: "Edit", value: "edit" } : null,
    can(Permission.CREATE_CAMPAIGNS)
      ? { label: "Duplicate", value: "duplicate" }
      : null,
    can(Permission.DESTROY_CAMPAIGNS)
      ? { label: "Archive", value: "archive" }
      : null,
  ].filter(Boolean);

  const handleDropdownSelect = async (value: string) => {
    if (value === "duplicate") {
      try {
        // Track campaign duplication attempt
        trackEvent(EVENTS.CAMPAIGN.DUPLICATED, {
          campaign_id: campaign.id,
          campaign_title: campaign.title,
          campaign_status: campaign.status,
          campaign_age_days: moment().diff(moment(campaign.created_at), "days"),
        });

        const response = await campaigns.duplicate(campaign.id);
        if (response.success && response.data) {
          toast.success("Campaign duplicated successfully");
          window.location.reload();
        } else {
          toast.error("Failed to duplicate campaign");

          // Track error
          trackEvent(EVENTS.ERROR.API_ERROR, {
            error_type: "campaign_duplicate_failure",
            campaign_id: campaign.id,
            campaign_title: campaign.title,
          });
        }
      } catch (error) {
        toast.error("Failed to duplicate campaign");

        // Track error
        trackEvent(EVENTS.ERROR.API_ERROR, {
          error_type: "campaign_duplicate_error",
          campaign_id: campaign.id,
          campaign_title: campaign.title,
          error_message: error.message,
        });
      }
    } else if (value === "archive") {
      try {
        // Track campaign archive attempt
        trackEvent(EVENTS.CAMPAIGN.ARCHIVED, {
          campaign_id: campaign.id,
          campaign_title: campaign.title,
          campaign_status: campaign.status,
          campaign_age_days: moment().diff(moment(campaign.created_at), "days"),
        });

        await campaigns.update(campaign.id, { status: "archived" });
        toast.success("Campaign archived successfully");
        window.location.href = "/campaigns";
      } catch (error) {
        toast.error("Failed to archive campaign");

        // Track error
        trackEvent(EVENTS.ERROR.API_ERROR, {
          error_type: "campaign_archive_error",
          campaign_id: campaign.id,
          campaign_title: campaign.title,
          error_message: error.message,
        });
      }
    } else if (value === "edit") {
      // Track campaign edit attempt
      trackEvent(EVENTS.CAMPAIGN.SETTINGS_ACCESSED, {
        campaign_id: campaign.id,
        campaign_title: campaign.title,
        campaign_status: campaign.status,
      });

      setIsEditModalVisible(true);
    }
  };

  const handleCampaignEdited = () => {
    // Track successful campaign edit
    trackEvent("Campaign Edited", {
      campaign_id: campaign.id,
      campaign_title: campaign.title,
    });

    setIsEditModalVisible(false);
    window.location.reload();
  };

  const renderUnderTitle = (
    brandName: string,
    go_live_start: string,
    go_live_end: string
  ) => {
    return (
      <div className="flex items-center gap-2 text-black">
        {centered ? (
          <span className="text-black opacity-50 text-[16px]">Dates:</span>
        ) : (
          <>
            {brandName}
            <span className="text-black text-[16px]"> | </span>
          </>
        )}

        {(() => {
          const startDate = moment(go_live_start);
          const endDate = moment(go_live_end);
          const sameYear = startDate.year() === endDate.year();
          const sameMonth = sameYear && startDate.month() === endDate.month();

          if (sameMonth) {
            return (
              <span className="text-black text-[16px] font-medium">
                {startDate.format("MMMM D")} - {endDate.format("D, YYYY")}
              </span>
            );
          } else if (sameYear) {
            return (
              <span className="text-black text-[16px] font-medium">
                {startDate.format("MMMM D")} - {endDate.format("MMMM D, YYYY")}
              </span>
            );
          } else {
            return (
              <span className="text-black text-[16px] font-medium">
                {startDate.format("MMMM D, YYYY")} -{" "}
                {endDate.format("MMMM D, YYYY")}
              </span>
            );
          }
        })()}
      </div>
    );
  };

  return (
    <>
      <div
        className={`flex flex-col ${
          centered ? "items-center" : "items-start"
        } gap-[12px] mb-[32px]`}
      >
        {centered ? (
          <div className="flex flex-col items-center gap-[0px] mb-[32px]">
            <div
              className="w-[72px] h-[72px] mb-[20px] border border-light_border bg-cover bg-center relative rounded-lg box-shadow-md bg-white"
              style={{ backgroundImage: `url("${coverImageSrc}")` }}
            />
            {centered ? (
              <span className="text-black text-[16px]">{brandName}</span>
            ) : null}
            <h1 className="text-[42px]">{title}</h1>
            {renderUnderTitle(brandName, go_live_start, go_live_end)}
            {centered ? (
              <span className="text-black text-[16px] flex flex-row gap-2">
                <p className="font-light text-[16px] opacity-50">
                  {participant ? "Creator:" : "Manager:"}
                </p>
                <p className="font-light text-[16px] font-medium">
                  {(participant ? participant?.creator : manager)?.full_name}
                </p>
                {(participant ? participant?.creator : manager)?.email && (
                  <a
                    href={`mailto:${(participant ? participant?.creator : manager)?.email}`}
                    className="flex items-center gap-1 font-light text-[16px]"
                  >
                    <FIcon
                      icon="email-bold"
                      size={16}
                      color="black"
                      className="-mt-[2px]"
                    />
                  </a>
                )}
              </span>
            ) : null}
          </div>
        ) : (
          <>
            <div className="flex items-center gap-[36px] w-full">
              <div
                className="w-[72px] h-[72px] border border-light_border bg-cover bg-center relative rounded-lg box-shadow-md bg-white"
                style={{ backgroundImage: `url("${coverImageSrc}")` }}
              />
              <div className="flex flex-col gap-0 w-full">
                <div className="flex gap-2 items-start justify-between w-full">
                  <div className="flex flex-col gap-0">
                    <h1 className="text-[32px]">{title}</h1>
                    {renderUnderTitle(brandName, go_live_start, go_live_end)}
                  </div>
                  {!isEmpty(cardDropDownOptions) ? (
                    <div className="flex pt-[4px] items-center justify-end">
                      <FDropdown
                        options={cardDropDownOptions}
                        onChange={(value) =>
                          handleDropdownSelect(value as string)
                        }
                        width="auto"
                        height="28"
                        iconOnly={true}
                        iconColor="black"
                        icon="ellipsis"
                        className="relative text-center bg-white border border-default_weak rounded-md"
                        zIndex={999}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {isEditModalVisible && (
        <CreateOrUpdateCampaignModal
          entity={campaign}
          onClose={() => setIsEditModalVisible(false)}
          onUpdated={handleCampaignEdited}
        />
      )}
    </>
  );
}
