import React, { ReactNode, useEffect, useState, useContext } from "react";

import { getMe } from "@api/user";
import { User } from "@types";
import { UserContext } from "@contexts/index";

import { Role } from "@constants/roles";
import { MixpanelContext } from "./MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true);

  // Get the mixpanel context - will be null during initial render
  // We'll use a ref to store it for later use
  const mixpanelContextValue = useContext(MixpanelContext);
  const [mixpanelTracker, setMixpanelTracker] = useState(null);

  // Once mixpanel is initialized, store the trackEvent function
  useEffect(() => {
    if (
      mixpanelContextValue?.isInitialized &&
      mixpanelContextValue?.trackEvent
    ) {
      setMixpanelTracker(() => mixpanelContextValue.trackEvent);
    }
  }, [mixpanelContextValue]);

  useEffect(() => {
    if (window.location.pathname.includes("/login")) {
      setLoading(false);

      return;
    }

    getMe()
      .then((user) => setUser(user))
      .finally(() => setLoading(false));
  }, []);

  const can = (permission: string): boolean => {
    if (!user || !user.permissions) {
      return false;
    }

    if (user.role === Role.SUPER_ADMIN) {
      return true;
    }

    const hasPermission = user.permissions[permission] === true;

    // Track permission denied errors only for actual users (not when user is loading)
    // and when a track function is available
    if (!hasPermission && user && mixpanelTracker) {
      // Get the current URL to include in error tracking
      const currentPath = window.location.pathname;

      mixpanelTracker(EVENTS.ERROR.PERMISSION_ERROR, {
        permission: permission,
        user_id: user.id,
        user_role: user.role,
        user_email: user.email,
        brand_id: user.brand_id,
        brand_name: user.brand_name,
        page_path: currentPath,
      });
    }

    return hasPermission;
  };

  const hasRole = (role: Role): boolean =>
    user && user.role && user.role === role;

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <UserContext.Provider value={{ user, setUser, can, hasRole, loading }}>
      {!loading && children}
    </UserContext.Provider>
  );
};
