import React from "react";
import FIcon from "../FIcon";

interface Props {
  className?: string;
  label?: string;
  id: string;
  error?: string;
  success?: string;
  size?: number;
  checked?: boolean;
  style?: any;
  onChange?: (isChecked: boolean) => void;
}

const FToggleButton = ({
  className = "",
  label = "",
  id = "",
  size = 18,
  checked = false,
  onChange,
  ...props
}: Props) => {
  const handleChange = (value: boolean) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={`flex ${className}`} style={props.style}>
      {onChange ? (
        <input
          className="hidden"
          type="checkbox"
          id={id}
          checked={checked}
          onChange={(e: any) => {
            handleChange(e.target.checked);
          }}
        />
      ) : null}

      {label && (
        <label className={`text-sm leading-[18px] cursor-pointer border rounded py-[12px] px-[8px] ${checked ? "text-white" : ""}`} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default FToggleButton;