import React, { useContext, useEffect, useMemo, useState } from "react";

import apiClient from "@apiClient";

import { Creator, GroupData } from "@apiTypes";
import useAuth from "@hooks/useAuth";
import FDropdown from "@components/FDropdown";
import SelectCreatorsTable from "@components/Creator/SelectCreatorsTable";
import SelectGroupsTable from "@components/Creator/SelectGroupsTable";
import FButton from "@components/FButton";
import { useNavigate } from "react-router-dom";
import { Brand, Outreach } from "@types";
import EditMessageForm from "../EditMessageForm";
import { FInput } from "@components/FInputs";
import toast from "react-hot-toast";
import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";
import FButtonGroup from "@components/FButtonGroup";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const { create, update } = apiClient.outreach;

enum SOURCE {
  CREATORS = "creators",
  GROUPS = "groups",
}

const SOURCES = [
  { value: SOURCE.CREATORS, label: "Add On-Platform" },
  { value: SOURCE.GROUPS, label: "Add by Group" },
];

interface UpdateOutreachModalProps {
  onClose: (newOutreach?: Outreach) => void;
  onUpdated?: (outreach: Outreach) => void;
  outreach?: Outreach;
  showJustParticipants?: boolean;
}

const UpdateOutreachModal = ({
  onClose,
  onUpdated,
  outreach: originalOutreach,
  showJustParticipants,
}: UpdateOutreachModalProps) => {
  const [waiting, setWaiting] = useState(false);
  const [selectedSource, setSelectedSource] = useState(SOURCES[0].value);
  const [step, setStep] = useState(showJustParticipants ? 2 : 1);
  const [selectedCreators, setSelectedCreators] = useState<Creator[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupData[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<number>(null);
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const [outreach, setOutreach] = useState<Partial<Outreach>>(
    originalOutreach || {
      message: { subject: "", body: "" },
      recipients: [],
    }
  );

  const { user } = useAuth();
  const navigate = useNavigate();
  const { trackEvent } = useContext(MixpanelContext);

  const handleSubmit = async () => {
    setWaiting(true);

    const recipients = selectedCreators.map(({ id }) => ({
      creator_id: id,
    }));

    const groupedRecipients = selectedGroups.flatMap((group) =>
      (group.creators || []).map(({ id }) => ({
        creator_id: id,
      }))
    );

    if (groupedRecipients.length > 0) {
      recipients.push(...groupedRecipients);
    }

    try {
      if (showJustParticipants) {
        const params = { recipients_attributes: recipients };
        const response = await update(outreach.id, params);

        // Track recipients added to existing outreach
        trackEvent(EVENTS.OUTREACH.RECIPIENTS_ADDED, {
          outreach_id: outreach.id,
          outreach_title: outreach.title,
          creators_count: selectedCreators.length,
          groups_count: selectedGroups.length,
          total_recipients_added: recipients.length,
          source: selectedSource,
        });

        onUpdated(response.data);
        return;
      }

      const params = {
        ...outreach,
        brand_id: selectedBrand,
        recipients_attributes: recipients,
        title,
        user_id: user.user_id,
      };

      const newCommunication = await create(params);

      // Track new outreach creation with recipients
      trackEvent(EVENTS.OUTREACH.CREATED, {
        outreach_id: newCommunication.data.id,
        outreach_title: newCommunication.data.title,
        creators_count: selectedCreators.length,
        groups_count: selectedGroups.length,
        total_recipients: recipients.length,
        source: selectedSource,
      });

      navigate(`/outreach/${newCommunication.data.id}/recipients`);
    } catch (e) {
      const errorMessage = "Error updating outreach";
      toast.error(errorMessage);
      setError(e.response?.data?.message || e.message || errorMessage);
    } finally {
      setWaiting(false);
    }
  };

  const excludeIds = useMemo(
    () => outreach.recipients.map(({ creator }) => creator.id),
    [outreach.recipients]
  );

  return (
    <SelectEntitiesModal
      title="Add recipients"
      onClose={() => {
        onClose();
        setSelectedCreators([]);
        setSelectedGroups([]);
        setStep(1);
      }}
      headerContent={
        <div className="flex justify-center">
          {step === 2 ? (
            <div className="w-[300px]">
              <FButtonGroup
                options={SOURCES}
                selectedValue={selectedSource}
                onSelectedValue={(value) => setSelectedSource(value as SOURCE)}
              />
            </div>
          ) : null}
        </div>
      }
      error={error}
      onBack={step === 2 && !showJustParticipants ? () => setStep(1) : null}
      actionButton={
        step === 1 ? (
          <FButton
            onClick={() => setStep(2)}
            label="Next"
            icon={{
              name: "fancy-arrow-right",
              size: 16,
              color: "white",
              className: "ml-2",
            }}
            loading={waiting}
            width="100%"
            height="40px"
            primary
          />
        ) : (
          <FButton
            loading={waiting}
            onClick={handleSubmit}
            primary
            label="Save"
            width="100%"
            height="40px"
          />
        )
      }
    >
      <>
        {step === 1 ? (
          <div className="flex flex-col gap-10 overflow-auto">
            <FDropdown
              label="Brand"
              options={user.memberships.map((membership) => ({
                label: membership.name,
                value: membership.brand_id,
              }))}
              required
              selectedValue={selectedBrand}
              onChange={(value) => setSelectedBrand(value as number)}
              titleDropDown={false}
              height="40"
              zIndex={9999}
              width="100%"
              className="font-sofia-pro h-[40px] text-[22px] text-dark tracking-[0.66px]"
            />
            <FInput
              label="Title"
              value={title}
              className="w-full"
              width="100%"
              type="text"
              onChange={(value) => setTitle(value)}
              required
            />
            <EditMessageForm
              outreach={outreach}
              onUpdate={(updates) => setOutreach({ ...outreach, ...updates })}
            />
          </div>
        ) : (
          <div style={{ height: "calc(100% - 70px)" }}>
            {selectedSource === SOURCE.CREATORS ? (
              <SelectCreatorsTable
                excludeIds={excludeIds}
                selectedItems={selectedCreators}
                onChangeSelectedItems={setSelectedCreators}
              />
            ) : null}
            {selectedSource === SOURCE.GROUPS ? (
              <SelectGroupsTable
                selectedItems={selectedGroups}
                onChangeSelectedItems={setSelectedGroups}
              />
            ) : null}
          </div>
        )}
      </>
    </SelectEntitiesModal>
  );
};

export default UpdateOutreachModal;
