import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { CustomEmail } from "@types"

const getCustomEmailsList = async (): Promise<ApiResponse<CustomEmail>> => {
  return apiWrapper("/custom_emails", "GET");
};

const getCustomEmail = async (
  kind: string
): Promise<ApiResponse<CustomEmail>> => {
  return apiWrapper(`/custom_emails/${kind}`, "GET");
};

const updateCustomEmail = async (
  kind: string,
  params: object
): Promise<ApiResponse<CustomEmail>> => {
  return apiWrapper(`/custom_emails/${kind}`, "PUT", params);
};

export { getCustomEmailsList, getCustomEmail, updateCustomEmail };
