import React from "react";

interface FilterSummaryProps {
  filters: {
    tags?: string;
    platforms?: string;
    exclude_groups?: string;
    custom_age_range?: string;
    popular_locations?: string;
    instagram_followers?: string;
    tiktok_followers?: string;
  };
}

const formatTags = (tags: string) => {
  // TODO: We should fetch tag names from an API or context
  // For now, just show the IDs in a cleaner format
  return `Tags: ${tags.split(",").join(", ")}`;
};

const formatPlatforms = (platforms: string) => {
  return `Platforms: ${platforms
    .split(",")
    .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
    .join(", ")}`;
};

const formatGroups = (groups: string) => {
  // TODO: We should fetch group names from an API or context
  return `Exclude Groups: ${groups.split(",").join(", ")}`;
};

const formatAgeRange = (ageRange: string) => {
  try {
    const { min, max } = JSON.parse(ageRange);
    if (min && max) {
      return `Age Range: ${min} - ${max} years`;
    } else if (min) {
      return `Age: ${min}+ years`;
    } else if (max) {
      return `Age: Up to ${max} years`;
    }
  } catch (e) {
    return null;
  }
};

const formatFollowers = (followers: string, platform: string) => {
  try {
    const { min, max } = JSON.parse(followers);
    const platformName = platform.charAt(0).toUpperCase() + platform.slice(1);

    if (min && max) {
      return `${platformName} Followers: ${formatNumber(min)} - ${formatNumber(max)}`;
    } else if (min) {
      return `${platformName} Followers: ${formatNumber(min)}+`;
    } else if (max) {
      return `${platformName} Followers: Up to ${formatNumber(max)}`;
    }
  } catch (e) {
    return null;
  }
};

const formatNumber = (num: string) => {
  const n = parseInt(num, 10);
  if (n >= 1000000) {
    return (n / 1000000).toFixed(1) + "M";
  } else if (n >= 1000) {
    return (n / 1000).toFixed(1) + "K";
  }
  return n.toString();
};

const formatLocations = (locations: string) => {
  // TODO: We should fetch location names from an API or context
  return `Popular Locations: ${locations.split(",").join(", ")}`;
};

const FilterSummary: React.FC<FilterSummaryProps> = ({ filters }) => {
  const filterElements = [];

  if (filters.tags) {
    filterElements.push(formatTags(filters.tags));
  }

  if (filters.platforms) {
    filterElements.push(formatPlatforms(filters.platforms));
  }

  if (filters.exclude_groups) {
    filterElements.push(formatGroups(filters.exclude_groups));
  }

  if (filters.custom_age_range) {
    const formattedAge = formatAgeRange(filters.custom_age_range);
    if (formattedAge) filterElements.push(formattedAge);
  }

  if (filters.instagram_followers) {
    const formattedFollowers = formatFollowers(
      filters.instagram_followers,
      "Instagram"
    );
    if (formattedFollowers) filterElements.push(formattedFollowers);
  }

  if (filters.tiktok_followers) {
    const formattedFollowers = formatFollowers(
      filters.tiktok_followers,
      "TikTok"
    );
    if (formattedFollowers) filterElements.push(formattedFollowers);
  }

  if (filters.popular_locations) {
    filterElements.push(formatLocations(filters.popular_locations));
  }

  return (
    <div className="flex flex-wrap gap-2">
      {filterElements.map((filter, index) => (
        <span
          key={index}
          className="px-3 py-1 bg-white border border-light_border text-sm rounded-full"
        >
          {filter}
        </span>
      ))}
    </div>
  );
};

export default FilterSummary;
