import React from "react";
import Modal from "@components/Modals/Modal";
import QuestionForm from "./QuestionForm";
import {
  QuestionnaireQuestion,
  QuestionnaireQuestionRequest,
} from "types/questionnaire";

interface QuestionFormModalProps {
  onClose: () => void;
  entityName: string;
  question: QuestionnaireQuestion;
  questions: QuestionnaireQuestion[];
  onSave: (question: Partial<QuestionnaireQuestionRequest>) => void;
  waiting: boolean;
  hideVisibilitySettings?: boolean;
}

const QuestionFormModal: React.FC<QuestionFormModalProps> = ({
  onClose,
  entityName,
  question,
  questions,
  onSave,
  waiting,
  hideVisibilitySettings,
}) => {
  return (
    <Modal
      title={`${question.id ? "Edit" : "Add New"} ${entityName} Question`}
      isOpen
      onClose={onClose}
      hasForm
      width={600}
      className="max-h-[90vh] overflow-y-auto !pb-0"
    >
      <QuestionForm
        entityName={entityName}
        question={question}
        questions={questions}
        onSave={onSave}
        onClose={onClose}
        waiting={waiting}
        isModal
        hideVisibilitySettings={hideVisibilitySettings}
      />
    </Modal>
  );
};

export default QuestionFormModal;
