import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { AdminBrand, AdminBrandUser, ICampaign } from "@types";
import { Sort } from "@types";
import { getQueryParamsForSearchRequest } from "@constants/api";



const getAdminBrandList = async (
  page?: number,
  sort?: Sort,
  params?: object
): Promise<
  ApiResponse<{
    brands: AdminBrand[];
    total_items: number;
  }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(`/admin/brands?${queryParams.toString()}`, "GET");
};

const getAdminBrand = async (id: number): Promise<ApiResponse<AdminBrand>> => {
  return apiWrapper(`/admin/brands/${id}`, "GET");
};

const createAdminBrand = async (params?: object): Promise<ApiResponse<AdminBrand>> => {
  return apiWrapper(`/admin/brands`, "POST", params);
};

const updateAdminBrand = async (id: number|string, params?: object): Promise<ApiResponse<AdminBrand>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value !== null) {
      formData.append(`brand[${key}]`, value);
    }
  });

  return apiWrapper(`/admin/brands/${id}`, "PUT", formData);
};

const impersonateUser = async (membershipId?: number): Promise<ApiResponse<any>> => {
  return apiWrapper(`/admin/impersonations?membership_id=${membershipId}`, "POST");
};

const stopImpersonateUser = async (membershipId?: number): Promise<ApiResponse<any>> => {
  return apiWrapper(`/admin/impersonations?membership_id=${membershipId}`, "DELETE");
};

const getAdminBrandUsers = async (brandId?: number): Promise<ApiResponse<AdminBrandUser[]>> => {
  return apiWrapper(`/admin/brand_users?brand_id=${brandId}`, "GET");
};

const createAdminBrandUser = async (params?: object): Promise<ApiResponse<AdminBrandUser>> => {
  return apiWrapper(`/admin/brand_users`, "POST", params);
};

  const getAdminBrandCampaigns = async (brandId?: number): Promise<ApiResponse<ICampaign[]>> => {
    return apiWrapper(`/admin/campaigns?q[brand_id_eq]=${brandId}`, "GET");
};

export { getAdminBrandList, getAdminBrand, getAdminBrandUsers, createAdminBrandUser, getAdminBrandCampaigns, createAdminBrand, updateAdminBrand, impersonateUser, stopImpersonateUser };
