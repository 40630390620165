import axios, { AxiosHeaders } from "axios";
import { ApiResponse } from "../types/apiTypes";
import { LOCAL_API_ORIGIN } from "../constants/api";

const API_BASE_URL = `${LOCAL_API_ORIGIN}/api/v1`;

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    const { response } = error || {};

    /*  if (
      response &&
      response.status === 401 &&
      !response.config.url.includes("/sign_in") &&
      !window.location.href.includes("/sign_up") &&
      !window.location.href.includes("/participant-review/") &&
      !window.location.href.includes("/creators/")
    ) {
      window.location.href = "/login";
    } */

    /*  if (response?.status === 401) {
      console.log("Authentication failed:", {
        url: response.config.url,
        headers: response.config.headers,
        cookies: document.cookie, // Check if token cookie exists
      });
    } */

    const { config, status } = response;

    if (
      status === 404 &&
      (!config.url || !config.url.includes("/contract_template"))
    ) {
      window.location.href = "/not-found";
    }

    return Promise.reject(error);
  }
);
const apiWrapper = async (
  endpoint: string,
  method: HttpMethod,
  params?: any,
  headers?: AxiosHeaders
): Promise<ApiResponse<any>> => {
  const url = `${API_BASE_URL}${endpoint}`;
  let response;

  switch (method) {
    case "GET":
      response = await axios.get(url, { params, headers });
      break;
    case "POST":
      response = await axios.post(url, params, { headers });
      break;
    case "PUT":
      response = await axios.put(url, params, { headers });
      break;
    case "DELETE":
      response = await axios.delete(url, { headers });
      break;
    case "PATCH":
      response = await axios.patch(url, params, { headers });
      break;
    default:
      throw new Error("Invalid HTTP method");
  }

  return {
    success: true,
    message: "Request successful",
    data: response.data,
  };
};

export default apiWrapper;
