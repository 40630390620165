import { getCreatorPublicData } from "@api/Creators/Creators"
import { CREATOR_CATEGORIES } from "@api/Search"
import { createTestimonial } from "@api/Testimonial"
import FButton from "@components/FButton"
import { FInput, FTextarea } from "@components/FInputs"
import FToggleButton from "@components/FInputs/FToggleButton"
import LoadingSpinner from "@components/LoadingSpinner"
import ProgressBar from "@components/ProgressBar"
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"

const frequencies = {
  always: "All the time",
  monthly: "Once a month",
  quarterly: "Four times a year",
  yearly: "Once a year",
  never: "Never",
}

const NewTestimonial = () => {
  const [loading, setLoading] = useState(true);
  const { creator_slug } = useParams<{ creator_slug: string }>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [displayName, setDisplayName] = useState<string>("");

  useEffect(() => {
    getCreatorPublicData(creator_slug).then((response) => {
      setDisplayName(response.data.full_name);
      setLoading(false);
    });
  }, []);

  // STEP 1 STATES
  const [email, setEmail] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  // STEP 2 STATES
  const [influenceCategories, setInfluenceCategories] = useState<string[]>([]);
  // STEP 3 STATES
  const [purchased, setPurchased] = useState("");
  // STEP 4 STATES
  const [frequency, setFrequency] = useState("");
  // STEP 5 STATES
  const [reasonToFollow, setReasonToFollow] = useState("");

  // Check if currentStep is complete to enable the Next button
  let stepComplete = false;

  if (currentStep === 1 && email && instagramHandle) {
    stepComplete = true
  }

  if (currentStep === 2 && influenceCategories.length > 0) {
    stepComplete = true
  }

  if (currentStep === 3 && purchased) {
    stepComplete = true
  }

  if (currentStep === 4 && frequency) {
    stepComplete = true
  }

  if (currentStep === 5 && reasonToFollow) {
    stepComplete = true
  }

  async function submitTestimonial() {
    const payload = {
      email,
      instagram_handle: instagramHandle,
      data: {
        follow_reason: reasonToFollow,
        purchased,
        frequency,
        influence_categories: influenceCategories,
      }
    };
    await createTestimonial(creator_slug, payload);
    setCurrentStep(6);
  }

  if (loading) {
    return <div className="flex w-full h-[300px] justify-center align-center">
      <LoadingSpinner />
    </div>
  }

  return (
    <div className="flex flex-col p-2 w-full items-center gap-4">
      { currentStep && currentStep < 6 ?
        <ProgressBar value={(currentStep - 1) * 20} className="w-full lg:w-[558px] mb-4 mt-2" />
        : null
      }
      <div className="flex flex-col gap-4 p-2 w-full lg:w-[558px]">
        <h4 className="uppercase">Follower Testimonial</h4>
        {currentStep === 1 && (
          <>
            <h1>Please provide your information</h1>
            <div className="flex flex-col gap-2">
              <FInput
                placeholder="Email"
                value={email}
                onChange={setEmail}
              />
              <FInput
                placeholder="Instagram Handle"
                value={instagramHandle}
                onChange={setInstagramHandle}
              />
              <FButton onClick={() => setCurrentStep(2)} label="Next" primary disabled={!stepComplete} />
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <h1>In what categories does this person influence your decision making?</h1>
            <div className="flex flex-row gap-2 flex-wrap">
              {Object.keys(CREATOR_CATEGORIES).map((category_id) => (
                <FToggleButton key={category_id} id={category_id} checked={influenceCategories.includes(category_id)} label={CREATOR_CATEGORIES[category_id]} onChange={(isChecked) => {
                  if (isChecked) {
                    setInfluenceCategories([...influenceCategories, category_id])
                  } else {
                    setInfluenceCategories(influenceCategories.filter((id) => id !== category_id))
                  }
                }} />
              ))}
            </div>
            <FButton onClick={() => setCurrentStep(3)} label="Next" primary disabled={!stepComplete} />
          </>
        )}

        {currentStep === 3 && (
          <>
            <h1>Have you ever purchased something because {displayName} has mentioned it?</h1>
            <div className="flex flex-row gap-2">
              <FToggleButton id="purchased-yes" checked={purchased === "true"} label="Yes" onChange={(isChecked) => setPurchased(isChecked ? "true" : "")} />
              <FToggleButton id="purchased-no" checked={purchased === "false"} label="No" onChange={(isChecked) => setPurchased(isChecked ? "false" : "")} />
            </div>
            <FButton onClick={() => setCurrentStep(purchased === "true" ? 4 : 5)} label="Next" primary disabled={!stepComplete} />
          </>
        )}

        {currentStep === 4 && (
          <>
            <h1>How often do you make these purchases?</h1>
            <div className="flex flex-row gap-2">
              {Object.keys(frequencies).map((key) => (
                <FToggleButton key={key} id={"frequency-"+key} checked={frequency === key} label={frequencies[key]} onChange={(isChecked) => setFrequency(isChecked ? key : "")} />
              ))}
            </div>
            <FButton onClick={() => setCurrentStep(5)} label="Next" primary disabled={!stepComplete} />
          </>
        )}

        {currentStep === 5 && (
          <>
            <h1>What makes {displayName} special?</h1>
            <FTextarea value={reasonToFollow} onChange={setReasonToFollow} width={"100%"} rows={5} className="w-full" />
            <FButton onClick={submitTestimonial} label="Next" primary disabled={!stepComplete} />
          </>
        )}

        {currentStep === 6 && (
          <>
            <h1>Thank you for completing {displayName}'s testimonial!</h1>
            <p>
              The testimonial you submitted will now live on {displayName}'s Fohr profile
              for their potential partners across brands and agencies to read. We appreciate your
              taking the time to provide these insights.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default NewTestimonial;