import React from "react";

import {
  createAmbassadorQuestion,
  deleteAmbassadorQuestion,
  listAmbassadorQuestions,
  updateAmbassadorQuestion,
} from "@api/Ambassadors/AmbassadorQuestions";

import ManageQuestions from "@components/questions/ManageQuestions";

import { EVENTS } from "@utils/mixpanel_utilities";

const AmbassadorQuestions = () => (
  <ManageQuestions
    entityName="Ambassador Onboarding"
    listQuestions={listAmbassadorQuestions}
    createQuestion={(question) => {
      return createAmbassadorQuestion(question);
    }}
    updateQuestion={updateAmbassadorQuestion}
    deleteQuestion={deleteAmbassadorQuestion}
    updatedQuestionEventName={EVENTS.AMBASSADOR.QUESTION_UPDATED}
    updatedSettingsEventName={EVENTS.AMBASSADOR.SETTINGS_UPDATED}
  />
);

export default AmbassadorQuestions;
