import React, { useCallback, useState } from "react";

import { Sort } from "@types";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

import CreateOrUpdateBrandModal from "../components/CreateOrUpdateBrandModal";

import {
  downloadCampaignAccountingSummary,
  listCampaignAccountingSummaries,
} from "@api/Campaign/CampaignAccountingSummaries";
import FButton from "@components/FButton";
import toast from "react-hot-toast";
import { downloadDataAsCSVFile } from "@utils/download";

const TABLE_COLUMNS = [
  {
    key: "date_created",
    label: "Date Created",
    isDate: true,
  },
  {
    key: "created_at",
    label: "Sale Date",
    isDate: true,
  },
  {
    key: "project_number",
    label: "Project Number",
  },
  {
    key: "customer",
    label: "Customer",
  },
  {
    key: "campaign_name",
    label: "Campaign Name",
  },
  {
    key: "campaign_status",
    label: "Campaign Status",
  },
  {
    key: "invoice_status",
    label: "Invoice Status",
  },
  {
    key: "content_flight_start",
    label: "Content Flight Start",
  },
  {
    key: "content_flight_end",
    label: "Content Flight End",
  },
  {
    key: "value",
    label: "Value",
    isMoney: true,
  },
  {
    key: "content_flight_end",
    label: "# Required Deliverables",
  },
  {
    key: "value",
    label: "# Completed Deliverables",
    isNumber: true,
  },
  {
    key: "influencer_costs_actual",
    label: "Influencer Costs Actual",
    isMoney: true,
  },
  {
    key: "influencer_costs_forecast",
    label: "Influencer Costs Forecast",
    isMoney: true,
  },
  {
    key: "influencer_costs_budget",
    label: "Influencer Costs Budget",
    isMoney: true,
  },
  {
    key: "execution_costs_actual",
    label: "Execution Costs Actual",
    isMoney: true,
  },
  {
    key: "execution_costs_forecast",
    label: "Execution Costs Forecast",
    isMoney: true,
  },
  {
    key: "execution_costs_budget",
    label: "Execution Costs Budget",
    isMoney: true,
  },
  {
    key: "gross_margin_actual",
    label: "Gross Margin Actual",
    isMoney: true,
  },
  {
    key: "gross_margin_forecast",
    label: "Gross Margin Forecast",
    isMoney: true,
  },
  {
    key: "gross_margin_budget",
    label: "Gross Margin Budget",
    isMoney: true,
  },
  {
    key: "percent_gross_margin_actual",
    label: "% Gross Margin Actual",
    isPercentage: true,
  },
  {
    key: "percent_gross_margin_forecast",
    label: "% Gross Margin Forecast",
    isPercentage: true,
  },
  {
    key: "percent_gross_margin_budget",
    label: "% Gross Margin Budget",
    isPercentage: true,
  },
  {
    key: "contract_status",
    label: "Contract Status",
  },
  {
    key: "po_status",
    label: "PO Status",
  },
  {
    key: "sales_person",
    label: "Sales Person",
  },
  {
    key: "percent_gross_margin_budget",
    label: "execution lead",
  },
];

// Default sorting configuration
const defaultSort: Sort = {
  key: "created_at",
  direction: "asc",
};

const FinanceAdmin = () => {
  const [isDownloading, setDownloading] = useState(false);

  // Fetch brands with sorting, filtering, and pagination
  const getCampaigns = useCallback(
    async (page?: number, sort?: Sort, params = {}) => {
      const { filter } = params;
      const sortToUse = sort || defaultSort;

      const response = await listCampaignAccountingSummaries({ page });

      return {
        ...response.data,
        items: response.data.summaries,
      };
    },
    []
  );

  const handleDownload = async () => {
    setDownloading(true);

    try {
      const response = await downloadCampaignAccountingSummary();

      downloadDataAsCSVFile(response.data, `campaign_accounting_summary.csv`);

      toast.success("Summary exported to CSV");
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toast.error("Failed to download CSV");
    }

    setDownloading(false);
  };

  return (
    <>
      <SinglePageLayoutWithData
        entityName="campaign"
        pageTitle="Campaigns Accounting Summary"
        pageName="Campaigns Accounting Summary"
        sortPathName="name"
        sortPathDate="created_at"
        hideCreateNewButton
        headerButtons={
          <div className="flex gap-2">
            <FButton
              loading={isDownloading}
              label="Download CSV"
              onClick={handleDownload}
            />
          </div>
        }
        tableOnly
        tableColumns={TABLE_COLUMNS}
        getItems={getCampaigns}
        updateModal={CreateOrUpdateBrandModal}
      />
    </>
  );
};

export default FinanceAdmin;
