import React from "react";
import SkeletonLoader from "@components/Dashboard/SkeletonLoader";

export default function TierPerformanceKPISkeleton() {
  return (
    <div className="flex flex-col text-left cursor-pointer rounded-lg transition-colors">
      <span className="text-sm text-black">
        <SkeletonLoader type="text" width="w-32" height="h-5" />
      </span>
      <div className="flex flex-row gap-1">
        <span className="text-2xl font-bold mt-[5px]">
          <SkeletonLoader type="text" width="w-12" height="h-8" />
        </span>
      </div>
    </div>
  );
}
