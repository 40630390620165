import React from "react";
import FIcon from "./FIcon";

interface ButtonProps {
  className?: string;
  label?: string;
  selected?: boolean;
  category?: boolean;
  icon?: {
    name: string;
    className?: string;
    size?: number;
    color: string;
    position?: "left" | "right";
  };
  onClick?: (arg?: any) => void;
  key?: any;
  labelLeft?: any;
  labelRight?: any;
}

export const FChip = ({
  className = "",
  label = "",
  icon,
  selected,
  category,
  labelLeft,
  labelRight,
  ...props
}: ButtonProps) => {
  const getChipClass = () => {
    let chipClass = "";
    if (category) {
      chipClass += `border ${
        selected ? "border-dark_night_sky" : "border-grey_lighter"
      }`;
    } else {
      if (selected) {
        chipClass += "border border-dark_night_sky";
      } else {
        chipClass += "border border-weak bg-white px-4 py-1.5";
      }
    }

    if (props.onClick) {
      chipClass += " cursor-pointer";
      if (category) {
        chipClass += " hover:bg-grey_lighter";
      } else {
        if (!selected) {
          chipClass += " hover:bg-neutral_200";
        }
      }
    }

    return chipClass;
  };

  const iconPosition =
    !icon?.position || icon.position === "right" ? "" : "flex-row-reverse";

  return (
    <div key={props?.key}>
      <div
        className={`
           font-normal inline-flex items-center
          py-[2px] text-dark_night_sky rounded-full px-[8px] ${className} ${iconPosition}
          ${getChipClass()} w-max
        `}
        {...props}
      >
        {labelLeft}
        {label}
        {labelRight}
        {icon && (
          <FIcon
            icon={icon.name}
            size={icon.size || 16}
            color={icon.color || "#000F45"}
            className={`${
              !icon.position || icon.position === "right"
                ? "ml-[8px]"
                : "mr-[8px]"
            } ${icon.className}`}
          />
        )}
      </div>
    </div>
  );
};
