import React, { useCallback, useEffect, useState, useContext } from "react";

import { Sort } from "@types";
import { getGroupList, deleteGroup, getGroupOwners } from "@api/Groups/Groups";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import CreateOrUpdateGroupModal from "./CreateOrUpdateGroupModal";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const actions = {
  delete: async (id: number) => deleteGroup(id),
};

const tableColumns = [
  {
    key: "name",
    label: "Name",
    isLink: true,
    linkURL: "/groups/{id}",
    isFirstColumn: true,
  },
  {
    key: "creators_count",
    label: "Creators",
    isNumber: true,
  },
  { nestedKey: "owner_user", key: "full_name", label: "Owner" },
  { key: "archived", label: "Archived", isStateBubble: true },
  { key: "featured", label: "Featured", isStateBubble: true },
  { key: "created_at", label: "Created", isDate: true },
];

export default function GroupsList() {
  const [groupOwners, setGroupOwners] = useState([]);
  const [groupState, setGroupState] = useState("all");
  const { trackEvent } = useContext(MixpanelContext);

  useEffect(() => {
    trackEvent(EVENTS.PAGE_VIEW.GROUPS);

    document.title = "Groups";
  }, []);

  useEffect(() => {
    const loadGroupOwners = async () => {
      const response = await getGroupOwners();

      setGroupOwners(response.data.group_owners);
    };

    loadGroupOwners();
  }, []);

  const getGroups = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const data = await getGroupList(false, page, sort, {
        user_id_eq: filter,
        search,
        archived_eq: groupState === "archived" ? true : undefined,
        featured_eq: groupState === "featured" ? true : undefined,
      });

      const { groups: items } = data.data;

      return {
        ...data.data,
        items,
      };
    },
    [groupState]
  );

  const filterOptions = [
    { label: "All Owners", static: "Groups", value: null },
    ...groupOwners.map(({ id, full_name }) => ({
      value: id,
      label: full_name,
    })),
  ];

  const pageSwitch = (
    <div className="flex justify-between border border-light_border bg-white h-[40px]">
      <div className="flex items-center">
        <button
          className={` text-[15px] px-4 py-2 h-full flex items-center justify-center ${groupState === "all" ? "bg-highlight_red" : "opacity-80 hover:opacity-100"} `}
          onClick={() => {
            setGroupState("all");
            trackEvent(EVENTS.GROUP.ALL_VIEWED);
          }}
        >
          All Groups
        </button>
        <button
          className={` text-[15px] px-4 py-2 h-full flex items-center justify-center border-l border-light_border ${groupState === "featured" ? "bg-highlight_red" : "opacity-80 hover:opacity-100"}`}
          onClick={() => {
            setGroupState("featured");
            trackEvent(EVENTS.GROUP.FEATURED_VIEWED);
          }}
        >
          Featured
        </button>
        <button
          className={`text-[15px] px-4 py-2 h-full flex items-center justify-center  border-l border-light_border ${groupState === "archived" ? "bg-highlight_red" : "opacity-80 hover:opacity-100"}`}
          onClick={() => {
            setGroupState("archived");
            trackEvent(EVENTS.GROUP.ARCHIVED_VIEWED);
          }}
        >
          Archived
        </button>
      </div>
    </div>
  );

  return (
    <>
      <SinglePageLayoutWithData
        pageSwitch={pageSwitch}
        entityName="group"
        pageTitle="Groups"
        pageName="Groups"
        sortPathName="name"
        sortPathDate="created_at"
        filterOptions={filterOptions}
        filterPath="owner_user.full_name"
        tableColumns={tableColumns}
        rowActions={cardDropDownOptions}
        cardDropDownOptions={cardDropDownOptions}
        tableRowDropDownOptions={cardDropDownOptions}
        tableOnly
        asyncActions={actions}
        getItems={getGroups}
        updateModal={CreateOrUpdateGroupModal}
      />
    </>
  );
}
