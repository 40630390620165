import SocialConnectButton from "@pages/auth/influencer-onboarding/social/SocialConnectButton";
import React from "react";

interface TiktokConnectButtonProps {
  isConnected: boolean;
  iconOnly?: boolean;
  onClick: () => void;
  onConnect?: () => void;
}

const TiktokConnectButton = ({
  isConnected,
  iconOnly,
  onClick,
  onConnect,
}: TiktokConnectButtonProps) => {
  const TIKTOK_CLIENT_KEY = import.meta.env.VITE_TIKTOK_CLIENT_KEY;
  const REDIRECT_URI = import.meta.env.VITE_TIKTOK_REDIRECT_URI; // Define in .env
  const redirectUriEncoded = encodeURIComponent(REDIRECT_URI);

  const tiktokRedirectUrl = `https://www.tiktok.com/v2/auth/authorize?client_key=${TIKTOK_CLIENT_KEY}&scope=user.info.basic%2Cuser.info.username%2Cuser.info.stats%2Cuser.info.profile%2Cuser.account.type%2Cuser.insights%2Cvideo.list%2Cvideo.insights%2Ccomment.list%2Ccomment.list.manage%2Cvideo.publish%2Cvideo.upload%2Cbiz.spark.auth%2Cbiz.creator.info%2Cbiz.creator.insights%2Ctcm.order.update&response_type=code&redirect_uri=${redirectUriEncoded}`;

  const connectTiktok = async () => {
    try {
      window.location.href = tiktokRedirectUrl;
    } catch (error) {
      console.error("Failed to connect to TikTok:", error);
    }
  };

  return (
    <SocialConnectButton
      onClick={() =>
        onConnect ? onConnect() : isConnected ? onClick() : connectTiktok()
      }
      name="TikTok"
      icon="tiktok"
      iconOnly={iconOnly}
      isConnected={isConnected}
    />
  );
};

export default TiktokConnectButton;
