import OnboardingStepContainer from "../components/OnboardingStepContainer";

import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useAuth from "@hooks/useAuth";
import { getCreator } from "@api/Creators/Creators";

import FSpinner from "@components/FSpinner";

import AddPersonalInfo from "./AddPersonalInfo";

import { Profile } from "@types";

const AddPersonalInfoPage = () => {
  const { brand } = useParams<{ brand: string }>();

  const [profile, setProfile] = useState<Partial<Profile>>(null);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setLoading(false);

      return;
    }

    const fetchCreatorData = async () => {
      const response = await getCreator(user.creator_id);

      const {
        about_you,
        birthday,
        firstName,
        lastName,
        gender,
        profile_image_url,
      } = response.data;

      const {
        address_city,
        address_country,
        address_line_1,
        address_state,
        address_zip,
        ethnicity,
      } = about_you;

      setProfile({
        first_name: firstName || "",
        last_name: lastName || "",
        city: address_city || "",
        country: address_country,
        state: address_state || "",
        zipcode: address_zip,
        gender,
        ethnicity: ethnicity || [],
        address: address_line_1 || "",
        birthday,
        profile_image_url,
      });

      setLoading(false);
    };

    fetchCreatorData();
  }, [user]);

  const handleSubmitted = () => {
    const destination = brand
      ? `/${brand}/ambassador-onboarding/confirm-details`
      : "/signup/influencer/confirm-details";

    navigate(destination);
  };

  return (
    <OnboardingStepContainer
      title={brand ? null : "Personal Info"}
      description="Add your personal info"
      currentStep={2}
      isBrand={!!brand}
      stepsCount={3}
    >
      {loading ? (
        <div className="h-[200px]">
          <FSpinner width={24} height={24} borderWidth={2} />
        </div>
      ) : (
        <AddPersonalInfo profile={profile} onSubmitted={handleSubmitted} />
      )}
    </OnboardingStepContainer>
  );
};

export default AddPersonalInfoPage;
