import axios from "axios";

const API2_PDF_API_KEY = "c27f4362-2752-49d7-9a68-795f203df5b7";

const ROOT_URL = "https://v2.api2pdf.com";

const headers = {
  "Content-Type": "application/json",
  Authorization: API2_PDF_API_KEY,
};

export const getPdfFromHtml = async (html: string) => {
  const response = await axios.post(
    `${ROOT_URL}/chrome/pdf/html?outputBinary=true&apikey=${API2_PDF_API_KEY}`,
    {
      html: `<div>${html}</div>`,
    },
    { headers, responseType: "blob", withCredentials: false }
  );

  return response.data;
};

export const getDocxFromHtml = async (html: string) => {
  const response = await axios.post(
    `${ROOT_URL}/libreoffice/html-to-docx?outputBinary=true&apikey=${API2_PDF_API_KEY}`,
    {
      html: `<div>${html}</div>`,
    },
    { headers, responseType: "blob", withCredentials: false }
  );

  return response.data;
};
