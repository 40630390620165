import FIcon from "@components/FIcon";
import placeholderImage from "@images/image_placeholder.png";
import { displayCounts } from "@utils/CampaignUtils";
import FDropdown from "@components/FDropdown";
import toast from "react-hot-toast";

const CmsBriefDeliverablePost = ({
  deliverable,
  participantGroups = [],
  isReadOnly,
  influencers: allInfluencers = [],
  openModal = null,
  editDeliverable = null,
  deleteDeliverable = null,
  onUploadContent = null,
}) => {
  const influencers = allInfluencers.filter(
    ({ participant_group_id }) =>
      participant_group_id === deliverable.participant_group_id
  );

  const { shorturl } = deliverable;

  const handleCopyShortUrl = (shorturl: string) => {
    navigator.clipboard.writeText(shorturl).then(
      () => {
        toast.success("Short link copied to clipboard!");
      },
      (err) => {
        toast.error("Failed to copy short link: ", err);
      }
    );
  };

  const participantGroup = participantGroups.find(
    ({ id }) => id === deliverable.participant_group_id
  );

  return (
    <>
      <div
        key={deliverable.id}
        className={`w-full bg-white px-4 border border-default_weak rounded-lg shadow-sm hover:shadow-md hover:border-black transition-all duration-300 cursor-pointer mb-4 last:mb-0`}
        onClick={() => openModal && openModal(deliverable)}
      >
        <div className="flex flex-row items-center py-6 w-full">
          <div className="flex items-center h-full flex-grow gap-4 w-full relative z-10 pr-4">
            <div className="flex items-center justify-center w-[50px] min-w-[50px] border border-neutral_400 h-[50px] rounded-full">
              <FIcon icon={deliverable.platform} size={20} color="#000F45" />
            </div>
            <div className="flex flex-col justify-center h-full">
              <h4 className="text-xl text-dark_night_sky capitalize">
                {deliverable.name}
              </h4>
              {participantGroup ? (
                <div className="border border-light_border my-2 px-2 py-1 rounded-3xl text-xs">
                  {participantGroup.name}
                </div>
              ) : null}
              {displayCounts(deliverable)}
              {shorturl ? (
                <p
                  className="mt-2 cursor-copy text-sm"
                  onClick={() => handleCopyShortUrl(shorturl)}
                >
                  {shorturl}
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex-shrink-0">
            {(influencers.length > 0 || !isReadOnly) && (
              <div className="flex justify-between">
                <div className="flex">
                  {influencers.slice(0, 3).map((influencer) => {
                    const { creator, id } = influencer;

                    const { first_name, lastName, profile_image_url } =
                      creator || {};

                    return (
                      <div
                        key={id}
                        title={`${first_name} ${lastName}`}
                        style={{
                          backgroundImage: `url(${
                            profile_image_url || placeholderImage
                          })`,
                        }}
                        className="w-[44px] h-[44px] bg-border_light bg-cover bg-center rounded-full border border-neutral_400 ml-[-16px]"
                      />
                    );
                  })}
                  {influencers.length > 3 && (
                    <div className="w-[44px] h-[44px] bg-white bg-cover bg-center rounded-full border border-neutral_400 ml-[-16px] flex items-center justify-center text-dark_night_sky text-sm">
                      {influencers.length > 99
                        ? "99+"
                        : `+${influencers.length - 3}`}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {(editDeliverable && deleteDeliverable) || onUploadContent ? (
            <div className="right-0">
              <FDropdown
                width="auto"
                height="100%"
                className="pl-4"
                options={[
                  ...(onUploadContent
                    ? [{ label: "Upload content", value: "Upload" }]
                    : []),
                  ...(editDeliverable
                    ? [{ label: "Edit", value: "Edit" }]
                    : []),
                  ...(deleteDeliverable
                    ? [{ label: "Delete", value: "Delete" }]
                    : []),
                ]}
                onChange={(event) => {
                  if (event === "Upload") {
                    onUploadContent();
                  } else if (event === "Edit") {
                    editDeliverable(deliverable);
                  } else if (event === "Delete") deleteDeliverable(deliverable);
                }}
                iconOnly={true}
                iconColor="#000F45"
                icon="ellipsis"
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CmsBriefDeliverablePost;
