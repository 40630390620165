import { updateBrand } from "@api/brands";
import { updateCustomEmail } from "@api/custom_emails";
import FButton from "@components/FButton";
import FFileUpload from "@components/FFileUpload/FFileUpload";
import { FInput, FTextarea, FCheckbox } from "@components/FInputs";
import FormField from "@components/Form/FormField";
import RichTextEditor from "@components/RichTextEditor";
import { AmbassadorsContext } from "@contexts/index";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { useContext, useState } from "react";
import toast from "react-hot-toast";

const AmbassadorsEmailSettings = () => {
  const { brand } = useContext(AmbassadorsContext);
  const { trackEvent } = useContext(MixpanelContext);
  const emailKind = "ambassador_application_complete";
  const ampEmail = brand.custom_emails.find(
    (email) => email.kind === emailKind
  );
  const [isSaving, setIsSaving] = useState(false);
  const [subject, setSubject] = useState(ampEmail?.subject);
  const [body, setBody] = useState(ampEmail?.body);

  const handleSave = async () => {
    if (!subject || !body) {
      toast.error("Both fields are required");
      return;
    }

    setIsSaving(true);

    const params = {
      subject: subject,
      body: body,
    };

    try {
      // Track that we're updating the email template
      trackEvent(EVENTS.AMBASSADOR.EMAIL_UPDATED, {
        brand_id: brand?.id,
        email_kind: emailKind,
        has_subject_changes: subject !== ampEmail?.subject,
        has_body_changes: body !== ampEmail?.body,
      });

      await updateCustomEmail(emailKind, params);
      toast.success("Updated email settings");

      // Track successful email update
      trackEvent(EVENTS.AMBASSADOR.SETTINGS_UPDATED, {
        brand_id: brand?.id,
        settings_section: "email",
        update_type: "email_template",
        success: true,
      });
    } catch (e) {
      toast.error("Error updating email settings");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "email_settings_update_error",
        brand_id: brand?.id,
        error_message: e?.message || "Error updating email settings",
        settings_section: "email",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col gap-8 mb-8 pb-8 w-[400px]">
      <FInput
        label="Subject"
        value={subject}
        onChange={setSubject}
        required
        width="100%"
      />

      <div>
        <label
          className="mb-[6px] inline-block text-sm font-medium leading-[18px]"
          htmlFor="email-body"
        >
          Body*
        </label>
        <RichTextEditor value={body} onChange={setBody} id="email-body" />
      </div>
      <div className="flex justify-end w-full">
        <FButton label="Save" loading={isSaving} onClick={handleSave} primary />
      </div>
    </div>
  );
};

export default AmbassadorsEmailSettings;
