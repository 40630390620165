import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

const listCampaignAccountingSummaries = async (params?: {
  q?: {
    campaign_id_eq?: number;
    brand_id_eq?: number;
    user_id_eq?: number;
  };
  page?: number;
}): Promise<ApiResponse<any>> => {
  const queryParams = new URLSearchParams();

  if (params?.q?.campaign_id_eq) {
    queryParams.append("q[campaign_id_eq]", params.q.campaign_id_eq.toString());
  }
  if (params?.q?.brand_id_eq) {
    queryParams.append("q[brand_id_eq]", params.q.brand_id_eq.toString());
  }
  if (params?.q?.user_id_eq) {
    queryParams.append("q[user_id_eq]", params.q.user_id_eq.toString());
  }
  if (params?.page) {
    queryParams.append("page", params.page.toString());
  }

  queryParams.append("per_page", "24");

  return apiWrapper(
    `/campaign_accounting_summaries?${queryParams.toString()}`,
    "GET"
  );
};

const downloadCampaignAccountingSummary = async (): Promise<
  ApiResponse<any>
> => {
  return apiWrapper(`/campaign_accounting_summaries.csv`, "GET");
};

export { listCampaignAccountingSummaries, downloadCampaignAccountingSummary };
