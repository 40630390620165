import React, { useContext, useEffect, useState } from "react";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";

import { QuestionnaireQuestion, FieldType } from "types/questionnaire";

import toast from "react-hot-toast";

import { useNavigate, useParams } from "react-router-dom";

import FButton from "@components/FButton";
import { isArray, isEmpty } from "lodash";

import { getQuestionsWithChildren } from "@utils/questions";
import Question from "@components/questions/Question";
import { listBulletinQuestions } from "@api/Bulletins/BulletinQuestions";
import {
  createBulletinResponse,
  getBulletinResponseList,
} from "@api/Bulletins/BulletinResponses";
import useAuth from "@hooks/useAuth";
import LoadingSpinner from "@components/LoadingSpinner";

import FIcon from "@components/FIcon";
import { BulletinContext } from "@contexts/index";
import { Role } from "@constants/roles";

const isQuestionAnswered = (
  question: QuestionnaireQuestion,
  answer: string | string[]
) => {
  const { field_type } = question;

  return field_type === FieldType.MULTI_SELECT
    ? !isEmpty(answer as string[])
    : answer;
};

const areAllQuestionsAnswered = (
  questions: QuestionnaireQuestion[],
  answers: Record<string, string | string[]>
) =>
  questions.every((question) => {
    const { required } = question;

    const answer = answers[question.id];

    return !required || isQuestionAnswered(question, answer);
  });

const getAllApplicableQuestions = (
  questions: QuestionnaireQuestion[],
  answers: Record<string, string | string[]>
) =>
  questions.reduce((result, question) => {
    const { children } = question;

    const answer = answers[question.id];

    const childQuestions = children && children[`${answer}`];

    let newResult = [...result, question];

    if (childQuestions) {
      newResult = [...newResult, ...childQuestions];
    }

    return newResult;
  }, []);

const BulletinAnswerQuestionsPage = () => {
  const { bulletin_id } = useParams();

  const [loading, setLoading] = useState(true);

  const [questions, setQuestions] = useState<QuestionnaireQuestion[]>([]);
  const [answers, setAnswers] = useState<Record<string, string | string[]>>({});

  const [existingResponses, setExistingResponses] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const navigate = useNavigate();

  const { hasRole, user } = useAuth();

  const { bulletin } = useContext(BulletinContext);

  const { trackEvent } = useMixpanelTracking();

  useEffect(() => {
    const loadQuestionsAndResponses = async () => {
      try {
        const questions = await listBulletinQuestions({
          bulletin_id: parseInt(bulletin_id, 10),
        });

        const response = await getBulletinResponseList(false, 1, null, {
          bulletin_id_eq: bulletin_id,
          creator_id_eq: user.creator_id,
        });

        const responses = response.data.bulletin_responses;

        setAnswers(
          responses.reduce(
            (result, response) => ({
              ...result,
              [response.question_id]: response.answer,
            }),
            {}
          )
        );

        setExistingResponses(responses);
        setQuestions(getQuestionsWithChildren(questions));
      } catch (e) {
        console.error("Error loading bulletin", e);
      } finally {
        setLoading(false);
      }
    };

    loadQuestionsAndResponses();
  }, []);

  const handleUpdateAnswer = (questionId: string, value: string | string[]) => {
    trackEvent(EVENTS.BULLETIN.QUESTION_ANSWERED, {
      bulletin_id: bulletin_id,
      bulletin_name: bulletin?.name,
      question_id: questionId,
      is_multi_select: Array.isArray(value),
    });

    setAnswers({
      ...answers,
      [questionId]: value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);

    const allQuestions = getAllApplicableQuestions(questions, answers);

    if (!areAllQuestionsAnswered(allQuestions, answers)) {
      return;
    }

    setIsWaiting(true);

    try {
      trackEvent(EVENTS.BULLETIN.FORM_SUBMITTED, {
        bulletin_id: bulletin_id,
        bulletin_name: bulletin?.name,
        questions_count: allQuestions.length,
        questions_answered: Object.keys(answers).length,
      });

      for (const question of allQuestions) {
        const { id } = question;

        await createBulletinResponse({
          bulletin_id: parseInt(bulletin_id, 10),
          creator_id: user.creator_id,
          question_id: id,
          answer: answers[id],
          data:
            question.field_type === "multi_select"
              ? { options: answers[id] }
              : null,
        });
      }

      navigate("/");
    } catch (e) {
      toast.error("Error saving changes");

      trackEvent("Error", {
        error_type: "Bulletin Form Submission Error",
        bulletin_id: bulletin_id,
        error_message: e?.message || "Unknown error",
      });
    } finally {
      setIsWaiting(false);
    }
  };

  const isReadOnly = !isEmpty(existingResponses);

  if (loading) {
    return (
      <div className="h-[400px] flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!questions.length) {
    return (
      <div className="min-h-screen bg-light_red flex items-center justify-center">
        <p className="text-xl">No questions available</p>
      </div>
    );
  }

  return (
    <>
      <button
        onClick={() => {
          trackEvent(EVENTS.BULLETIN.CONTINUE_CLICKED, {
            bulletin_id: bulletin_id,
            bulletin_name: bulletin?.name,
            direction: "back_to_bulletin",
            questions_answered: Object.keys(answers).length,
          });
          navigate(`/bulletins/${bulletin_id}/preview`);
        }}
        className="flex items-center gap-2 text-sm text-gray-600 hover:text-black transition-colors mb-4"
      >
        <FIcon color="#000F45" icon="back" size={16} />
        Back to Bulletin
      </button>
      {bulletin.category ? (
        <p className="text-sm capitalize mb-4 opacity-50">
          {bulletin.category}
        </p>
      ) : null}
      <h1 className="text-4xl font-bold mb-6">{bulletin.name}</h1>
      <div className="flex flex-col gap-8 max-w-[800px] mt-16">
        {questions.map((question) => {
          const { children, field_type } = question;

          const answer = answers[question.id];

          let childQuestions;

          if (field_type === FieldType.MULTI_SELECT) {
            childQuestions =
              children &&
              isArray(answer) &&
              !isEmpty(answer) &&
              answer.reduce((result, value) => {
                const questions = children[value] || [];

                return [...result, ...questions];
              }, []);
          } else if (children) {
            childQuestions = children[`${answer}`];
          }

          return (
            <>
              <Question
                isDisabled={isReadOnly}
                question={question}
                answer={answer}
                onUpdateAnswer={(value) =>
                  handleUpdateAnswer(question.id, value)
                }
                validate={isSubmitted}
                key={question.id}
              />
              {childQuestions
                ? childQuestions.map((childQuestion) => (
                    <Question
                      isDisabled={isReadOnly}
                      question={childQuestion}
                      answer={answers[childQuestion.id]}
                      onUpdateAnswer={(value) =>
                        handleUpdateAnswer(childQuestion.id, value)
                      }
                      validate={isSubmitted}
                      key={childQuestion.id}
                    />
                  ))
                : null}
            </>
          );
        })}
        {!isReadOnly ? (
          <div className="flex justify-end">
            <FButton
              primary
              disabled={!hasRole(Role.INFLUENCER)}
              loading={isWaiting}
              onClick={handleSubmit}
              label="Submit Application"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default BulletinAnswerQuestionsPage;
