import FDropdown from "@components/FDropdown";
import { BriefSection } from "@types";
import React from "react";

interface BriefContractSectionProps {
  section: BriefSection;
  editSection?: (section: BriefSection) => void;
  onClick?: (section: BriefSection) => void;
  onExport?: (section: BriefSection, format: string) => void;
}

const BriefContractSection = ({
  section,
  editSection,
  onClick,
  onExport,
}: BriefContractSectionProps) => {
  const options = [];

  if (editSection) {
    options.push({ label: "Edit", value: "Edit" });
  }

  if (onExport) {
    options.push({ label: "Export to PDF", value: "exportToPdf" });
  }

  return (
    <div
      key={section.id}
      className="w-full bg-white px-8 border flex flex-row justify-between items-center border-default_weak rounded-md hover:border-black cursor-pointer mb-4 last:mb-0"
      onClick={() => (onClick ? onClick(section) : editSection(section))}
    >
      {/* container for controls */}
      <div className="flex flex-row items-center py-6 w-full">
        <div className="flex items-start h-full flex-grow gap-4 w-full relative z-10 pr-4">
          <div className="flex flex-col justify-center w-full">
            <h4 className="text-xl relative text-dark_night_sky capitalize w-full">
              {section.name}
            </h4>
          </div>
        </div>
      </div>
      {editSection || onExport ? (
        <div className="right-0 flex items-center">
          <FDropdown
            width="auto"
            height="100%"
            className="pl-4"
            options={options}
            onChange={(event) => {
              if (event === "Edit") {
                editSection(section);
              } else if (event === "exportToPdf") {
                onExport(section, "pdf");
              }
            }}
            iconOnly={true}
            iconColor="#000F45"
            icon="ellipsis"
          />
        </div>
      ) : null}
    </div>
  );
};

export default BriefContractSection;
