import React, { useEffect, useState } from "react";

import { QuestionnaireQuestion, FieldType } from "types/questionnaire";

import { listAmbassadorQuestions } from "@api/Ambassadors/AmbassadorQuestions";
import toast from "react-hot-toast";

import { useNavigate, useParams } from "react-router-dom";
import Question from "@components/questions/Question";
import FButton from "@components/FButton";
import { isArray, isEmpty } from "lodash";

import {
  createAmbassadorResponse,
  getAmbassadorCreatorResponses,
} from "@api/Ambassadors/AmbassadorResponses";
import { completeAmbassadorApplication } from "@api/Ambassadors/Ambassadors";
import SocialMediaConnections from "@pages/auth/influencer-onboarding/SocialMediaConnections";
import { getBrandBySlugForAmbassadorOnboarding } from "@api/brands";
import { Brand, SocialMedialPlatform } from "@types";
import { getQuestionsWithChildren } from "@utils/questions";

const isQuestionAnswered = (
  question: QuestionnaireQuestion,
  answer: string | string[]
) => {
  const { field_type } = question;

  return field_type === FieldType.MULTI_SELECT
    ? !isEmpty(answer as string[])
    : answer;
};

const areAllQuestionsAnswered = (
  questions: QuestionnaireQuestion[],
  answers: Record<string, string | string[]>
) =>
  questions.every((question) => {
    const { required } = question;

    const answer = answers[question.id];

    return !required || isQuestionAnswered(question, answer);
  });

const getAllApplicableQuestions = (
  questions: QuestionnaireQuestion[],
  answers: Record<string, string | string[]>
) =>
  questions.reduce((result, question) => {
    const { children } = question;

    const answer = answers[question.id];

    const childQuestions = children && children[`${answer}`];

    let newResult = [...result, question];

    if (childQuestions) {
      newResult = [...newResult, ...childQuestions];
    }

    return newResult;
  }, []);

const AnswerQuestions = () => {
  const { brand: brandSlug } = useParams<{
    brand: string;
  }>();

  const [brand, setBrand] = useState<Brand>(null);
  const [questions, setQuestions] = useState<QuestionnaireQuestion[]>([]);

  const [connections, setConnections] = useState({
    instagram: null,
    tiktok: null,
    youtube: null,
  });

  const [answers, setAnswers] = useState<Record<string, string | string[]>>({});

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const navigate = useNavigate();

  const {
    primary_connection = "instagram",
    required_connections = ["instagram"],
  } = brand || {};

  const otherConnections = ["instagram", "tiktok", "youtube"].filter(
    (connection) => connection !== primary_connection
  );

  useEffect(() => {
    const loadBrandAndResponses = async () => {
      const brand = await getBrandBySlugForAmbassadorOnboarding(brandSlug);

      setBrand(brand);

      const questions = await listAmbassadorQuestions({
        q: {
          brand_id_eq: brand.id,
        },
      });

      const responses = await getAmbassadorCreatorResponses(brand.id);

      const existingAnswers = responses.reduce((result, response) => {
        if (response.answer) {
          return {
            ...result,
            [response.ambassador_question_id]: response.answer,
          };
        }

        return result;
      }, {});

      setAnswers(existingAnswers);

      setQuestions(getQuestionsWithChildren(questions));
    };

    loadBrandAndResponses();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitted(true);

    const allQuestions = getAllApplicableQuestions(questions, answers);

    if (!areAllQuestionsAnswered(allQuestions, answers)) {
      return;
    }

    try {
      for (const question of allQuestions) {
        const { id } = question;

        await createAmbassadorResponse({
          ambassador_question_id: id,
          answer: answers[id],
          data: question.field_type === "multi_select" ? answers[id] : null,
          brand_slug: brandSlug,
        });
      }

      if (required_connections && required_connections.length > 0) {
        const allPresent = required_connections.every(
          (connection) => !!connections[connection]
        );

        if (!allPresent) {
          toast.error("Please connect your required social accounts.");

          return;
        }
      }

      // If there are no required connections defined, proceed as normal.
      completeAmbassadorApplication({ slug: brandSlug });

      toast.success("Application submitted!");

      navigate(`/${brand.slug}/ambassador-onboarding/application-submitted`);
    } catch (e) {
      toast.error("Error saving changes");
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <div className="flex flex-col gap-8 w-full h-full">
      <div className="flex-1 h-full overflow-y-auto">
        <SocialMediaConnections
          hideContinueButton
          platforms={[primary_connection as SocialMedialPlatform]}
          connections={connections}
          source="ambassador_onboarding"
          onUpdateConnections={(updates) =>
            setConnections({ ...connections, ...updates })
          }
        />
        <p className="mb-8 text-xs text-default_weak">
          Answering these questions and connecting your account will allow Fohr
          Inc to view your content, as well as your performance metrics to help
          track the partnerships you work on. Want to learn more about how this
          information will be used, or see our privacy policy.
        </p>
        <p className="text-md mb-2">Connect additional socials (optional)</p>
        <SocialMediaConnections
          hasCompactView
          hideContinueButton
          platforms={otherConnections}
          connections={connections}
          source="ambassador_onboarding"
          onUpdateConnections={(updates) =>
            setConnections({ ...connections, ...updates })
          }
        />
        <div className="flex flex-col gap-8">
          {questions.map((question) => {
            const { children, field_type } = question;

            const answer = answers[question.id];

            let childQuestions;

            if (field_type === FieldType.MULTI_SELECT) {
              childQuestions =
                children &&
                isArray(answer) &&
                !isEmpty(answer) &&
                answer.reduce((result, value) => {
                  const questions = children[value] || [];

                  return [...result, ...questions];
                }, []);
            } else if (children) {
              childQuestions = children[`${answer}`];
            }

            return (
              <>
                <Question
                  question={question}
                  answer={answer}
                  onUpdateAnswer={(value) =>
                    setAnswers({
                      ...answers,
                      [question.id]: value,
                    })
                  }
                  validate={isSubmitted}
                  key={question.id}
                />
                {childQuestions
                  ? childQuestions.map((childQuestion) => (
                      <Question
                        question={childQuestion}
                        answer={answers[childQuestion.id]}
                        onUpdateAnswer={(value) =>
                          setAnswers({
                            ...answers,
                            [childQuestion.id]: value,
                          })
                        }
                        validate={isSubmitted}
                        key={childQuestion.id}
                      />
                    ))
                  : null}
              </>
            );
          })}
        </div>
      </div>
      <FButton
        loading={isWaiting}
        primary
        onClick={handleSubmit}
        label="Submit application"
        width="100%"
      />
    </div>
  );
};
export default AnswerQuestions;
