import FButton from "@components/FButton";
import FIcon from "@components/FIcon";
import { ContentReviewRound } from "@types";

import { capitalize } from "lodash";
import moment from "moment";

interface ContentRejectedAlertProps {
  round: ContentReviewRound;
  onAction: () => void;
}

const ContentRejectedAlert = ({
  round,
  onAction,
}: ContentRejectedAlertProps) => {
  const { feedbacks } = round;

  return (
    <div
      className={`flex flex-col items-center justify-center mb-8 p-10 bg-highlight_red mt-10 rounded-xl`}
    >
      <FIcon icon="warning-2" size={50} color="#000F45" className="mb-4" />
      <h1 className="text-xl font-bold mb-4 capitalize">Content Rejected</h1>

      <p className="mb-8">
        Your content has been rejected with the following feedback:
      </p>
      <div className="flex flex-col gap-4 mb-8 w-full">
        {feedbacks
          .filter(({ recipient }) => recipient === "influencer")
          .map((feedback) => {
            const { author_email, author_name, body, subject, updated_at } =
              feedback;

            return (
              <div className="flex gap-12 border-b border-default_weak pb-4">
                <div>
                  <p>{author_name}</p>
                  <p className="text-sm">{author_email}</p>
                  <p className="text-sm text-default_weak">
                    {moment(updated_at).format("MMM D, YYYY, HH:mm")}
                  </p>
                </div>
                <div>
                  <p>
                    <span>Subject: </span>
                    <span className="font-bold mt-4">
                      {capitalize(subject)}
                    </span>
                  </p>
                  <p className="text-default_weak">{body}</p>
                </div>
              </div>
            );
          })}
      </div>
      {onAction ? (
        <FButton
          onClick={onAction}
          label="Upload new content"
          primary
          width={200}
          type="button"
        />
      ) : null}
    </div>
  );
};

export default ContentRejectedAlert;
