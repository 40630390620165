import React, { useContext, useEffect, useState } from "react";
import { CampaignContext } from "@contexts/index";
import BriefContractSection from "./BriefContractSection";

import apiClient from "@apiClient";
import Modal from "@components/Modals/Modal";
import AddEditContractSection, {
  AddEditContractSectionValues,
} from "./AddEditContractSection";
import { BriefSection, ContractTemplate } from "@types";
import { updateParticipantBrief } from "@api/CampaignParticipantBriefs/CampaignParticipantBriefs";
import toast from "react-hot-toast";

import { updateCampaignBrief } from "@api/CampaignBriefs/CampaignBriefs";

import {
  exportBriefContract,
  exportBriefContractSection,
  getContractSections,
} from "./contract";
import useAuth from "@hooks/useAuth";

import FButton from "@components/FButton";
import ConfirmModal from "@components/Modals/ConfirmModal";

const { get } = apiClient.contract_templates;

export default function BriefContract({ onUpdate }) {
  const { campaign, activeBrief: brief } = useContext(CampaignContext);

  const [loading, setLoading] = useState(false);

  const [sectionToEdit, setSectionToEdit] = useState(null);

  const [contractTemplate, setContractTemplate] =
    useState<ContractTemplate>(null);

  const [waiting, setWaiting] = useState(false);

  const [exportingContract, setExportingContract] = useState(false);

  const [isConfirmingCancelChanges, setConfirmingCancelChanges] =
    useState(false);

  const { user } = useAuth();

  const isParticipantBrief = !!brief.participant_id;

  const templateId = campaign.contract_template_id || 1;

  const handleSubmit = async (sectionData) => {
    setWaiting(true);

    const updates = {
      [sectionToEdit.kind]: {
        free_form: sectionData.body,
      },
    };

    await (isParticipantBrief ? updateParticipantBrief : updateCampaignBrief)(
      brief.id,
      { [isParticipantBrief ? "participant_brief" : "campaign_brief"]: updates }
    );

    onUpdate(updates);

    toast.success("Saved Successfully");

    setWaiting(false);

    setSectionToEdit(null);
  };

  useEffect(() => {
    const getTemplate = async () => {
      setLoading(true);

      try {
        const result = await get(templateId);

        setContractTemplate(result.data);
      } catch (error) {
        console.error("Error fetching contract template:", error);
      } finally {
        setLoading(false);
      }
    };

    getTemplate();
  }, [templateId]);

  const sections = getContractSections(brief, contractTemplate);

  const handleExportContract = async () => {
    setExportingContract(true);

    await exportBriefContract(campaign, brief, user, contractTemplate);

    setExportingContract(false);
  };

  const handleExportSection = async (section: BriefSection) =>
    exportBriefContractSection(campaign, brief, user, section);

  const handleCloseEditModal = (values: AddEditContractSectionValues) => {
    const { name, body } = sectionToEdit;

    if (name !== values.name || body !== values.body) {
      setConfirmingCancelChanges(true);
    } else {
      setSectionToEdit(null);
    }
  };

  return (
    <div className="mt-6">
      <FButton
        className="absolute right-0 top-0"
        primary
        loading={exportingContract}
        label="Export to PDF"
        onClick={handleExportContract}
      />
      {loading || sections?.length > 0 ? (
        <div className={`flex flex-col gap-4 ${sections.length > 0 ? "" : ""}`}>
          <div className="flex flex-col mb-8 last:mb-0">
            {sections
              ?.sort(
                (a, b) =>
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
              )
              .map((section) => (
                <BriefContractSection
                  key={section.id}
                  section={section}
                  editSection={(section) => {
                    setSectionToEdit(section);
                  }}
                  onExport={handleExportSection}
                />
              ))}
          </div>
        </div>
      ) : (
        <p>Loading</p>
      )}
      {sectionToEdit ? (
        <Modal
          title="Edit Contract"
          isOpen
          onClose={() => {
            setConfirmingCancelChanges(true);

            setSectionToEdit(null);
          }}
          actionLabel="Save"
          hasForm
          className="w-[850px] pb-0"
          zIndex={9999}
          children={
            <AddEditContractSection
              section={sectionToEdit}
              onClose={handleCloseEditModal}
              onSubmit={handleSubmit}
              waiting={waiting}
            />
          }
        />
      ) : null}
      {isConfirmingCancelChanges ? (
        <ConfirmModal
          title="Cancel changes"
          isOpen
          onClose={() => setConfirmingCancelChanges(null)}
          onAction={async () => {
            setConfirmingCancelChanges(null);

            setSectionToEdit(null);
          }}
          actionLabel="Confirm"
          subtitle="Are you sure you want to discard your changes?"
        />
      ) : null}
    </div>
  );
}
