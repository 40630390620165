import React from "react";
import Tippy from "@tippyjs/react/headless";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";

interface Props {
  style?: any;
  label: string | React.ReactNode;
  children: React.ReactNode;
}

export const FTooltip = ({ style = {}, label, children }: Props) => {
  return (
    <Tippy
      render={(attrs) => (
        <div
          className="bg-dark_night_sky text-white px-3 pt-2 pb-[6px] rounded-lg shadow-lg text-sm pointer-events-none"
          style={style}
          tabIndex={-1}
          {...attrs}
        >
          {label}
        </div>
      )}
      placement="top"
      zIndex={9999}
    >
      <div className="inline-block">{children}</div>
    </Tippy>
  );
};
