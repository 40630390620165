import React, { useEffect, useContext } from "react";
import NotFound from "@components/NotFound";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { useLocation } from "react-router-dom";

const NotFoundPage = () => {
  const { trackEvent } = useContext(MixpanelContext);
  const location = useLocation();

  useEffect(() => {
    // Track 404 page view
    trackEvent(EVENTS.PAGE_VIEW.NOT_FOUND, {
      attempted_path: location.pathname,
      referrer: document.referrer || null,
    });

    // Set page title
    document.title = "Page Not Found";
  }, [trackEvent, location.pathname]);

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <NotFound />
    </div>
  );
};

export default NotFoundPage;
