import React from "react";
import { addCommas, formatDollarValue } from "@utils/number_utilities";
import moment from "moment";
import { Column } from "@types";

interface TableTotalsProps {
  data: any[];
  columns: Column[];
  columnWidths: number[];
  showTableCheckbox: boolean;
  tableWidth: number;
}

const TableTotals: React.FC<TableTotalsProps> = ({
  data,
  columns,
  columnWidths,
  showTableCheckbox,
  tableWidth,
}) => {
  const calculateTotal = (column: Column) => {
    if (column.isMoney || column.isDate || column.isNumber) {
      const sum = data.reduce((acc, item) => {
        const value = column.nestedKey
          ? item[column.nestedKey][column.key]
          : item[column.key];

        return acc + (parseFloat(value) || 0);
      }, 0);

      if (column.isMoney) {
        return { value: formatDollarValue(sum), type: "Sum" };
      } else if (column.isDate) {
        return { value: moment.duration(sum).humanize(), type: "Sum" };
      } else if (column.isNumber) {
        const formattedValue = addCommas(sum);
        return { value: formattedValue, type: "Sum" };
      }
    } else if (column.isStateBubble) {
      const categories = data.reduce((acc, item) => {
        const value = column.nestedKey
          ? item[column.nestedKey][column.key]
          : item[column.key];
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});
      return {
        value: Object.entries(categories)
          .map(([key, count]) => `${key}: ${count}`)
          .join(", "),
        type: "Count",
      };
    } else if (column.isFirstColumn) {
      return { value: `Count: ${data.length}`, type: "Count" };
    } else {
      // return { value: data.length.toString(), type: "Count" };
      return { value: "--", type: "" };
    }
  };

  return (
    <div
      className="flex w-full border-b border-dark_night_sky bg-highlight_red "
      style={{ width: `${tableWidth}px` }}
    >
      {showTableCheckbox && (
        <div className="px-4 sticky left-0 bg-highlight_red_opacity backdrop-blur z-50 flex items-center">
          <div className="flex items-center w-[16px]" />
        </div>
      )}
      {columns.map((column, columnIndex) => (
        <div
          key={`total-${column.nestedKey ? `${column.nestedKey}-${column.key}` : column.key}`}
          className={`capitalize flex items-center ${
            columnIndex === 0
              ? `sticky left-${showTableCheckbox ? "12" : "0"} bg-highlight_red_opacity backdrop-blur z-50`
              : column.key === "actions"
                ? "sticky right-0 bg-highlight_red_opacity backdrop-blur z-50"
                : ""
          } py-[16px] z-10 bg-highlight_red_opacity`}
          style={{
            minWidth: `${columnWidths[columnIndex]}px`,
            width: "100%",
            left:
              columnIndex === 0
                ? showTableCheckbox
                  ? "48px"
                  : "0px"
                : undefined,
          }}
        >
          <div
            className="overflow-hidden text-ellipsis text-[14px]"
            style={{
              minWidth: `${columnWidths[columnIndex]}px`,
              width: "100%",
              padding: "2px 20px",
            }}
          >
            {column.key === "actions" ? (
              ""
            ) : (
              <div className="flex flex-col gap-2">
                <span className="text-[15px]">
                  {calculateTotal(column).value}
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableTotals;
