import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { Testimonial } from "@types";

const getTestimonialsList = async (
  cardId: number | string
): Promise<ApiResponse<{ testimonials: Testimonial[] }>> => {
  return apiWrapper(`/testimonials?q[creator_id_eq]=${cardId}&q[s]="created_at desc"`, "GET");
};

const getTestimonial = async (
  slug: string
): Promise<ApiResponse<Testimonial>> => {
  return apiWrapper(`/testimonials/${slug}`, "GET");
};

const createTestimonial = async (
  creator_slug: string,
  answers: object
): Promise<ApiResponse<GroupData>> => {
  return apiWrapper(
    "/testimonials",
    "POST",
    { creator_slug: creator_slug, testimonial: {...answers} }
  )
};

export { getTestimonialsList, getTestimonial, createTestimonial };
