import React, { useState, useContext } from "react";

import FButton from "@components/FButton";
import { toast } from "react-hot-toast";
import SelectCampaignTable from "@components/Creator/SelectCampaignsTable";
import { ParticipantGroup } from "@types";

import apiClient from "@apiClient";
import { useParams } from "react-router-dom";
import { Campaign } from "@types";
import {
  createCampaignParticipantFromGroups,
  createCampaignParticipant,
} from "@api/Campaign/CampaignParticipants";
import Table from "@components/Table";
import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const TABLE_COLUMNS = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "status",
    label: "Status",
    isStateBubble: true,
  },
];

interface Props {
  onClose: () => void;
  creatorID?: number;
}

const AddToCampaignModal = ({ onClose, creatorID }: Props) => {
  const { group_id } = useParams<{ group_id: string }>();
  const { trackEvent } = useContext(MixpanelContext);

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  const [step, setStep] = useState(1);
  const [selectedCampaigns, setSelectedCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null
  );
  const [selectedParticipantGroup, setSelectedParticipantGroup] =
    useState<ParticipantGroup | null>(null);
  const [participantGroups, setParticipantGroups] = useState<
    ParticipantGroup[]
  >([]);

  const fetchParticipantGroups = async (campaignId: number) => {
    try {
      const response =
        await apiClient.campaign_participant_group.get(campaignId);
      setParticipantGroups(response.data.participant_groups);
    } catch (error) {
      console.error("Failed to fetch participant groups:", error);
      setError("Failed to fetch participant groups");
    }
  };

  const handleCampaignSelect = (campaigns: Campaign[]) => {
    setSelectedCampaigns(campaigns);
    if (campaigns.length === 1) {
      setSelectedCampaign(campaigns[0]);
      fetchParticipantGroups(campaigns[0].id);
      setStep(2);
    }
  };

  const handleSubmit = async () => {
    setWaiting(true);
    setError(null);

    try {
      if (!selectedCampaign || !selectedParticipantGroup) {
        throw new Error("Please select a campaign and participant group");
      }

      if (creatorID) {
        await createCampaignParticipant(selectedCampaign.id, {
          participant_group_id: selectedParticipantGroup.id,
          creator_id: creatorID,
          status: "pending",
        });

        // Track member added to campaign
        trackEvent(EVENTS.GROUP.MEMBER_ADDED_TO_CAMPAIGN, {
          campaign_id: selectedCampaign.id,
          campaign_name: selectedCampaign.title,
          participant_group_id: selectedParticipantGroup.id,
          participant_group_name: selectedParticipantGroup.name,
          creator_id: creatorID,
        });
      } else {
        await createCampaignParticipantFromGroups(selectedCampaign.id, {
          participant_group_id: selectedParticipantGroup.id,
          group_ids: [parseInt(group_id, 10)],
          status: "pending",
        });

        // Track group added to campaign
        trackEvent(EVENTS.GROUP.ADDED_TO_CAMPAIGN, {
          campaign_id: selectedCampaign.id,
          campaign_name: selectedCampaign.title,
          participant_group_id: selectedParticipantGroup.id,
          participant_group_name: selectedParticipantGroup.name,
          group_id: parseInt(group_id, 10),
        });
      }

      setWaiting(false);
      toast.success(
        `${creatorID ? "Creator" : "Group"} added to selected campaign`
      );
      onClose();
    } catch (error) {
      setError(`Failed to add ${creatorID ? "creator" : "group"} to campaign`);
      setWaiting(false);
    }
  };

  return (
    <SelectEntitiesModal
      title={step === 1 ? "Select Campaign" : "Select Participant Group"}
      onClose={onClose}
      error={error}
      onBack={
        step === 2
          ? () => {
              setStep(1);
              setSelectedCampaigns([]);
            }
          : null
      }
      actionButton={
        <FButton
          loading={waiting}
          onClick={step === 1 ? () => {} : handleSubmit}
          primary
          label={step === 1 ? "Next" : "Add to Campaign"}
          height="40px"
          disabled={
            step === 1
              ? selectedCampaigns.length !== 1
              : !selectedParticipantGroup
          }
        />
      }
    >
      <>
        {step === 1 ? (
          <div style={{ height: "calc(100% - 70px)" }}>
            <SelectCampaignTable
              selectedItems={selectedCampaigns}
              onChangeSelectedItems={handleCampaignSelect}
            />
          </div>
        ) : (
          <div className="min-h-[60vh]">
            <Table
              data={participantGroups}
              columns={TABLE_COLUMNS}
              onChangeSelectedItems={(ids) => {
                const selectedGroup = participantGroups.find(
                  (group) => group.id === ids[0]
                );
                setSelectedParticipantGroup(selectedGroup || null);
              }}
              selectedItems={
                selectedParticipantGroup ? [selectedParticipantGroup.id] : []
              }
            />
          </div>
        )}
      </>
    </SelectEntitiesModal>
  );
};

export default AddToCampaignModal;
