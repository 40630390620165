import React, { useContext, useEffect, useState } from "react";
import { addCommas } from "@utils/number_utilities";
import { Pagination, Sort } from "@types";

import BlankState from "@components/BlankState";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AmbassadorsContext } from "@contexts/index";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { capitalize, isEmpty } from "lodash";

import FButtonGroup from "@components/FButtonGroup";
import PlatformSelect from "@pages/search/advanced/components/PlatformSelect";
import FButton from "@components/FButton";
import AdvancedCreatorSearchModal from "@pages/search/advanced/AdvancedCreatorSearchModal";
import {
  DEFAULT_ADVANCED_FILTERS,
  DEFAULT_AUDIENCE_FILTERS,
  DEFAULT_FILTERS,
  getDefaultFilters,
  areAdvancedFiltersEmpty,
} from "@pages/search/utils";
import { AdvancedSearchParams } from "@pages/search/types";
import SinglePageLayout from "@layouts/SinglePageLayout";
import { getSearchString } from "@utils/history";
import useDebouncedValue from "@hooks/useDebouncedValue";

import ResponsesSearchModal, {
  SelectedAnswer,
} from "./components/ResponsesSearchModal";
import Modal from "@components/Modals/Modal";

import FDropdown from "@components/FDropdown";
import toast from "react-hot-toast";
import { updateAmbassador } from "@api/Ambassadors/Ambassadors";

import AmbassadorReviewModal from "./review/AmbassadorReviewModal";
import { AMBASSADOR_REVIEW_STATUSES } from "./constants";
import { searchCreators } from "@api/AmbassadorSearch";
import { Ambassador } from "types/ambassador";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import { FCheckbox } from "@components/FInputs";
import { EVENTS } from "@utils/mixpanel_utilities";
import { decorationColor } from "@utils/cardUtilities";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

const PAGE_SIZE = 24;

const TABS = [
  {
    value: "interested",
    label: "Applied",
  },
  {
    value: "review_pending",
    label: "Pending Review",
  },
];

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isImage: true,
    imageType: "profile" as "asset" | "profile",
    imageKey: "profile_image",
  },
  { key: "gender", label: "Gender" },
  { key: "location", label: "Location" },
  { key: "overall_followers", label: "Followers", isNumber: true },
  { key: "onboarding_completed_at", label: "Applied", isDate: true },
  {
    key: "ambassador_status",
    label: "Status",
    isStateBuble: true,
    getContent: (row) => {
      const { ambassador_status: status } = row;

      return (
        <span
          className={`px-3 py-1 text-xs rounded-full text-white ${decorationColor(
            status
          )}`}
        >
          {status === "review_pending"
            ? "Pending Review"
            : `${capitalize(status)}  `}
        </span>
      );
    },
  },
];

const cardDropDownOptions = [
  {
    label: "Edit",
    value: "edit",
  },
];

const AmbassadorsList = ({ status }: { status: string }) => {
  const { brand, isListEmpty, overallTotals } = useContext(AmbassadorsContext);
  const { trackEvent } = useContext(MixpanelContext);

  const { ambassador_id } = useParams();

  const location = useLocation();

  const { can } = useAuth();

  const searchParams = new URLSearchParams(location.search);

  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("selectedTab") || TABS[0].value
  );

  const [ambassadors, setAmbassadors] = useState([]);
  const [sort, setSort] = useState<Sort>(null);

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    pageSize: 24,
    total: 0,
  });

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [isLoading, setLoading] = useState(false);

  const [isResponseFiltersModalVisible, setResponseFiltersModalVisible] =
    useState(false);

  const [isAdvancedFiltersModalVisible, setAdvancedFiltersModalVisible] =
    useState(false);

  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);

  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [ambassadorEdited, setAmbassadorEdited] = useState<Ambassador>(null);
  const [pendingStatus, setPendingStatus] = useState(
    AMBASSADOR_REVIEW_STATUSES[0].value
  );
  const [isUpdatingAmbassadors, setUpdatingAmbassadors] = useState(false);

  const [filters, setFilters] = useState(
    getDefaultFilters(searchParams, DEFAULT_FILTERS)
  );

  const [advancedFilters, setAdvancedFilters] = useState(
    getDefaultFilters(searchParams, DEFAULT_ADVANCED_FILTERS)
  );

  const [responsesFilters, setResponsesFilters] = useState<SelectedAnswer[]>();

  const navigate = useNavigate();

  const { searchTerm, selectedPlatforms } = filters;

  const debouncedSearchTerm = useDebouncedValue(
    searchTerm !== null ? searchTerm : searchParams.get("searchTerm"),
    500
  );

  useEffect(() => {
    trackEvent(EVENTS.PAGE_VIEW.AMBASSADORS);
  }, []);

  useEffect(() => {
    const handleSearch = async () => {
      const queryParams = getSearchString({
        ...advancedFilters,
        searchTerm: debouncedSearchTerm,
        selectedPlatforms,
        selectedTab,
      });

      navigate(
        `/ambassadors/${status}${ambassador_id ? `/${ambassador_id}` : ""}?${queryParams.toString()}`
      );

      const { currentPage } = pagination;

      setLoading(true);

      const response = await searchCreators({
        ...advancedFilters,
        p: currentPage,
        searchTerm: debouncedSearchTerm,
        status: status === "review" ? selectedTab : status,
        platforms: selectedPlatforms,
        selected_questions: !isEmpty(responsesFilters)
          ? JSON.stringify(responsesFilters)
          : null,
        page_size: PAGE_SIZE,
        sort: sort?.key || "overall_followers",
        sort_order: sort?.direction,
      });

      const { results, total } = response.data;

      const resultsWithIds = results.map((result) => ({
        ...result,
        id: result.creator_id,
      }));

      setAmbassadors(resultsWithIds);

      pagination.total = total;

      setLoading(false);
    };

    handleSearch();
  }, [
    debouncedSearchTerm,
    selectedPlatforms,
    advancedFilters,
    responsesFilters,
    pagination,
    status,
    sort,
    selectedTab,
  ]);

  const handlePageChange = (page: number) => {
    setPagination({ ...pagination, currentPage: page });
  };

  const handleSortChange = (sort: Sort) => {
    setPagination({ ...pagination, currentPage: 1 });
    setSort(sort);
  };

  const handleTableRowDropdownSelect = (value: string, id: number) => {
    const ambassador = ambassadors.find((c) => c.id === id);

    if (value === "edit") {
      setAmbassadorEdited(ambassador);
      setEditModalVisible(true);
    }
  };

  const handleUpdateStatus = async () => {
    const ambassadorsToUpdate = ambassadorEdited
      ? [ambassadorEdited.ambassador_id]
      : selectedItems.map(
          (creatorId) =>
            ambassadors.find(({ creator_id }) => creator_id === creatorId)
              .ambassador_id
        );

    setUpdatingAmbassadors(true);

    const updatePromises = ambassadorsToUpdate.map(async (ambassadorId) => {
      try {
        await updateAmbassador(ambassadorId, {
          status: pendingStatus,
        });
      } catch (error) {
        console.error(`Error updating ambassador ${ambassadorId}:`, error);
      } finally {
        setUpdatingAmbassadors(false);
      }
    });

    await Promise.all(updatePromises);

    toast.success(`Updated`);

    // Update the participants state with the new data
    const newAmbassadors = ambassadors.filter(
      (ambassador) => !ambassadorsToUpdate.includes(ambassador.ambassador_id)
    );

    setAmbassadors(newAmbassadors);

    setEditModalVisible(false);
    setSelectedItems([]);
  };

  const handleCreateSettings = () =>
    navigate("/ambassadors/onboarding-settings");

  const handleGoToAmbassadorProfile = (ambassadorId?: number) => {
    navigate(
      `/ambassadors/${status}${ambassadorId ? `/${ambassadorId}` : ""}${location.search}`,
      { replace: true }
    );
  };

  const handleTableCellClick = (item, key) => {
    if (key === tableColumns[0].key) {
      handleGoToAmbassadorProfile(item.ambassador_id);
    }
  };

  const handleUpdateAmbassador = (id, updates: Partial<Ambassador>) => {
    let newAmbassadors;

    if (updates.ambassador_status) {
      newAmbassadors = ambassadors.filter(
        (ambassador) => ambassador.ambassador_id !== id
      );
    } else {
      newAmbassadors = ambassadors.map((ambassador) =>
        ambassador.ambassador_id === id
          ? { ...ambassador, ...updates }
          : ambassador
      );
    }

    setAmbassadors(newAmbassadors);
  };

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab);
    setPagination({ ...pagination, currentPage: 1 });

    // Track tab change
    trackEvent(EVENTS.AMBASSADOR.RESPONSE_FILTERED, {
      brand_id: brand?.id,
      tab_changed: true,
      previous_tab: selectedTab,
      new_tab: tab,
    });
  };

  const handleAdvancedSearch = () => {
    // Track advanced search modal opened
    trackEvent(EVENTS.AMBASSADOR.ADVANCED_SEARCH, {
      brand_id: brand?.id,
      search_type: "advanced",
      has_existing_filters: !areAdvancedFiltersEmpty(advancedFilters),
    });

    setAdvancedFiltersModalVisible(true);
  };

  const handleResponseFilters = () => {
    // Track response filters modal opened
    trackEvent(EVENTS.AMBASSADOR.ADVANCED_SEARCH, {
      brand_id: brand?.id,
      search_type: "responses",
      has_existing_filters: !isEmpty(responsesFilters),
      filter_count: responsesFilters?.length || 0,
    });

    setResponseFiltersModalVisible(true);
  };

  const handleUpdateAdvancedFilters = (filters: AdvancedSearchParams) => {
    // Track advanced filters applied
    trackEvent(EVENTS.AMBASSADOR.RESPONSE_FILTERED, {
      brand_id: brand?.id,
      filter_type: "advanced",
      filter_count: Object.keys(filters).filter((key) => !!filters[key]).length,
      has_audience_filters: !areAdvancedFiltersEmpty(filters),
    });

    setAdvancedFilters(filters);
    setAdvancedFiltersModalVisible(false);
  };

  const handleUpdateResponsesFilters = (filters: SelectedAnswer[]) => {
    // Track response filters applied
    if (filters) {
      trackEvent(EVENTS.AMBASSADOR.RESPONSE_FILTERED, {
        brand_id: brand?.id,
        filter_type: "responses",
        filter_count: filters.length,
        question_count: filters.length,
      });
    }

    setResponsesFilters(filters);
    setResponseFiltersModalVisible(false);
  };

  const handleBulkAddToGroup = () => {
    // Track bulk add to group
    trackEvent(EVENTS.AMBASSADOR.BULK_ADD_TO_GROUP, {
      brand_id: brand?.id,
      creator_count: selectedItems.length,
      status: status,
    });

    setAddToGroupModalVisible(true);
  };

  if (!brand?.amp_description) {
    return (
      <BlankState
        title="No Landing Page Found"
        subtitle="Build your brand's landing page to start recruiting talent right away"
        icon="user"
        onActionClick={handleCreateSettings}
        actionLabel={
          can(Permission.CREATE_AMBASSADORS_ONBOARDING_SETTINGS)
            ? "Create Landing Page"
            : ""
        }
      />
    );
  }

  let statusOptions = [
    ...AMBASSADOR_REVIEW_STATUSES,
    { value: "interested", label: "Applied" },
  ];

  statusOptions = statusOptions
    .filter(({ value }) => {
      if (selectedTab === "interested") {
        return value !== selectedTab;
      }

      return value !== status;
    })
    .map((status) => ({
      ...status,
      value: status.value === "review" ? "review_pending" : status.value,
    }));

  if (ambassador_id) {
    return (
      <AmbassadorReviewModal
        ambassadors={ambassadors}
        ambassadorId={parseInt(ambassador_id, 10)}
        onClose={() => handleGoToAmbassadorProfile(null)}
        onGoToProfile={handleGoToAmbassadorProfile}
        onUpdate={handleUpdateAmbassador}
        statuses={statusOptions}
      />
    );
  }

  const areAllRowsSelected = selectedItems.length === ambassadors.length;

  return (
    <>
      <SinglePageLayout
        pageName="Ambassadors"
        cardType="individual"
        loading={isLoading}
        sortPathName="full_name"
        sortPathDate="created_at"
        externalSort={sort}
        tableColumns={tableColumns}
        pagination={pagination}
        onSearchChange={(searchTerm) => setFilters({ ...filters, searchTerm })}
        onPageChange={handlePageChange}
        onTableCellClick={handleTableCellClick}
        cardData={ambassadors}
        defaultGrid={false}
        cardDropDownOptions={cardDropDownOptions}
        numCardColumns={6}
        onCardClick={({ ambassador_id }) =>
          handleGoToAmbassadorProfile(ambassador_id)
        }
        onSortChange={handleSortChange}
        onChangeSelectedItems={setSelectedItems}
        tableRowDropDownOptions={cardDropDownOptions}
        onTableRowDropDownSelect={handleTableRowDropdownSelect}
        selectedItems={selectedItems}
        customBlankState={
          <BlankState
            title="No Applicants Here"
            subtitle={
              isListEmpty && (status === "accepted" || status === "denied")
                ? "Go to Applicants tab to review your applicants"
                : "Share your landing page, recruit tons of talent right away"
            }
            icon="user"
          />
        }
        subNavMainButton={
          selectedItems.length > 0 && (
            <div className="flex items-center gap-2">
              <FButton
                label={`Edit (${selectedItems.length})`}
                onClick={() => setEditModalVisible(true)}
              />
              {selectedItems.length ? (
                <FButton
                  primary
                  label={`(${selectedItems.length}) Add to group`}
                  onClick={handleBulkAddToGroup}
                />
              ) : null}
            </div>
          )
        }
        customFiltersContent={
          <div className="flex justify-between items-center mt-[40px] w-full">
            {status === "review" ? (
              <FButtonGroup
                options={TABS.map((tab) => ({
                  ...tab,
                  label: overallTotals[tab.value]
                    ? `${tab.label} (${addCommas(overallTotals[tab.value])})`
                    : tab.label,
                }))}
                selectedValue={selectedTab}
                onSelectedValue={handleSelectedTab}
                buttonWidth="200px"
              />
            ) : (
              <div />
            )}
            <div className="flex gap-8 items-center">
              <PlatformSelect
                selectedPlatforms={filters.selectedPlatforms}
                onChange={(newSelectedPlatforms) => {
                  setFilters({
                    ...filters,
                    selectedPlatforms: newSelectedPlatforms,
                  });

                  if (isEmpty(newSelectedPlatforms)) {
                    setAdvancedFilters({
                      ...advancedFilters,
                      ...DEFAULT_AUDIENCE_FILTERS,
                    });
                  }
                }}
              />
              <div className="flex gap-4 items-center">
                <FButton
                  label="Responses Filters"
                  primary
                  iconLeft={{ name: "filter", size: 14, color: "#fff" }}
                  onClick={handleResponseFilters}
                />
                <FButton
                  label="Advanced Search"
                  primary
                  iconLeft={{ name: "filter", size: 14, color: "#fff" }}
                  onClick={handleAdvancedSearch}
                />
              </div>
            </div>
          </div>
        }
        topLeftTableContent={
          <div className="pl-4">
            <FCheckbox
              checked={areAllRowsSelected}
              id="select-all-rows"
              label="Select all ambassadors"
              onChange={() =>
                setSelectedItems(
                  areAllRowsSelected
                    ? []
                    : ambassadors.map(({ creator_id }) => creator_id)
                )
              }
            />
          </div>
        }
        totalItemsLabel={`${overallTotals[status === "review" ? selectedTab : status]} Total ${status === "review" ? TABS.find(({ value }) => value === selectedTab).label : capitalize(status)} Applicants`}
      />
      {isAdvancedFiltersModalVisible && (
        <AdvancedCreatorSearchModal
          filters={advancedFilters}
          onClose={() => setAdvancedFiltersModalVisible(false)}
          onUpdateFilters={handleUpdateAdvancedFilters}
          selectedPlatforms={selectedPlatforms}
        />
      )}

      {isResponseFiltersModalVisible && (
        <ResponsesSearchModal
          filters={responsesFilters}
          brand={brand}
          onClose={() => setResponseFiltersModalVisible(false)}
          onUpdateFilters={handleUpdateResponsesFilters}
        />
      )}
      {isEditModalVisible ? (
        <Modal
          isOpen
          className="w-[540px]"
          onClose={() => setEditModalVisible(false)}
          zIndex={9000}
          title="Update Status"
          actionLabel="Save"
          waiting={isUpdatingAmbassadors}
          onAction={handleUpdateStatus}
        >
          <FDropdown
            width="100%"
            selectedValue={pendingStatus}
            onChange={(value) => setPendingStatus(value as string)}
            options={statusOptions}
          />
        </Modal>
      ) : null}
      {isAddToGroupModalVisible ? (
        <AddCreatorsToGroupModal
          creatorIds={selectedItems}
          onClose={() => {
            setAddToGroupModalVisible(false);
            setSelectedItems([]);
          }}
        />
      ) : null}
    </>
  );
};

export default AmbassadorsList;
