import React, { useContext, useCallback } from "react";

import { Bulletin, Sort } from "@types";
import { getBulletinList, deleteBulletin } from "@api/Bulletins/Bulletins";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import CreateOrUpdateBulletinModal from "@pages/bulletins/components/CreateOrUpdateBulletinModal";
import { UserContext } from "@contexts/index";
import { changeMembership } from "@api/user";
import toast from "react-hot-toast";

const filterOptions = [
  { label: "All", static: "Bulletins", value: null },
  { label: "Active", static: "Bulletins", value: "active" },
  { label: "Featured", static: "Bulletins", value: "featured" },
  { label: "Archived", static: "Bulletins", value: "archived" },
];

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const actions = {
  delete: async (id: number) => deleteBulletin(id),
};

export default function BulletinsPage() {
  const { user, setUser } = useContext(UserContext);
  const tableColumns = [
    {
      key: "name",
      label: "Title",
      isLink: true,
      imageKey: "image_url",
      handleTableCellClick: () => {},
    },
    { key: "status", label: "State", isStateBubble: true },
    { nestedKey: "owner_user", key: "full_name", label: "Owner" },
    { nestedKey: "brand", key: "name", label: "Company" },
    {
      key: "membership",
      label: "Your Membership",
      getValue: (item) => {
        const membership = user.memberships.find(
          (membership) => membership.brand_id === item.brand.id
        );

        return membership ? "User" : ""; // Handle null or undefined
      },
    },
    { key: "start_date", label: "Start Date", isDate: true },
    { key: "created_at", label: "Created", isDate: true },
  ];

  const getBulletins = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;
      const response = await getBulletinList(true, page, sort, {
        status_eq: filter,
        name_cont: search,
      });

      const { bulletins: items } = response.data;
      return {
        ...response.data,
        items,
      };
    },
    []
  );

  const handleTableCellClick = (item: Bulletin, columnKey: string) => {
    const membership = user.memberships.find(
      (membership) => membership.brand_id === item.brand.id
    );

    if (user.brand_id === item.brand.id) {
      // If the user is already part of the brand, directly redirect
      return redirectToBulletin(item.id);
    }

    if (membership) {
      // Handle membership change if a valid membership is found
      handleMembershipChange(membership, item);
    } else {
      // Show error if user is not a member of the brand
      toast.error(
        "You are not a member of this brand. Add yourself via the Brands page."
      );
    }
  };

  const handleMembershipChange = async (membership, item) => {
    try {
      const result = await changeMembership({ id: membership.id });
      if (result.data.brand_id === membership.brand_id) {
        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        redirectToBulletin(item.id);
      }
    } catch (error) {
      console.error("Error changing membership:", error);
    }
  };

  const redirectToBulletin = (itemId) => {
    window.location.href = `${window.location.origin}/bulletins/${itemId}`;
  };

  return (
    <SinglePageLayoutWithData
      entityName="bulletin"
      pageTitle="Bulletins"
      pageName="Bulletins"
      sortPathName="title"
      sortPathDate="date"
      filterOptions={filterOptions}
      filterPath="state"
      tableColumns={tableColumns}
      cardDropDownOptions={cardDropDownOptions}
      tableOnly
      rowActions={cardDropDownOptions}
      onTableCellClick={handleTableCellClick}
      asyncActions={actions}
      getItems={getBulletins}
      updateModal={CreateOrUpdateBulletinModal}
    />
  );
}
