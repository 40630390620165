export enum CONTENT_REVIEW_STATUS {
  NOT_SUBMITTED = "not_submitted",
  ADMIN_REVIEW = "admin_review",
  CLIENT_REVIEW = "client_review",
  ADMIN_CHANGES = "admin_changes",
  INFLUENCER_CHANGES = "influencer_changes",
  SOFT_APPROVED = "soft_approved",
  APPROVED = "approved",
}

const STATUSES = [
  {
    value: CONTENT_REVIEW_STATUS.NOT_SUBMITTED,
    label: "Not Yet Submitted",
  },
  {
    value: CONTENT_REVIEW_STATUS.ADMIN_REVIEW,
    label: "Admin Review",
  },
  {
    value: CONTENT_REVIEW_STATUS.CLIENT_REVIEW,
    label: "In Client Review",
  },
  {
    value: CONTENT_REVIEW_STATUS.ADMIN_CHANGES,
    label: "Rejected",
  },
  {
    value: CONTENT_REVIEW_STATUS.INFLUENCER_CHANGES,
    label: "Pending Changes",
  },
  {
    value: CONTENT_REVIEW_STATUS.SOFT_APPROVED,
    label: "Brand Approved",
  },
  {
    value: CONTENT_REVIEW_STATUS.APPROVED,
    label: "Approved",
  },
];

export default STATUSES;
