export const MEDIA_PRODUCT_TYPES = {
  Instagram: ["reels", "story", "feed"],
};

export const CONTENT_SORT_OPTIONS = [
  { label: "Best Match", value: null },
  { label: "Published At", value: "published_at" },
  { label: "Highest Engagement Rate", value: "followers_engagement" },
  { label: "Lowest Engagement Rate", value: "followers_engagement_asc" },
  { label: "Virality", value: "viral_ratio" },
];

export const CREATOR_SORT_OPTIONS = [
  { label: "Best Match", value: null },
  { label: "Joined Fohr", value: "created_at" },
  { label: "Most Overall Followers", value: "overall_followers" },
  { label: "Least Overall Followers", value: "overall_followers_asc" },
  { label: "Average Engagement", value: "average_engagement" },
];
