import React, { useState, useEffect, useContext } from "react";
import InstagramConnectButton from "@components/InstagramConnectionButton";
import TiktokConnectButton from "@components/TiktokConnectButton";
import YoutubeConnectButton from "@components/YoutubeConnectButton";
import { useLocation, useNavigate } from "react-router-dom";

import { UserContext } from "@contexts/index";
import Modal from "@components/Modals/Modal";
import FButton from "@components/FButton";
import FInput from "@components/FInputs/FInput";
import {
  connectToPlatform,
  getCreator,
  removePlatformConnection,
  checkUsername,
} from "@api/Creators/Creators";
import toast from "react-hot-toast";
import { capitalize } from "lodash";
import { SocialMedialPlatform } from "@types";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const INSTAGRAM = "instagram";
const TIKTOK = "tiktok";
const YOUTUBE = "youtube";

const showSuccessToast = (platform: string, isConnected = false) =>
  toast.success(
    `${capitalize(platform)} ${isConnected ? "" : "dis"}connected successfully`
  );

const showErrorToast = (platform: string, isConnecting = false) =>
  toast.success(
    `Error ${isConnecting ? "connecting to " : "disconnecting from "} ${capitalize(platform)} `
  );

// Reusable component for handling modals
const SocialMediaModal = ({
  isOpen,
  title,
  username,
  setUsername,
  handleSubmit,
  onClose,
  errorMessage,
}) => {
  if (!isOpen) return null;

  const isTikTok = title.includes("TikTok");
  // Use the platform name based on the title; if not TikTok, try to parse it from the title.
  const platformName = isTikTok ? "TikTok" : title.split(" ")[2];

  return (
    <Modal
      hasForm
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      actionLabel="Add"
      className="w-[650px] overflow-auto"
    >
      <div className="modal-content">
        {errorMessage && (
          <div className="mb-4">
            {errorMessage.split("\n").map((line, index) => (
              <p key={index} className="text-red mb-2">
                {line}
              </p>
            ))}
          </div>
        )}
        {isTikTok ? (
          <>
            <p className="mb-[8px]">Add your TikTok username.</p>
            <p className="mb-[8px]">
              By providing your username, we can track your posts and access
              select stats.
            </p>
          </>
        ) : (
          <>
            <p className="mb-[8px]">
              It looks like we couldn't connect to your {platformName}. But
              don't worry!
            </p>
            <p className="mb-[8px]">
              By providing your username, we can track your posts and access
              select stats.
            </p>
          </>
        )}
        <FInput
          type="text"
          label={`${platformName} Username`}
          value={username}
          onChange={(value) => setUsername(value)}
          width="100%"
          className="mb-[8px]"
        />
        <FButton
          primary={true}
          onClick={handleSubmit}
          label="Submit"
          type="button"
        />
      </div>
    </Modal>
  );
};

interface SocialMediaConnectionsProps {
  hasCompactView?: boolean;
  hideContinueButton?: boolean;
  platforms?: SocialMedialPlatform[];
  connections: {
    instagram?: any;
    tiktok?: any;
    youtube?: any;
  };
  source?: string;
  onUpdateConnections: (updates: any) => void;
}

export default function SocialMediaConnections({
  hasCompactView,
  hideContinueButton,
  platforms,
  connections,
  source,
  onUpdateConnections,
}: SocialMediaConnectionsProps) {
  const location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const { trackEvent } = useContext(MixpanelContext);

  const [loading, setLoading] = useState(true);
  const [instagramStatus, setInstagramStatus] = useState(null);
  const [tiktokStatus, setTiktokStatus] = useState(null);
  const [youtubeStatus, setYoutubeStatus] = useState(null);
  const [isInstagramModalOpen, setIsInstagramModalOpen] = useState(false);
  const [isTiktokModalOpen, setIsTiktokModalOpen] = useState(false);
  const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(false);
  const [instagramUsername, setInstagramUsername] = useState("");
  const [tiktokUsername, setTiktokUsername] = useState("");
  const [instagramErrorMessage, setInstagramErrorMessage] = useState("");
  const [tiktokErrorMessage, setTiktokErrorMessage] = useState("");

  const navigate = useNavigate();

  // Fetch creator data
  useEffect(() => {
    const fetchCreatorData = async () => {
      try {
        const response = await getCreator(user.creator_id);

        const creator = response.data.socials;

        onUpdateConnections({
          instagram: creator.instagram || null,
          tiktok: creator.tiktok || null,
          youtube: creator.youtube || null,
        });
      } catch (error) {
        console.error("Failed to fetch creator data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCreatorData();
  }, [user.creator_id]);

  // Check URL params and set status
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setInstagramStatus(params.get("instagram_status"));
    setTiktokStatus(params.get("tiktok_status"));
  }, [location]);

  // Auto open modals based on status
  useEffect(() => {
    setIsInstagramModalOpen(instagramStatus && instagramStatus !== "success");
    setIsTiktokModalOpen(tiktokStatus && tiktokStatus !== "success");
  }, [instagramStatus, tiktokStatus]);

  const handleConnectToInstagram = async () => {
    try {
      const isUsernameValid = await checkUsername(INSTAGRAM, instagramUsername);
      if (!isUsernameValid.data) {
        console.log(isUsernameValid.data);
        setInstagramErrorMessage(
          "That username does not exist as a creator or business account. " +
            "To switch your Instagram account to a creator or business account, follow these steps:\n" +
            "1. Open the Instagram app and go to your profile.\n" +
            "2. Tap the menu (☰) in the top right, then select 'Settings and Privacy'.\n" +
            "3. Select 'Account Type and Tools', then tap 'Switch to Professional Account'.\n" +
            "4. Choose 'Creator' or 'Business' and follow the prompts."
        );
        return; // Stop execution if username is invalid
      }

      const response = await connectToPlatform(INSTAGRAM, instagramUsername);

      showSuccessToast(INSTAGRAM, true);

      onUpdateConnections({ [INSTAGRAM]: { username: instagramUsername } });

      setInstagramStatus("success");
      setIsInstagramModalOpen(false);

      // Track Instagram connection
      trackEvent(EVENTS.AUTH.SOCIAL_ACCOUNT_CONNECTED, {
        platform: INSTAGRAM,
        connection_method: "username",
        is_onboarding: location.pathname.includes("/signup/"),
        follower_count: response.data?.followers_count || null,
      });
    } catch (e) {
      showErrorToast(INSTAGRAM, true);

      // Track connection error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "social_connection",
        platform: INSTAGRAM,
        error_message:
          e?.response?.data?.message || "Unknown error connecting to Instagram",
        is_onboarding: location.pathname.includes("/signup/"),
      });
    }
  };

  const handleDisconnectFromInstagram = async () => {
    try {
      await removePlatformConnection(INSTAGRAM);

      setInstagramStatus(null);
      setIsInstagramModalOpen(false);

      onUpdateConnections({
        [INSTAGRAM]: null,
      });

      showSuccessToast(INSTAGRAM);

      // Track Instagram disconnection
      trackEvent("Social Account Disconnected", {
        platform: INSTAGRAM,
        is_onboarding: location.pathname.includes("/signup/"),
      });
    } catch (e) {
      showErrorToast(INSTAGRAM);

      // Track disconnection error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "social_disconnection",
        platform: INSTAGRAM,
        error_message:
          e?.response?.data?.message ||
          "Unknown error disconnecting from Instagram",
        is_onboarding: location.pathname.includes("/signup/"),
      });
    }
  };

  const handleConnectToTikTok = async () => {
    try {
      const isUsernameValid = await checkUsername(TIKTOK, tiktokUsername);

      if (!isUsernameValid.data) {
        setTiktokErrorMessage(
          "That username is not available on TikTok Creator Marketplace. " +
            "To make your TikTok account eligible, follow these steps:\n\n" +
            "Check Your TikTok Account Type:\n" +
            "1. Open the TikTok app.\n" +
            "2. Go to your profile by tapping the profile icon (bottom right).\n" +
            "3. Tap the menu (☰) in the top right corner.\n" +
            "4. Select 'Settings and Privacy'.\n" +
            "5. Scroll down and tap 'Account Type and Tools'.\n" +
            "   - If you see 'Switch to Business Account', you currently have a Creator account.\n" +
            "   - If you see 'Switch to Creator Account', you currently have a Business account.\n" +
            "   - If you see 'Switch to Professional Account', you're likely on a Personal account and need to switch.\n\n" +
            "Join TikTok Creator Marketplace:\n" +
            "1. Go to 'Settings and Privacy' → 'Account Type and Tools'.\n" +
            "2. Scroll down and select 'TikTok Creator Marketplace'.\n" +
            "3. Follow the prompts to join the marketplace.\n" +
            "4. Once approved, try connecting again."
        );
        return;
      }

      const response = await connectToPlatform(TIKTOK, tiktokUsername);

      onUpdateConnections({
        [TIKTOK]: response.data,
      });

      showSuccessToast(TIKTOK, true);

      onUpdateConnections({ tiktok: { username: tiktokUsername } });

      setTiktokStatus("success");
      setIsTiktokModalOpen(false);
      setTiktokErrorMessage(""); // Clear error on success

      // Track TikTok connection
      trackEvent(EVENTS.AUTH.SOCIAL_ACCOUNT_CONNECTED, {
        platform: TIKTOK,
        connection_method: "username",
        is_onboarding: location.pathname.includes("/signup/"),
        follower_count: response.data?.followers_count || null,
      });
    } catch (e) {
      showErrorToast(TIKTOK, true);

      // Track connection error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "social_connection",
        platform: TIKTOK,
        error_message:
          e?.response?.data?.message || "Unknown error connecting to TikTok",
        is_onboarding: location.pathname.includes("/signup/"),
      });
    }
  };

  const handleDisconnectFromTikTok = async () => {
    try {
      await removePlatformConnection(TIKTOK);

      onUpdateConnections({
        [TIKTOK]: null,
      });

      showSuccessToast(TIKTOK);

      setTiktokStatus(null);
      setIsTiktokModalOpen(false);

      // Track TikTok disconnection
      trackEvent("Social Account Disconnected", {
        platform: TIKTOK,
        is_onboarding: location.pathname.includes("/signup/"),
      });
    } catch (e) {
      showErrorToast(TIKTOK);

      // Track disconnection error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "social_disconnection",
        platform: TIKTOK,
        error_message:
          e?.response?.data?.message ||
          "Unknown error disconnecting from TikTok",
        is_onboarding: location.pathname.includes("/signup/"),
      });
    }
  };

  const handleDisconnectFromYoutube = async () => {
    try {
      await removePlatformConnection(YOUTUBE);

      onUpdateConnections({
        [YOUTUBE]: null,
      });

      showSuccessToast(YOUTUBE);

      setYoutubeStatus(null);
      setIsYoutubeModalOpen(false);

      // Track YouTube disconnection
      trackEvent("Social Account Disconnected", {
        platform: YOUTUBE,
        is_onboarding: location.pathname.includes("/signup/"),
      });
    } catch (e) {
      showErrorToast(YOUTUBE);

      // Track disconnection error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "social_disconnection",
        platform: YOUTUBE,
        error_message:
          e?.response?.data?.message ||
          "Unknown error disconnecting from YouTube",
        is_onboarding: location.pathname.includes("/signup/"),
      });
    }
  };

  return (
    <div className="w-full">
      {instagramStatus === "success" && <p>Instagram Connection Successful!</p>}
      {tiktokStatus === "success" && <p>TikTok Connection Successful!</p>}

      <div className="w-full">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div
            className={`flex ${hasCompactView ? "" : "flex-col"} gap-2 w-full mb-8`}
          >
            {!platforms ||
            platforms.includes(SocialMedialPlatform.INSTAGRAM) ? (
              <InstagramConnectButton
                isConnected={connections.instagram}
                iconOnly={hasCompactView}
                onClick={handleDisconnectFromInstagram}
              />
            ) : null}

            {!platforms || platforms.includes(SocialMedialPlatform.TIKTOK) ? (
              <TiktokConnectButton
                isConnected={connections.tiktok}
                iconOnly={hasCompactView}
                onClick={handleDisconnectFromTikTok}
              />
            ) : null}
            {!platforms || platforms.includes(SocialMedialPlatform.YOUTUBE) ? (
              <YoutubeConnectButton
                isConnected={false}
                iconOnly={hasCompactView}
                onClick={handleDisconnectFromYoutube}
                source={source}
              />
            ) : null}
          </div>
        )}
        {!hideContinueButton ? (
          <FButton
            height="48px"
            width="100%"
            primary
            label="Continue"
            onClick={() =>
              !user.first_name || !user.last_name
                ? navigate("/signup/influencer/add-personal-info")
                : navigate("/")
            }
          />
        ) : null}
      </div>

      {/* Instagram Modal */}
      <SocialMediaModal
        isOpen={isInstagramModalOpen}
        title="Enter Your Instagram Username"
        username={instagramUsername}
        setUsername={setInstagramUsername}
        handleSubmit={handleConnectToInstagram}
        onClose={() => setIsInstagramModalOpen(false)}
        errorMessage={instagramErrorMessage}
      />

      {/* TikTok Modal */}
      <SocialMediaModal
        isOpen={isTiktokModalOpen}
        title="Enter Your TikTok Username"
        username={tiktokUsername}
        setUsername={setTiktokUsername}
        handleSubmit={handleConnectToTikTok}
        onClose={() => setIsTiktokModalOpen(false)}
        errorMessage={tiktokErrorMessage}
      />
    </div>
  );
}
