import React, { useContext, useEffect } from "react";
import { NewReportContext } from "@contexts/index";
import ReportContentTable from "./components/ReportContentOverview";
import LoadingSpinner from "@components/LoadingSpinner";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

export default function Content() {
  const {
    report,
    isLoading,
    activePost,
    contentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
  } = useContext(NewReportContext);

  const { trackEvent } = useContext(MixpanelContext);

  // Track content view when component mounts
  useEffect(() => {
    if (report) {
      trackEvent(EVENTS.REPORT.CONTENT_VIEWED, {
        report_id: report.id,
        report_title: report.title,
        content_count: report.content_count || 0,
      });
    }
  }, [report, trackEvent]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="">
      <ReportContentTable
        report_id={report?.id}
        onCardClick={handleContentModalClick}
      />

      {activePost && (
        <GeneralPostModal
          post={activePost}
          isOpen={contentModalOpen}
          onClose={handleContentModalClose}
        />
      )}
    </div>
  );
}
