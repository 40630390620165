import Modal from "@components/Modals/Modal";
import { CampaignDeliverable } from "@types";
import { capitalize, isEmpty } from "lodash";

const SOCIAL_DELIVERABLE_FIELDS = [
  { value: "name", label: "Name" },
  { value: "platform", label: "Platform", shouldCapitalize: true },
  { value: "media_type", label: "Media Type", shouldCapitalize: true },
  { value: "hashtags", label: "Hashtags" },
  { value: "mentions", label: "Mentions" },
  { value: "url", label: "URL" },
  { value: "position", label: "Position" },
  { value: "submission_due_date", label: "Submission Due Date" },
  { value: "forecasted_date", label: "Deliverable Forecast Date" },
  { value: "asset_quantity", label: "Asset Quantity" },
  { value: "estimated_cpm", label: "Estimated CPM" },
  { value: "estimated_flat_cost", label: "Estimated Flat Cost" },
  { value: "tagged_users", label: "Tagged Users" },
  { value: "geotags", label: "Geotags" },
  { value: "syndication_requirements", label: "Syndication" },
  { value: "overwrite", label: "Overwrite All Participants" },
];

const EVENT_DELIVERABLE_FIELDS = [
  { value: "name", label: "Name" },
  { value: "deliverable_start", label: "Deliverable Start" },
  { value: "deliverable_end", label: "Deliverable End" },
  { value: "forecasted_date", label: "Deliverable Forecast Date" },
  { value: "location", label: "Location" },
  { value: "position", label: "Position" },
  { value: "estimated_flat_cost", label: "Estimated Flat Cost" },
  { value: "hashtags", label: "Hashtags" },
  { value: "mentions", label: "Mentions" },
  { value: "tagged_users", label: "Tagged Users" },
  { value: "geotags", label: "Geotags" },
  { value: "overwrite", label: "Overwrite All Participants" },
];

interface BriefDeliverablePreviewProps {
  deliverable: CampaignDeliverable;
  onClose: () => void;
}

const BriefDeliverablePreviewModal = ({
  deliverable,
  onClose,
}: BriefDeliverablePreviewProps) => {
  const { kind, notes } = deliverable;

  const fields =
    kind === "social" ? SOCIAL_DELIVERABLE_FIELDS : EVENT_DELIVERABLE_FIELDS;

  return (
    <Modal
      hasForm
      isOpen
      title="Deliverable Overview"
      onClose={onClose}
      className="w-[758px] overflow-auto"
      zIndex={9999}
    >
      <div className="max-h-[60vh] overflow-y-auto">
        <div className="grid grid-cols-2 gap-4">
          {fields.map(({ value, label, shouldCapitalize = false }) => {
            const fieldValue = deliverable[value];

            return (
              <div className="flex gap-4 items-center" key={value}>
                <p className="">{label}:</p>
                <p className="font-bold">
                  {fieldValue && !isEmpty(fieldValue)
                    ? shouldCapitalize
                      ? capitalize(fieldValue)
                      : fieldValue
                    : "-"}
                </p>
              </div>
            );
          })}
          {deliverable[notes] ? (
            <div className="mt-4">
              <h2 className="text-lg font-bold mb-4">Notes:</h2>
              <div className="ql-snow">
                <div
                  className="ql-editor no-padding"
                  dangerouslySetInnerHTML={{
                    __html: deliverable[notes],
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default BriefDeliverablePreviewModal;
