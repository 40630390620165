import { Content, Platform, ViewsData } from "@types";
import { numberWithKMBFormat } from "@utils/number_utilities";
import { useMemo } from "react";
import ViewershipOverTime from "./ViewershipOverTime";

interface CoreMetricsSectionProps {
  viewsData: ViewsData;
  content: {
    content: Content[];
    total: number;
  };
  selectedPlatform: string;
  platforms: Platform[];
  postCount: number;
  onPostCountChange: (count: number) => void;
}

function CoreMetricsSection({
  viewsData,
  content,
  selectedPlatform,
  platforms,
  postCount,
  onPostCountChange,
}: CoreMetricsSectionProps) {
  const filteredContent = useMemo(() => {
    if (!content?.content) return [];

    let filtered = [...content.content];

    // First filter out Stories
    filtered = filtered.filter((post) => post.media_type !== "Story");

    // Then filter by platform if selected
    if (selectedPlatform) {
      filtered = filtered.filter((post) => post.platform === selectedPlatform);
    }

    // Sort by date descending
    filtered = filtered.sort(
      (a, b) =>
        new Date(b.published_at).getTime() - new Date(a.published_at).getTime()
    );

    // Finally take the requested number of posts from the filtered set
    return filtered.slice(0, postCount);
  }, [content, selectedPlatform, postCount]);

  const {
    total_views = 0,
    average_views = 0,
    viral_posts_count = 0,
    viral_post_views = 0,
  } = viewsData || {};

  return (
    <div className="flex flex-col rounded-lg bg-white border border-highlight_red_opacity shadow-sm">
      <div className="flex items-center justify-between px-8 py-4 pb-3">
        <div className="text-lg font-bold">Views</div>
        <div className="flex items-center gap-2">
          <span className="text-sm">Posts to show:</span>
          <input
            type="number"
            min="1"
            max="100"
            value={postCount}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (value >= 1 && value <= 100) {
                onPostCountChange(value);
              }
            }}
            className="w-20 px-2 py-1 border border-default_weak gyueujjudjus7s8dududdududduduuduuddujh5o rounded"
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 border-t border-b bg-light_red border-highlight_red_opacity px-4">
        <div className=" p-4 pb-3 ">
          <div className="text-sm text-gray-600">Total Views</div>
          <div className="text-xl font-bold mt-1">
            {numberWithKMBFormat(total_views)}
          </div>
        </div>
        <div className="p-4 pb-3 ">
          <div className="text-sm text-gray-600">Average Views</div>
          <div className="text-xl font-bold mt-1">
            {numberWithKMBFormat(average_views)}
          </div>
        </div>
        <div className=" p-4 pb-3 ">
          <div className="text-sm text-gray-600">Viral Posts</div>
          <div className="text-xl font-bold mt-1">{viral_posts_count}</div>
        </div>
        <div className="p-4 pb-3 ">
          <div className="text-sm text-gray-600">Viral Driven Views</div>
          <div className="text-xl font-bold mt-1">
            {numberWithKMBFormat(viral_post_views)}
          </div>
        </div>
      </div>
      <div>
        {/* <div className="text-sm text-gray-600 mb-2">
          Showing views over time for {filteredContent.length} most recent posts
          {selectedPlatform ? ` on ${selectedPlatform}` : ""}
          {" (excluding Stories)"}
        </div> */}
        <ViewershipOverTime
          content={filteredContent}
          platforms={platforms}
          selectedPlatform={selectedPlatform}
        />
      </div>
    </div>
  );
}

export default CoreMetricsSection;
