import React, { useState } from "react";
import { createAdminBrandUser } from "@api/Admin/AdminBrands";
import { toast } from "react-hot-toast";
import { FInput } from "@components/FInputs";
import FDropdown from "@components/FDropdown";
import Modal from "@components/Modals/Modal";

interface CreateBrandUserModalProps {
  onClose: () => void;
  onCreated: () => void;
  isOpen: boolean;
  brandId: number;
}

const ROLE_OPTIONS = {
  fohr_admin: "Fohr Admin",
  brand_owner: "Brand Owner",
  brand_manager_managed_services: "Brand Manager - Managed Services",
  brand_user_managed_services: "Brand User - Managed Services",
  brand_manager_saas: "Brand Manager - SAAS",
  brand_user_saas: "Brand User - SAAS",
};

export default function CreateBrandUserModal({
  isOpen,
  onClose,
  onCreated,
  brandId,
}: CreateBrandUserModalProps) {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<keyof typeof ROLE_OPTIONS | "">("");

  const handleCreateBrandUser = async () => {
    if (!brandId || !firstName || !lastName || !email || !role) {
      toast.error("All fields are required");
      return;
    }

    try {
      setLoading(true);
      const response = await createAdminBrandUser({
        brand_id: brandId,
        first_name: firstName,
        last_name: lastName,
        email: email,
        role: role,
      });
      if (response.success) {
        toast.success("Brand user created successfully");
        onCreated();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to create brand user");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="w-[600px] overflow-auto"
      isOpen={isOpen}
      onClose={onClose}
      onAction={handleCreateBrandUser}
      title="Add Brand User"
      waiting={loading}
    >
      <div className="flex flex-col gap-6 w-full">
        <FInput
          label="First Name"
          width="100%"
          value={firstName}
          onChange={(value) => setFirstName(value)}
        />
        <FInput
          label="Last Name"
          width="100%"
          value={lastName}
          onChange={(value) => setLastName(value)}
        />
        <FInput
          label="Email"
          value={email}
          onChange={(value) => setEmail(value)}
          width="100%"
        />
        <FDropdown
          label="Role"
          selectedValue={role}
          onChange={(value) => setRole(value as keyof typeof ROLE_OPTIONS)}
          options={Object.entries(ROLE_OPTIONS).map(([key, label]) => ({
            value: key,
            label,
          }))}
          placeholder="Select a role"
          width="100%"
        />
      </div>
    </Modal>
  );
}
