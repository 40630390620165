import { TopStats } from "@types";

const getPerformanceIndicator = (value: number, average: number) => {
  const ratio = value / average;
  const maxWidth = 45; // Maximum percentage width for the bar
  const width = `${Math.min(Math.abs(ratio) * 25, maxWidth)}%`;

  // Determine if the bar should be on the left or right of center
  const isLeft = ratio < 1;
  const left = isLeft
    ? `${50 - Math.min(Math.abs(ratio) * 25, maxWidth)}%`
    : "50%";

  // Color coding based on performance
  const color = isLeft ? "#E47667" : "#47B37F";
  const textColor = isLeft ? "text-red" : "text-green";

  // Border radius based on position (rounded on the outer edge)
  const borderRadius = isLeft ? "4px 0 0 4px" : "0 4px 4px 0";

  // Transform for left side bars
  const transform = isLeft ? "none" : "none";

  return {
    ratio: ratio.toFixed(1),
    width,
    left,
    color,
    textColor,
    borderRadius,
    transform,
  };
};

const MetricBar = ({
  value,
  average,
  label,
}: {
  value: number;
  average: number;
  label: string;
}) => {
  const { ratio, width, left, color, textColor, borderRadius, transform } =
    getPerformanceIndicator(value, average);

  return (
    <div className="flex flex-col px-4">
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm text-neutral_600">{label}</span>
        <span className="text-sm font-medium text-neutral_800">{value}%</span>
      </div>
      <div className="relative h-2 bg-highlight_red_opacity rounded-full overflow-hidden">
        {/* Center marker */}
        <div className="absolute top-0 left-1/2 w-px h-full bg-black z-10" />

        {/* Performance bar */}
        <div
          className="absolute top-0 h-full transition-all duration-500"
          style={{
            width,
            left,
            backgroundColor: color,
            borderRadius,
            transform,
          }}
        />
      </div>
      <div className="flex justify-between text-sm mt-1">
        <span className="text-neutral_600">(-)</span>
        <span className={`font-medium ${textColor}`}>{ratio}x Avg.</span>
        <span className="text-neutral_600">(+)</span>
      </div>
    </div>
  );
};

export default MetricBar;
