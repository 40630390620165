import { TopStats } from "@types";
import { formatLargeNumber } from "@utils/number_utilities";
import { FTooltip } from "@components/FTooltip";
import { capitalize } from "lodash";
import FIcon from "@components/FIcon";
import FDropdown from "@components/FDropdown";
import MetricBar from "../../components/MetricsBar";

const TIME_PERIODS = [
  { label: "Past Month", value: 30 },
  { label: "Past 60 days", value: 60 },
  { label: "Past 90 days", value: 90 },
];

const FOLLOWER_TIERS = {
  Nano: "1K-10K Followers",
  Micro: "10K-50K Followers",
  Mid: "50K-500K Followers",
  Macro: "500K-1M Followers",
  Mega: "1M+ Followers",
};

export const getKpis = (topStats: TopStats) => {
  if (!topStats) return [];

  const {
    engagement_rate,
    reels_engagement_rate,
    reels_views,
    stories_views,
    views,
  } = topStats;

  const kpis = [];

  if (engagement_rate?.value !== undefined) {
    kpis.push({
      name: "Engagement Rate",
      value: engagement_rate.value,
      isPercentage: true,
    });
  }

  if (reels_engagement_rate?.value !== undefined) {
    kpis.push({
      name: "Reels Engagement",
      value: reels_engagement_rate.value,
      isPercentage: true,
    });
  }

  if (reels_views?.value !== undefined) {
    kpis.push({
      name: "Reels Views",
      value: reels_views.value,
    });
  }

  if (stories_views?.value !== undefined) {
    kpis.push({
      name: "Stories Views",
      value: stories_views.value,
    });
  }

  return kpis;
};

interface StatsProps {
  profileStats: TopStats;
  onTimeChange: (value: number) => void;
  timePeriod: number;
}

const Stats = ({ profileStats, onTimeChange, timePeriod }: StatsProps) => {
  const rawTier = profileStats?.follower_tier?.toLowerCase() || "";
  const tier = capitalize(rawTier);
  const tooltipContent = FOLLOWER_TIERS[tier]
    ? `${tier} (${FOLLOWER_TIERS[tier]})`
    : tier;

  const metrics = [];

  if (
    profileStats?.engagement_rate?.value !== undefined &&
    profileStats?.engagement_rate?.tier_average !== undefined
  ) {
    metrics.push({
      label: "Engagement Rate",
      value: profileStats.engagement_rate.value,
      average: profileStats.engagement_rate.tier_average,
    });
  }

  if (
    profileStats?.reels_engagement_rate?.value !== undefined &&
    profileStats?.reels_engagement_rate?.tier_average !== undefined
  ) {
    metrics.push({
      label: "Reels Engagement",
      value: profileStats.reels_engagement_rate.value,
      average: profileStats.reels_engagement_rate.tier_average,
    });
  }

  if (
    profileStats?.reels_views_rate?.value !== undefined &&
    profileStats?.reels_views_rate?.tier_average !== undefined
  ) {
    metrics.push({
      label: "Reels Views",
      value: profileStats.reels_views_rate.value,
      average: profileStats.reels_views_rate.tier_average,
    });
  }

  if (
    profileStats?.stories_views_rate?.value !== undefined &&
    profileStats?.stories_views_rate?.tier_average !== undefined
  ) {
    metrics.push({
      label: "Stories Views",
      value: profileStats.stories_views_rate.value,
      average: profileStats.stories_views_rate.tier_average,
    });
  }

  const kpis = getKpis(profileStats);

  if (kpis.length === 0 && metrics.length === 0) {
    return (
      <div className="bg-white rounded-lg border border-highlight_red_opacity shadow-sm overflow-hidden p-8 text-center text-neutral_600">
        No tier performance data available for this platform
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg border border-highlight_red_opacity shadow-sm overflow-hidden">
      <div className="flex items-center justify-between px-8 py-4 pb-3 border-b border-highlight_red_opacity">
        <div className="flex items-center gap-2">
          <h3 className="text-lg font-bold">Tier Performance</h3>
          <span className="text-sm text-neutral_400">
            (Last {timePeriod} days)
          </span>
        </div>
        {tier && (
          <div className="flex items-center gap-2">
            <div className="text-xs text-black rounded-full border border-black capitalize bg-white px-3 pt-[4px] pb-[3px] flex items-center gap-1">
              <FTooltip label={tooltipContent}>
                <FIcon
                  icon="info"
                  size={16}
                  color="black"
                  className="-mt-[2px]"
                />
              </FTooltip>
              {tier}
            </div>
          </div>
        )}
      </div>
      {kpis.length > 0 && (
        <div className="grid grid-cols-4 gap-4 p-4 bg-light_red">
          {kpis.map((stat, index) => {
            const { name, value, isPercentage } = stat;
            const formattedValue = isPercentage
              ? `${value}%`
              : formatLargeNumber(value);

            return (
              <div
                className={`${
                  index === 0 ? "bg-light_red" : "bg-gray-50"
                } flex flex-col p-4 pb-3 rounded-lg`}
                key={index}
              >
                <div className="text-sm text-gray-600">{name}</div>
                <div className="text-xl font-bold mt-1">{formattedValue}</div>
              </div>
            );
          })}
        </div>
      )}
      {metrics.length > 0 && (
        <div className="grid grid-cols-2 gap-x-4 gap-y-8 px-4 py-8 border-t border-highlight_red_opacity">
          {metrics.map((metric, index) => (
            <MetricBar
              key={index}
              label={metric.label}
              value={metric.value}
              average={metric.average}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Stats;
