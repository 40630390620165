import React, { useEffect, useState } from "react";

import LoadingSpinner from "@components/LoadingSpinner";

import { Bulletin } from "@types";
import { BulletinContext } from "@contexts/index";
import { Outlet, useParams } from "react-router-dom";
import { getCreatorBulletin } from "@api/creator_view";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";
import { getBulletin } from "@api/Bulletins/Bulletins";

const BulletinPreviewContainer = () => {
  const { bulletin_id } = useParams();

  const [bulletin, setBulletin] = useState<Bulletin>();
  const [loading, setLoading] = useState(true);

  const { hasRole } = useAuth();

  useEffect(() => {
    const loadBulletin = async () => {
      try {
        const bulletinResult = await (
          hasRole(Role.INFLUENCER) ? getCreatorBulletin : getBulletin
        )(bulletin_id);

        setBulletin(bulletinResult.data);
      } catch (error) {
        console.error("Error loading bulletin data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadBulletin();
  }, [bulletin_id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-light_red flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!bulletin) {
    return (
      <div className="min-h-screen bg-light_red flex items-center justify-center">
        <p className="text-xl">Bulletin not found</p>
      </div>
    );
  }

  return (
    <BulletinContext.Provider value={{ bulletin, setBulletin }}>
      <div className="min-h-screen bg-light_red">
        <div className="w-full mx-auto pb-[40px]">
          <div className="relative">
            <div
              style={{
                backgroundImage: `url(${bulletin.image_url})`,
              }}
              className="w-full h-[400px] bg-cover bg-center z-0 inset-0 relative"
            >
              <div className="absolute inset-0 bg-gradient-to-t from-[rgba(255,250,249,1)] via-[rgba(255,250,249,0.8)] to-[rgba(255,250,249,0.1)]" />
            </div>
            <div className="max-w-[80%] m-auto mt-[-11%] z-10 relative p-14 bg-white rounded-xl shadow-md">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </BulletinContext.Provider>
  );
};

export default BulletinPreviewContainer;
