import React, { useContext, useState } from "react";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";
import Modal from "@components/Modals/Modal";
import { toast } from "react-hot-toast";
import { sendTestEmail } from "@api/Bulletins/BulletinRecipients";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

interface Props {
  id: number | string;
  isOpen: boolean;
  onClose: () => void;
}

const TestEmailModal = ({ id, isOpen, onClose }: Props) => {
  const [email, setEmail] = useState("");
  const { trackEvent } = useContext(MixpanelContext);

  const handleSubmit = async () => {
    try {
      // Track test email send attempt
      trackEvent(EVENTS.BULLETIN.TEST_EMAIL_SENT, {
        bulletin_id: id,
        recipient_email_domain: email.split("@")[1] || "",
        has_recipient: !!email,
      });

      await toast.promise(sendTestEmail(id, email), {
        loading: "Sending test email...",
        success: "Email sent successfully!",
        error:
          "Failed to send test email! Have you filled every required field?",
      });
      onClose();
    } catch (error) {
      console.error("Failed to send test email:", error);

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "bulletin_test_email_error",
        bulletin_id: id,
        error_message: error?.message || "Failed to send test email",
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Send a test email"
      className="w-[400px]"
      hasForm
    >
      <div className="flex flex-col gap-6">
        <FInput
          label="Email address"
          width="100%"
          required
          value={email}
          onChange={(value) => setEmail(value)}
        />
      </div>
      <div className="flex justify-end w-full space-x-2 pt-4 mt-4">
        <FButton
          type="submit"
          width="100%"
          className="w-full"
          label="Send Test"
          onClick={handleSubmit}
          primary
        />
      </div>
    </Modal>
  );
};

export default TestEmailModal;
