import * as Sentry from "@sentry/react";

Sentry.init({
  enabled: import.meta.env.VITE_SENTRY_DSN !== undefined,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_RELEASE_NAME,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserSessionIntegration(),
  ],
  environment: import.meta.env.VITE_ENVIRONMENT || 'development',
  tracesSampleRate: 0.1,
  tracePropagationTargets: ["localhost", /fohr\.co/],
  ignoreErrors: ['AxiosError']
});
