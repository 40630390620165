import React, { useContext, useEffect, useState } from "react";

import { ProfileContext } from "@contexts/index";

import GeneralPostModal from "@components/Modals/GeneralPostModal";
import FDropdown from "@components/FDropdown";
import ContentHighlight from "./components/ContentHighlight";
import ContentThumbnail from "./components/ContentThumbnail";
import FIcon from "@components/FIcon";
import PaginationDots from "./components/PaginationDots";
import { searchPosts } from "@api/Search";
import { Content } from "@types";

// Add type augmentation for the stats object
type ExtendedStats = {
  reach: number;
  replies: number; // Make required to match base type
  impressions: number;
  likes_count: number;
  views_count: number;
  comments_count?: number;
  saved?: number;
  total_interactions?: number;
  engagement_rate?: number;
};

// Extend the Content type
type ExtendedContent = Omit<Content, "stats"> & {
  stats: ExtendedStats;
};

const PAGE_SIZE = 12;

const SORT_OPTIONS = [
  {
    label: "Views",
    value: "views_count",
  },
  {
    label: "Interactions",
    value: "interactions",
  },
  {
    label: "Likes",
    value: "likes_count",
  },
];

export default function ProfileBasics() {
  const { contentHighlight, profile, selectedCategoryId } =
    useContext(ProfileContext);

  const [currentPage, setCurrentPage] = useState(0);

  const [activePost, setActivePost] = useState(null);

  const [sortValue, setSortValue] = useState(SORT_OPTIONS[1].value);

  const [content, setContent] = useState<ExtendedContent[]>([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sortContent = (items: ExtendedContent[]) => {
    return [...items].sort((a, b) => {
      switch (sortValue) {
        case "views_count":
          return (b.stats.views_count || 0) - (a.stats.views_count || 0);
        case "interactions":
          // Use total_interactions if available, otherwise calculate from individual metrics
          const bInteractions =
            b.stats.total_interactions ||
            (b.stats.likes_count || 0) +
              (b.stats.comments_count || 0) +
              (b.stats.replies || 0) +
              (b.stats.saved || 0);
          const aInteractions =
            a.stats.total_interactions ||
            (a.stats.likes_count || 0) +
              (a.stats.comments_count || 0) +
              (a.stats.replies || 0) +
              (a.stats.saved || 0);
          return bInteractions - aInteractions;
        case "likes_count":
          return (b.stats.likes_count || 0) - (a.stats.likes_count || 0);
        default:
          return 0;
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await searchPosts({
        creator_ids: [profile.id],
        page_size: 48,
        sort: sortValue,
        categories: selectedCategoryId ? [`${selectedCategoryId}`] : null,
      });

      const postsResponse = response.data;
      setContent(sortContent(postsResponse.results));
    };

    fetchData();
  }, [profile.id, sortValue, selectedCategoryId]);

  // Re-sort content when sort value changes
  useEffect(() => {
    setContent((prevContent) => sortContent(prevContent));
  }, [sortValue]);

  return (
    <div className="flex flex-col 2xl:flex-row pt-[29px] pr-[46px] pb-[24px] 2xl:h-[calc(100%-38px)] w-full">
      <div className="w-full 2xl:max-w-[400px] mb-4 2xl:mb-0">
        <ContentHighlight
          data={contentHighlight}
          profile={profile}
          width="100%"
        />
      </div>
      <div className="flex-1 2xl:pl-4">
        <div className="flex items-center justify-between mb-[14px]">
          <FDropdown
            customHeader={
              <div className="flex items-center gap-2 text-sm">
                <span>Best Performing by</span>
                <FIcon icon="filter-rounded" size={18} />
                <span className="font-bold">
                  {SORT_OPTIONS.find(({ value }) => value === sortValue).label}
                </span>
              </div>
            }
            options={SORT_OPTIONS}
            selectedValue={sortValue}
            onChange={(value) => setSortValue(value as string)}
            titleDropDown
            transparent
            height={24}
            width="auto"
          />
          <div className={isLargeScreen ? "" : "hidden"}>
            <PaginationDots
              currentIndex={currentPage}
              itemsCount={Math.ceil(content.length / PAGE_SIZE)}
              onChange={setCurrentPage}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 grid-rows-4 gap-x-2 gap-y-2 w-full h-full">
          {(isLargeScreen
            ? content.slice(
                currentPage * PAGE_SIZE,
                (currentPage + 1) * PAGE_SIZE
              )
            : content
          ).map((item, index) => {
            // Patterns designed for 3-column layout (indices 0-11)
            const patterns = [
              [0, 4, 8], // diagonal pattern
              [0, 5, 7], // triangle pattern
              [1, 6, 11], // scattered pattern
              [0, 5, 6], // zigzag pattern
              [2, 5, 9], // reverse diagonal
              [1, 4, 10], // centered pattern
            ];

            const patternIndex = isLargeScreen
              ? (currentPage + Math.floor(index / 6)) % patterns.length
              : Math.floor(index / 12) % patterns.length;

            const localIndex = isLargeScreen ? index : index % 12;
            const isTall = patterns[patternIndex].includes(localIndex);

            // For small screens, add additional height variation based on position
            const shouldBeTall = isLargeScreen
              ? isTall
              : isTall || index % 7 === 3 || index % 5 === 2;

            const isLastRow = isLargeScreen
              ? index >= 9
              : index >= content.length - 3;

            return (
              <div
                key={index}
                className={`${shouldBeTall ? "row-span-2" : ""} ${isLastRow ? "mb-0" : ""} h-full bg-highlight_red`}
              >
                <ContentThumbnail content={item} onClick={setActivePost} />
              </div>
            );
          })}
        </div>
      </div>
      {activePost && (
        <GeneralPostModal
          post={activePost}
          isOpen
          onClose={() => setActivePost(null)}
        />
      )}
    </div>
  );
}
