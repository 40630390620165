import React, { useEffect, useState, useContext } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

import { getBrandBySlugForAmbassadorOnboarding } from "@api/brands";
import { Brand } from "@types";
import Toaster from "@components/Toaster";
import LoadingSpinner from "@components/LoadingSpinner";

export default function AmbassadorOnboardingLayout() {
  const { brand: brand_slug } = useParams<{ brand: string }>();
  const { trackEvent } = useContext(MixpanelContext);
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);

  const [brand, setBrand] = useState<Brand>(null);

  useEffect(() => {
    const loadAmbassadorOnboardingSettings = async () => {
      try {
        const settings =
          await getBrandBySlugForAmbassadorOnboarding(brand_slug);

        setLoading(false);

        setBrand(settings);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (!brand_slug) {
      return;
    }

    loadAmbassadorOnboardingSettings();
  }, [brand_slug]);

  const {
    background_photo,
    logo_photo,
    amp_description,
    slug,
    accepting_applications,
  } = brand || {};

  const BackgroundPhotoSection = () => (
    <div className="h-[450px] sm:h-full relative w-screen sm:w-auto">
      <div className="absolute h-full w-full left-[0px] right-[0px] bg-[#0c040454]" />
      <div className="absolute top-[30px] sm:top-[25%] left-[20px] sm:left-[80px] w-[350px] sm:w-[450px]">
        <p className="mb-4 sm:mb-10 text-white text-2xl sm:text-4xl">
          {slug === "dick-s-2025"
            ? "Join the Varsity Team"
            : "Join the Ambassador Team"}
        </p>
        <p className="text-white">
          {amp_description}
          <br />
        </p>
      </div>
      <img
        src={background_photo?.original}
        className="h-full w-full object-cover"
      />
    </div>
  );

  const handleSupportClick = () => {
    trackEvent(EVENTS.SUPPORT.REQUESTED, {
      source: "ambassador_onboarding",
      method: "email",
      email: "support@fohr.co",
      page_path: location.pathname,
    });
  };

  if (isLoading) {
    return <LoadingSpinner className="w-full h-full mt-[20%]" />;
  }

  return (
    <>
      <Toaster />
      <div className="flex flex-col sm:flex-row sm:h-screen w-full">
        <div className="sm:hidden">
          <BackgroundPhotoSection />
        </div>
        <div className="w-full sm:w-1/2 flex flex-col items-center justify-center h-full p-2 sm:p-0 sm:overflow-y-auto">
          <a href="">
            <img
              src={logo_photo?.original}
              className="mb-[10px] sm:mb-[30px] w-[180px] mx-auto"
            />
          </a>
          {accepting_applications ? (
            <div className="w-full flex-1 sm:w-[440px] sm:max-h-[calc(100vh-250px)]">
              <Outlet />
            </div>
          ) : (
            <div className=" text-center">
              <p className="text-xl">Applications Closed</p>
              <p>
                Applications have now officially closed, but stay tuned for
                program updates and future opportunities by{" "}
                <Link to="/signup" className="text-red hover:underline">
                  creating an account
                </Link>
                .
              </p>
            </div>
          )}
          <div className="text-center mt-8">
            <div className="mb-2">
              <p className="text-xs opacity-50">
                Need additional help? Please reach out to{" "}
                <a
                  className="underline"
                  href="mailto:support@fohr.co"
                  onClick={handleSupportClick}
                >
                  support@fohr.co
                </a>
              </p>
            </div>
            <div className="text-xs mb-2 opacity-50 max-w-[400px] mx-auto">
              By continuing, you acknowledge that you understand and agree to
              the{" "}
              <a
                href="https://www.fohr.co/terms"
                target="_blank"
                className="underline"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.fohr.co/privacy"
                target="_blank"
                className=" underline"
              >
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </div>
        <div className="w-1/2 hidden sm:block">
          <BackgroundPhotoSection />
        </div>
      </div>
    </>
  );
}
