const getStats = (content) => {
  const { interactions, likes_count, views_count } = content;

  return [
    {
      icon: "audience",
      value: views_count,
    },
    {
      icon: "impression",
      value: interactions,
    },
    {
      icon: "heart",
      value: likes_count,
    },
  ];
};

export default getStats;
