import React, { useState } from "react";

import RichTextEditor from "@components/RichTextEditor";
import FInput from "@components/FInputs/FInput";
import FButton from "@components/FButton";
import { BriefSection } from "@types";

export interface AddEditContractSectionValues {
  name: string;
  body: string;
}

interface AddEditContractSectionProps {
  section: BriefSection;
  onSubmit: (values: AddEditContractSectionValues) => void;
  onClose: (values: AddEditContractSectionValues) => void;
  waiting: boolean;
}

const AddEditContractSection = ({
  section,
  onSubmit,
  onClose,
  waiting,
}: AddEditContractSectionProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [values, setValues] = useState<AddEditContractSectionValues>({
    name: section.name || "",
    body: section.body || "",
  });

  const handleClose = () => {
    onClose(values);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);

    if (!values.name) {
      return;
    }

    onSubmit(values);
  };

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  return (
    <div className="max-h-[80vh] overflow-y-auto">
      <>
        <div className="space-y-4 relative">
          <FInput
            value={values.name}
            onChange={(value) => handleUpdate({ name: value })}
            type="text"
            required
            validate={isSubmitted}
            label="Name"
            width="100%"
          />
          <div className="mb-4">
            <label
              className="mb-[6px] inline-block text-sm font-medium leading-[18px]"
              htmlFor="notes"
            >
              Body
            </label>
            <div className="bg-white rounded-lg">
              <RichTextEditor
                value={values.body}
                id="contract_body"
                onChange={(value) => handleUpdate({ body: value })}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4 sticky bottom-0 bg-light_red_opacity py-6 backdrop-blur-sm">
          <FButton
            onClick={handleClose}
            label="Cancel"
            width="100%"
            height="40px"
          />
          <FButton
            onClick={handleSubmit}
            loading={waiting}
            label="Save"
            width="100%"
            height="40px"
            primary
            type="submit"
          />
        </div>
      </>
    </div>
  );
};

export default AddEditContractSection;
