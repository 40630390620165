import React, { useEffect, useState } from "react";

import SinglePageLayout from "@layouts/SinglePageLayout";
import { getCreatorBulletins } from "@api/creator_view";
import BulletinsEmptyState from "./components/BulletinsEmptyState";
import { useNavigate } from "react-router-dom";

const filterOptions = [
  { label: "All", static: "Bulletins", value: null },
  { label: "Active", static: "Bulletins", value: "new" },
  { label: "Responded", static: "Bulletins", value: "completed" },
];

const tableColumns = [
  {
    key: "name",
    label: "Title",
    isLink: true,
    linkURL: "/bulletins/{id}/answer-questions",
    isImage: true,
    imageType: "asset" as "asset" | "profile",
    imageKey: "image_url",
  },
  { key: "recipient_status", label: "State", isStateBubble: true },
];

const convertStatus = (status) => {
  switch (status) {
    case "new":
      return "Active";
    case "completed":
      return "Responded";
    case "declined":
      return "Declined";
    default:
      return "Unknown";
  }
};

export default function InfluencerBulletinsPage() {
  const [bulletins, setBulletins] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function getBulletins() {
      try {
        const response = await getCreatorBulletins();

        setBulletins(
          response.data.map((item) => ({
            hideParticipantCount: true,
            ...item,
            status: convertStatus(item.bulletin_recipient?.status),
          }))
        );
      } catch {
        setBulletins([]);
      } finally {
        setLoading(false);
      }
    }
    getBulletins();
  }, []);

  return (
    <SinglePageLayout
      pageTitle="Bulletins"
      loading={loading}
      pageName="Bulletins"
      cardData={bulletins}
      hideCreateNewButton
      cardType="asset"
      sortPathName="name"
      sortPathDate="date"
      filterOptions={filterOptions}
      filterPath="recipient_status"
      tableColumns={tableColumns}
      customBlankState={<BulletinsEmptyState />}
      defaultGrid
      onCardClick={(bulletinId) => navigate(`/bulletins/${bulletinId}/preview`)}
    />
  );
}
