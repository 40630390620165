import { listAmbassadorQuestions } from "@api/Ambassadors/AmbassadorQuestions";
import AdvancedSearchModal from "@pages/search/advanced/components/AdvancedSearchModal";
import { Brand } from "@types";
import { getQuestionsWithChildren } from "@utils/questions";
import { isArray, isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { FieldType, QuestionnaireQuestion } from "types/questionnaire";
import Question from "@components/questions/Question";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

export interface SelectedAnswer {
  id: number;
  selected_values: string | string[];
}

interface ResponsesSearchModalProps {
  brand: Brand;
  onUpdateFilters: (filters: SelectedAnswer[]) => void;
  onClose: () => void;
  filters: SelectedAnswer[];
}

const ResponsesSearchModal = ({
  brand,
  onClose,
  filters,
  onUpdateFilters,
}: ResponsesSearchModalProps) => {
  const [questions, setQuestions] = useState<QuestionnaireQuestion[]>([]);
  const [answers, setAnswers] = useState<Record<string, string | string[]>>({});
  const { trackEvent } = useContext(MixpanelContext);

  useEffect(() => {
    // Track when the responses filter modal is opened
    trackEvent(EVENTS.AMBASSADOR.ADVANCED_SEARCH, {
      brand_id: brand?.id,
      search_type: "responses",
      has_existing_filters: !!filters && filters.length > 0,
      filter_count: filters?.length || 0,
    });

    if (!filters) {
      return;
    }

    const answers = filters.reduce(
      (result, filter) => ({
        ...result,
        [filter.id]: filter.selected_values,
      }),
      {}
    );

    setAnswers(answers);
  }, []);

  useEffect(() => {
    const loadQuestions = async () => {
      const questions = await listAmbassadorQuestions({
        q: {
          brand_id_eq: brand.id,
        },
      });

      setQuestions(getQuestionsWithChildren(questions));
    };

    loadQuestions();
  }, []);

  const handleUpdateAnswer = (questionId: number, value: string | string[]) =>
    setAnswers({
      ...answers,
      [questionId]: value,
    });

  const handleAddFilters = () => {
    const selectedQuestions = [];

    Object.keys(answers).forEach((questionId) => {
      if (answers[questionId] && !isEmpty(answers[questionId])) {
        selectedQuestions.push({
          id: questionId,
          selected_values: answers[questionId],
        });
      }
    });

    // Track when response filters are applied
    trackEvent(EVENTS.AMBASSADOR.RESPONSE_FILTERED, {
      brand_id: brand?.id,
      filter_count: selectedQuestions.length,
      question_ids: selectedQuestions.map((q) => q.id),
    });

    onUpdateFilters(selectedQuestions);
  };

  const handleClearFilters = () => {
    // Track when filters are cleared
    if (filters && filters.length > 0) {
      trackEvent(EVENTS.AMBASSADOR.RESPONSE_FILTERED, {
        brand_id: brand?.id,
        action: "clear_filters",
        previous_filter_count: filters.length,
      });
    }

    onUpdateFilters(undefined);
  };

  return (
    <AdvancedSearchModal
      onClose={onClose}
      onAddToFilters={handleAddFilters}
      onClearFilters={handleClearFilters}
      title="Response Filters"
    >
      <div className="flex flex-col gap-8">
        {questions.map((question) => {
          const { children, field_type } = question;

          const answer = answers[question.id];

          let childQuestions;

          if (field_type === FieldType.MULTI_SELECT) {
            childQuestions =
              children &&
              isArray(answer) &&
              !isEmpty(answer) &&
              answer.reduce((result, value) => {
                const questions = children[value] || [];

                return [...result, ...questions];
              }, []);
          } else if (children) {
            childQuestions = children[`${answer}`];
          }

          childQuestions =
            childQuestions &&
            childQuestions.filter(({ field_type }) =>
              [FieldType.MULTI_SELECT, FieldType.SINGLE_SELECT].includes(
                field_type
              )
            );

          return (
            <>
              <Question
                question={question}
                answer={
                  field_type === FieldType.SINGLE_SELECT
                    ? answer && answer[0]
                    : answer
                }
                onUpdateAnswer={(value) =>
                  handleUpdateAnswer(
                    question.id,
                    field_type === FieldType.SINGLE_SELECT
                      ? [value as string]
                      : value
                  )
                }
                placeholder="Enter any keywords"
              />
              {childQuestions
                ? childQuestions.map((childQuestion) => {
                    const answer = answers[childQuestion.id];

                    return (
                      <Question
                        question={childQuestion}
                        answer={
                          childQuestion.field_type === FieldType.SINGLE_SELECT
                            ? answer && answer[0]
                            : answer
                        }
                        onUpdateAnswer={(value) =>
                          handleUpdateAnswer(
                            childQuestion.id,
                            childQuestion.field_type === FieldType.SINGLE_SELECT
                              ? [value as string]
                              : value
                          )
                        }
                        key={childQuestion.id}
                      />
                    );
                  })
                : null}
            </>
          );
        })}
      </div>
    </AdvancedSearchModal>
  );
};

export default ResponsesSearchModal;
