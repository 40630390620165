import React, { useEffect, useState } from "react";

import { getOverviewData, getStatsWithPerformanceTiers } from "@api/Profile";

import { searchPosts } from "@api/Search";

import toast from "react-hot-toast";
import CreatorReviewModalContainer, {
  CreatorForReview,
} from "@components/Creator/creator-review-modal/CreatorReviewModalContainer";

import FSubNav from "@components/FSubNav";

import { LazyRouteContent } from "@components/navigation/LazyRouteContent";
import PROFILE_ROUTES from "@pages/profile/routes";
import { updateAmbassador } from "@api/Ambassadors/Ambassadors";
import apiClient from "@apiClient";
import { ProfileContext } from "@contexts/index";
import LoadingSpinner from "@components/LoadingSpinner";
import RationaleEditor from "@components/review/RationaleEditor";
import { Ambassador } from "types/ambassador";
import { Creator } from "@apiTypes";
import { omit } from "lodash";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import FButton from "@components/FButton";
import ProfileResponses from "@pages/profile/ProfileResponses";
import { ProfileStats, ViewsData } from "@types";

const { profiles } = apiClient;

const TAB_ROUTES = [
  ...PROFILE_ROUTES.filter(({ value }) => value !== "testimonials"),
  {
    value: "responses",
    label: "Responses",
    component: ProfileResponses,
  },
  {
    value: "description",
    label: "Comment",
    component: null,
  },
];

interface AmbassadorReviewModalProps {
  onClose: () => void;
  onUpdate: (ambassadorId: number, updates: Partial<Ambassador>) => void;
  onGoToProfile: (creatorId: number) => void;
  ambassadors: Ambassador[];
  ambassadorId: number;
  statuses: { label: string; value: string }[];
}

export default function AmbassadorReviewModal({
  onClose,
  onUpdate,
  onGoToProfile,
  ambassadors,
  ambassadorId,
  statuses,
}: AmbassadorReviewModalProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState(PROFILE_ROUTES[0].value);
  const [activeContentIndex, setActiveContentIndex] = useState(0);

  const [creatorData, setCreatorData] = useState<Creator>(null);
  const [contentHighlight, setContentHighlight] = useState([]);
  const [topContent, setTopContent] = useState([]);

  const [content, setContent] = useState({ content: [], total: 0 });

  const [stats, setStats] = useState<ProfileStats>(null);
  const [viewsData, setViewsData] = useState<ViewsData>(null);
  const [tierPerformance, setTierPerformance] = useState<number>(null);

  const [responses, setResponses] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);

  const currentIndex = ambassadors.findIndex(
    (p) => p.ambassador_id === ambassadorId
  );

  const currentCreator = ambassadors[currentIndex];

  const { id: creatorId, slug } = currentCreator || {};

  const handleUpdateStatus = async (status: string) => {
    if (status === currentCreator.ambassador_status) {
      return;
    }

    try {
      await updateAmbassador(ambassadorId, {
        status,
      });

      onUpdate(ambassadorId, {
        ambassador_status: status,
      });

      toast.success("Status updated");

      if (ambassadors.length === 1) {
        onClose();
      } else {
        handleGoToIndex((currentIndex + 1) % ambassadors.length);
      }
    } catch {
      toast.error("Error updating status");
    }
  };

  const handleGoToIndex = (index: number) => {
    onGoToProfile(ambassadors[index].ambassador_id);
  };

  useEffect(() => {
    if (!slug) return;

    setLoading(true);

    const loadData = async () => {
      setCreatorData(null);

      try {
        const topContentResponse = await searchPosts({
          creator_ids: [creatorId],
          page_size: 10,
          post_type: "video",
        });

        const { results } = topContentResponse.data;

        setContentHighlight(results);
      } catch {
        setContentHighlight([]);
      }

      try {
        const result = await getStatsWithPerformanceTiers(profile.slug);

        if (result) {
          const { statsData, tierPerformance } = result;

          setStats(statsData);
          setTierPerformance(tierPerformance);
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      }

      const overviewResponse = await getOverviewData(slug);

      const topContent = await profiles.top_content(slug);

      setTopContent(topContent.data);

      setCreatorData(overviewResponse.data);

      try {
        const response = await searchPosts({
          creator_ids: [profile.id],
          page_size: 10000,
        });

        const postsResponse = response.data;

        setContent({
          content: postsResponse.results,
          total: postsResponse.total,
        });
      } catch {
        setContent({
          content: [],
          total: 0,
        });
      }

      setLoading(false);
    };

    loadData();
  }, [creatorId, slug]);

  const profile = {
    ...currentCreator,
    ...(creatorData ? omit(creatorData, "id") : {}),
    status: currentCreator?.ambassador_status,
  };

  const handleSubmitDescription = async (description) => {
    setIsUpdating(true);

    try {
      await updateAmbassador(ambassadorId, {
        review_description: description,
      });

      onUpdate(ambassadorId, { review_description: description });

      toast.success("Updates saved");
    } catch (error) {
      toast.error("Error submitting description");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        contentHighlight,
        content,
        topContent,
        responses,
        setResponses,
        stats,
        setStats,
        setContent,
        tierPerformance,
        setTierPerformance,
        viewsData,
        setViewsData,
      }}
    >
      <CreatorReviewModalContainer
        activeContentIndex={activeContentIndex}
        setActiveContentIndex={setActiveContentIndex}
        profile={profile as CreatorForReview}
        sampleContent={contentHighlight}
        currentIndex={currentIndex}
        statuses={statuses}
        onUpdateStatus={handleUpdateStatus}
        onGoToIndex={handleGoToIndex}
        participants={ambassadors as unknown as CreatorForReview[]}
        onClose={onClose}
        isCollapsed={isCollapsed}
        onChangeCollapsed={setIsCollapsed}
        topRightContent={
          profile ? (
            <FButton
              label="Add to group"
              onClick={() => setAddToGroupModalVisible(true)}
            />
          ) : null
        }
      >
        <FSubNav
          className=""
          items={TAB_ROUTES.map((item) => ({
            ...item,
            label: item.label,
            active: item.value === activeTab,
          }))}
          onChange={(value) => setActiveTab(value as string)}
          selectedValue={activeTab}
        />
        {loading || !profile ? (
          <LoadingSpinner className="h-[100vh]" />
        ) : (
          TAB_ROUTES.map(({ value, component: Component }, index) => {
            if (value === "description" && value === activeTab) {
              return (
                <div className="items-start flex flex-col w-full pt-3">
                  <RationaleEditor
                    description={profile.review_description}
                    id={`creatorReviewDescription-${profile.id}`}
                    isSaving={isUpdating}
                    label="comment"
                    onSubmit={handleSubmitDescription}
                    width="100%"
                  />
                </div>
              );
            }

            return (
              <LazyRouteContent isActive={value === activeTab} key={index}>
                {Component && <Component />}
              </LazyRouteContent>
            );
          })
        )}
        {isAddToGroupModalVisible ? (
          <AddCreatorsToGroupModal
            creatorIds={[creatorId]}
            onClose={() => setAddToGroupModalVisible(false)}
          />
        ) : null}
      </CreatorReviewModalContainer>
    </ProfileContext.Provider>
  );
}
