import React, { useContext, useEffect, useState } from "react";

import FButton from "../../components/FButton";
import { FCheckbox } from "../../components/FInputs";
import { UserContext } from "@contexts/index";
import AddPersonalInfo from "@pages/auth/influencer-onboarding/AddPersonalInfo";
import { pick } from "lodash";
import {
  EmailPreferences,
  getEmailPreferences,
  saveEmailPreferences,
} from "@api/user";
import toast from "react-hot-toast";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const EMAIL_PREFERENCES_BRAND = [
  {
    label: "Send me general information about events, news, etc.",
    value: "general",
  },
  {
    label: "Send me a weekly digest of new and notable influencers",
    value: "new_influencers",
  },
  {
    label: "Send me a weekly digest of influencers who mentioned me",
    value: "brand_mentions",
  },
];

const EMAIL_PREFERENCES = [
  {
    label: "Send me general information about events, news, etc.",
    value: "general",
  },
  {
    label: "Send me notifications if my social accounts disconnect",
    value: "auth_error",
  },
  {
    label: "Send me bulletin emails",
    value: "bulletin",
  },
  {
    label: "Send direct messages from brands",
    value: "outreach",
  },
];

const ProfileSettings = () => {
  const { user } = useContext(UserContext);
  const { trackEvent } = useContext(MixpanelContext);

  const [emailPreferences, setEmailPreferences] = useState<EmailPreferences>(
    {}
  );

  useEffect(() => {
    const loadEmailPreferences = async () => {
      const result = await getEmailPreferences();

      setEmailPreferences(result);

      // Track page view
      trackEvent(EVENTS.PAGE_VIEW.PROFILE, {
        section: "settings",
        profile_id: user?.id,
      });
    };

    loadEmailPreferences();
  }, [trackEvent, user]);

  const handleSaveEmailPreference = async (key, isChecked) => {
    const newEmailPreferences = {
      ...emailPreferences,
      [key]: isChecked,
    };

    try {
      await saveEmailPreferences(newEmailPreferences);

      // Track preference update
      trackEvent(EVENTS.AUTH.ACCOUNT_SETTINGS_UPDATED, {
        preference_type: "email",
        preference_name: key,
        preference_value: isChecked,
        user_type: user?.brand_id ? "brand" : "creator",
      });

      toast.success("Saved email preference");

      setEmailPreferences(newEmailPreferences);
    } catch (error) {
      toast.error("Failed to save email preference");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "email_preference_update",
        error_message:
          error?.response?.data?.message || "Failed to save email preference",
        preference_name: key,
        preference_value: isChecked,
      });
    }
  };

  const profile = pick(user, [
    "first_name",
    "last_name",
    "address",
    "country",
    "state",
    "city",
    "zipcode",
    "gender",
    "ethnicity",
    "birthday",
  ]);

  const isBrand = !!user.brand_id;

  return (
    <div className="max-w-[600px]">
      {!isBrand ? (
        <div className="mb-[40px]">
          <h1 className="text-[24px] mb-[24px]">Basic Info</h1>
          <AddPersonalInfo
            action="Save"
            profile={{ ...profile, profile_image_url: user.avatar_url }}
          />
        </div>
      ) : null}
      <div className="pb-[40px]">
        <h1 className="text-[24px] mb-[24px]">Email Preferences</h1>
        <div className="flex flex-col gap-4">
          {(isBrand ? EMAIL_PREFERENCES_BRAND : EMAIL_PREFERENCES).map(
            ({ label, value }, index) => (
              <FCheckbox
                label={label}
                id={value}
                key={index}
                onChange={(newValue) =>
                  handleSaveEmailPreference(value, newValue)
                }
                checked={emailPreferences[value]}
              />
            )
          )}
        </div>
      </div>
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Log Out of Fohr</h1>
        <div className="mt-[30px] flex justify-end max-w-[200px]">
          <FButton primary={true} label="Log Out" width="100%" type="submit" />
        </div>
      </div>
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Delete Fohr Account</h1>
        <p className="mb-4">
          If you would no longer like to keep your account with Fohr, please
          reach out to support@fohr.co to cancel.
        </p>
        <div className="flex max-w-[200px]">
          <a href="mailto:support@fohr.co">
            <FButton
              primary={false}
              label="Delete my account"
              width="100%"
              type="button"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
