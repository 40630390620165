import FIcon from "@components/FIcon";
import { FTooltip } from "@components/FTooltip";
import { Platform } from "@types";
import { formatLargeNumber } from "@utils/number_utilities";

interface PlatformStatsProps {
  platforms: Platform[];
  isInTable?: boolean;
}

const getFormattedConnectionType = (platform, type) => {
  const instagramConnectionMap = {
    // instagram_business: "Instagram Business Connection",
    // facebook_instagram_basic: "Instagram Basic Connection",
    // instagram_v1: "V1 (old) Connection",
    // v1_incomplete_basic: "V1 (old) but Basic Connection failed",
    // incomplete_basic: "Basic Connection failed",
    // "": "V1 Missing access token",
    // instagram_display: "Instagram Display",
    // off_platform: "Off-Platform",
    // manual_username: "Manual Connection",
    business: "Instagram Business Connection",
    instagram_business: "Business API Connected",
    facebook_instagram_basic: "Connected",
    instagram_v1: "Not Connected",
    v1_incomplete_basic: "Not Connected",
    basic: "Connected",
    incomplete_basic: "Not Connected",
    "": "Not Connected",
    instagram_display: "Connected",
    off_platform: "Off-Platform",
    manual_username: "Discovery API",
  };

  if (platform === "instagram") {
    return instagramConnectionMap[type];
  }

  if (platform === "tiktok") {
    return type === "api" ? "Connected" : "Not Connected";
  }
};

interface PlatformStatLinkProps {
  href: string;
  name: string;
  followers: number;
  isInTable?: boolean;
}

const PlatformStatLink = ({
  href,
  name,
  followers,
  isInTable,
}: PlatformStatLinkProps) => (
  <a
    href={href}
    target="_blank"
    className={`flex ${isInTable ? "gap-[4px]" : "gap-[8px]"} items-center text-md ${isInTable ? "" : "opacity-70"} text-black z-[9999]`}
  >
    <FIcon
      icon={name}
      size={isInTable ? 14 : 20}
      color="black"
      className="-mt-[2px]"
    />
    {isInTable ? <p /> : <p>{formatLargeNumber(followers)}</p>}
  </a>
);

const PlatformStats = ({ platforms, isInTable }: PlatformStatsProps) => (
  <div
    className={`flex flex-wrap ${isInTable ? "gap-2" : "gap-6"} items-center`}
  >
    {platforms
      ?.filter((platform) => platform.followers > 0)
      .map((platform, index) => {
        const { connection_type, followers, name, url } = platform;

        const href = platform.url.includes("http") ? url : `https://${url}`;

        if (["instagram", "tiktok"].indexOf(name) === -1) {
          return (
            <PlatformStatLink
              href={href}
              followers={followers}
              name={name}
              key={index}
              isInTable={isInTable}
            />
          );
        } else {
          return (
            <FTooltip
              label={getFormattedConnectionType(name, connection_type)}
              style={{ width: "auto" }}
              key={index}
            >
              <PlatformStatLink
                href={href}
                followers={followers}
                name={name}
                isInTable={isInTable}
              />
            </FTooltip>
          );
        }
      })}
  </div>
);

export default PlatformStats;
