import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";
import { FCheckbox, FInput } from "@components/FInputs";
import Droppable from "@components/drag-and-drop/Droppable";
import Heading from "@components/Heading";
import React, { useState } from "react";

import { DragDropContext, Draggable } from "react-beautiful-dnd";

import {
  QuestionnaireQuestion,
  QuestionnaireQuestionRequest,
  FieldType,
} from "types/questionnaire";

import { useLocation, useNavigate } from "react-router-dom";
import { getItemStyle, getListStyle, reorder } from "@components/drag-and-drop";

const FIELD_TYPES = [
  {
    value: FieldType.TEXT,
    label: "Short Form",
  },
  {
    value: FieldType.TEXTAREA,
    label: "Long Form",
  },
  {
    value: FieldType.SINGLE_SELECT,
    label: "Single select",
  },
  {
    value: FieldType.MULTI_SELECT,
    label: "Multi select",
  },
];

interface QuestionFormProps {
  entityName: string;
  question: QuestionnaireQuestion;
  questions: QuestionnaireQuestion[];
  onSave: (question: Partial<QuestionnaireQuestionRequest>) => void;
  hideVisibilitySettings?: boolean;
  waiting: boolean;
  isModal?: boolean;
  onClose?: () => void;
}

const QuestionForm = ({
  entityName,
  question,
  questions,
  hideVisibilitySettings,
  onSave,
  waiting,
  isModal = false,
  onClose,
}: QuestionFormProps) => {
  const [values, setValues] =
    useState<Partial<QuestionnaireQuestion>>(question);

  const [newOption, setNewOption] = useState("");
  const [editingOption, setEditingOption] = useState<number>(null);

  const [pendingOptionText, setPendingOptionText] = useState("");

  const navigate = useNavigate();

  const location = useLocation();

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  const handleUpdateOption = (indexToUpdate, value) => {
    const newOptions = values.data.options.map((option, index) =>
      index === indexToUpdate ? value : option
    );

    handleUpdate({ data: { options: newOptions } });
  };

  const handleAddNewOption = () => {
    if (!newOption) {
      return;
    }

    handleUpdate({
      data: { options: [...(values.data?.options || []), newOption] },
    });

    setNewOption("");
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newOptions = reorder(
      values.data.options,
      result.source.index,
      result.destination.index
    );

    handleUpdate({ data: { options: newOptions } });
  };

  const handleDeleteOption = (indexToDelete) => {
    const newOptions = values.data.options.filter(
      (option, index) => option && index !== indexToDelete
    );

    handleUpdate({ data: { options: newOptions } });
  };

  const handleGoBackToList = () =>
    navigate(location.pathname.split("/").slice(0, -1).join("/"));

  const urlParams = new URLSearchParams(location.search);

  const questionId = urlParams.get("question_id");

  const parentQuestionId =
    question.question_id || (questionId && parseInt(questionId, 10));

  const parentQuestion = parentQuestionId
    ? questions.find((question) => question.id === parentQuestionId)
    : null;

  const handleSave = () => {
    const { data, ...rest } = values;

    let params = {
      ...rest,
      ...(parentQuestion ? { question_id: parentQuestion.id } : {}),
    } as QuestionnaireQuestionRequest;

    if (parentQuestion && !params.conditional_answer) {
      params = {
        ...params,
        conditional_answer: parentQuestion.data.options[0],
      };
    }

    if (data?.options) {
      params = {
        ...params,
        data: data.options.join(","),
      };
    }

    onSave(params);
  };

  return (
    <div className={`${isModal ? "" : "w-[600px] pb-20"}`}>
      {!isModal && (
        <div className="flex flex-col gap-8">
          <FButton
            iconLeft={{
              name: "fancy-arrow-left",
              size: 12,
              color: "#000721",
              className: "mr-2",
            }}
            onClick={handleGoBackToList}
            label="Back"
            width="100px"
            height="40px"
          />
          <Heading
            title={`${question.id ? "Edit" : "Add New"} ${entityName} Question`}
          />
        </div>
      )}
      <div
        className={`flex flex-col gap-6 m-auto ${
          isModal ? "mt-[0px]" : "mt-[40px]"
        } w-full`}
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium text-default">
              Question*
            </label>
            <FCheckbox
              id="required"
              checked={values.required || false}
              label="Required"
              onChange={(value) => handleUpdate({ required: value })}
            />
          </div>
          <div className="mt-1">
            <FInput
              name="question"
              onChange={(value) => handleUpdate({ question: value })}
              value={values.question}
              required
              width="100%"
              label=""
            />
          </div>
        </div>
        <FInput
          name="description"
          onChange={(value) => handleUpdate({ description: value })}
          label="Description"
          value={values.description}
          width="100%"
        />
        <FDropdown
          label="Field type"
          options={FIELD_TYPES}
          required
          selectedValue={values.field_type}
          onChange={(value) => handleUpdate({ field_type: value })}
          height="40"
          width="100%"
        />
        {parentQuestion ? (
          <FDropdown
            label="Conditional answer for parent question"
            options={(parentQuestion.data?.options || []).map((option) => ({
              value: option,
              label: option,
            }))}
            required
            selectedValue={values.conditional_answer}
            onChange={(value) => handleUpdate({ conditional_answer: value })}
            height="40"
            width="100%"
          />
        ) : null}
        {!hideVisibilitySettings ? (
          <div className="flex items-center gap-4">
            <FCheckbox
              id="visible_on_form"
              checked={values.visible_on_form || false}
              label="Visible on onboarding form?"
              onChange={(value) => handleUpdate({ visible_on_form: value })}
            />
            <FCheckbox
              id="visible_search"
              checked={values.visible_search || false}
              label="Visible in search filters?"
              onChange={(value) => handleUpdate({ visible_search: value })}
            />
          </div>
        ) : null}
        <FInput
          name="search_question"
          onChange={(value) => handleUpdate({ search_question: value })}
          label="Search question"
          value={values.search_question}
          width="100%"
        />

        {[FieldType.SINGLE_SELECT, FieldType.MULTI_SELECT].includes(
          values.field_type
        ) ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div className="flex flex-col gap-4 pb-[40px]">
                    <h4>Manage Options</h4>
                    {(values.data?.options || []).map((option, index) => {
                      const isEditing = editingOption === index;

                      return (
                        <Draggable
                          key={`key-${option}`}
                          draggableId={`ID-${option}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ),
                                background: "transparent",
                              }}
                            >
                              <div
                                {...provided.dragHandleProps}
                                className="flex items-center justify-between border-default_weak rounded-md gap-2 border px-4 py-1 pr-1"
                              >
                                {isEditing ? (
                                  <FInput
                                    onChange={setPendingOptionText}
                                    label="Option"
                                    value={pendingOptionText}
                                    width="100%"
                                  />
                                ) : (
                                  <span>{option}</span>
                                )}
                                <div className="flex gap-2">
                                  <div
                                    onClick={() => {
                                      if (isEditing) {
                                        handleUpdateOption(
                                          index,
                                          pendingOptionText
                                        );
                                        setEditingOption(null);
                                      } else {
                                        setEditingOption(index);
                                        setPendingOptionText(option);
                                      }
                                    }}
                                    className="cursor-pointer bg-white w-[28px] h-[28px] rounded-lg border flex all-center"
                                  >
                                    <FIcon
                                      icon={isEditing ? "checkmark" : "edit"}
                                      color="#000000"
                                      size={16}
                                    />
                                  </div>
                                  <div
                                    onClick={() => handleDeleteOption(index)}
                                    className="cursor-pointer bg-white w-[28px] h-[28px] rounded-lg border border-red flex all-center"
                                  >
                                    <FIcon
                                      icon="delete"
                                      color="#E47667"
                                      size={16}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    <div className="flex items-end gap-2">
                      <FInput
                        onChange={(value) => setNewOption(value)}
                        onEnter={handleAddNewOption}
                        label="New Option"
                        value={newOption}
                        width="100%"
                      />
                      <FButton
                        onClick={handleAddNewOption}
                        primary
                        label="Add+"
                        height="40px"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
      </div>
      <div className="flex gap-2 justify-end py-4 sticky bottom-0 bg-white">
        <FButton
          onClick={isModal ? onClose : handleGoBackToList}
          label="Cancel"
          width="100%"
          height="40px"
        />
        <FButton
          onClick={handleSave}
          loading={waiting}
          primary
          label="Save"
          width="100%"
          height="40px"
        />
      </div>
    </div>
  );
};

export default QuestionForm;
