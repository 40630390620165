export const downloadDataAsFile = (data, fileName, type) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const downloadDataAsCSVFile = (data, fileName) =>
  downloadDataAsFile(data, fileName, "text/csv");
