// Import category images
import content1 from "./assets/content1.jpg";
import content2 from "./assets/content2.jpg";
import content3 from "./assets/content3.jpg";
import content4 from "./assets/content4.jpg";
import content5 from "./assets/content5.jpg";

import creator1 from "./assets/creator1.jpg";
import creator2 from "./assets/creator2.jpg";
import creator3 from "./assets/creator3.jpg";
import creator4 from "./assets/creator4.jpg";
import creator5 from "./assets/creator5.jpg";
import creator6 from "./assets/creator6.jpg";

export const platformList = [
  { label: "Instagram", value: "instagram", icon: "instagram" },
  { label: "TikTok", value: "tiktok", icon: "tiktok" },
  { label: "YouTube", value: "youtube", icon: "youtube" },
];

export const TARGET_OPPORTUNITIES = [
  ["sponsoredcontent", "Sponsored Content"],

  ["eventattendance", "Event Attendance"],

  ["eventhosting", "Event Hosting"],

  ["brandtakeovers", "Brand Take Overs"],

  ["ugc", "UGC (User Generated Content)"],

  ["gifting", "Gifting"],
];

export const MANAGED_OPTIONS = [
  {
    value: "true",
    label: "Managed",
  },
  { value: "false", label: "Self Managed" },
];

export const PARENTAL_OPTIONS = [
  {
    value: "true",
    label: "Children",
  },
  { value: "false", label: "No Children" },
];

export const INCOME_OPTIONS = [
  {
    value: 1,
    label: "Under $30,000",
  },
  {
    value: 2,
    label: "$30k - $50k",
  },
  {
    value: 3,
    label: "$50k - $75k",
  },
  {
    value: 4,
    label: "$75k - $100k",
  },
  {
    value: 5,
    label: "$100k+",
  },
];

export const AGE_RANGES_OPTIONS = [
  {
    value: 2,
    label: "12-17",
  },
  {
    value: 3,
    label: "18-24",
  },
  {
    value: 4,
    label: "25-34",
  },
  {
    value: 5,
    label: "35-44",
  },
  {
    value: 6,
    label: "45-54",
  },
  {
    value: 7,
    label: "55-64",
  },
  {
    value: 8,
    label: "65+",
  },
];

// Types
export interface CategoryImage {
  image: string;
  advanced_params: {};
  label: string;
  description: string;
}

// Search categories
export const allSearchCategories = [
  {
    value: "3",
    label: "Fashion",
    icon: "fashion",
  },
  {
    value: "4",
    label: "Lifestyle",
    icon: "lifestyle",
  },
  {
    value: "37",
    label: "Makeup",
    icon: "makeup",
  },
  {
    value: "24",
    label: "Travel",
    icon: "travel",
  },
  {
    value: "44",
    label: "Sports",
    icon: "sports",
  },
  {
    value: "32",
    label: "Gaming",
    icon: "gaming",
  },
  {
    value: "31",
    label: "Fitness",
    icon: "fitness",
  },
  {
    value: "17",
    label: "Parenting",
    icon: "parenting",
  },
  {
    value: "40",
    label: "Food",
    icon: "food",
  },
  {
    value: "23",
    label: "Art & Design",
    icon: "art",
  },
  {
    value: "10",
    label: "Photography",
    icon: "photography",
  },
  {
    value: "30",
    label: "Fragrance",
    icon: "fragrance",
  },
  {
    value: "33",
    label: "Hair",
    icon: "hair",
  },
  {
    value: "43",
    label: "Outdoors",
    icon: "sun",
  },
  {
    value: "34",
    label: "Wellness & Mindfulness",
    icon: "wellness",
  },
  {
    value: "38",
    label: "Sustainability",
    icon: "sustainability",
  },
  {
    value: "41",
    label: "Music & Entertainment",
    icon: "music",
  },
  {
    value: "35",
    label: "Home Decor & DIY",
    icon: "home-2",
  },
  {
    value: "39",
    label: "Skincare",
    icon: "skincare",
  },
  {
    value: "42",
    label: "Pets",
    icon: "pets",
  },
  {
    value: "45",
    label: "Education",
    icon: "whiteboard",
  },
  {
    value: "46",
    label: "Business Owner",
    icon: "business",
  },
  {
    value: "47",
    label: "Professional",
    icon: "professionals",
  },
  {
    value: "48",
    label: "Jewelry",
    icon: "jewelry",
  },
  {
    value: "49",
    label: "College Students",
    icon: "graduation-cap",
  },
];

// Creator category images
export const categoryImages: CategoryImage[] = [
  {
    image: creator1,
    label: "High TikTok Viewership",
    description: "Creators with 10K+ followers on TikTok",
    advanced_params: { tiktok_followers: { max: "undefined", min: "10000" } },
  },
  {
    image: creator2,
    label: "Rapid Instagram Growth",
    description:
      "Creators with 10K+ followers on Instagram and a monthly growth rate over 25%",
    advanced_params: {
      instagram_followers: { max: "undefined", min: "10000" },
      instagram_growth_period: "monthly",
      instagram_growth_rate_range: { max: "undefined", min: "25" },
    },
  },
  {
    image: creator3,
    label: "Rapid TikTok Growth",
    description:
      "Creators with 10K+ followers on TikTok and a monthly growth rate over 25%",
    advanced_params: {
      tiktok_followers: { max: "undefined", min: "10000" },
      tiktok_growth_period: "monthly",
      tiktok_growth_rate_range: { max: "undefined", min: "25" },
    },
  },
  {
    image: creator4,
    label: "High Reels Engagement",
    description:
      "Creators with 10K+ followers on Instagram and an over 20% engagement rate",
    advanced_params: {
      instagram_followers: { max: "undefined", min: "10000" },
      instagram_engagement_rate: { max: undefined, min: "20" },
    },
  },
  {
    image: creator5,
    label: "Open to Gifting",
    description: "Creators on any platform open to giving",
    advanced_params: {
      instagram_followers: { max: "100000", min: "10000" },
      tiktok_followers: { max: "100000", min: "10000" },
      target_opportunities: ["gifting"],
    },
  },
  //following delayed until we can combine rapid tiktok and instagram growth - SE 2/18/25
  // {
  //   image: travelImg,
  //   label: "Most Consistent Engagement",
  //   advanced_params: {
  //     instagram_followers: { max: "undefined", min: "10000" },
  //     tiktok_followers: { max: "undefined", min: "10000" },
  //     instagram_engagement_rate: { max: "undefined", min: "10" },
  //     tiktok_engagement_rate: { max: "undefined", min: "10" },
  //   },
  // },
  {
    image: creator6,
    label: "New Sign Ups",
    description: "Creators signed up in the last week",
    advanced_params: {
      sort: "created_at",
    },
  },
];

// Content category images
export const contentCategoryImages: CategoryImage[] = [
  {
    image: content1,
    label: "High Performing Reels",
    description: "Top 5% of Instagram Reels based on viewership",
    advanced_params: {
      media_product_type: "reels",
      sort: "impressions",
    },
  },
  {
    image: content2,
    label: "High Performing TikToks",
    description: "Top 5% of TikTok posts based on viewership",
    advanced_params: {
      platforms: ["tiktok"],
      sort: "impressions",
    },
  },
  {
    image: content3,
    label: "Highest Engaged Reels Posts",
    description: "Top 5% of Reels on Instagram based on engagement",
    advanced_params: {
      media_product_type: "reels",
      sort: "interactions",
    },
  },
  {
    image: content4,
    label: "Highest Engaged TikToks",
    description: "Top 5% of posts on TikTok based on engagement",
    advanced_params: {
      platforms: ["tiktok"],
      sort: "interactions",
    },
  },
  {
    image: content5,
    label: "Highest Growth",
    description: "Posts by creators with the highest follower growth",
    advanced_params: {
      tiktok_growth_period: "quarterly",
      tiktok_growth_rate_range: { max: "undefined", min: "25" },
      instagram_growth_period: "quarterly",
      instagram_growth_rate_range: { max: "undefined", min: "25" },
    },
  }, // Travel
  // Skipping Comments for now as we do not have the filtering capabilities for it atm - SE 2/18/25
];
