import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import { CampaignContext, ContentReviewContext } from "@contexts/index";

import { addFeedback, updateContentStatus } from "@api/Campaign/ContentReview";
import {
  CampaignContentReview,
  ContentReviewContent,
  ContentReviewRound,
} from "types/content-review";
import { capitalize, groupBy, last } from "lodash";

import toast from "react-hot-toast";

import FProcessNav from "@components/FProcessNav";
import FButtonSelect from "@components/FButtonSelect";
import FilterDropdowns from "@components/FilterDropdowns";
import ParticipantDeliverables from "./components/ParticipantDeliverables";
import STATUSES, { CONTENT_REVIEW_STATUS } from "./statuses";
import ContentReviewList from "./components/ContentReviewList";
import { Campaign } from "@types";

import ContentReviewModal from "./components/ContentReviewModal";
import DeliverablesOverview from "./components/DeliverablesOverview";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import {
  startWorkflowTimer,
  completeWorkflowTimer,
  trackStepInWorkflow,
} from "@utils/mixpanel_time";

const VIEW_OPTIONS = ["participant", "deliverable"];

const getContentByStatus = (
  allContent: ContentReviewContent[],
  isBrandUser: boolean
) => {
  const result = groupBy(allContent, "status");

  const brandApproved = result[CONTENT_REVIEW_STATUS.SOFT_APPROVED] || [];
  const approved = result[CONTENT_REVIEW_STATUS.APPROVED] || [];

  if (!isBrandUser) {
    return result;
  }

  return {
    ...result,
    [CONTENT_REVIEW_STATUS.APPROVED]: [...brandApproved, ...approved],
  };
};

const getUpdatedContentReview = (
  contentReview: CampaignContentReview,
  contentId: number,
  updates: { status: string }
) => {
  const { participants } = contentReview;

  return {
    ...contentReview,
    participants: participants.map((participant) => {
      const { deliverables } = participant;

      return {
        ...participant,
        deliverables: deliverables.map((deliverable) => {
          const { content } = deliverable;

          const updatedContent =
            content?.id === contentId
              ? {
                  ...content,
                  ...updates,
                }
              : content;

          return {
            ...deliverable,
            content: updatedContent,
          };
        }),
      };
    }),
  };
};

const getAllContent = (
  contentReview: CampaignContentReview,
  campaign: Campaign
): ContentReviewContent[] => {
  if (!contentReview) {
    return [];
  }

  const { participants } = contentReview;

  let result = [] as ContentReviewContent[];

  const { participants: campaignParticipants } = campaign;

  participants.forEach((participant) => {
    const { deliverables, fohr_campaigns_participant_id } = participant;

    const campaignParticipant = campaignParticipants.find(
      ({ id }) => id === fohr_campaigns_participant_id
    );

    deliverables.forEach((deliverable) => {
      const { content } = deliverable;

      if (content) {
        result = [
          ...result,
          { ...content, deliverable, participant, campaignParticipant },
        ];
      }
    });
  });

  return result;
};

const ContentReview = () => {
  const { campaign } = useContext(CampaignContext);
  const { contentReview, setContentReview } = useContext(ContentReviewContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const { hasRole } = useAuth();

  const { trackEvent } = useContext(MixpanelContext);

  // Get status from URL or default to "not_submitted"
  const selectedStatus = searchParams.get("status") || "not_submitted";
  const selectedView = searchParams.get("view") || VIEW_OPTIONS[0];

  const [selectedFilters, setSelectedFilters] = useState({
    stage: "",
    deliverable: "",
    participant: "",
    mediaType: "",
  });

  const [activePost, setActivePost] = useState(null);
  const [rejecting, setRejecting] = useState(false);

  // Update URL when status changes
  const handleStatusChange = (value: CONTENT_REVIEW_STATUS) => {
    // Track content review status filter event
    trackEvent(EVENTS.CONTENT_REVIEW.STATUS_FILTERED, {
      previous_status: selectedStatus,
      new_status: value,
      campaign_id: campaign?.id,
      campaign_name: campaign?.title,
      content_count: (contentByStatus[value] || []).length,
    });

    setSearchParams((prev) => {
      prev.set("status", value);
      return prev;
    });
  };

  // Update URL when view changes
  const handleViewChange = (value: string) => {
    // Track content review tab changed event
    trackEvent(EVENTS.CONTENT_REVIEW.TAB_CHANGED, {
      previous_tab: selectedView,
      new_tab: value,
      campaign_id: campaign?.id,
      campaign_name: campaign?.title,
    });

    setSearchParams((prev) => {
      prev.set("view", value);
      return prev;
    });
  };

  const isBrandUser =
    hasRole(Role.BRAND_MANAGER) ||
    hasRole(Role.BRAND_OWNER) ||
    hasRole(Role.BRAND_USER);

  const statuses = STATUSES.filter(({ value }) => {
    const { ADMIN_REVIEW, SOFT_APPROVED } = CONTENT_REVIEW_STATUS;

    if (isBrandUser) {
      return ![ADMIN_REVIEW, SOFT_APPROVED].includes(value);
    }

    return true;
  });

  const content = useMemo(() => {
    const statusValues = statuses.map(({ value }) => value);

    return getAllContent(contentReview, campaign).filter((item) =>
      [...statusValues, CONTENT_REVIEW_STATUS.SOFT_APPROVED].includes(
        item.status as CONTENT_REVIEW_STATUS
      )
    );
  }, [contentReview, campaign, statuses]);

  const contentByStatus = useMemo(
    () => getContentByStatus(content, isBrandUser),
    [content, isBrandUser]
  );

  const visibleContent = contentByStatus[selectedStatus] || [];

  const handleUpdateContentStatus = async (
    contentId: number,
    status: CONTENT_REVIEW_STATUS
  ) => {
    const selectedItems = [contentId];

    // Find the content item before it's updated to track changes
    const contentBeforeUpdate = getAllContent(contentReview, campaign).find(
      (content) => content.id === contentId
    );

    const previousStatus = contentBeforeUpdate?.status;
    const isApproval =
      status === CONTENT_REVIEW_STATUS.SOFT_APPROVED ||
      status === CONTENT_REVIEW_STATUS.APPROVED;
    const isRejection =
      status === CONTENT_REVIEW_STATUS.ADMIN_CHANGES ||
      status === CONTENT_REVIEW_STATUS.INFLUENCER_CHANGES;

    Promise.all(
      selectedItems.map(async (contentId) => {
        try {
          await updateContentStatus(contentId, status);

          return contentId;
        } catch (e) {
          toast.error(`Error updating content with ID ${contentId}`);

          // Track error
          trackEvent(EVENTS.ERROR.API_ERROR, {
            error_type: "content_status_update",
            error_message:
              e?.response?.data?.message || `Error updating content status`,
            content_id: contentId,
            previous_status: previousStatus,
            attempted_status: status,
            campaign_id: campaign?.id,
            campaign_name: campaign?.title,
          });

          return null;
        }
      })
    ).then((updatedIds) => {
      setContentReview(
        getUpdatedContentReview(contentReview, updatedIds[0], { status })
      );

      setActivePost(null);

      // Determine the actual status (handling special case for brand users)
      const actualStatus =
        isBrandUser && status === CONTENT_REVIEW_STATUS.SOFT_APPROVED
          ? CONTENT_REVIEW_STATUS.APPROVED
          : status;

      // Track approval event
      if (isApproval) {
        trackEvent(EVENTS.CAMPAIGN.CONTENT_APPROVED, {
          content_id: contentId,
          campaign_id: campaign?.id,
          campaign_name: campaign?.title,
          previous_status: previousStatus,
          approver_type: isBrandUser ? "brand" : "fohr",
          rounds_count: contentBeforeUpdate?.rounds?.length || 1,
        });

        // Complete the workflow if applicable
        if (contentBeforeUpdate) {
          completeWorkflowTimer("Content Approval", {
            content_id: contentId,
            campaign_id: campaign?.id,
            outcome: "approved",
            approver_type: isBrandUser ? "brand" : "fohr",
          });
        }
      }
      // Track rejection event
      else if (isRejection) {
        trackEvent(EVENTS.CAMPAIGN.CONTENT_REJECTED, {
          content_id: contentId,
          campaign_id: campaign?.id,
          campaign_name: campaign?.title,
          previous_status: previousStatus,
          rejector_type: isBrandUser ? "brand" : "fohr",
          rounds_count: contentBeforeUpdate?.rounds?.length || 1,
        });

        // Track workflow step
        trackStepInWorkflow("Content Approval", "Rejected", {
          content_id: contentId,
          campaign_id: campaign?.id,
          rejector_type: isBrandUser ? "brand" : "fohr",
          requires_changes: true,
        });
      }
      // Track other status changes
      else {
        trackEvent("Content Status Changed", {
          content_id: contentId,
          campaign_id: campaign?.id,
          campaign_name: campaign?.title,
          previous_status: previousStatus,
          new_status: actualStatus,
        });
      }

      toast.success(
        `Content moved to ${STATUSES.find(({ value }) => value === (isBrandUser && status === CONTENT_REVIEW_STATUS.SOFT_APPROVED ? CONTENT_REVIEW_STATUS.APPROVED : status)).label}`
      );
    });
  };

  const handleReject = async ({ mediaFeedback, captionFeedback }) => {
    const { id, rounds } = activePost;

    setRejecting(true);

    const status = isBrandUser
      ? CONTENT_REVIEW_STATUS.ADMIN_CHANGES
      : CONTENT_REVIEW_STATUS.INFLUENCER_CHANGES;

    const previousStatus = activePost?.status;

    const params = {
      content_id: id,
      round_id: (last(rounds) as ContentReviewRound).id,
      recipient: isBrandUser ? "admin" : "influencer",
    };

    try {
      await addFeedback({
        ...params,
        subject: "media",
        body: mediaFeedback,
      });

      await addFeedback({
        ...params,
        subject: "description",
        body: captionFeedback,
      });

      await updateContentStatus(id, status);

      setContentReview(getUpdatedContentReview(contentReview, id, { status }));

      // Track content rejection with feedback
      trackEvent(EVENTS.CAMPAIGN.CONTENT_REJECTED, {
        content_id: id,
        campaign_id: campaign?.id,
        campaign_name: campaign?.title,
        previous_status: previousStatus,
        rejector_type: isBrandUser ? "brand" : "fohr",
        rounds_count: rounds?.length || 1,
        has_media_feedback: !!mediaFeedback,
        has_caption_feedback: !!captionFeedback,
      });

      // Track workflow step
      trackStepInWorkflow("Content Approval", "Rejected with Feedback", {
        content_id: id,
        campaign_id: campaign?.id,
        rejector_type: isBrandUser ? "brand" : "fohr",
        feedback_types: [
          mediaFeedback ? "media" : null,
          captionFeedback ? "caption" : null,
        ]
          .filter(Boolean)
          .join(","),
      });

      setActivePost(null);

      toast.success(`Content Rejected`);
    } catch (e) {
      toast.error("Error rejecting content");

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "content_rejection",
        error_message: e?.response?.data?.message || `Error rejecting content`,
        content_id: id,
        previous_status: previousStatus,
        campaign_id: campaign?.id,
        campaign_name: campaign?.title,
      });
    } finally {
      setRejecting(false);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center py-[40px] border-light_border">
        <div className="flex flex-row justify-between items-center gap-4 w-full">
          <h2 className="text-2xl">
            Content Review ({content ? content.length : ""})
          </h2>
          {selectedStatus !== "not_submitted" && (
            <FilterDropdowns
              content={visibleContent}
              campaign={campaign}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              disabled={visibleContent.length === 0}
            />
          )}
        </div>
        {selectedStatus === "not_submitted" ? (
          <div className="flex items-center gap-4 w-full justify-end">
            <span className="text-sm">View By:</span>
            <FButtonSelect
              options={VIEW_OPTIONS.map((view) => ({
                label: capitalize(view),
                value: view,
              }))}
              selectedValue={selectedView}
              onChange={handleViewChange}
            />
          </div>
        ) : null}
      </div>
      <FProcessNav
        className="mb-[40px]"
        items={statuses.map((item) => ({
          ...item,
          label: `${!isBrandUser && item.value === CONTENT_REVIEW_STATUS.ADMIN_CHANGES ? "Client Rejected" : item.label} (${contentByStatus[item.value]?.length || 0})`,
        }))}
        selectedValue={selectedStatus}
        onChange={handleStatusChange}
      />

      {selectedStatus === "not_submitted" ? (
        selectedView === "participant" ? (
          <ParticipantDeliverables
            campaign={campaign}
            contentReview={contentReview}
            showNotSubmitted
          />
        ) : (
          <DeliverablesOverview
            campaign={campaign}
            contentReview={contentReview}
          />
        )
      ) : (
        <ContentReviewList
          campaign={campaign}
          content={visibleContent}
          status={selectedStatus}
          onSelectedContent={setActivePost}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}
      {activePost && (
        <ContentReviewModal
          post={activePost}
          onClose={() => setActivePost(null)}
          onUpdateStatus={(value) =>
            handleUpdateContentStatus(activePost.id, value)
          }
          onReject={handleReject}
          rejecting={rejecting}
          statuses={statuses}
        />
      )}
    </>
  );
};

export default ContentReview;
