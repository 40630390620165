import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const OPTIONS = {
  chart: {
    type: "pie",
    height: 56,
    margin: 0,
    minPadding: 0,
    maxPadding: 0,
    renderTo: "chart",
    width: 56,
    backgroundColor: "transparent",
  },
  series: [
    {
      type: "pie",
      name: "Percentage",
      borderRadius: 0,
      size: 56,
    },
  ],
  tooltip: {
    formatter: function () {
      return `<span style="color: white"><b>${this.key}</b>: ${Math.round(this.y)}%</span>`;
    },
    outside: true,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    borderWidth: 0,
    shadow: false,
    style: {
      color: "white",
    },
    useHTML: true,
  },
};

interface PieChartProps {
  data: { name: string; percentage: number }[];
  colors: string[];
  onCategoryClick: (categoryName: string) => void;
}

const PieChart = ({ data, colors, onCategoryClick }: PieChartProps) => {
  const seriesData = data.map(({ name, percentage }) => [name, percentage]);

  const options = {
    ...OPTIONS,
    colors,
    series: [
      {
        ...OPTIONS.series[0],
        data: seriesData,
        events: {
          click: function (event) {
            const categoryName = event.point.name;
            onCategoryClick(categoryName);
          },
        },
        states: {
          hover: {
            brightness: 0.1,
            enabled: true,
          },
        },
      },
    ],
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: "pie",
        size: "100%",
        innerSize: "60%",
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
        },
      },
    },
  };

  return (
    <HighchartsReact
      containerProps={{
        style: { height: "100%", width: "100%", background: "transparent" },
      }}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default PieChart;
