import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import apiClient from "@apiClient";
import { GroupData } from "@apiTypes";
import SinglePageLayout from "@layouts/SinglePageLayout";
import { GroupContext } from "@contexts/index";
import LoadingSpinner from "@components/LoadingSpinner";
import FButton from "@components/FButton";
import { ButtonDropDown } from "@components/ButtonDropDown";
import EntityCRUD from "@pages/campaigns/EntityCRUD";
import { mapCategories } from "@utils/cardUtilities";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import AddToGroupModal from "./AddToGroupModal";
import AddToCampaignModal from "./AddToCampaignModal";
import CombineGroupsModal from "./CombineGroupsModal";
import ConfirmModal from "@components/Modals/ConfirmModal";
import BlankState from "@components/BlankState";
import SendMessageModal from "@components/Modals/SendMessageModal";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { Role } from "@constants/roles";
import useAuth from "@hooks/useAuth";

const { get, remove_creator, download_csv, duplicate } = apiClient.groups;

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isImage: true,
    imageType: "profile" as "asset" | "profile",
    imageKey: "profile_image_url",
    isFirstColumn: true,
  },
  {
    nestedKey: "followers",
    key: "total",
    label: "Total Followers",
    isNumber: true,
  },
  {
    nestedKey: "followers",
    key: "instagram",
    label: "Instagram",
    isNumber: true,
  },
  { nestedKey: "followers", key: "tiktok", label: "TikTok", isNumber: true },
  {
    nestedKey: "followers",
    key: "facebook",
    label: "Facebook",
    isNumber: true,
  },
  { nestedKey: "followers", key: "youtube", label: "Youtube", isNumber: true },
  { key: "age", label: "Age" },
  { key: "location", label: "Location" },
  { key: "categories", label: "Categories", isCategories: true },
];

const cardDropDownOptions = [
  { label: "Message", value: "message" },
  { label: "Add to Campaign", value: "addToCampaign" },
  { label: "Remove", value: "remove" },
  { label: "Add to other Group", value: "addToAnotherGroup" },
];

const editFormFields = [{ label: "Name", key: "name" }];

export default function GroupsPage() {
  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);
  const { group_id } = useParams<{ group_id: string }>();
  const [group, setGroup] = useState<GroupData>();
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [editOptions, setEditOptions] = useState<{
    id: string | number;
    value: string | boolean | number;
  }>();
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false);
  const [showAddToCampaignModal, setShowAddToCampaignModal] = useState(false);
  const [showCombineGroupsModal, setShowCombineGroupsModal] = useState(false);
  const [showGroupEditModal, setShowGroupEditModal] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [creatorID, setCreatorID] = useState<number>();
  const navigate = useNavigate();
  const { hasRole } = useAuth();
  const { trackEvent } = useContext(MixpanelContext);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await get(group_id);
        const groupData = response.data;
        const updatedGroupData = {
          ...groupData,
          creators:
            groupData.creators?.map((creator) => ({
              ...creator,
              categories: mapCategories(
                creator.tags ? creator.tags.split(", ") : [],
                "creator"
              ),
              overall_followers: creator.followers?.total || 0,
            })) || [],
        };
        setGroup(updatedGroupData);
        trackEvent(EVENTS.PAGE_VIEW.GROUP, { group: groupData.name });
      } catch (error) {
        console.error("Failed to fetch data", error);
        toast.error("Failed to load group data");
      } finally {
        setLoading(false);
      }
    }
    fetchData();

    document.title = "Group Profile";
  }, [group_id]);

  const highLevel = group && [
    {
      icon: "user",
      name: "Owner",
      value: group.owner_user?.full_name || "N/A",
    },
    { icon: "business", name: "Brand", value: group.brand?.name || "N/A" },
    {
      icon: "clock",
      name: "Created",
      value: moment(group.created_at).format("MMMM YYYY") || "N/A",
    },
  ];

  const entityAction = async ({ id, value }) => {
    if (value === "remove") {
      setSelectedItems([...selectedItems, id]);
      setShowGroupEditModal(true);
    } else if (value === "message") {
      setCreatorID(id);
      setShowSendMessageModal(true);
    } else if (value === "addToCampaign") {
      setCreatorID(id);
      setShowAddToCampaignModal(true);
    } else if (value == "addToAnotherGroup") {
      setSelectedItems([id]);
      setAddToGroupModalVisible(true);
    }
  };
  const downloadCSV = async () => {
    try {
      const response = await download_csv(group.id);
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.style.display = "none";
      a.href = url;
      a.download = `${group.name}_creators.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // Track group downloaded event
      trackEvent(EVENTS.GROUP.DOWNLOADED, {
        group_id: group.id,
        group_name: group.name,
        creators_count: group.creators?.length || 0,
      });

      toast.success("Group exported to CSV");
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toast.error("Failed to download CSV");
    }
  };

  const handleDuplicateGroup = async (id: number) => {
    try {
      const response = await duplicate(id);

      // Track group duplicated event
      trackEvent(EVENTS.GROUP.DUPLICATED, {
        original_group_id: id,
        original_group_name: group.name,
        new_group_id: response.data.id,
        new_group_name: response.data.name,
      });

      toast.success("Group duplicated successfully!");
      navigate(`/groups/${response.data.id}`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to duplicate group. Please try again.");
    }
  };

  const handleEditOptionChange = (value) => {
    if (value === "addToCampaign") {
      setShowAddToCampaignModal(true);
      // This will be tracked in the AddToCampaignModal when action is completed
    } else if (value === "remove") {
      setShowGroupEditModal(true);
      // This will be tracked in the removeCreatorsFromGroup function
    } else if (value === "downloadCSV") {
      downloadCSV();
      // Tracking is handled in the downloadCSV function
    } else if (value === "duplicate") {
      handleDuplicateGroup(group.id);
      // Tracking is handled in the handleDuplicateGroup function
    } else if (value === "combine") {
      setShowCombineGroupsModal(true);
      // This will be tracked in the CombineGroupsModal when action is completed
    } else if (value === "add_to_other_group") {
      setAddToGroupModalVisible(true);
    }
    setEditOptions({ id: group.id, value });
  };

  const removeCreatorsFromGroup = async () => {
    setLoading(true);
    try {
      const creatorsToRemove = group.creators.filter((creator) =>
        selectedItems.includes(creator.id)
      );
      const creatorIds = creatorsToRemove.map(
        (creator) => creator.group_creators_id
      );
      const response = await remove_creator(group.id, creatorIds);
      if (response.success) {
        setGroup(response.data);

        // Track successful removal of creators from group
        trackEvent(EVENTS.GROUP.INFLUENCER_REMOVED, {
          group_id: group.id,
          group_name: group.name,
          creators_count: creatorsToRemove.length,
          creators_ids: creatorsToRemove.map((creator) => creator.id),
        });

        toast.success("Creators removed successfully");
      }
    } catch (error) {
      console.error("Error removing creators from group:", error);

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: "remove_creators_from_group",
        error_message:
          error?.response?.data?.message ||
          "Error removing creators from group",
        group_id: group?.id,
        group_name: group?.name,
        creators_count: selectedItems.length,
      });

      toast.error("Failed to remove creators");
    } finally {
      setLoading(false);
      setSelectedItems([]);
      setShowGroupEditModal(false);
    }
  };

  const customBlankState = (
    <BlankState
      title={`Your group is empty`}
      subtitle="Add creators to your group to get started"
      icon="user"
      onActionClick={() => setShowAddToGroupModal(true)}
      actionLabel="Add Creators"
    />
  );

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  if (!group) {
    return null;
  }

  return (
    <GroupContext.Provider value={{ group, setGroup }}>
      {isAddToGroupModalVisible ? (
        <AddCreatorsToGroupModal
          creatorIds={selectedItems}
          onClose={() => setAddToGroupModalVisible(false)}
        />
      ) : null}
      <SinglePageLayout
        pageName={group.name}
        pageTitle={group.name}
        cardData={group.creators}
        cardType="individual"
        tableColumns={tableColumns}
        cardDropDownOptions={cardDropDownOptions}
        rowActions={cardDropDownOptions}
        onTableRowDropDownSelect={(value, id) => entityAction({ id, value })}
        selectedItems={selectedItems}
        onChangeSelectedItems={setSelectedItems}
        highLevel={highLevel}
        loading={loading}
        defaultGrid={false}
        showTotals
        sortPathName="full_name"
        sortPathDate="created_at"
        filterPath="status"
        decorationPath="status"
        customBlankState={customBlankState}
        headerButtons={
          <div className="flex gap-2">
            <FButton
              primary={true}
              onClick={() => setShowAddToGroupModal(true)}
              label="Add Creators"
              type="button"
              iconLeft={{
                name: "add",
                size: 12,
                color: "#fff",
                className: "mr-2",
              }}
            />
            <ButtonDropDown
              zIndex={99999}
              iconOnly
              icon="ellipsis"
              alignDropDownRight
              options={[
                { label: "Add to Campaign", value: "addToCampaign" },
                { label: "Duplicate", value: "duplicate" },
                { label: "Combine", value: "combine" },
                ...(hasRole(Role.FREEMIUM)
                  ? []
                  : [{ label: "Download CSV", value: "downloadCSV" }]),
                { label: "Edit", value: "edit" },
                { label: "Delete", value: "delete" },
              ]}
              onChange={handleEditOptionChange}
            />
          </div>
        }
        subNavMainButton={
          selectedItems.length > 0 && (
            <ButtonDropDown
              zIndex={99999}
              label={`Edit (${selectedItems.length})`}
              options={[
                { label: "Remove", value: "remove" },
                { label: "Add to other Group", value: "add_to_other_group" },
              ]}
              onChange={handleEditOptionChange}
            />
          )
        }
      />
      <EntityCRUD
        apiNamespace="groups"
        pageName="Groups"
        entityData={[group]}
        setEntityData={(data) => setGroup(data[0])}
        editFormFields={editFormFields}
        entityName="Group"
        entitySelected={editOptions}
        setEntitySelected={setEditOptions}
      />
      {showAddToGroupModal && (
        <AddToGroupModal onClose={() => setShowAddToGroupModal(false)} />
      )}
      {showAddToCampaignModal && (
        <AddToCampaignModal
          onClose={() => {
            setShowAddToCampaignModal(false);
            setCreatorID(null);
          }}
          creatorID={creatorID}
        />
      )}
      {showCombineGroupsModal && (
        <CombineGroupsModal onClose={() => setShowCombineGroupsModal(false)} />
      )}
      <ConfirmModal
        title="Remove Creators"
        isOpen={showGroupEditModal}
        onClose={() => setShowGroupEditModal(false)}
        onAction={removeCreatorsFromGroup}
        actionLabel="Remove"
        subtitle="Are you sure you want to remove these creators from the group?"
      />
      {showSendMessageModal && (
        <SendMessageModal
          isOpen={showSendMessageModal}
          onClose={() => setShowSendMessageModal(false)}
          onCreated={(outreach) => {
            // Track group member messaged event
            const creator = group.creators.find(
              (creator) => creator.id === creatorID
            );
            trackEvent(EVENTS.GROUP.MEMBER_MESSAGED, {
              group_id: group.id,
              group_name: group.name,
              creator_id: creatorID,
              creator_name: creator?.full_name || "Unknown",
            });
            setShowSendMessageModal(false);
          }}
        />
      )}
    </GroupContext.Provider>
  );
}
