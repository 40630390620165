import React, { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import Heading from "@components/Heading";
import { getParticipantRecords } from "@api/creator_view";
import ResultsGrid from "@components/ResultsGrid";

import { Campaign, ParticipantStatus } from "@types";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";

interface CreatorCampaign extends Campaign {
  hideParticipantCount: boolean;
  participantId: number;
}

const CreatorCampaignsPage = () => {
  const [campaigns, setCampaigns] = useState<CreatorCampaign[]>([]);
  const { trackEvent, trackButtonClick } = useMixpanelTracking();
  const navigate = useNavigate();

  useEffect(() => {
    const loadCampaigns = async () => {
      const response = await getParticipantRecords();

      const campaignsData = response.data
        .map(({ campaign, id: participantId, status }) => ({
          ...campaign,
          hideParticipantCount: true,
          participantId,
          status,
        }))
        .filter(({ status }) =>
          [ParticipantStatus.ACCEPTED, ParticipantStatus.SENT].includes(status)
        );

      setCampaigns(campaignsData);

      // Track influencer-specific page view
      trackEvent(EVENTS.PAGE_VIEW.CAMPAIGN_OPPORTUNITIES, {
        campaign_count: campaignsData.length,
        accepted_campaigns: campaignsData.filter(
          (c) => c.status === ParticipantStatus.ACCEPTED
        ).length,
        sent_campaigns: campaignsData.filter(
          (c) => c.status === ParticipantStatus.SENT
        ).length,
      });
    };

    loadCampaigns();

    // Set page title
    document.title = "My Campaigns";
  }, [trackEvent]);

  const handleSelectedCampaign = (campaignId: number) => {
    const campaign = campaigns.find(({ id }) => id === campaignId);

    const { status } = campaign;

    // Track campaign selection
    trackButtonClick("Campaign Card", {
      campaign_id: campaignId,
      campaign_name: campaign.title,
      campaign_status: status,
    });

    navigate(
      status === "accepted"
        ? `/campaign-view/${campaign.participantId}`
        : `/participant-brief/${campaign.id}/${campaign.participantId}`
    );
  };

  return (
    <div className="pb-16">
      <Heading title="Campaigns" className="mb-20 pb-8" />
      <ResultsGrid
        cardData={campaigns}
        cardType="asset"
        onCardClick={handleSelectedCampaign}
      />
    </div>
  );
};

export default CreatorCampaignsPage;
