import React, { useEffect, ReactNode, useContext, useRef } from "react";

import { LayoutContext, ProfileContext } from "@contexts/index";

interface ModalProps {
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

const ProfileModal: React.FC<ModalProps> = ({
  onClose,
  children,
  className,
}) => {
  const { collapsedNavWidth, fullNavWidth, collapsed } =
    useContext(LayoutContext);

  const { setIsBriefNavFixed } = useContext(ProfileContext);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Handle the escape key
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  // Prevent body scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const navWidth = collapsed ? collapsedNavWidth : fullNavWidth;

  useEffect(() => {
    const handleScroll = () => {
      if (
        scrollContainerRef.current &&
        scrollContainerRef.current.scrollTop >= 400
      ) {
        setIsBriefNavFixed(true);
      } else {
        setIsBriefNavFixed(false);
      }
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      // Initial check
      handleScroll();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      className={`absolute z-[9000] w-full inset-0 bg-dark_night_sky bg-opacity-70 flex justify-end`}
      onClick={onClose}
    >
      <div
        className={`bg-light_red shadow-2xl w-full max-w-[calc(100vw-${navWidth})] ${className}`}
        onClick={(e) => e.stopPropagation()}
        ref={scrollContainerRef}
      >
        {children}
      </div>
    </div>
  );
};

export default ProfileModal;
