import React, { useEffect, useState } from "react";

import apiClient from "@apiClient";

import LoadingSpinner from "@components/LoadingSpinner";
import { Campaign, ParticipantOffer } from "@types";
import { useParams } from "react-router-dom";

import { getCampaignBrief } from "@api/CampaignBriefs/CampaignBriefs";
import { getParticipantBriefs } from "@api/CampaignParticipantBriefs/CampaignParticipantBriefs";
import { getParticipantBriefIndex } from "@api/CampaignParticipantBriefs/CampaignParticipantBriefSections";

import BriefPreviewContent from "./BriefPreviewContent";
import { getParticipantOffers } from "@api/ParticipantBriefs/ParticipantOffers";
import { useMixpanelTracking } from "@hooks/useMixpanelTracking";
import { EVENTS } from "@utils/mixpanel_utilities";

const { campaigns, campaign_brief_sections } = apiClient;

interface BriefPreviewProps {
  hideHeader?: boolean;
  participant_group_id: number;
  participant_id: number;
}

export default function BriefPreview({
  hideHeader,
  participant_group_id,
  participant_id,
}: BriefPreviewProps) {
  const { campaign_id } = useParams();
  const { trackEvent, trackWorkflowStart } = useMixpanelTracking();

  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign>(null);

  const [participantOffers, setParticipantOffers] =
    useState<ParticipantOffer[]>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const campaignResponse = await campaigns.get(campaign_id);

      const campaign = campaignResponse.data;

      let brief;

      if (participant_id) {
        // This is an influencer viewing a campaign brief
        document.title = `Campaign Brief - ${campaign.title || "Campaign Details"}`;

        const participantBriefs = await getParticipantBriefs({
          participant_campaign_id_eq: parseInt(campaign_id, 10),
        });

        brief = participantBriefs.find(
          (brief) => brief.participant_id === participant_id
        );

        const participantBriefSections = await getParticipantBriefIndex({
          participant_brief_id: brief.id,
        });

        brief.sections = participantBriefSections || [];

        const offers = await getParticipantOffers(participant_id);

        setParticipantOffers(offers);

        // Track campaign application view for influencers
        trackEvent(EVENTS.PAGE_VIEW.CAMPAIGN_APPLICATION, {
          campaign_id: campaign_id,
          campaign_name: campaign.title,
          brief_id: brief.id,
          has_offers: offers && offers.length > 0,
          offers_count: offers ? offers.length : 0,
        });

        // Start the application workflow tracking if this is a new view
        if (
          sessionStorage.getItem(`campaign_application_${campaign_id}`) !==
          "started"
        ) {
          trackWorkflowStart("Campaign Application", {
            campaign_id: campaign_id,
            campaign_name: campaign.title,
          });
          sessionStorage.setItem(
            `campaign_application_${campaign_id}`,
            "started"
          );
        }
      } else {
        // This is a brand/internal user viewing a campaign brief template
        document.title = `Brief Preview - ${campaign.title || "Campaign"}`;

        const briefs = await getCampaignBrief(campaign_id);

        brief = participant_group_id
          ? briefs.find(
              (brief) => brief.participant_group_id === participant_group_id
            )
          : campaign.brief;

        const campaignBriefSections = await campaign_brief_sections.get(
          brief.id
        );

        brief.sections = campaignBriefSections || [];

        // Track brief preview view for brand users
        trackEvent(EVENTS.CAMPAIGN.VIEWED, {
          campaign_id: campaign_id,
          campaign_name: campaign.title,
          brief_id: brief.id,
          view_type: "preview",
          participant_group_id: participant_group_id || null,
        });
      }

      campaign.brief = brief;

      setCampaign(campaign);

      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  return (
    <BriefPreviewContent
      campaign={campaign}
      hideHeader={hideHeader}
      participant_id={participant_id}
      participantOffers={participantOffers}
    />
  );
}
