import React from "react";

interface BgCoverImageProps {
  imageUrl: string;
  absolute?: boolean;
}

const BgCoverImage = ({ imageUrl, absolute }: BgCoverImageProps) => {
  const isValid =
    imageUrl && !imageUrl.includes("missing_default") && imageUrl.trim() !== "";

  return (
    <div
      className={`w-full z-0 h-[400px] ${absolute ? "absolute" : "relative"}`}
    >
      {isValid ? (
        <>
          <div
            className="absolute inset-0 bg-center bg-cover"
            style={{ backgroundImage: `url(${imageUrl})` }}
            role="img"
            aria-label="Cover image"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-[rgba(255,250,249,1)] via-[rgba(255,250,249,0.8)] to-[rgba(255,250,249,0.1)]" />
        </>
      ) : null}
    </div>
  );
};

export default BgCoverImage;
