import SocialConnectButton from "@pages/auth/influencer-onboarding/social/SocialConnectButton";
import React from "react";

interface YoutubeConnectButtonProps {
  isConnected: boolean;
  iconOnly: boolean;
  onClick: () => void;
  source: string;
}

const YoutubeConnectButton = ({
  isConnected,
  iconOnly,
  onClick,
  source,
}: YoutubeConnectButtonProps) => {
  const connectYoutube = async () => {
    try {
      const youtubeOAuthUrl = `https://www.ytstats.social/youtube-redirect?source=${source}`;
      window.location.href = youtubeOAuthUrl;
    } catch (error) {
      console.error("Failed to connect to YouTube:", error);
    }
  };

  return (
    <SocialConnectButton
      onClick={() => (isConnected ? onClick() : connectYoutube())}
      name="YouTube"
      icon="youtube"
      iconOnly={iconOnly}
      isConnected={isConnected}
    />
  );
};

export default YoutubeConnectButton;
