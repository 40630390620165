import React, { useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UpdateOutreachModal from "../../outreach/components/UpdateOutreachModal";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { Outreach, Sort } from "@types";
import FButton from "@components/FButton";

import apiClient from "@apiClient";

import toast from "react-hot-toast";
import { UserContext } from "@contexts/index";
import { changeMembership } from "@api/user";

const { create, delete: deleteOutreach, index } = apiClient.outreach;

const filterOptions = [
  { label: "All", value: null },
  { label: "Sent", value: "sent" },
  { label: "Draft", value: "draft" },
  { label: "Archived", value: "archived" },
];

const actions = {
  delete: async (id: number) => deleteOutreach(id),
};

const tableRowDropDownOptions = [
  { label: "Duplicate", value: "duplicate" },
  { label: "Delete", value: "delete" },
];

const OutreachAdmin = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Outreach[]>([]);

  const [isCreatingOutreach, setCreatingOutreach] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const [isUpdateOutreachModalVisible, setUpdateOutreachModalVisible] =
    useState(false);

  const getOutreach = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const query = {
        title_i_cont: search,
      };

      if (filter) {
        query[`${filter}_eq`] = true;
      }
      const response = await index(false, page, query);

      const { communications: items } = response.data;

      setData(items);

      return {
        ...response.data,
        items,
      };
    },
    [setData]
  );

  const tableColumns = [
    {
      key: "title",
      label: "Title",
      isLink: true,
      linkURL: "/outreach/{id}/message",
    },
    { key: "state", label: "State", isStateBubble: true },
    { key: "owner_name", label: "Created By" },
    { key: "brand_name", label: "Brand" },

    { key: "recipients", label: "Recipients" },
    { key: "delivered", label: "Delivered" },
    { key: "opened", label: "Opened" },
    { key: "clicks", label: "Clicks" },
    { key: "date_sent", label: "Date Sent" },
    { key: "created_at", label: "Created At" },
  ];

  const handleTableRowDropDownSelect = (value: string) => {};

  const handleCloseOutreachModal = (newCommunication: Outreach) => {
    if (newCommunication) {
      setData([newCommunication, ...data]);
    }

    setUpdateOutreachModalVisible(false);
  };

  const handleCreateOutreach = async () => {
    setCreatingOutreach(true);

    try {
      const response = await create({
        brand_id: user.brand_id,
        title: "New Outreach",
        message: {
          subject: "",
          body: "test",
          archived: false,
          user_id: user.user_id,
        },
      });

      setCreatingOutreach(false);

      toast.success("Outreach created successfully!");
      navigate(`/outreach/${response.data.id}/message`);
    } catch (error) {
      toast.error("Failed to create outreach. Please try again.");
    } finally {
      setCreatingOutreach(false);
    }
  };

  const headerButtons = (
    <div className="flex flex-row justify-end items-center gap-2">
      <FButton
        label="View Templates"
        onClick={() => navigate("/outreach/templates")}
      />
      <FButton
        primary
        iconLeft={{ name: "add", size: 14, color: "white" }}
        loading={isCreatingOutreach}
        onClick={handleCreateOutreach}
        label="Create Outreach"
      />
    </div>
  );

  const handleTableCellClick = (item, columnKey) => {
    const membership = user.memberships.find(
      (membership) => membership.brand_id === item.brand.id
    );

    if (user.brand_id === item.brand.id) {
      redirectToOutreach(item.id);
    } else if (membership) {
      handleMembershipChange(membership, item);
    } else {
      toast.error(
        "You are not a member of this brand. Add yourself via the Brands page."
      );
    }
  };

  const handleMembershipChange = async (membership, item) => {
    try {
      const result = await changeMembership({ id: membership.id });
      if (result.data.brand_id === membership.brand_id) {
        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        redirectToOutreach(item.id);
      } else {
        console.error("Failed to change membership");
      }
    } catch (error) {
      console.error("Error changing membership:", error);
    }
  };

  const redirectToOutreach = (itemId) => {
    window.location.href = `${window.location.origin}/groups/${itemId}`;
  };

  return (
    <>
      <SinglePageLayoutWithData
        entityName="Outreach"
        pageTitle="Outreach"
        pageName="Outreach"
        headerButtons={headerButtons}
        sortPathName="title"
        sortPathDate="dateSent"
        filterOptions={filterOptions}
        filterPath="state"
        tableColumns={tableColumns}
        rowActions={tableRowDropDownOptions}
        cardDropDownOptions={tableRowDropDownOptions}
        onTableCellClick={handleTableCellClick}
        createWithEntityCrud={() => setUpdateOutreachModalVisible(true)}
        onTableRowDropDownSelect={handleTableRowDropDownSelect}
        tableOnly
        asyncActions={actions}
        getItems={getOutreach}
      />
      {isUpdateOutreachModalVisible ? (
        <UpdateOutreachModal onClose={() => handleCloseOutreachModal(null)} />
      ) : null}
    </>
  );
};
export default OutreachAdmin;
