import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import USTopoData from "us-atlas/states-10m.json";
import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";
import getFillColor from "./map-utils";
import StatesTable from "@components/Tables/StatesTable";
import WidgetContainer from "./WidgetContainer";

interface StatesBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function StatesBreakdown({ items }: StatesBreakdownProps) {
  const maxValueItem = maxBy(items, "percentage");

  return (
    <div className="border border-highlight_red_opacity rounded-lg bg-white shadow-sm">
      <div className="p-4 flex justify-between items-center border-b border-highlight_red_opacity">
        <h3 className="text-lg font-medium">Top States</h3>
        <p className="text-lg opacity-50">{maxValueItem?.name}</p>
      </div>
      <div className="p-4">
        <ComposableMap projection="geoAlbersUsa">
          <Geographies geography={USTopoData}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const value = items?.find((c) =>
                  c.name
                    .toLowerCase()
                    .includes(geo.properties.name.toLowerCase())
                )?.percentage;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={getFillColor(value)}
                    stroke="#fff"
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        <div className="mt-4 border-t border-light_border  p-4 pb-0">
          <StatesTable states={items} />
        </div>
      </div>
    </div>
  );
}
