import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";

import Modal from "@components/Modals/Modal";
import { CampaignDeliverable } from "@types";

import { useState } from "react";

interface SelectDeliverableForAttributionModalProps {
  isAttributing?: boolean;
  onClose: () => void;
  deliverables: CampaignDeliverable[];
  onSelectedDeliverable: (deliverableId: number) => void;
}

const SelectDeliverableForAttributionModal = ({
  isAttributing,
  onClose,
  deliverables,
  onSelectedDeliverable,
}: SelectDeliverableForAttributionModalProps) => {
  const [selectedDeliverable, setSelectedDeliverable] = useState(
    deliverables[0]?.id
  );

  const handleSubmit = async () => {
    onSelectedDeliverable(selectedDeliverable);
  };

  return (
    <Modal
      isOpen
      className="w-[540px]"
      onClose={onClose}
      title="Deliverable Attribution"
      hasForm
    >
      <FDropdown
        hasNoDefault
        label="Deliverable"
        selectedValue={selectedDeliverable}
        onChange={(value) => {
          if (value === -1) {
            setSelectedDeliverable(value as number);
          } else {
            setSelectedDeliverable(value as number);
          }
        }}
        options={deliverables
          .map(({ id, name }) => ({
            value: id,
            label: name,
          }))
          .concat({ value: -1, label: "Bonus Content" })}
        width="100%"
      />
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 pt-6 border-t border-light_border">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={handleSubmit}
          label="Save"
          loading={isAttributing}
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </Modal>
  );
};

export default SelectDeliverableForAttributionModal;
