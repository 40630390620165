export const getVideoOrientation = async (url) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = url;
    video.onloadedmetadata = () => {
      const isLandscape = video.videoWidth > video.videoHeight;
      resolve(isLandscape ? "landscape" : "portrait");
      video.remove();
    };

    video.onerror = () => {
      console.error("Failed to load video metadata:", url);
      reject("Failed to load video");
      video.remove();
    };
    document.body.appendChild(video);
    video.style.display = "none";
  });
};
