import FIcon from "@components/FIcon";
import { Content } from "@types";
import { RefObject, useState } from "react";
import ContentPreview from "./ContentPreview";

interface ContentPreviewControlsProps {
  prevSlide: () => void;
  nextSlide: () => void;
  onSlideChange: (index: number) => void;
  currentSlide: number;
  isPaused: boolean;
  data: Partial<Content>[];
  videoRef: RefObject<HTMLVideoElement>;
  label?: string;
}

const ContentPreviewControls = ({
  prevSlide,
  nextSlide,
  onSlideChange,
  isPaused,
  data,
  currentSlide,
  videoRef,
  label,
}: ContentPreviewControlsProps) => {
  const [hoveredSlideIndex, setHoveredSlideIndex] = useState(null);

  return (
    <div className="flex items-center h-[69px] justify-between w-full">
      {label ? <span>{label}</span> : null}
      <button
        onClick={prevSlide}
        className="text-white font-bold py-2 px-4 rounded"
      >
        <FIcon icon="fancy-arrow-left" color="#000F45" size={18} />
      </button>
      <div className="bubbles flex justify-center flex-grow">
        {data.map((_, index) => (
          <div
            key={index}
            className={`cursor-pointer relative inline-block h-2.5 w-2.5 m-1 rounded-full ${
              index === currentSlide
                ? "bg-dark_night_sky"
                : "bg-transparent border border-dark_night_sky"
            }`}
            onClick={() => onSlideChange(index)}
            onMouseEnter={() => setHoveredSlideIndex(index)}
            onMouseLeave={() => setHoveredSlideIndex(null)}
          >
            {index === hoveredSlideIndex ? (
              <div className="absolute border-2 border-white z-12 bottom-[20px] left-[-60px] h-[120px] overflow-hidden rounded-lg w-[120px]">
                <ContentPreview
                  content={data[index]}
                  isPaused={isPaused}
                  videoRef={videoRef}
                  onVideoEnded={nextSlide}
                />
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <button
        onClick={nextSlide}
        className="text-white font-bold py-2 px-4 rounded"
      >
        <FIcon icon="fancy-arrow-right" color="#000F45" size={18} />
      </button>
    </div>
  );
};

export default ContentPreviewControls;
