import React, { useState, useContext, ComponentType, ReactNode } from "react";
import { useParams } from "react-router-dom";

import { ProfileContext } from "@contexts/index";

import { User } from "@types";

import { LazyRouteContent } from "@components/navigation/LazyRouteContent";

import ProfileNav from "@pages/profile/ProfileNav";

import ProfileOverview from "./ProfileOverview";
import toast from "react-hot-toast";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";
import InviteOffPlatformCreatorModal from "@components/Modals/InviteOffPlatformCreatorModal";
import { impersonateUser } from "@api/Admin/AdminBrands";

interface ProfileLayoutProps {
  user?: User;
  isValidProfileHash?: boolean;
  subNavItems: {
    label: string;
    value: string;
    component: ComponentType;
  }[];
  height?: string;
  navActions?: ReactNode;
  overviewWidth?: string;
}

export default function ProfileLayout({
  height,
  user,
  subNavItems,
  navActions,
}: ProfileLayoutProps) {
  const { content, profile, viewsData } = useContext(ProfileContext);

  const { subpage, participant_id } = useParams();

  const { hasRole } = useAuth();
  const [showOffPlatformInviteModal, setShowOffPlatformInviteModal] = useState(false);

  const dropdownOptions = [
    { label: "Share", value: "share_profile" },
    ...(profile.off_platform
      ? [{ label: "Invite Creator to Fohr", value: "invite_creator" }]
      : []),
    ...(hasRole(Role.SUPER_ADMIN)
      ? [{ label: "Log in as this user", value: "impersonate_user" }]
      : []),
  ];

  const handleDropdownOptionSelect = async (value) => {
    if (value === "share_profile") {
      navigator.clipboard.writeText(window.location.href).then(
        () => {
          toast.success("URL copied to clipboard!");
        },
        (err) => {
          toast.error("Failed to copy URL");
        }
      );
    }
  
    if (value === "invite_creator") {
      setShowOffPlatformInviteModal(true);
    }
  
    if (value === "impersonate_user") {
      debugger;
      try {
        const response = await impersonateUser(profile.membership_id); // or however you access it
        if (response.success) {
          toast.success("You are now impersonating this user.");
          setTimeout(() => window.location.reload(), 1000);
        } else {
          toast.error("Failed to impersonate user.");
        }
      } catch (error) {
        toast.error("An error occurred while impersonating.");
      }
    }
  };  

  const selectedNav =
    subNavItems.find(({ value }) => value === subpage)?.value ||
    subNavItems[0].value;

  const isParticipantProfile = !!participant_id;

  return (
    <div className="">
      {showOffPlatformInviteModal && (
        <InviteOffPlatformCreatorModal
          creatorId={profile.id}
          isOpen={showOffPlatformInviteModal}
          onClose={() => setShowOffPlatformInviteModal(false)}
        />
      )}

      {navActions}
      <ProfileNav
        readOnly={hasRole(Role.INFLUENCER)}
        profile={profile}
        navItems={subNavItems}
        user={user}
        dropdownOptions={dropdownOptions}
        onDropdownOptionSelect={handleDropdownOptionSelect}
      />
      <div
        className={`grid pl-[60px] c-lg:pl-0  ${isParticipantProfile ? "c-lg:grid-cols-[2fr,5fr]" : "c-lg:grid-cols-[4fr,5fr]"} 2xl:grid-cols-[2.5fr,5fr] w-full c-lg:overflow-hidden`}
      >
        <div className={`${height || "c-lg:h-[calc(100vh-80px)]"}`}>
          <ProfileOverview
            content={content?.content || []}
            profile={profile}
            viewsData={viewsData}
          />
        </div>
        <div
          className={`${height || "c-lg:h-[calc(100vh-80px)]"} bg-light_red overflow-auto`}
        >
          {subNavItems.map(({ value, component: Component }, index) => (
            <LazyRouteContent isActive={value === selectedNav} key={index}>
              {Component && <Component />}
            </LazyRouteContent>
          ))}
        </div>
      </div>
    </div>
  );
}
