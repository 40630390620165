import React, { useState, useContext, useEffect } from "react";
import { FInput } from "@components/FInputs";
import FButton from "@components/FButton";
import {
  createOffPlatformCreator,
  checkOffPlatformUsernames,
} from "@api/Creators/Creators";
import WaitingModal from "./WaitingModal"; // Import the WaitingModal component
import AccountDisplay from "./AccountDisplay";
import SelectedPlatforms from "./SelectedPlatforms";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const CreateOffPlatformCreator = () => {
  const [username, setUsername] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [tiktokHandle, setTiktokHandle] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [platformsFound, setPlatformsFound] = useState({
    instagram: false,
    tiktok: false,
  });
  const [isChecking, setIsChecking] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal state
  const [startTime, setStartTime] = useState<Date | null>(null);
  const { trackEvent } = useContext(MixpanelContext);

  // Track when the component is mounted - button clicked event
  useEffect(() => {
    setStartTime(new Date());
    trackEvent(EVENTS.OFF_PLATFORM_CREATOR.BUTTON_CLICKED);

    // Track form abandonment when component unmounts
    return () => {
      if (!isSubmitted && startTime) {
        const timeSpent = Math.round(
          (new Date().getTime() - startTime.getTime()) / 1000
        );
        trackEvent(EVENTS.OFF_PLATFORM_CREATOR.FORM_ABANDONED, {
          time_spent_seconds: timeSpent,
          has_instagram: !!instagramHandle,
          has_tiktok: !!tiktokHandle,
          username_entered: !!username,
        });
      }
    };
  }, []);

  const handleUsernameCheck = async () => {
    setIsChecking(true);
    try {
      trackEvent("Off Platform Creator Username Checked", {
        username: username,
      });

      const response = await checkOffPlatformUsernames(
        username.trim().toLowerCase()
      );
      setPlatformsFound(response);
    } catch (error) {
      console.error("Error checking username:", error);
    } finally {
      setIsChecking(false);
    }
  };

  const handleAddPlatform = (platform) => {
    const normalizedUsername = username.trim().toLowerCase();

    // Track platform selection
    trackEvent("Off Platform Creator Platform Selected", {
      platform: platform,
      username: normalizedUsername,
    });

    if (platform === "instagram") {
      setInstagramHandle((prev) =>
        prev === normalizedUsername ? "" : normalizedUsername
      );
    } else if (platform === "tiktok") {
      setTiktokHandle((prev) =>
        prev === normalizedUsername ? "" : normalizedUsername
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!instagramHandle && !tiktokHandle) {
      alert("Please add at least one platform handle."); // Replace toast with alert
      return;
    }

    setIsModalOpen(true); // Show waiting modal

    // Track form submission
    const timeSpent = startTime
      ? Math.round((new Date().getTime() - startTime.getTime()) / 1000)
      : 0;

    try {
      const response = await createOffPlatformCreator({
        instagram_handle: instagramHandle,
        tiktok_handle: tiktokHandle,
      });

      if (response?.success) {
        // Track successful creator addition
        trackEvent(EVENTS.OFF_PLATFORM_CREATOR.CREATED, {
          time_spent_seconds: timeSpent,
          has_instagram: !!instagramHandle,
          has_tiktok: !!tiktokHandle,
          instagram_followers:
            platformsFound.instagram?.table?.profile?.followers || 0,
          tiktok_followers: platformsFound.tiktok?.followers_count || 0,
          creator_slug: response.creator_slug,
        });

        window.location.href = response.creator_slug
          ? `/card/${response.creator_slug}/content`
          : "/collaborators/offPlatform";
      }
    } catch (error) {
      console.error("Error during submission:", error);
      alert("Failed to save creator."); // Replace toast with alert

      // Track submission error
      trackEvent("Off Platform Creator Submission Error", {
        time_spent_seconds: timeSpent,
        error: error.message || "Unknown error",
        has_instagram: !!instagramHandle,
        has_tiktok: !!tiktokHandle,
      });
    } finally {
      setIsModalOpen(false); // Close waiting modal
    }
  };

  return (
    <div>
      <h1>Create an Off-Platform Creator</h1>
      <h3 className="mb-8">
        Add any Instagram or TikTok creator via their handle. Creators must have
        a publicly visible business or creator account.
      </h3>

      <form onSubmit={handleSubmit}>
        {/* Handle Search Section */}
        <div className="mb-6 flex w-1/2">
          <span className="pt-1 pr-1 text-xl">@</span>
          <FInput
            value={username}
            onChange={(value) => {
              if (!value.includes("@")) {
                setUsername(value);
              }
            }}
            width="50%"
            className="mb-6 mr-4"
          />
          <FButton
            label={isChecking ? "Checking..." : "Check Username"}
            onClick={handleUsernameCheck}
            disabled={isChecking}
          />
        </div>

        {/* Instagram and TikTok Account Display */}
        {platformsFound.instagram?.table?.profile?.username && (
          <AccountDisplay
            platform="instagram"
            handle={platformsFound.instagram.table.profile.username}
            image={platformsFound.instagram.table.profile.profile_picture_url}
            followers={platformsFound.instagram.table.profile.followers}
            selectedHandle={instagramHandle}
            onAdd={() => handleAddPlatform("instagram")}
          />
        )}
        {platformsFound.tiktok && (
          <AccountDisplay
            platform="tiktok"
            handle={platformsFound.tiktok.handle_name}
            image={platformsFound.tiktok.profile_image}
            followers={platformsFound.tiktok.followers_count}
            selectedHandle={tiktokHandle}
            onAdd={() => handleAddPlatform("tiktok")}
          />
        )}

        {/* Selected Platforms Section */}
        {(instagramHandle || tiktokHandle) && (
          <SelectedPlatforms
            instagramHandle={instagramHandle}
            tiktokHandle={tiktokHandle}
            setInstagramHandle={setInstagramHandle}
            setTiktokHandle={setTiktokHandle}
          />
        )}

        {/* Submit Button */}
        <div className="w-1/2">
          <FButton
            type="submit"
            label="Create"
            primary
            className="float-right"
          />
        </div>
      </form>

      {/* Waiting Modal */}
      <WaitingModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default CreateOffPlatformCreator;
