import { useParams } from "react-router-dom";
import BriefPreview from "./BriefPreview";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";
import CreatorOffer from "./CreatorOffer";

export default function BriefPreviewPage() {
  const { participant_group_id, participant_id } = useParams();

  const { hasRole } = useAuth();

  if (hasRole(Role.INFLUENCER)) {
    return <CreatorOffer participant_id={parseInt(participant_id)} />;
  }

  return (
    <BriefPreview
      participant_group_id={parseInt(participant_group_id, 10)}
      participant_id={parseInt(participant_id, 10)}
    />
  );
}
