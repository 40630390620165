/* eslint-disable */

import React, { useContext } from "react";
import { DashboardContext } from "@contexts/DashboardProvider";
import CampaignStats from "./components/CampaignStats";
import AnalyticsMessage from "./components/AnalyticsMessage";
import AnalyticsMessageSkeleton from "./components/AnalyticsMessageSkeleton";
import { getAnalyticsData } from "@utils/dashboardUtils";
import PostSnapshot from "./components/PostSnapshot";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import NotificationSnapshot from "./components/NotificationSnapshot";
import NotificationSkeleton from "./components/NotificationSkeleton";
import PostSnapshotSkeleton from "./components/PostSnapshotSkeleton";
import useAuth from "@hooks/useAuth";
import { UserContext } from "@contexts/index";
export default function Home() {
  const {
    isLoading,
    isPageLoading,
    campaignContent,
    activePost,
    contentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
  } = useContext(DashboardContext);

  const { hasRole } = useAuth();

  if (isPageLoading) {
    return (
      <div className="space-y-8 pt-10">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 min-[1300px]:grid-cols-4 gap-4">
          <div className="col-span-full lg:col-span-1 min-[1300px]:col-span-1">
            <NotificationSkeleton />
          </div>
          <div className="col-span-full lg:col-span-1 min-[1300px]:col-span-3 space-y-8">
            <PostSnapshotSkeleton maxPosts={5} title="Top Performing Content" />
            <PostSnapshotSkeleton
              maxPosts={5}
              title="Latest Campaign Content"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8 pt-10">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 min-[1300px]:grid-cols-4 gap-4">
        <div className="col-span-full lg:col-span-1 min-[1300px]:col-span-1">
          <NotificationSnapshot maxNotifications={8} />
        </div>
        <div className="col-span-full lg:col-span-1 min-[1300px]:col-span-3 space-y-8">
          <PostSnapshot
            campaignContent={campaignContent}
            isLoading={isLoading}
            title="Top Performing Content"
            maxPosts={5}
            contentType="top"
            onCardClick={handleContentModalClick}
          />
          <PostSnapshot
            campaignContent={campaignContent}
            isLoading={isLoading}
            title="Latest Campaign Content"
            maxPosts={5}
            contentType="latest"
            onCardClick={handleContentModalClick}
          />
        </div>
      </div>

      {activePost && (
        <GeneralPostModal
          post={activePost}
          isOpen={contentModalOpen}
          onClose={handleContentModalClose}
        />
      )}
    </div>
  );
}
