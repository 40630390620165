import React, { useCallback, useState, useContext } from "react";
import apiClient from "@apiClient";
import { useNavigate } from "react-router-dom";

import UpdateOutreachModal from "./components/UpdateOutreachModal";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { OutreachTemplate, Sort } from "@types";
import FButton from "@components/FButton";
import { createOutreachTemplate } from "@api/Outreach/OutreachTemplates";
import useAuth from "@hooks/useAuth";
import toast from "react-hot-toast";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const { index, delete: deleteOutreachTemplate } = apiClient.outreach_templates;

const OutreachList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<OutreachTemplate[]>([]);

  const [isUpdateOutreachModalVisible, setUpdateOutreachModalVisible] =
    useState(false);

  const { user } = useAuth();
  const { trackEvent } = useContext(MixpanelContext);

  const tableRowDropDownOptions = [
    { label: "Duplicate", value: "duplicate" },
    { label: "Delete", value: "delete" },
  ];

  // Define actions inside component to access trackEvent
  const actions = {
    delete: async (id: number) => {
      const result = await deleteOutreachTemplate(id);
      // Track template deletion
      trackEvent(EVENTS.OUTREACH.TEMPLATE_DELETED, {
        template_id: id,
      });
      return result;
    },
  };

  const getOutreach = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const query = {
        title_i_cont: search,
      };

      if (filter) {
        query[`${filter}_eq`] = true;
      }
      const response = await index(page, query);

      const { templates: items } = response.data;

      setData(items);

      // Track templates viewed
      trackEvent(EVENTS.OUTREACH.TEMPLATES_VIEWED);

      return {
        ...response.data,
        items,
      };
    },
    [setData, trackEvent]
  );

  const tableColumns = [
    {
      key: "name",
      label: "Name",
      isLink: true,
      linkURL: "/outreach/templates/{id}",
    },
    { key: "owner_name", label: "Created By" },
    { key: "brand_name", label: "Brand" },
  ];

  const handleTableRowDropDownSelect = (value: string, id: number) => {
    if (value === "duplicate") {
      // TODO: Implement template duplication
      // When implemented, add tracking:
      trackEvent(EVENTS.OUTREACH.TEMPLATE_DUPLICATED, {
        template_id: id,
      });
    }
  };

  const handleCloseOutreachModal = (newCommunication: OutreachTemplate) => {
    if (newCommunication) {
      setData([newCommunication, ...data]);
    }

    setUpdateOutreachModalVisible(false);
  };

  const handleCreateOutreachTemplate = async () => {
    try {
      const response = await createOutreachTemplate({
        brand_id: user.brand_id,
        name: "New Outreach Template",
        subject: "Template Subject",
        body: "Template Message",
      });

      // Track template creation
      trackEvent(EVENTS.OUTREACH.TEMPLATE_CREATED, {
        template_id: response.data.id,
        template_name: response.data.name,
        brand_id: user.brand_id,
      });

      toast.success(
        "Outreach template created successfully! Please update fields to save."
      );

      navigate(`/outreach/templates/${response.data.id}`);
    } catch (error) {
      toast.error("Failed to create outreach template. Please try again.");
    }
  };

  const headerButtons = (
    <div className="flex flex-row justify-end items-center gap-2">
      <FButton label="View Outreaches" onClick={() => navigate("/outreach")} />
      <FButton
        primary
        iconLeft={{ name: "add", size: 14, color: "white" }}
        onClick={handleCreateOutreachTemplate}
        label="Create Template"
      />
    </div>
  );

  return (
    <>
      <SinglePageLayoutWithData
        entityName="Outreach"
        pageTitle="Outreach Templates"
        headerButtons={headerButtons}
        pageName="Outreach Templates"
        sortPathName="title"
        sortPathDate="dateSent"
        filterPath="state"
        tableColumns={tableColumns}
        rowActions={tableRowDropDownOptions}
        cardDropDownOptions={tableRowDropDownOptions}
        createWithEntityCrud={handleCreateOutreachTemplate}
        onTableRowDropDownSelect={handleTableRowDropDownSelect}
        tableOnly
        asyncActions={actions}
        getItems={getOutreach}
      />
      {isUpdateOutreachModalVisible ? (
        <UpdateOutreachModal onClose={() => handleCloseOutreachModal(null)} />
      ) : null}
    </>
  );
};
export default OutreachList;
