import { Content } from "@types";
import getStats from "./ContentStats";
import FIcon from "@components/FIcon";
import { formatLargeNumber } from "@utils/number_utilities";

interface ContentThumbnailProps {
  content: Content;
  onClick: (content: Content) => void;
}

const ContentThumbnail = ({ content, onClick }: ContentThumbnailProps) => {
  const { photo_url, video_url } = content;

  const className =
    "cursor-pointer w-full h-full object-cover overflow-hidden rounded-[12px]";

  const handleClick = () => onClick(content);

  return (
    <div className="relative h-full w-full">
      {!photo_url || photo_url.includes("missing") ? (
        <div className="w-full h-full m-auto bg-gray-100 flex items-center bg-[rgba(255,255,255,0.2)] justify-center p-4 border-2 border-[rgba(0,0,0,0.07)] rounded-[6px]">
          <FIcon icon="photo" size={30} color="rgba(0,0,0,0.16)" />
        </div>
      ) : photo_url ? (
        <img onClick={handleClick} className={className} src={photo_url} />
      ) : (
        <video
          onClick={handleClick}
          src={video_url}
          autoPlay
          muted
          className={className}
        />
      )}
      <div className="absolute backdrop-blur-[3px] overflow-hidden max-w-[calc(100%-14px)] bg-[#00000080] flex items-center gap-[6px] py-[4px] left-[7px] bottom-[7px] px-[7px] rounded-[6px]">
        {getStats(content).map((item, index) => (
          <div className="flex items-center gap-1 " key={index}>
            <FIcon
              icon={item.icon}
              color="#FFFFFF"
              size={12}
              className="-mt-[1px]"
            />
            <span className="text-[10px] text-white">
              {formatLargeNumber(item.value)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentThumbnail;
