import FIcon from "@components/FIcon";
import VideoPlayer from "@components/Post/VideoPlayer";
import { Content, Profile } from "@types";
import { formatLargeNumber } from "@utils/number_utilities";
import { RefObject } from "react";
import getStats from "./ContentStats";

interface ContentPreviewProps {
  content: Partial<Content>;
  isPaused: boolean;
  onVideoEnded: () => void;
  profile: Profile;
  videoRef?: RefObject<HTMLVideoElement>;
  width?: string;
}

const ContentPreview = ({
  content,
  onVideoEnded,
  profile,
  width,
}: ContentPreviewProps) => {
  const { content_type, permalink, photo_url, platform, video_url } = content;

  const creatorPlatform = profile.platforms.find(
    ({ name }) => name === platform
  );

  const { handle, url: rawUrl = "" } = creatorPlatform || {};

  const url = rawUrl.startsWith("https") ? rawUrl : `https://${rawUrl}`;
  const isVideo = content_type?.includes("video");

  return (
    <div
      className="relative 2xl:h-full h-[300px] bg-highlight_red"
      style={{
        width: width || "auto",
      }}
    >
      {video_url || (isVideo && permalink) ? (
        <VideoPlayer
          className="bg-white justify-center w-full rounded-[12px] overflow-hidden"
          hidePlatformIcon
          onVideoEnded={onVideoEnded}
          post={content}
        />
      ) : photo_url ? (
        <div
          className="w-full h-full bg-cover bg-center flex justify-center items-center"
          style={{
            backgroundImage: `url(${photo_url})`,
          }}
        />
      ) : (
        <div className="w-[60px] h-[60px] m-auto bg-gray-100 flex items-center bg-[rgba(255,255,255,0.2)] justify-center p-10 border-2 border-[rgba(0,0,0,0.07)] rounded-full">
          <FIcon icon="photo" size={30} color="rgba(0,0,0,0.16)" />
        </div>
      )}

      <div className="absolute flex items-center gap-2 left-[22px] top-[18px]">
        <FIcon
          icon={(platform || "").toLowerCase()}
          size={15}
          color="#FFFFFF"
          className="inline-block vertical-align-middle"
        />
        <a className="text-white" href={url} target="_blank">
          {handle}
        </a>
      </div>
      <div className="absolute backdrop-blur-[5px] bg-[#00000080] flex items-center gap-[16px] py-[6px] left-[14px] bottom-[12px] px-[15px] rounded-[12px]">
        {getStats(content).map((item) => (
          <div className="flex items-center gap-2">
            <FIcon
              icon={item.icon}
              color="#FFFFFF"
              size={16}
              className="-mt-[2px]"
            />
            <span className="text-white font-regular text-[16px]">
              {formatLargeNumber(item.value)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentPreview;
