import React, { useContext, useEffect } from "react";
import { NewReportContext } from "@contexts/index";
import ReportCreatorsTable from "@pages/reports/components/ReportCreatorsTable";
import LoadingSpinner from "@components/LoadingSpinner";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

export default function Creators() {
  const {
    report,
    isLoading,
    audience,
    audienceDataFetched,
    availablePlatforms,
    selectedPlatform,
    setSelectedPlatform,
  } = useContext(NewReportContext);

  const { trackEvent } = useContext(MixpanelContext);

  // Track creators view when component mounts
  useEffect(() => {
    if (report) {
      trackEvent(EVENTS.REPORT.CREATORS_VIEWED, {
        report_id: report.id,
        report_title: report.title,
        influencers_count:
          report.metrics?.creators ||
          report.content_summary?.influencers_count ||
          0,
      });
    }
  }, [report, trackEvent]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="">
      <div className="mb-[48px]">
        <ReportCreatorsTable report_id={report?.id} />
      </div>
    </div>
  );
}
