import moment from "moment";
import FDatePicker from "./FDatePicker";

interface FRangePickerProps {
  minDate?: string;
  maxDate?: string;
  value: {
    from: string;
    to: string;
  };
  fromLabel?: string;
  toLabel?: string;
  required?: boolean;
  onChange: (value: { from: string; to: string }) => void;
}

const FRangePicker = ({
  minDate,
  maxDate,
  value,
  onChange,
  fromLabel,
  toLabel,
  required,
}: FRangePickerProps) => {
  const handleUpdateFrom = (from) => {
    let to = value.to;

    if (moment(value.to).isBefore(from)) {
      to = from;
    }

    onChange({
      from,
      to,
    });
  };

  return (
    <div className="flex gap-4 w-full">
      <FDatePicker
        disabled={{
          before: minDate ? moment(minDate).toDate() : null,
          after: maxDate ? moment(maxDate).toDate() : null,
        }}
        label={fromLabel || "From"}
        value={value.from}
        onSelectedValue={(value) => handleUpdateFrom(value)}
        required={required}
      />
      <FDatePicker
        disabled={{
          before: moment(value.from).toDate(),
          after: maxDate ? moment(maxDate).toDate() : null,
        }}
        label={toLabel || "To"}
        value={value.to}
        onSelectedValue={(newValue) =>
          onChange({
            ...value,
            to: newValue,
          })
        }
        required={required}
      />
    </div>
  );
};

export default FRangePicker;
