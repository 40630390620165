import React, { useContext, useState } from "react";

import { CampaignDeliverable } from "@types";

import ConfirmModal from "@components/Modals/ConfirmModal";

import SinglePageLayout from "@layouts/SinglePageLayout";
import { CampaignContext } from "@contexts/index";
import FIcon from "@components/FIcon";
import { platformList } from "@constants/constants";
import { capitalize } from "lodash";

import FButton from "@components/FButton";
import AddDeliverableModal from "./components/AddDeliverableModal";
import {
  deleteCampaignDeliverable,
  deleteParticipantDeliverable,
} from "@api/Campaign/CampaignDeliverables";
import toast from "react-hot-toast";
import { ButtonDropDown } from "@components/ButtonDropDown";
import useAuth from "@hooks/useAuth";
import { Permission, Role } from "@constants/roles";
import CampaignParticipantDeliverables from "./components/CampaignParticipantDeliverables";
import BlankState from "@components/BlankState";

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const TABLE_COLUMNS = [
  {
    key: "platform",
    label: "Platform",
    getContent: (deliverable: CampaignDeliverable) => {
      const { platform } = deliverable;

      const { icon } =
        platformList.find(({ value }) => platform === value) || {};

      return <FIcon icon={icon} size={16} color="#000721" />;
    },
  },
  {
    key: "name",
    label: "Deliverable Name",
  },
  {
    key: "media_type",
    label: "Type",
    getValue: (deliverable: CampaignDeliverable) => {
      return capitalize(deliverable.media_type);
    },
  },
  {
    key: "hashtags",
    label: "Hashtags",
  },
  {
    key: "mentions",
    label: "Mentions",
  },
  {
    key: "tagged_users",
    label: "Tagged users",
  },
];

const CampaignDeliverablesPage = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [deliverableToEdit, setDeliverableToEdit] =
    useState<CampaignDeliverable>(null);

  const [deliverablesToDelete, setDeliverablesToDelete] =
    useState<CampaignDeliverable[]>(null);
  const [isDeleting, setDeleting] = useState(false);

  const [isUpdateDeliverableModalVisible, setUpdateDeliverableModalVisible] =
    useState(false);

  const { campaign, setCampaign } = useContext(CampaignContext);

  const { can } = useAuth();

  const { deliverables = [], participants } = campaign;

  const handleBulkAction = (value) => {
    if (value === "remove") {
      const allDeliverables = [
        ...deliverables,
        ...participants.reduce(
          (result, participant) => [...result, ...participant.deliverables],
          []
        ),
      ];

      setDeliverablesToDelete(
        selectedItems.map((id) =>
          allDeliverables.find((deliverable) => id === deliverable.id)
        )
      );
    }
  };

  const handleDelete = async () => {
    const successfullyDeleted = [];

    setDeleting(true);

    for (const deliverable of deliverablesToDelete) {
      try {
        await (
          deliverable.participant_id
            ? deleteParticipantDeliverable
            : deleteCampaignDeliverable
        )(deliverable.id);

        successfullyDeleted.push(deliverable.id);
      } catch (e) {
        toast.error(`Error archiving deliverable ${deliverable.name}`);
      }
    }

    setDeleting(false);

    setDeliverablesToDelete(null);

    const newDeliverables = deliverables.filter(
      (deliverable) => !successfullyDeleted.includes(deliverable.id)
    );

    const newParticipants = participants.map((participant) => {
      const newDeliverables = participant.deliverables.filter(
        (deliverable) => !successfullyDeleted.includes(deliverable.id)
      );

      return { ...participant, deliverables: newDeliverables };
    });

    setCampaign({
      ...campaign,
      deliverables: newDeliverables,
      participants: newParticipants,
    });
  };

  const handleTableRowDropdownSelect = (value: string, id: number) => {
    const deliverable = deliverables.find((item) => item.id === id);

    if (value === "edit") {
      setDeliverableToEdit(deliverable);
      setUpdateDeliverableModalVisible(true);
    } else if (value === "delete") {
      setDeliverablesToDelete([
        deliverables.find((deliverable) => deliverable.id === id),
      ]);
    }
  };

  const tableColumns = TABLE_COLUMNS.map((column) => {
    if (column.key === "name") {
      if (!can(Permission.UPDATE_DELIVERABLES)) {
        return column;
      }

      return {
        ...column,
        getContent: (deliverable: CampaignDeliverable) => (
          <span
            className="cursor-pointer underline"
            onClick={() => {
              setDeliverableToEdit(deliverable);
              setUpdateDeliverableModalVisible(true);
            }}
          >
            {deliverable.name}
          </span>
        ),
      };
    }

    return column;
  });

  return (
    <div className="mt-[40px]">
      <SinglePageLayout
        cardData={deliverables}
        pageName=""
        pageTitle={`Deliverables (${deliverables.length})`}
        tableOnly
        inlineLayout
        sortPathName="name"
        sortPathDate="created_at"
        tableColumns={tableColumns}
        rowActions={cardDropDownOptions}
        cardDropDownOptions={cardDropDownOptions}
        createWithEntityCrud={() => setUpdateDeliverableModalVisible(true)}
        subNavMainButton={
          <div className="flex gap-4">
            {selectedItems.length > 0 && (
              <ButtonDropDown
                zIndex={99999}
                className="rounded-md bg-white border border-default_weak whitespace-nowrap"
                label={`Edit (${selectedItems.length})`}
                options={[{ label: "Remove", value: "remove" }]}
                onChange={(value) => handleBulkAction(value)}
              />
            )}
            {deliverables.length > 0 && can(Permission.UPDATE_DELIVERABLES) && (
              <FButton
                primary
                onClick={() => setUpdateDeliverableModalVisible(true)}
                label="Add Deliverable"
                type="button"
                className="whitespace-nowrap"
                iconLeft={{
                  name: "add",
                  size: 12,
                  color: "#fff",
                  className: "mr-1 -mt-[2px]",
                }}
              />
            )}
          </div>
        }
        selectedItems={selectedItems}
        onChangeSelectedItems={setSelectedItems}
        onTableRowDropDownSelect={handleTableRowDropdownSelect}
        customBlankState={
          <BlankState
            title="No Deliverables Here"
            subtitle="Add a deliverable"
            icon="user"
            actionLabel="Create New"
            onActionClick={() => setUpdateDeliverableModalVisible(true)}
          />
        }
      />
      <>
        <h1 className="mt-16 mb-[60px] text-2xl self-center">
          Participant Deliverables
        </h1>
        <CampaignParticipantDeliverables
          campaign={campaign}
          onEdit={(deliverable) => {
            setDeliverableToEdit(deliverable);
            setUpdateDeliverableModalVisible(true);
          }}
          onDelete={(deliverable) => {
            setDeliverablesToDelete([deliverable]);
          }}
          selectedItems={selectedItems}
          onChangeSelectedItems={setSelectedItems}
        />
      </>
      {isUpdateDeliverableModalVisible ? (
        <AddDeliverableModal
          campaign={campaign}
          deliverable={deliverableToEdit}
          onClose={() => setUpdateDeliverableModalVisible(false)}
          onUpdateCampaign={setCampaign}
          participantId={deliverableToEdit?.participant_id}
        />
      ) : null}
      {deliverablesToDelete ? (
        <ConfirmModal
          title="Delete deliverable"
          isOpen
          onClose={() => setDeliverablesToDelete(null)}
          onAction={handleDelete}
          actionLabel="Delete"
          isWaiting={isDeleting}
          subtitle={`Are you sure you want to delete ${deliverablesToDelete.length === 1 ? "this deliverable" : "these deliverables"}?`}
        />
      ) : null}
    </div>
  );
};

export default CampaignDeliverablesPage;
