import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";

import apiClient from "@api/index";
import React, { useRef, useState, useContext } from "react";
import { Captcha, CaptchaError, CaptchaInstance } from "@components/Captcha";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

export default function Login() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const returnTo = searchParams.get("returnTo");

  const { trackEvent } = useContext(MixpanelContext);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const captchaRef = useRef<CaptchaInstance>();

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState("");

  const handleUpdate = (updates) => setValues({ ...values, ...updates });
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setWaiting(true);

    try {
      const captchaToken = await captchaRef.current?.getToken();
      await apiClient.user.signIn(values.email, values.password, captchaToken);

      // Track successful sign-in
      trackEvent(EVENTS.AUTH.SIGNED_IN, {
        email_domain: values.email.split("@")[1],
        has_return_to: !!returnTo,
        return_path: returnTo || null,
      });

      if (returnTo) {
        navigate(returnTo);
      } else {
        window.location.href = "/";
      }
    } catch (e) {
      if (e instanceof CaptchaError) {
        setError(e.message);
        trackEvent(EVENTS.ERROR.AUTH_ERROR, {
          error_type: "captcha",
          error_message: e.message,
        });
      } else {
        captchaRef.current?.reset();
        setError(e?.response?.data?.message);
        trackEvent(EVENTS.ERROR.AUTH_ERROR, {
          error_type: "credentials",
          error_message: e?.response?.data?.message || "Unknown login error",
          status_code: e?.response?.status,
        });
      }
    } finally {
      setWaiting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      <p className="text-3xl leading-[30px] mb-4 text-center font-light">
        Welcome back
      </p>
      <p className="mb-8 text-center">
        New to Fohr?{" "}
        <Link className="underline" to="/signup">
          Sign up
        </Link>
      </p>
      <form onSubmit={handleSubmit} onKeyDown={handleKeyPress}>
        <FInput
          className="mb-6"
          value={values.email}
          onChange={(value) => handleUpdate({ email: value })}
          type="text"
          required
          name="email"
          label="Email"
          width="100%"
        />
        <FInput
          className="mb-8"
          value={values.password}
          onChange={(value) => handleUpdate({ password: value })}
          type="password"
          name="password"
          label="Password"
          width="100%"
        />
        <Captcha ref={captchaRef} className="mb-8" action="login" />
        <FButton
          onClick={handleSubmit}
          loading={waiting}
          primary
          label="Log in"
          width="100%"
          height="50px"
          type="submit"
        />
      </form>
      {error ? (
        <p className={`text-[16px] mt-[16px] m-auto text-center text-red`}>
          {error}
        </p>
      ) : null}
      <div className="flex mt-6 justify-center items-center text-center">
        <Link
          className="underline"
          to="/forgot_password"
          onClick={() =>
            trackEvent(EVENTS.AUTH.PASSWORD_RESET_REQUESTED, {
              source: "login_page",
            })
          }
        >
          Forgot your password?
        </Link>
      </div>
    </>
  );
}
