import { getSearchParts } from "@api/Search";
import { ApiResponse, BulletinRecipient } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

import { isArray, isEmpty, isPlainObject } from "lodash";

const getBulletinRecipient = async (
  bulletin_recipient_id: string | number
): Promise<ApiResponse<BulletinRecipient>> => {
  return await apiWrapper(
    `/bulletin_recipients/${bulletin_recipient_id}`,
    "GET"
  );
};

const getBulletinRecipientList = async (
  admin?: boolean,
  page?: number,
  sort?: Sort,
  params?: object
): Promise<
  ApiResponse<{
    bulletin_recipients: BulletinRecipient[];
    total_items: number;
    current_page: number;
    page_count: number;
  }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  // Check if params.admin is true
  if (admin === true) {
    return apiWrapper(
      `/admin/bulletin_recipients?${queryParams.toString()}`,
      "GET"
    );
  } else {
    return apiWrapper(`/bulletin_recipients?${queryParams.toString()}`, "GET");
  }
};

export const createBulletinRecipient = async (
  params: Partial<BulletinRecipient>
): Promise<ApiResponse<BulletinRecipient>> => {
  return apiWrapper("/bulletin_recipients", "POST", params);
};

export const createBulletinRecipientFromSearch = async (
  originalParams
): Promise<ApiResponse<BulletinRecipient>> => {
  const { searchTerm, ...rest } = originalParams;

  const params = {
    ...rest,
    ...getSearchParts(searchTerm),
  };

  const paramsToUse = {};

  Object.keys(params).forEach((param) => {
    if (params[param] && (!isArray(params[param]) || !isEmpty(params[param]))) {
      const value = params[param];

      if (isPlainObject(value)) {
        if (!isEmpty(value)) {
          paramsToUse[param] = JSON.stringify(value);
        }
      } else {
        paramsToUse[param] = `${value}`;
      }
    }
  });

  return apiWrapper(
    "/bulletin_recipients/create_from_search",
    "POST",
    paramsToUse
  );
};

export const updateBulletinRecipient = async (
  bulletin_id: string | number,
  params: Partial<BulletinRecipient>
): Promise<ApiResponse<BulletinRecipient>> => {
  return apiWrapper(`/bulletin_recipients/${bulletin_id}`, "PUT", params);
};

const deleteBulletinRecipient = async (bulletin_id: string | number) => {
  return apiWrapper(`/bulletin_recipients/${bulletin_id}`, "DELETE");
};

export const sendEmailToAllRecipients = async (
  bulletin_id: number
): Promise<ApiResponse<void>> => {
  return apiWrapper(`/bulletins/${bulletin_id}/send_emails`, "POST");
};

export const sendTestEmail = async (
  bulletin_id: number | string,
  email: string
): Promise<ApiResponse<void>> => {
  return apiWrapper(`/bulletins/${bulletin_id}/send_test_email`, "POST", {"email": email});
};

export {
  getBulletinRecipient,
  getBulletinRecipientList,
  deleteBulletinRecipient,
};
