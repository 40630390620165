import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Bulletin } from "@types";
import { downloadCsv, getBulletinResponseList } from "@api/Bulletins/BulletinResponses";
import { getBulletin } from "@api/Bulletins/Bulletins";
import FButton from "@components/FButton";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import BlankState from "@components/BlankState";
import BulletinResponseGrid from "./components/BulletinResponseGrid";
import FPagination from "@components/FPagination";
import LoadingSpinner from "@components/LoadingSpinner";
import { downloadDataAsCSVFile } from "@utils/download";
import toast from "react-hot-toast"

export default function BulletinResponseListPage() {
  const { bulletin_id } = useParams<{ bulletin_id: string }>();
  const [bulletin, setBulletin] = useState<Bulletin>(null);
  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);
  const [areAllRowsSelected, setAllRowsSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [responses, setResponses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setDownloading] = useState(false);

  const navigate = useNavigate();

  // Get unique creator IDs from responses
  const allCreatorIds = React.useMemo(() => {
    const uniqueIds = new Set(responses.map((response) => response.creator_id));
    return Array.from(uniqueIds);
  }, [responses]);

  const handleSelectAll = () => {
    const newState = !areAllRowsSelected;
    setAllRowsSelected(newState);
    setSelectedItems(newState ? allCreatorIds : []);
  };

  const loadResponses = useCallback(
    async (page: number = 1) => {
      setIsLoading(true);
      try {
        const response = await getBulletinResponseList(false, page, null, {
          bulletin_id_eq: bulletin_id,
        });

        setResponses(response.data.bulletin_responses);
        setTotalPages(response.data.page_count);
        setCurrentPage(page);
      } catch (error) {
        console.error("Error loading responses:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [bulletin_id]
  );

  const handleDownload = async () => {
    setDownloading(true);

    try {
      const response = await downloadCsv(bulletin_id);
      downloadDataAsCSVFile(response.data, `bulletin_responses.csv`);
      toast.success("Responses exported to CSV");
    } catch {
      toast.error("Failed to download CSV");
    }

    setDownloading(false);
  };

  useEffect(() => {
    getBulletin(bulletin_id).then((response) => {
      setBulletin(response.data);
    });
    loadResponses(1);
  }, [bulletin_id]);

  const handleSelectCreator = (creatorId: number) => {
    setSelectedItems((prev) => {
      const newItems = prev.includes(creatorId)
        ? prev.filter((id) => id !== creatorId)
        : [...prev, creatorId];

      // Update areAllRowsSelected based on whether all creators are selected
      setAllRowsSelected(newItems.length === allCreatorIds.length);
      return newItems;
    });
  };

  return (
    <div className="w-full pb-[10px] mt-[40px] z-10">
      <div className="mb-6 flex  items-center justify-between">
        <h1 className="text-2xl font-medium">Responses</h1>
        <div className="flex items-center gap-4">
          <FButton
            loading={isDownloading}
            label="Download"
            onClick={handleDownload}
          />
          <FButton
            label={areAllRowsSelected ? "Deselect All" : "Select All"}
            onClick={handleSelectAll}
            className={areAllRowsSelected ? "!bg-red text-white" : ""}
          />
          {selectedItems.length > 0 && (
            <FButton
              primary
              label={`Add ${areAllRowsSelected ? bulletin?.response_creator_count : selectedItems.length} to group`}
              onClick={() => setAddToGroupModalVisible(true)}
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner />
        </div>
      ) : responses.length > 0 ? (
        <>
          <BulletinResponseGrid
            responses={responses}
            selectedCreatorIds={selectedItems}
            onSelectCreator={handleSelectCreator}
          />

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="my-8 flex justify-center">
              <FPagination
                page={currentPage}
                pageSize={24}
                total={totalPages * 24}
                onPageChange={loadResponses}
              />
            </div>
          )}
        </>
      ) : (
        <BlankState
          title="No Responses"
          subtitle="No responses found for this bulletin."
          icon="user"
          actionLabel="Add Recipients"
          onActionClick={() => navigate(`/bulletins/${bulletin_id}/recipients`)}
        />
      )}

      {isAddToGroupModalVisible && (
        <AddCreatorsToGroupModal
          creatorIds={selectedItems}
          onClose={() => {
            setAddToGroupModalVisible(false);
            setSelectedItems([]);
          }}
          resource={
            areAllRowsSelected
              ? {
                  id: parseInt(bulletin_id, 10),
                  type: "bulletin",
                }
              : null
          }
        />
      )}
    </div>
  );
}
