import React, { useState } from "react";
import { CampaignContext } from "@contexts/index";
import { useContext } from "react";
import FButton from "@components/FButton";
import ProductPost from "@components/Campaign/Brief/ProductPost";
import Modal from "@components/Modals/Modal";
import AddProduct from "@components/Campaign/Brief/BriefForms/AddProduct";
import ConfirmModal from "@components/Modals/ConfirmModal";
import { toast } from "react-hot-toast";

import apiClient from "@apiClient";

import BriefPageTemplate, {
  BriefPageTemplateProps,
} from "@components/Campaign/Brief/BriefPageTemplate";
import FIcon from "@components/FIcon";

const { delete: deleteProduct } = apiClient.campaign_products;

interface Props extends BriefPageTemplateProps {}

export default function BriefProducts({
  brief,
  onUpdate,
  pageTitle,
  pageDescription,
  pageTitleCMSName,
  customSectionName,
  containerRef,
}: Props) {
  const { campaign, setCampaign } = useContext(CampaignContext);

  const { products = [] } = campaign;

  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);

  const removeProduct = async (productId) => {
    await deleteProduct(productId);

    setCampaign({
      ...campaign,
      products: products.filter((p) => p.id !== productId),
    });
    setIsDeleteModalOpen(false);

    setProductToEdit(null);

    toast.success("Deleted");
  };

  return (
    <BriefPageTemplate
      brief={brief}
      onUpdate={onUpdate}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      customSectionName={customSectionName}
      pageTitleCMSName={pageTitleCMSName}
      containerRef={containerRef}
      actionRight={
        <FButton
          label="Add Product"
          iconLeft={{
            name: "plus",
            size: 12,
            color: "black",
            className: "-mt-1",
          }}
          className="w-fit whitespace-nowrap"
          onClick={() => setIsLocalModalOpen(true)}
        />
      }
    >
      <div className="w-full border-l border-light_border pl-10 mt-8">
        {products.length > 0 && (
          <div
            className={`flex flex-col gap-4 ${products.length > 0 ? "" : ""}`}
          >
            <div className="flex flex-col">
              {products
                ?.sort(
                  (a, b) =>
                    new Date(b.updated_at).getTime() -
                    new Date(a.updated_at).getTime()
                )
                .map((product) => (
                  <div className="mt-8 first:mt-0">
                    <ProductPost
                      key={product.id}
                      product={product}
                      deleteProduct={(product) => {
                        setIsDeleteModalOpen(true);
                        setProductToEdit(product);
                      }}
                      editProduct={(product) => {
                        setIsLocalModalOpen(true);
                        setProductToEdit(product);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        {products.length > 0 && (
          <div
            className="rounded-lg p-4 pb-3 bg-[#f5f0ee] shadow-sm text-center flex flex-row items-center justify-center gap-2 mb-4"
            role="button"
            onClick={() => setIsLocalModalOpen(true)}
          >
            <FIcon icon="plus" size={12} color="black" className="-mt-1" />
            <p className="text-1xl">Add Product</p>
          </div>
        )}
      </div>
      <ConfirmModal
        title="Delete Product"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onAction={() => removeProduct(productToEdit.id)}
        actionLabel="Delete"
        subtitle="Are you sure you want to delete this product?"
      />
      <Modal
        title="Add Product"
        isOpen={isLocalModalOpen}
        onClose={() => {
          setIsLocalModalOpen(false);
          setProductToEdit(null);
        }}
        actionLabel="Save"
        hasForm
        className="w-[650px]"
        zIndex={9999}
        children={
          <AddProduct
            onClose={() => {
              setProductToEdit(null);
              setIsLocalModalOpen(false);
            }}
            campaign={campaign}
            product={productToEdit}
            onUpdateCampaign={setCampaign}
          />
        }
      />
    </BriefPageTemplate>
  );
}
