import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Content } from "@types";
import FIcon from "@components/FIcon";
import ContentPreview from "./ContentPreview";
import { LayoutContext } from "@contexts/index";
import ContentPreviewControls from "./ContentPreviewControls";

const DEFAULT_LIMIT = 5;

const DEFAULT_WIDTH = 370;

interface ContentHighlightProps {
  limit?: number;
  data: Partial<Content>[];
  highlightRight?: boolean;
  onChangeCollapsed?: (isCollapsed: boolean) => void;
  collapsed?: boolean;
  // TODO: Make this more generic. We need fixed for the profile, but that should be done in a container
  isStatic?: boolean;
  currentSlide?: number;
  onSlideChange: (slide: number) => void;
  width?: number;
  height?: string;
  showVideoControls?: boolean;
  label?: string;
}

const ContentHighlight: React.FC<ContentHighlightProps> = ({
  highlightRight = true,
  limit = DEFAULT_LIMIT,
  data = [],
  isStatic = false,
  collapsed,
  onChangeCollapsed,
  onSlideChange,
  currentSlide,
  width,
  height,
  showVideoControls,
  label,
}) => {
  const [isPaused, setIsPaused] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { collapsed: isMainLayoutCollapsed } = useContext(LayoutContext);

  const videoPosts =
    limit === null
      ? data
      : data.filter((item) => item.video_url).slice(0, limit);

  const limitedData =
    limit && videoPosts.length < limit
      ? [
          ...videoPosts,
          ...data
            .filter((item) => !item.video_url)
            .sort((a, b) => b.views_count - a.views_count)
            .slice(0, limit - videoPosts.length),
        ]
      : videoPosts;

  const nextSlide = useCallback(() => {
    onSlideChange((currentSlide + 1) % limitedData.length);
    setIsPaused(false);
  }, [limitedData.length, currentSlide, onSlideChange]);

  const prevSlide = useCallback(() => {
    onSlideChange((currentSlide - 1 + limitedData.length) % limitedData.length);
    setIsPaused(false);
  }, [limitedData.length, currentSlide, onSlideChange]);

  const togglePlayPause = useCallback(() => {
    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
  }, [isPaused]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        // case " ":
        // 	event.preventDefault();
        // 	togglePlayPause();
        // 	break;
        case "ArrowRight":
          nextSlide();
          break;
        case "ArrowLeft":
          prevSlide();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPaused, currentSlide, togglePlayPause, nextSlide, prevSlide]);

  const content = limitedData[currentSlide] || {};

  return (
    <div
      className="relative"
      style={{
        height: height || "100vh",
      }}
    >
      {collapsed && (
        <div
          onClick={() => {
            onChangeCollapsed(!collapsed);
          }}
          className={`${
            highlightRight ? "border-l" : "border-r"
          }  cursor-pointer border-light_border bg-light_red z-20 fixed top-0 w-[40px] h-full flex justify-center items-center`}
        />
      )}
      {collapsed && (
        <div
          className={`fixed flex items-center justify-center ${
            highlightRight
              ? "right-0"
              : isMainLayoutCollapsed
                ? "left-[66px]"
                : "left-[240px]"
          } top-1/2 -translate-y-1/2 z-40`}
          onClick={() => {
            onChangeCollapsed(!collapsed);
          }}
          style={{
            width: `${collapsed ? 40 : width || DEFAULT_WIDTH}px`,
          }}
        >
          <p className="text-dark_night_sky cursor-pointer transform -rotate-90 whitespace-nowrap">
            Preview
          </p>
        </div>
      )}
      <div
        className={`${isStatic ? "" : "fixed"} flex ${
          highlightRight
            ? "right-0"
            : `left-[${isMainLayoutCollapsed ? 66 : 244}px]`
        } flex-col sm:gap-[24px] ${collapsed ? "w-[40px]" : `w-[${width || DEFAULT_WIDTH}px]`}`}
        style={{
          height: "100%",
          width: `${collapsed ? 40 : width || DEFAULT_WIDTH}px`,
        }}
      >
        <div
          className={`overflow-hidden relative ${
            collapsed ? "w-[40px]" : "w-full"
          }`}
          style={{
            height: height || "100vh",
          }}
        >
          <ContentPreview
            content={content}
            isPaused={isPaused}
            videoRef={videoRef}
            onTogglePlayPause={togglePlayPause}
            onVideoEnded={nextSlide}
            key={currentSlide}
            showVideoControls={showVideoControls}
          />
          {limitedData.length > 1 ? (
            <div
              className={`absolute z-10 bottom-0 w-full left-0 text-white backdrop-blur`}
            >
              <ContentPreviewControls
                prevSlide={prevSlide}
                nextSlide={nextSlide}
                onSlideChange={onSlideChange}
                currentSlide={currentSlide}
                isPaused={isPaused}
                data={limitedData}
                videoRef={videoRef}
                label={label}
              />
            </div>
          ) : null}
        </div>
      </div>
      {onChangeCollapsed ? (
        <>
          {highlightRight ? (
            <div
              className={`fixed z-30 top-1/2 ${
                collapsed ? "right-[260px]" : "right-[590px]"
              } transform -translate-y-1/2 transition-transform duration-300`}
            >
              <button
                onClick={() => onChangeCollapsed(!collapsed)}
                className="p-4 rounded-full bg-light_red relative"
              >
                {collapsed ? (
                  <FIcon icon="fancy-arrow-left" color="#000F45" size={18} />
                ) : (
                  <FIcon icon="fancy-arrow-right" color="#000F45" size={18} />
                )}
              </button>
            </div>
          ) : (
            <div
              className={`fixed z-30 top-1/2 ${
                collapsed
                  ? isMainLayoutCollapsed
                    ? "left-[82px]"
                    : "left-[260px]"
                  : isMainLayoutCollapsed
                    ? "left-[412px]"
                    : "left-[590px]"
              } transform -translate-y-1/2 transition-transform duration-300`}
            >
              <button
                onClick={() => onChangeCollapsed(!collapsed)}
                className="p-4 rounded-full bg-light_red relative"
              >
                {collapsed ? (
                  <FIcon icon="fancy-arrow-right" color="#000F45" size={18} />
                ) : (
                  <FIcon icon="fancy-arrow-left" color="#000F45" size={18} />
                )}
              </button>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default ContentHighlight;
