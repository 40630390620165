import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FButton from "@components/FButton";

import { BulletinContext } from "@contexts/index";
import useAuth from "@hooks/useAuth";
import { Role } from "@constants/roles";
import { isEmpty } from "lodash";

export default function BulletinPreviewPage() {
  const { bulletin_id } = useParams();
  const navigate = useNavigate();

  const { hasRole } = useAuth();

  const { bulletin } = useContext(BulletinContext);

  const handleContinue = () => {
    navigate(`/bulletins/${bulletin_id}/preview/questions`);
  };

  const handleNotInterested = () => {
    navigate(
      hasRole(Role.INFLUENCER) ? "/bulletins" : `/bulletins/${bulletin_id}`
    );
  };

  const hasAnswered = !isEmpty(bulletin.responses);

  return (
    <>
      <p className="text-sm capitalize mb-4 opacity-50">{bulletin.category}</p>
      <h1 className="text-4xl font-bold mb-6">{bulletin.name}</h1>
      <div
        className="text-lg mb-10 bulletin-content"
        dangerouslySetInnerHTML={{ __html: bulletin.description }}
      />
      <div className="flex flex-row gap-4 justify-center">
        {!hasAnswered ? (
          <FButton label="Not Interested" onClick={handleNotInterested} />
        ) : null}
        <FButton
          label={hasAnswered ? "View my answers" : "Continue"}
          primary
          onClick={handleContinue}
        />
      </div>
    </>
  );
}
