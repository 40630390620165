import { useState, useEffect } from "react";

interface LazyRouteContentProps {
  children: React.ReactNode;
  isActive: boolean;
}

export const LazyRouteContent: React.FC<LazyRouteContentProps> = ({
  isActive,
  children,
}) => {
  const [hasBeenMounted, setHasBeenMounted] = useState(false);

  useEffect(() => {
    if (isActive && !hasBeenMounted) {
      setHasBeenMounted(true);
    }
  }, [isActive, hasBeenMounted]);

  // Don't render anything until first activation
  if (!hasBeenMounted && !isActive) {
    return null;
  }

  return (
    <div
      className="h-full w-full"
      style={{ display: isActive ? "block" : "none" }}
    >
      {children}
    </div>
  );
};
