import React, { useEffect, useState, useRef } from "react";
import BasicCard from "@components/Cards/BasicCard";
import FIcon from "@components/FIcon";
import { Campaign, Participant, ParticipantStatus } from "@types";

import { updateParticipantGroup } from "@api/Campaign/CampaignParticipantGroups";
import toast from "react-hot-toast";

import RationaleEditor from "@components/review/RationaleEditor";
import { decorationColor } from "@utils/cardUtilities";
import { capitalize } from "lodash";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

interface CarouselViewProps {
  campaign: Campaign;
  currentState: string;
  selectedGroupId: number;
  transitionDirection: string;
  cardData: any;
  selectedItems: number[];
  onChangeSelectedItems: (id: number[]) => void;
  onSelectedCreator: (participant: Participant) => void;
  onUpdateCampaign: (campaign: Partial<Campaign>) => void;
}

export default function CarouselView({
  campaign,
  cardData,
  currentState,
  transitionDirection,
  selectedGroupId,
  selectedItems,
  onChangeSelectedItems,
  onSelectedCreator,
  onUpdateCampaign,
}: CarouselViewProps) {
  const scrollContainerRef = useRef(null);
  const [cardOpacities, setCardOpacities] = useState([]);
  const [textAnimation, setTextAnimation] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const { can } = useAuth();

  const { participant_groups = [] } = campaign;

  const selectedGroup = participant_groups.find(
    ({ id }) => id === selectedGroupId
  );

  const handleSubmitDescription = async (description) => {
    if (!description) {
      return;
    }

    setIsSaving(true);

    try {
      await updateParticipantGroup(selectedGroup.id, {
        creator_review_description: description,
      });

      const updatedParticipantGroups = participant_groups.map((p) =>
        p.id === selectedGroup.id
          ? {
              ...p,
              creator_review_description: description,
            }
          : p
      );

      onUpdateCampaign({ participant_groups: updatedParticipantGroups });

      toast.success("Updates saved");
    } catch (error) {
      toast.error("Error submitting description");

      console.error("Failed to submit comment:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleToggleItemSelection = (id) => {
    let newSelectedItems = selectedItems || [];

    if (selectedItems.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedItems, id];
    }

    onChangeSelectedItems(newSelectedItems);
  };

  const updateCardOpacities = () => {
    if (!scrollContainerRef.current) {
      return;
    }

    const container = scrollContainerRef.current;
    const newOpacities = cardData.map((item, index) => {
      const cardElement = container.querySelector(`.card-${index}`);
      if (!cardElement) return 0.5; // Set minimum opacity when card is not found

      const cardRect = cardElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      // Calculate the center of the card
      const cardCenter = cardRect.left + cardRect.width / 2;
      // Calculate the center of the container
      const containerCenter = containerRect.left + containerRect.width / 2;

      // Determine the distance from the center of the container
      const distanceFromCenter = Math.abs(cardCenter - containerCenter);
      // Normalize the distance based on container width
      const normalizedDistance = distanceFromCenter / (containerRect.width / 2);

      // Calculate opacity: Closer to center means higher opacity
      const opacity = Math.max(1 - normalizedDistance, 0.5); // Ensure minimum opacity of 0.5
      return opacity;
    });

    setCardOpacities(newOpacities);
  };

  useEffect(() => {
    setTextAnimation(`fade-in-${transitionDirection}`);
    const timer = setTimeout(() => setTextAnimation(""), 500); // Reset animation class after it completes
    return () => clearTimeout(timer);
  }, [currentState, selectedGroup, transitionDirection]);

  useEffect(() => {
    const handleScroll = () => {
      updateCardOpacities();
    };

    const handleResize = () => {
      updateCardOpacities();
    };

    window.addEventListener("resize", handleResize);
    scrollContainerRef.current?.addEventListener("scroll", handleScroll);

    // Initial update
    updateCardOpacities();

    return () => {
      window.removeEventListener("resize", handleResize);
      scrollContainerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [cardData]); // Depend on card data to re-attach listeners if it changes

  const { creator_review_description } = selectedGroup || {};

  return (
    <div className="participant-review-content flex flex-col justify-between">
      <div className="h-full">
        <div
          className={`flex overflow-x-auto w-full gap-4 h-screen py-[40px] carousel-sizing ${textAnimation}`}
          ref={scrollContainerRef}
        >
          <div className={`group-intro-sizing flex items-center h-full`}>
            {selectedGroup ? (
              <RationaleEditor
                description={creator_review_description}
                id={`creatorReviewDescription-${selectedGroup?.id}`}
                isSaving={isSaving}
                onSubmit={handleSubmitDescription}
              />
            ) : null}
          </div>
          {cardData.length === 0 ? (
            <div className="flex h-full w-full items-center justify-center">
              <div className="h-full w-full carousel-card-sizing bg-highlight_red rounded-xl bg-gray-100 flex items-center justify-center">
                <div className="flex w-[200px] h-[200px] rounded-full items-center justify-center p-10 bg-light_red">
                  <FIcon
                    icon="audience"
                    className=""
                    size={100}
                    color="#F9E9E7"
                  />
                </div>
              </div>
            </div>
          ) : (
            cardData.map((item, index) => {
              const { creator } = item;

              const { firstName, lastName } = creator || {};

              return (
                <div
                  key={index}
                  className={`card-${index} cursor-pointer`}
                  style={{
                    opacity: cardOpacities[index] || 0.5,
                  }}
                >
                  <BasicCard
                    {...item}
                    creator={creator}
                    full_name={`${firstName} ${lastName}`}
                    statusBadge={
                      <span
                        className={`absolute top-[15px] left-[44px] px-3 py-1 text-xs rounded-full text-white ${decorationColor(
                          item.status
                        )}`}
                      >
                        {item.status === ParticipantStatus.REVIEW_PENDING
                          ? "Pending Review"
                          : (item.status || "")
                              .split("_")
                              .map((part) => capitalize(part))
                              .join(" ")}
                      </span>
                    }
                    status={null}
                    overall_followers={creator?.overall_followers || 0}
                    cardType="individual"
                    className={`carousel-card-sizing rounded-xl card-visible`}
                    onCheckBoxClick={
                      can(Permission.UPDATE_PARTICIPANTS)
                        ? () => handleToggleItemSelection(item.id)
                        : null
                    }
                    checkBoxClickList={selectedItems}
                    onCardClick={(item) => onSelectedCreator(item)}
                  />
                </div>
              );
            })
          )}
          {cardData.length > 0 && (
            <div className="group-intro-sizing h-full">
              <div className="flex h-full flex-col gap-4 group-intro-sizing-end items-left justify-center pr-[20px]"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
