import React, { useState, useContext } from "react";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";
import { toast } from "react-hot-toast";
import SelectGroupsTable from "@components/Creator/SelectGroupsTable";

import apiClient from "@apiClient";
import { useNavigate } from "react-router-dom";
import { GroupContext } from "@contexts/index";
import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { GroupData } from "@apiTypes";

const { combine } = apiClient.groups;

interface Props {
  onClose: () => void;
}

const CombineGroupsModal = ({ onClose }: Props) => {
  const navigate = useNavigate();
  const { group } = useContext(GroupContext);
  const { trackEvent } = useContext(MixpanelContext);

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroups, setSelectedGroups] = useState<GroupData[]>([group]);

  const handleSubmit = async () => {
    if (!newGroupName) {
      setError("New group name is required");
      return;
    }

    setWaiting(true);
    setError(null);

    try {
      const groupIds = selectedGroups.map((group) => group.id);
      const response = await combine(newGroupName, groupIds);

      // Track group combined event
      trackEvent(EVENTS.GROUP.COMBINED, {
        new_group_id: response.data.id,
        new_group_name: newGroupName,
        source_group_ids: groupIds,
        source_group_count: groupIds.length,
      });

      navigate(`/groups/${response.data?.group?.id}`);
      onClose();
    } catch (error) {
      console.error("Failed to create new group:", error);
      setError("Failed to create new group");
    } finally {
      setWaiting(false);
    }
  };

  return (
    <SelectEntitiesModal
      title="Combine Groups"
      onClose={onClose}
      error={error}
      headerContent={
        <FInput
          label="New Group Name"
          height="40px"
          className="w-full"
          onChange={(value) => setNewGroupName(value)}
          required
        />
      }
      actionButton={
        <FButton
          loading={waiting}
          onClick={handleSubmit}
          primary
          label="Combine Selected Groups"
          width="100%"
          height="40px"
        />
      }
    >
      <div style={{ height: "calc(100% - 70px)" }}>
        <SelectGroupsTable
          selectedItems={selectedGroups}
          onChangeSelectedItems={(groups: GroupData[]) =>
            setSelectedGroups(groups)
          }
        />
      </div>
    </SelectEntitiesModal>
  );
};

export default CombineGroupsModal;
