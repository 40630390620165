import React, { useEffect, useState, useContext } from "react";

import { toast } from "react-hot-toast";

import { FCheckbox, FInput } from "@components/FInputs";

import { Group } from "@types";

import Modal from "@components/Modals/Modal";
import { createGroup, updateGroup } from "@api/Groups/Groups";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

interface Props {
  entity?: Group;
  onClose: () => void;
  onUpdated?: (group: Group) => void;
  onCreated?: (group: Group) => void;
}

export default function CreateOrUpdateGroupModal({
  onClose,
  onUpdated,
  onCreated,
  entity,
}: Props) {
  const { user, can } = useAuth();
  const { trackEvent } = useContext(MixpanelContext);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<Partial<Group>>({
    name: "",
    archived: false,
    featured: false,
  });

  useEffect(() => {
    if (entity) {
      setValues((values) => ({ ...values, ...entity }));
    }
  }, [entity]);

  const handleUpdate = (updates: Partial<Group>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const { name, featured } = values;

    if (!name) {
      return;
    }

    setLoading(true);

    try {
      if (entity?.id) {
        const response = await updateGroup(entity.id, values);

        onUpdated(response.data);

        // Track group update
        trackEvent(EVENTS.GROUP.UPDATED, {
          group_id: entity.id,
          group_name: response.data.name,
          is_featured: !!response.data.featured,
          is_archived: !!response.data.archived,
          creator_count: response.data.creators_count || 0,
        });

        toast.success("Group Updated");
      } else {
        const response = await createGroup({
          name,
          brand_id: user.brand_id,
          user_id: user.user_id,
          featured,
        });

        onCreated(response.data);

        // Track group creation
        trackEvent(EVENTS.GROUP.CREATED, {
          group_id: response.data.id,
          group_name: response.data.name,
          is_featured: !!response.data.featured,
          brand_id: user.brand_id,
        });

        toast.success("Group Created");
      }
    } catch (e) {
      toast.error(`Error ${entity?.id ? "updating" : "creating"} group`);

      // Track error
      trackEvent(EVENTS.ERROR.API_ERROR, {
        error_type: entity?.id ? "group_update" : "group_creation",
        error_message:
          e?.response?.data?.message ||
          `Unknown error ${entity?.id ? "updating" : "creating"} group`,
        group_id: entity?.id,
        group_name: values.name,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="w-[500px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title={`${entity?.id ? "Edit" : "Add"} Group`}
      waiting={loading}
    >
      <div className="flex flex-col gap-6 m-auto w-full">
        <FInput
          name="name"
          onChange={(value) => handleUpdate({ name: value })}
          label="Group name"
          value={values.name}
          required
          validate={isSubmitted}
          width="100%"
        />
        {entity?.id ? (
          <FCheckbox
            label="Archived"
            id="group_modal-archived"
            onChange={(isChecked) =>
              handleUpdate({
                archived: isChecked,
              })
            }
            checked={values.archived}
          />
        ) : null}
        {can(Permission.FEATURE_GROUPS) ? (
          <FCheckbox
            label="Featured"
            id="group_modal-featured"
            onChange={(isChecked) =>
              handleUpdate({
                featured: isChecked,
              })
            }
            checked={values.featured}
          />
        ) : null}
      </div>
    </Modal>
  );
}
