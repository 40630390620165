import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { BulletinContext } from "@contexts/index";
import { listBulletinQuestions } from "@api/Bulletins/BulletinQuestions";
import { last } from "lodash";

const NAV_ITEMS = [
  {
    label: "Content",
    value: "content",
  },
  {
    label: "Questions",
    value: "questions",
  },
  {
    label: "Invitation Email",
    value: "invitation-email",
  },
];

export default function BulletinSetup() {
  const navigate = useNavigate();
  const location = useLocation();
  const { bulletin } = useContext(BulletinContext);
  const [questionCount, setQuestionCount] = useState(0);
  const [activeTab, setActiveTab] = useState("content");

  useEffect(() => {
    const fetchQuestionCount = async () => {
      if (bulletin?.id) {
        try {
          const response = await listBulletinQuestions({
            bulletin_id: bulletin.id,
          });
          setQuestionCount(Array.isArray(response) ? response.length : 0);
        } catch (error) {
          console.error("Error fetching questions:", error);
        }
      }
    };
    fetchQuestionCount();
  }, [bulletin?.id]);

  useEffect(() => {
    const path = last(location.pathname.split("/"));
    // Update activeTab based on current path
    setActiveTab(path);
  }, [location]);

  const handleTabChange = (tab: string) => navigate(tab);

  return (
    <div className="flex flex-col">
      <div className="py-3 bg-light_red  border-b border-light_border px-4 flex flex-row gap-[40px]">
        {NAV_ITEMS.map(({ value, label }) => (
          <button
            className={`text-[16px] ${
              activeTab === value
                ? "font-bold text-black"
                : "opacity-30 hover:opacity-100"
            } ${value === "questions" ? "flex items-center gap-2" : ""}`}
            onClick={() => handleTabChange(value)}
          >
            {label}
            {value === "questions" ? (
              <p className="text-[16px] opacity-50">({questionCount})</p>
            ) : null}
          </button>
        ))}
      </div>

      <div className="py-8">
        <Outlet />
      </div>
    </div>
  );
}
