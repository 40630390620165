import React from "react";
import { SubscriptionPlan, SUBSCRIPTION_PLANS } from "./types";
import FButton from "@components/FButton";

interface SubscriptionPlansProps {
  onSelectPlan: (priceId: string) => void;
}

const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  onSelectPlan,
}) => {
  return (
    <div className="p-6 pt-0">
      <p className="mb-4">Upgrade to unlock full access:</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {SUBSCRIPTION_PLANS.map((plan) => (
          <div
            key={plan.id}
            className="bg-white rounded-lg p-6 border border-gray-200"
          >
            <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
            <p className="text-gray-600 mb-4">{plan.description}</p>
            <p className="text-2xl font-bold mb-4">
              ${plan.amount.toLocaleString()}/{plan.interval}
            </p>
            <FButton
              onClick={() => onSelectPlan(plan.priceId)}
              label={`Subscribe ${
                plan.interval === "month" ? "Monthly" : "Yearly"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
