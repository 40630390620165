import { createParticipantOffer } from "@api/ParticipantBriefs/ParticipantOffers";
import FButton from "@components/FButton";

import { FInput } from "@components/FInputs";
import Modal from "@components/Modals/Modal";

import { useState } from "react";
import toast from "react-hot-toast";

interface UpdateOfferModalProps {
  onClose: () => void;
  offerRate: number;
  participantId: number;
  onUpdated: (newOffer: number) => void;
}

const UpdateOfferModal = ({
  offerRate,
  onClose,
  participantId,
  onUpdated,
}: UpdateOfferModalProps) => {
  const [pendingOfferRate, setPendingOfferRate] = useState(`${offerRate || 0}`);

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    setIsSaving(true);

    const offerRateAsNumber = parseFloat(pendingOfferRate);

    try {
      await createParticipantOffer(participantId, offerRateAsNumber);

      onUpdated(offerRateAsNumber);

      toast.success(`New offer created`);
    } catch {
      toast.error("Error creating offer");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      isOpen
      className="w-[540px]"
      onClose={onClose}
      title="New Offer Rate"
      hasForm
    >
      <div className="flex flex-col gap-4 h-[100px]">
        <FInput
          width="100%"
          value={`${pendingOfferRate}`}
          onChange={(value) => setPendingOfferRate(value)}
          prefix="$"
          type="number"
        />
      </div>
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 pt-6 border-t border-light_border">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={handleSubmit}
          label="Save"
          loading={isSaving}
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </Modal>
  );
};

export default UpdateOfferModal;
