import React, { useEffect, useState } from "react";

import LoadingSpinner from "@components/LoadingSpinner";
import { Campaign } from "@types";

import BriefPreviewContent from "./BriefPreviewContent";
import { getParticipantRecord } from "@api/creator_view";
import useAuth from "@hooks/useAuth";

interface CreatorOfferProps {
  participant_id: number;
}

export default function CreatorOffer({ participant_id }: CreatorOfferProps) {
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign>(null);

  const { user } = useAuth();

  const { first_name: firstName, last_name: lastName } = user;

  useEffect(() => {
    const loadCampaign = async () => {
      const response = await getParticipantRecord(participant_id);

      const { brief, campaign, name, status } = response.data;

      const participant = {
        creator: { firstName: name, full_name: `${firstName} ${lastName}` },
        id: participant_id,
        status,
      };

      setCampaign({ ...campaign, brief, participants: [participant] });

      setLoading(false);
    };

    loadCampaign();
  }, []);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  return (
    <BriefPreviewContent
      backLink="/campaigns"
      campaign={campaign}
      participant_id={participant_id}
    />
  );
}
