import * as React from "react";

import FormConstructor from "@components/FormConstructor";

const GenerateFormFields: React.FC<{
  data: any;
  rootURL: string;
  tableColumns: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
  entityId: string;
  formRef: React.RefObject<HTMLFormElement>;
  initialData: any;
  loading: boolean;
}> = ({ data, tableColumns, onClose, onSubmit, loading, initialData }) => {
  const formValues = React.useMemo(() => {
    return tableColumns.map((column) => {
      const field: any = {
        id: column.hasParent ? `${column.hasParent}.${column.key}` : column.key,
        name: column.hasParent
          ? `${column.hasParent}.${column.key}`
          : column.key,
        label: column.label,
        type: "text", // default type
        required: false, // default required
      };

      if (column.isFirstColumn) {
        field.type = "text";
      } else if (column.isDate) {
        field.type = "date";
      } else if (column.isMoney || column.isNumber) {
        field.type = "number";
      } else if (column.isImage) {
        field.type = "text";
      } else if (column.isStateBubble) {
        let uniqueValues = Array.from(
          new Set(data.map((item: any) => item[column.key]))
        );
        const isBoolean = uniqueValues.every(
          (value) => typeof value === "boolean"
        );

        if (isBoolean) {
          field.type = "select";
          field.options = [true, false];
        } else {
          field.type = "select";
          if (column.options) {
            uniqueValues = Array.from(
              new Set([...uniqueValues, ...column.options])
            );
          }
          field.options = uniqueValues;
        }
      }

      return field;
    });
  }, [tableColumns, data]);

  return (
    <FormConstructor
      initialData={initialData}
      formValues={formValues}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export default GenerateFormFields;
