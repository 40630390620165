import React, { useEffect } from "react";
import FIcon from "@components/FIcon";
import { Profile } from "@types";
import { formatLargeNumber } from "@utils/number_utilities";

interface PlatformFilterProps {
  profile: Profile;
  onSelectedPlatform: (platformName: string) => void;
  selectedPlatform: string;
}

const PlatformFilter = ({
  profile,
  onSelectedPlatform,
  selectedPlatform,
}: PlatformFilterProps) => {
  // Filter platforms with followers > 0, then sort them by followers (descending)
  const sortedPlatforms = (profile.platforms || [])
    .filter((platform) => platform.followers > 0)
    .sort((a, b) => b.followers - a.followers);

  // If no platform is selected, choose the first one (the one with the highest followers)
  useEffect(() => {
    if (!selectedPlatform && sortedPlatforms.length > 0) {
      onSelectedPlatform(sortedPlatforms[0].name);
    }
  }, [selectedPlatform, sortedPlatforms, onSelectedPlatform]);

  return (
    <div className="flex items-center gap-4">
      {sortedPlatforms.map((platform, index) => {
        const { followers, name } = platform;
        const isSelected = name === selectedPlatform;

        return (
          <div
            key={index}
            className={`cursor-pointer hover:underline flex gap-[6px] items-center rounded-full py-1 px-3 ${
              isSelected ? "bg-black text-white hover:no-underline" : ""
            }`}
            onClick={() => onSelectedPlatform(name)}
          >
            <FIcon
              icon={name}
              size={16}
              color={isSelected ? "#FFFFFF" : "rgba(0,0,0,0.5)"}
              className="-mt-[2px]"
            />
            <p className="text-sm">{formatLargeNumber(followers)}</p>
          </div>
        );
      })}
    </div>
  );
};

export default PlatformFilter;
