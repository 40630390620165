import FDropdown from "@components/FDropdown";
import { FInput, FTextarea } from "@components/FInputs";
import FMultiSelect from "@components/FMultiSelect";

import { QuestionnaireQuestion, FieldType } from "types/questionnaire";

const MAX_TEXTAREA_LENGTH = 2000;

interface QuestionProps {
  answer: string | string[];
  question: QuestionnaireQuestion;
  onUpdateAnswer: (answer: string | string[]) => void;
  placeholder?: string;
  validate?: boolean;
  isDisabled?: boolean;
}

const Question = ({
  question,
  answer,
  onUpdateAnswer,
  placeholder,
  validate,
  isDisabled,
}: QuestionProps) => {
  const { question: questionLabel, required, data, field_type } = question;

  const commonProps = {
    label: questionLabel,
    required,
    validate,
    disabled: isDisabled,
    width: "100%",
  };

  if (field_type === FieldType.TEXT) {
    return (
      <FInput
        onChange={onUpdateAnswer}
        placeholder={placeholder}
        value={`${answer || ""}`}
        {...commonProps}
      />
    );
  }

  if (field_type === FieldType.TEXTAREA) {
    const charactersLeft = MAX_TEXTAREA_LENGTH - (answer?.length || 0);

    return (
      <div className="flex flex-col gap-2">
        <FTextarea
          maxLength={MAX_TEXTAREA_LENGTH}
          onChange={onUpdateAnswer}
          rows={4}
          value={`${answer || ""}`}
          {...commonProps}
        />
        <span
          className={`self-end text-sm ${charactersLeft < 100 ? "text-red" : ""}`}
        >
          {charactersLeft} characters left
        </span>
      </div>
    );
  }

  const dropdownProps = {
    ...commonProps,
    options: data
      ? data.options.map((value) => ({
          value,
          label: value,
        }))
      : [],
  };

  if (field_type === FieldType.SINGLE_SELECT) {
    return (
      <FDropdown
        hasNoDefault
        selectedValue={answer as string}
        onChange={(value) => onUpdateAnswer(value as string)}
        required={required}
        {...dropdownProps}
      />
    );
  }

  if (field_type === FieldType.MULTI_SELECT) {
    return (
      <FMultiSelect
        selected={answer as string[]}
        onChange={(value) => onUpdateAnswer(value as string[])}
        required={required}
        {...dropdownProps}
      />
    );
  }
};

export default Question;
