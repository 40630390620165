import moment from "moment";
import FRangePicker from "@components/FDatePicker/FRangePicker";
import LocationFilter from "./LocationFilter";
import ModalSection from "./ModalSection";

import { AdvancedSearchParams } from "@pages/search/types";
import FormField from "@components/Form/FormField";
import { FRadioGroup } from "@components/FInputs";

interface ContentDetailsProps {
  advancedFilters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const ContentDetails = ({
  advancedFilters,
  onFiltersUpdate,
}: ContentDetailsProps) => {
  const { date_from, date_to } = advancedFilters;

  const selectedContentType =
    advancedFilters.sponsored === true
      ? "sponsored"
      : advancedFilters.sponsored === false
        ? "organic"
        : "all";

  return (
    <ModalSection icon="content" title="Content Details">
      <FormField label="Date Posted Range" width="700px">
        <FRangePicker
          maxDate={moment().format()}
          value={{
            from: date_from,
            to: date_to,
          }}
          onChange={({ from, to }) =>
            onFiltersUpdate({
              date_from: from,
              date_to: to,
            })
          }
        />
      </FormField>
      <div className="mb-8" />

      <div className="flex justify-between">
        <div className="w-1/2 pr-4">
          <LocationFilter
            filters={advancedFilters}
            onFiltersUpdate={onFiltersUpdate}
          />
        </div>
        <div className="w-1/2 pl-4">
          <h3 className="mb-2">Content Type</h3>
          <FRadioGroup
            direction="column"
            name="content-type"
            options={[
              { label: "Display All", value: "all" },
              { label: "Sponsored Posts", value: "sponsored" },
              { label: "Organic Posts", value: "organic" },
            ]}
            value={selectedContentType}
            onChange={(selectedValue) => {
              onFiltersUpdate({
                sponsored:
                  selectedValue === "all"
                    ? undefined
                    : selectedValue === "sponsored",
              });
            }}
          />
        </div>
      </div>
    </ModalSection>
  );
};

export default ContentDetails;
