import { useState } from "react";
import OnboardingStepContainer from "../components/OnboardingStepContainer";
import SocialMediaConnections from "./SocialMediaConnections";

const ConnectSocials = () => {
  const [connections, setConnections] = useState({
    instagram: null,
    tiktok: null,
    youtube: null,
  });

  return (
    <OnboardingStepContainer
      title="Connect your socials"
      description="One connection required. The more connections, the better the insights"
      currentStep={1}
      stepsCount={3}
    >
      <SocialMediaConnections
        connections={connections}
        source="onboarding"
        onUpdateConnections={(updates) =>
          setConnections({ ...connections, ...updates })
        }
      />
    </OnboardingStepContainer>
  );
};

export default ConnectSocials;
