import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { CampaignContext } from "@contexts/index";

import { Participant, ParticipantStatus } from "@types";

import { getOverviewData } from "@api/Profile";

import { searchPosts } from "@api/Search";

import Rationale from "./fragments/Rationale";
import { Creator } from "@apiTypes";
import {
  getParticipantSampleContent,
  updateCampaignParticipant,
} from "@api/Campaign/CampaignParticipants";

import toast from "react-hot-toast";
import CreatorReviewModalContainer, {
  CreatorForReview,
} from "../../../../components/Creator/creator-review-modal/CreatorReviewModalContainer";
import { capitalize, isEmpty, omit, partition } from "lodash";
import ResultsGrid from "@components/ResultsGrid";
import BlankState from "@components/BlankState";
import FSubNav from "@components/FSubNav";

import { LazyRouteContent } from "@components/navigation/LazyRouteContent";
import { CREATOR_REVIEW_STATUSES } from "../constants";
import CreatorReviewComments from "./fragments/CreatorReviewComments";

const getPlatformFromPermalink = (permalink: string) => {
  if (!permalink) {
    return null;
  }

  if (permalink.includes("instagram.com")) {
    return "instagram";
  }

  if (permalink.includes("tiktok.com")) {
    return "tiktok";
  }

  if (permalink.includes("youtube.com")) {
    return "youtube";
  }
};

const getContentForPermalinks = (permalinkContent) =>
  permalinkContent.map((item) => ({
    ...item,
    permalink_url: item.permalink,
  }));

const TABS = [
  {
    value: "content",
  },
  {
    value: "comments",
  },
];

interface CreatorReviewModalProps {
  onClose: () => void;
  onUpdate: (updates: Partial<Participant>) => void;
  stateFilter: ParticipantStatus | null;
  selectedGroup?: number;
}

export default function CreatorReviewModal({
  onClose,
  onUpdate,
  stateFilter,
  selectedGroup,
}: CreatorReviewModalProps) {
  const navigate = useNavigate();

  const { campaign_id, participant_id } = useParams<{
    participant_id: string;
    campaign_id: string;
  }>();

  const { campaign } = useContext(CampaignContext);

  const { participants: allParticipants } = campaign;

  const [userDetails, setUserDetails] = useState<Creator>(null);
  const [sampleContent, setSampleContent] = useState([]);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [loading, setLoading] = useState(true);

  const [activeContentIndex, setActiveContentIndex] = useState(0);

  const [activeTab, setActiveTab] = useState(TABS[0].value);

  const participants = allParticipants.filter(
    ({ participant_group_id, status }) =>
      (!stateFilter || status === stateFilter) &&
      (!selectedGroup || `${selectedGroup}` === `${participant_group_id}`)
  );

  const currentIndex = participants.findIndex(
    (p) => p.id === Number(participant_id)
  );

  const currentParticipant = participants[currentIndex];

  const { creator } = currentParticipant;

  const { id, firstName, lastName, slug } = creator;

  const handleUpdateStatus = async (status: string) => {
    try {
      await updateCampaignParticipant(participant_id, {
        status,
      });

      onUpdate({
        status,
      });

      toast.success("Status updated");

      handleGoToIndex((currentIndex + 1) % participants.length);
    } catch {
      toast.error("Error updating status");
    }
  };

  const handleGoToIndex = (index: number) => {
    handleGoToParticipant(participants[index]);
  };

  const handleGoToParticipant = (participant: Participant) =>
    navigate(`/campaigns/${campaign_id}/participant-review/${participant.id}`);

  useEffect(() => {
    setLoading(true);
    setUserDetails(null);

    const loadData = async () => {
      const topContentResponse = await searchPosts({
        creator_ids: [id],
        page_size: 10,
        post_type: "video",
      });

      const allSampleContent = [...topContentResponse.data.results];

      const [permalinkContent, platformContent] = partition(
        allSampleContent,
        "isPermalink"
      );

      /*  setSampleContent([
        ...getContentForPermalinks(permalinkContent),
        ...platformContent,
      ]); */

      const overviewResponse = await getOverviewData(slug);

      setUserDetails(overviewResponse.data);

      try {
        const response = await getParticipantSampleContent(participant_id);

        const { contents } = response.data;

        setSampleContent(
          contents.map((content) => {
            const { permalink, post_id } = content;

            if (post_id) {
              return content;
            }

            return {
              ...content,
              permalink_url: permalink,
              platform: getPlatformFromPermalink(permalink),
              video_url: permalink,
            };
          })
        );

        if (isEmpty(contents)) {
          setIsCollapsed(true);
        }
      } catch {
        setIsCollapsed(true);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [participant_id, id, slug]);

  const profile = {
    ...currentParticipant,
    ...omit(creator, "id"),
    first_name: firstName,
    last_name: lastName,
    platforms: userDetails?.platforms || [],
  };

  return (
    <CreatorReviewModalContainer
      profile={profile}
      sampleContent={sampleContent}
      currentIndex={currentIndex}
      activeContentIndex={activeContentIndex}
      setActiveContentIndex={setActiveContentIndex}
      statuses={CREATOR_REVIEW_STATUSES}
      onUpdateStatus={handleUpdateStatus}
      onGoToIndex={handleGoToIndex}
      participants={participants as unknown as CreatorForReview[]}
      onClose={onClose}
      isCollapsed={isCollapsed}
      onChangeCollapsed={setIsCollapsed}
    >
      <FSubNav
        className=""
        items={TABS.map((item) => ({
          ...item,
          label: capitalize(item.value),
          active: item.value === activeTab,
        }))}
        onChange={(value) => setActiveTab(value as string)}
      />
      <LazyRouteContent isActive={activeTab === "content"}>
        <>
          <div className="mt-3" />
          <Rationale
            profile={profile}
            onUpdate={(description) =>
              onUpdate({
                creator_review_description: description,
              })
            }
          />
          <div className="border-t border-light_border mt-4 mb-9" />
          {!isEmpty(sampleContent) ? (
            <ResultsGrid
              cardData={sampleContent}
              cardType="content"
              onCardClick={(content) =>
                setActiveContentIndex(
                  sampleContent.findIndex((item) => item.id === content.id)
                )
              }
              highlightedId={sampleContent[activeContentIndex].id}
            />
          ) : (
            !loading && (
              <BlankState
                title="No sample content found"
                subtitle="Try selecting items from creator's content in the profile section or add permalinks"
                icon="content"
                actionLabel="Add Sample Content"
                onActionClick={() =>
                  navigate(
                    `/campaigns/${campaign_id}/participants/${participant_id}/content?showSampleContent=true`
                  )
                }
              />
            )
          )}
        </>
      </LazyRouteContent>
      <LazyRouteContent isActive={activeTab === "comments"}>
        <CreatorReviewComments />
      </LazyRouteContent>
    </CreatorReviewModalContainer>
  );
}
