import React, { useContext } from "react";
import FDropdown from "./FDropdown";
import { uniqBy } from "lodash";
import { ContentReviewContent } from "types/content-review";
import { Campaign } from "@types";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { useParams } from "react-router-dom";

interface FilterDropdownsProps {
  content: ContentReviewContent[];
  campaign: Campaign;
  selectedFilters: {
    stage: string;
    deliverable: string;
    participant: string;
    mediaType: string;
  };
  setSelectedFilters: (filters: {
    stage: string;
    deliverable: string;
    participant: string;
    mediaType: string;
  }) => void;
  className?: string;
  disabled?: boolean;
}

const getParticipantOptions = (participants: any[], campaign: Campaign) => {
  const { participants: campaignParticipants } = campaign;

  return participants.map((participant) => {
    const { fohr_campaigns_participant_id } = participant;

    const campaignParticipant = campaignParticipants.find(
      ({ id }) => id === fohr_campaigns_participant_id
    );

    const { creator } = campaignParticipant;
    const { firstName, lastName } = creator;

    return {
      value: fohr_campaigns_participant_id,
      label: `${firstName} ${lastName}`,
    };
  });
};

const getMediaTypes = (deliverables: any[]) => {
  const mediaTypes = deliverables.map(({ media_type }) => media_type);
  return uniqBy(mediaTypes, (type) => type).map((value) => ({
    value,
    label: value.charAt(0).toUpperCase() + value.slice(1),
  }));
};

const getRounds = (content: ContentReviewContent[]) => {
  const roundNumbers = content.reduce((result, item) => {
    const { rounds } = item;
    return [...result, ...rounds.map(({ stage }) => stage)];
  }, []);

  return uniqBy(roundNumbers, (num) => num).map((stage) => ({
    value: stage,
    label: `R${stage}`,
  }));
};

const FilterDropdowns: React.FC<FilterDropdownsProps> = ({
  content,
  campaign,
  selectedFilters,
  setSelectedFilters,
  className = "",
  disabled = false,
}) => {
  const { trackEvent } = useContext(MixpanelContext);
  const { campaign_id } = useParams();

  const participants = content.reduce((result, { participant }) => {
    if (result.find(({ id }) => id === participant.id)) {
      return result;
    }
    return [...result, participant];
  }, []);

  const deliverables = content.reduce((result, { deliverable }) => {
    if (result.find(({ id }) => id === deliverable.id)) {
      return result;
    }
    return [...result, deliverable];
  }, []);

  const filters = [
    {
      name: "stage",
      options: [{ value: "", label: "All Rounds" }, ...getRounds(content)],
    },
    {
      name: "deliverable",
      options: [
        { value: "", label: "All Deliverables" },
        ...uniqBy(deliverables, "name").map(({ name }) => ({
          value: name,
          label: name,
        })),
      ],
    },
    {
      name: "participant",
      options: [
        { value: "", label: "All Participants" },
        ...getParticipantOptions(participants, campaign),
      ],
    },
    {
      name: "mediaType",
      options: [
        { value: "", label: "All Media Types" },
        ...getMediaTypes(deliverables),
      ],
    },
  ];

  return (
    <div className={`flex items-center gap-4 ${className}`}>
      {filters.map((filter, index) => {
        const { name, options } = filter;
        return (
          <FDropdown
            key={index}
            options={options}
            onChange={(value: string) => {
              // Track filter change event
              if (selectedFilters[name] !== value) {
                trackEvent(EVENTS.CONTENT_REVIEW.SORTED, {
                  filter_name: name,
                  previous_value: selectedFilters[name] || "All",
                  new_value: value || "All",
                  campaign_id: campaign_id,
                  content_count: content.length,
                });
              }

              setSelectedFilters({
                ...selectedFilters,
                [name]: value,
              });
            }}
            selectedValue={selectedFilters[name]}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default FilterDropdowns;
