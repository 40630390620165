import React, { useState } from "react";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";
import Modal from "@components/Modals/Modal";
import { inviteOffPlatformCreator } from "@api/Creators/Creators";
import { toast } from "react-hot-toast";

interface Props {
  creatorId: number;
  isOpen: boolean;
  onClose: () => void;
}

const InviteOffPlatformCreatorModal = ({
  creatorId,
  isOpen,
  onClose,
}: Props) => {
  const [email, setEmail] = useState("");

  const handleInvite = async () => {
    if (!email) {
      return;
    }

    try {
      await inviteOffPlatformCreator(creatorId, email);
      toast.success("Creator invited to Fohr!", { duration: 8000 });
      onClose();
    } catch (error: any) {
      const message =
        error?.response?.data?.error ||
        error?.message ||
        "Something went wrong while sending the invitation.";
      toast.error(`Error sending invitation: ${message}`, { duration: 8000 });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Invite Creator to Fohr"
      className="w-[600px]"
      hasForm
    >
      <p className="text-sm text-gray-600 mb-4">
        This creator isn’t officially on Fohr yet. To collaborate with them,
        invite them to join and verify their account. Once they accept, you’ll
        be able to send campaign offers, bulletins, outreach messages, and more.
      </p>
      <p className="text-sm text-gray-600 mb-4">
        The invitation will guide them through confirming their email, setting a
        password, and logging in to access their profile.
      </p>
      <FInput
        label="Creator's Email"
        width="100%"
        required
        value={email}
        onChange={(value) => setEmail(value)}
      />
      <div className="flex justify-end space-x-2 pt-4 mt-6">
        <FButton type="button" label="Cancel" onClick={onClose} />
        <FButton
          type="submit"
          label="Send Invite"
          onClick={handleInvite}
          primary
        />
      </div>
    </Modal>
  );
};

export default InviteOffPlatformCreatorModal;
