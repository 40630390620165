import React, { useContext } from "react";
import CampaignBrief from "@pages/campaigns/CampaignBrief";
import { ProfileContext } from "@contexts/index";

const ProfileBrief = () => {
  const { collapsed } = useContext(ProfileContext);

  return (
    <div className="pr-[40px]">
      <CampaignBrief collapsed={collapsed} />
    </div>
  );
};

export default ProfileBrief;
